<template>
  <div>
    <div class="row">
      <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
          <h1>Employers</h1>
          <!-- <b-link class="btn green-border-btn" :to="{name:'employer-create'}">Add</b-link> -->
          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark email_tmp_tbl_nav">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Employers</li>
            </ul>
          </nav>
        </div>
        <div class="row">
          <div
            class="col-xl-12 dual_admin_emp_btn col-md-12 mobile_tab_max_width_flex common_margin_bottom_28 mobile_tab_display_block"
          >
            <b-link
              class="btn green-border-btn add_btn_admin_e desktop_margin_left_zero"
              :to="{ name: 'employer-create' }"
              v-if="show('add company')"
              >Add</b-link
            >
            <button
              class="btn green-border-btn add_btn_admin_e employer_export_btn"
              @click="exportCompanies(currentTab)"
              style="background-color: #4c8bf5; color: white"
              v-if="
                currentTab != 'supervisors' &&
                currentTab != 'om' &&
                show('export company')
              "
            >
              Export Companies
            </button>
            <button
              class="btn green-border-btn add_btn_admin_e employer_export_btn"
              @click="exportSupervisors()"
              style="background-color: #4c8bf5; color: white"
              v-if="currentTab == 'supervisors' && show('export company')"
            >
              Export Supervisors
            </button>
            <button
              class="btn green-border-btn add_btn_admin_e desktop_margin_right_zero employer_export_btn"
              @click="exportOm()"
              style="background-color: #4c8bf5; color: white"
              v-if="currentTab == 'om' && show('export company')"
            >
              Export OM
            </button>

            <!-- <b-link class="btn green-border-btn add_btn_admin_e desktop_margin_left_zero" :to="{name:'employer-create'}">Add</b-link>
                            <button class="btn green-border-btn add_btn_admin_e employer_export_btn" @click="exportCompanies(currentTab)" style="background-color: #4c8bf5;color:white" v-if="currentTab != 'supervisors' && currentTab != 'om'">Export Companies</button>
                            <button class="btn green-border-btn add_btn_admin_e employer_export_btn" @click="exportSupervisors()" style="background-color: #4c8bf5;color:white" v-if="currentTab == 'supervisors'">Export Supervisors</button>
                            <button class="btn green-border-btn add_btn_admin_e desktop_margin_right_zero employer_export_btn" @click="exportOm()" style="background-color: #4c8bf5;color:white" v-if="currentTab == 'om'">Export OM</button> -->
          </div>
        </div>
        <!-- <b-tabs class="my_tab_job remove_bx"> -->
        <div class="tab-section jobseeker_tab show_filter_arrow">
          <b-tabs class="my_tab_job jobseeker_tab">
            <b-tab :title="'ALL'" @click="getDatas('companies')">
              <div class="row">
                <div
                  class="col-xl-4 col-md-4 admin_listing_search mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div
                    class="search-box custom_search_admin desktop_margin_top_zero"
                  >
                    <!-- <b-input-group> -->
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchKeywordCompanies"
                        @keyup.enter.native="
                          filterCompanies = searchKeywordCompanies
                        "
                        :value="searchKeywordCompanies"
                        @change="(v) => (searchKeywordCompanies = v)"
                        placeholder="Search by name, email, contact person, mobile"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i
                          class="fa fa-search"
                          @click="filterCompanies = searchKeywordCompanies"
                        ></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <span>DP Status:</span> -->
                  <b-form-select
                    v-model="userDPAccountStatusCompanies"
                    @change="getDatas('companies')"
                    class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
                  >
                    <option value="all">DP Status</option>
                    <option
                      :value="dp_state"
                      v-for="dp_state in dpStatusCompanies"
                    >
                      {{ dp_state | removeUnderscore }}
                    </option>
                  </b-form-select>
                </div>
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <span>DP Status:</span> -->
                  <b-form-select
                    v-model="userFTAccountStatusCompanies"
                    @change="getDatas('companies')"
                    class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
                  >
                    <option value="all">FT Status</option>
                    <option
                      :value="ft_state"
                      v-for="ft_state in ftStatusCompanies"
                    >
                      {{ ft_state | capitalize }}
                    </option>
                  </b-form-select>
                </div>
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <span>DP Status:</span> -->
                  <b-form-select
                    v-model="userJobPreferenceCompanies"
                    @change="getDatas('companies')"
                    class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
                  >
                    <option value="all">Posting Preference</option>
                    <option value="daily_paid">Temp. Staff</option>
                    <option value="full_time">Full Time</option>
                    <option value="both">Both</option>
                  </b-form-select>
                </div>
                <!-- <div class="big_screen_calendr_font search_common_margin_bottom_28 mobile_tab_max_width_flex  select-service-box date-picker-field clearfix col-xl-4 col-md-4 calender_z_index_9 calender_in_mobile  new_calender_section">
                                  <label class="new_style_label required_sign required desktop_margin_bottom_zero common_small_font_thirteen">Date Range</label>
                                <div class="">
                                <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                                    <date-range-picker ref="picker" :minDate="minDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRangeCompanies" @update="getDatas('companies')" :ranges='range'   class="common_width_100 common_small_font_thirteen manage_job_date_claneder common_date_range_picker_new">
                                        <div slot="input" slot-scope="picker">
                                            {{ picker.startDate | datePicker1 }} - {{ picker.endDate | datePicker1 }}
                                        </div>
                                    </date-range-picker>
                                </b-form-group>
                                </div>
                                </div> -->
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div
                    class="search-box custom_search_admin desktop_margin_top_zero"
                  >
                    <b-form-input
                      id="input-1"
                      type="date"
                      placeholder=""
                      v-model="dateRangeCompanies.startDate"
                      class="desktop_margin_bottom_zero date_style_size"
                    ></b-form-input>
                  </div>
                </div>
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div
                    class="search-box custom_search_admin desktop_margin_top_zero"
                  >
                    <!-- <datepicker v-model="end_date" @input="changeTab()" :format="customFormatterTwo" id="scheduled_post" class=""></datepicker> -->
                    <b-form-input
                      id="input-1"
                      type="date"
                      placeholder=""
                      v-model="dateRangeCompanies.endDate"
                      class="desktop_margin_bottom_zero date_style_size"
                    ></b-form-input>
                  </div>
                </div>

                <div
                  class="col-xl-12 col-md-12 search_common_margin_bottom_28 mobile_tab_max_width_flex"
                >
                  <b-form-group
                    id="input-group-6"
                    class="ss desktop_margin_bottom_zero"
                  >
                    <button
                      class="ml-3 mobile_margin_left_10 common_small_font_thirteen customize_filter_btn button ripple-effect search_all_red_btn daily_deployment_red_btn"
                      @click="getDatas('companies')"
                      style="background-color: #4c8bf5; color: white"
                    >
                      Search
                    </button>
                    <b-button
                      v-if="
                        filterCompanies ||
                        searchKeywordCompanies ||
                        userDPAccountStatusCompanies != 'all' ||
                        userFTAccountStatusCompanies != 'all' ||
                        userJobPreferenceCompanies != 'all' ||
                        dateRangeCompanies.startDate != start_date_filter ||
                        dateRangeCompanies.endDate != end_date_filter
                      "
                      @click="resetFilterAll()"
                      class="ml-3 new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                      style="background-color: red; margin-top: 20px"
                      ><span class="mobile_reset">Reset filters</span></b-button
                    >
                  </b-form-group>
                </div>

                <div class="container my_new_container">
                  <b-table
                    ref="datatableCompanies"
                    show-empty
                    striped
                    hover
                    responsive
                    :items="getCompanies"
                    :fields="fieldsCompanies"
                    :current-page="currentPageCompanies"
                    :per-page="perPageCompanies"
                    :filter="filterCompanies"
                    :sort-by.sync="sortByCompanies"
                    :sort-desc.sync="sortDescCompanies"
                    :sort-direction="sortDirectionCompanies"
                    class="common_small_font_table new_jbs_full_over_flow mobile_margin_top_20 common_border_top_right_left_radius_4px static_page new_layout_table_like_v1 new_box_tbl_mrgn desktop_margin_top_zero"
                  >
                    <template #cell(name)="items">
                      <!-- <b-img :src="(items.item.profile_image != null ? items.item.profile_image: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp">
                                            </b-img> -->
                      <b-link
                        :to="
                          show('view profile')
                            ? {
                                name: 'employer-profile-view',
                                params: {
                                  id: encrypt(items.item.id),
                                  company_id: encrypt(items.item.company_id),
                                },
                              }
                            : null
                        "
                        target="_blank"
                      >
                        <div class="new-name-td-box-full-icon">
                          <i
                            v-if="items.item.posting_paused == 'yes'"
                            class="material-icons-outlined pause_icon_listing"
                            >pause_circle_outline_outlined</i
                          >
                        </div>
                        <div class="whole_div_img_and_name">
                          <div class="manage_user_img">
                            <b-img
                              v-if="
                                items.item.thumbnail || items.item.profile_image
                              "
                              :src="
                                items.item.thumbnail
                                  ? items.item.thumbnail
                                  : items.item.profile_image
                                  ? items.item.profile_image
                                  : webUrl +
                                    'assets/images/user-avatar-small-01.png'
                              "
                              alt="User Icon"
                              height="100px"
                              width="100px"
                              class="square_img user_img_emp"
                            >
                            </b-img>
                            <b-img
                              v-else-if="
                                items.item.ft_profile_image_thumbnail ||
                                items.item.ft_profile_image
                              "
                              :src="
                                items.item.ft_profile_image_thumbnail
                                  ? items.item.ft_profile_image_thumbnail
                                  : items.item.ft_profile_image
                                  ? items.item.ft_profile_image
                                  : webUrl +
                                    'assets/images/user-avatar-small-01.png'
                              "
                              alt="User Icon"
                              height="100px"
                              width="100px"
                              class="square_img user_img_emp"
                            >
                            </b-img>

                            <b-img
                              v-else
                              :src="
                                webUrl +
                                'assets/images/user-avatar-small-01.png'
                              "
                              alt="User Icon"
                              height="100px"
                              width="100px"
                              class="square_img user_img_emp"
                            >
                            </b-img>
                          </div>
                          <div class="user_name_div">
                            <p style="color: #4c8bf5">
                              {{ items.item.name | capitalize }}
                            </p>
                          </div>
                        </div>
                      </b-link>
                      <p class="grey-text">
                        {{ items.item.email }}
                      </p>
                    </template>
                    <template #cell(last_name)="items">
                      {{ items.item.last_name | capitalize }}
                      {{ items.item.first_name | capitalize }}
                    </template>
                    <template #cell(last_logged_in_at)="items">
                      {{ items.item.last_logged_in_at | moment }}
                    </template>
                    <template #cell(mobile_number)="items">
                      <b-link
                        :href="'https://wa.me/+65' + items.item.mobile_number"
                        target="_blank"
                      >
                        <p style="color: #4c8bf5">
                          {{ items.item.country_code }}-{{
                            items.item.mobile_number
                          }}
                        </p>
                      </b-link>
                    </template>
                    <template #cell(job_preference)="items">
                      {{ items.item.job_preference | removeUnderscore }}
                    </template>
                    <template #cell(first_job_posted_date)="items">
                      {{ items.item.first_job_posted_date | moment }}
                    </template>
                    <template #cell(created_at)="items">
                      {{ items.item.created_at | moment }}
                    </template>
                    <template #cell(status)="items">
                      <p
                        class="green_status"
                        v-if="items.item.status == 'active'"
                      >
                        {{ items.item.status | capitalize }}
                      </p>
                      <p
                        class="orange__status"
                        v-if="items.item.status == 'inactive'"
                      >
                        {{ items.item.status | capitalize }}
                      </p>
                      <p
                        class="red_status"
                        v-if="items.item.status == 'blacklisted'"
                      >
                        {{ items.item.status | capitalize }}
                      </p>
                    </template>
                    <template #cell(ft_account_status)="items">
                      <!-- {{items.item.ft_account_status | capitalize}} -->
                      <p
                        v-if="items.item.ft_account_status == 'incomplete'"
                        v-bind:class="{
                          red_status:
                            items.item.ft_account_status == 'incomplete',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                      <p
                        v-if="items.item.ft_account_status == 'completed'"
                        v-bind:class="{
                          green_status:
                            items.item.ft_account_status == 'completed',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                      <p
                        v-if="items.item.ft_account_status == 'pending'"
                        v-bind:class="{
                          orange__status:
                            items.item.ft_account_status == 'pending',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                      <p
                        v-if="items.item.ft_account_status == 'rejected'"
                        v-bind:class="{
                          red_status:
                            items.item.ft_account_status == 'rejected',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                    </template>
                    <template #cell(account_verification_status)="items">
                      <!-- {{items.item.account_verification_status | capitalize}} -->
                      <p
                        v-if="
                          items.item.account_verification_status == 'incomplete'
                        "
                        v-bind:class="{
                          red_status:
                            items.item.account_verification_status ==
                            'incomplete',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                      <p
                        v-if="
                          items.item.account_verification_status == 'completed'
                        "
                        v-bind:class="{
                          green_status:
                            items.item.account_verification_status ==
                            'completed',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                      <p
                        v-if="
                          items.item.account_verification_status == 'pending'
                        "
                        v-bind:class="{
                          orange__status:
                            items.item.account_verification_status == 'pending',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                      <p
                        v-if="
                          items.item.account_verification_status == 'rejected'
                        "
                        v-bind:class="{
                          red_status:
                            items.item.account_verification_status ==
                            'rejected',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                    </template>
                    <template #cell(toggleAction)="items">
                      <div class="toggle-action toggle_margin_unset top_100">
                        <!-- <b-dropdown class="mx-1 common_toggle_menu_hide_from_top" right text="Action" boundary="window"> -->
                        <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                        <b-dropdown
                          class="mx-1"
                          left
                          text="Action"
                          boundary="window"
                        >
                          <b-dropdown-item
                            v-if="show('view profile')"
                            :to="{
                              name: 'employer-profile-view',
                              params: {
                                id: encrypt(items.item.id),
                                company_id: encrypt(items.item.company_id),
                              },
                            }"
                            >View Profile
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('all jobs')"
                            @click="allJobs(items.item.company_id)"
                            >All Jobs
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('audit jobs')"
                            :to="{
                              name: 'employer-audit-jobs',
                              params: {
                                id: encrypt(items.item.id),
                                company_id: encrypt(items.item.company_id),
                              },
                            }"
                            >Audit Jobs
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="showComUser('view')"
                            :to="{
                              name: 'EmpManageUserAdmin',
                              params: { id: encrypt(items.item.company_id) },
                            }"
                            >Company Users
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-if="
                              (items.item.status == 'active' ||
                                items.item.status == 'inactive') &&
                              designation != 'Customer Service' &&
                              show('edit company')
                            "
                            :to="{
                              name: 'employer-edit',
                              params: { id: encrypt(items.item.id) },
                            }"
                            >Edit
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-on:click="
                              changeCompanyStatus(
                                items.item.id,
                                items.item.company_id,
                                items.item.status
                              )
                            "
                            v-if="
                              items.item.status == 'active' &&
                              items.item.account_verification_status ==
                                'completed' &&
                              show('activate/inactivate')
                            "
                            >Inactivate</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-else-if="
                              items.item.status == 'inactive' &&
                              items.item.account_verification_status ==
                                'completed' &&
                              show('activate/inactivate')
                            "
                            v-on:click="
                              changeCompanyStatus(
                                items.item.id,
                                items.item.company_id,
                                items.item.status
                              )
                            "
                            >Activate
                          </b-dropdown-item>
                          <!-- <b-dropdown-item
                            v-if="
                              items.item.ft_account_status != 'completed' && show('delete company')
                            "
                            v-on:click="deleteCompany(items.item.id, items.item.company_id)"
                            >Delete</b-dropdown-item
                          > -->
                          <b-dropdown-item
                            v-if="
                              items.item.email &&
                              show('send password reset link')
                            "
                            @click="sendResetPasswordLink(items.item.id)"
                            >Send Reset Password Link
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.email_verified == 'no' &&
                              items.item.email &&
                              show('send verify email link')
                            "
                            @click="sendEmailVerificationLink(items.item.id)"
                            >Send Email Verification Link
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('update password')"
                            @click="
                              openInfoModel(
                                items.item.id,
                                items.item.company_id,
                                'password'
                              )
                            "
                            >Update Password
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.account_verification_status !=
                                'rejected' &&
                              items.item.status != 'deleted' &&
                              show('update email')
                            "
                            @click="
                              openInfoModel(
                                items.item.id,
                                items.item.company_id,
                                'email'
                              )
                            "
                            >Update Email
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.account_verification_status !=
                                'rejected' &&
                              items.item.status != 'deleted' &&
                              show('update mobile number')
                            "
                            @click="
                              openInfoModel(
                                items.item.id,
                                items.item.company_id,
                                'mobile'
                              )
                            "
                            >Update Mobile Number
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('update elc credits')"
                            @click="
                              openCoinModel(
                                items.item.id,
                                items.item.company_id,
                                items.item.coins,
                                'coins'
                              )
                            "
                            >Update ELC Credits
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('update free job ads')"
                            @click="
                              openJobAdsModel(
                                items.item.id,
                                items.item.company_id,
                                items.item.coins,
                                'coins'
                              )
                            "
                            >Update Free Job Ads
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.posting_paused == 'yes' &&
                              items.item.account_verification_status ==
                                'completed' &&
                              show('freeze/unfreeze Account')
                            "
                            v-on:click="
                              pausePosting(
                                items.item.id,
                                items.item.posting_paused
                              )
                            "
                            >Unfreeze Account</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-if="
                              items.item.posting_paused == 'no' &&
                              items.item.account_verification_status ==
                                'completed' &&
                              show('freeze/unfreeze Account')
                            "
                            v-on:click="
                              pausePosting(
                                items.item.id,
                                items.item.posting_paused
                              )
                            "
                            >Freeze Account</b-dropdown-item
                          >

                          <b-dropdown-item
                            v-if="
                              items.item.email_verified == 'no' &&
                              items.item.email &&
                              show('verify email')
                            "
                            v-on:click="verifyEmail(items.item.id)"
                            >Verify Email</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-if="
                              items.item.mobile_verified == 'no' &&
                              items.item.mobile_number &&
                              show('verify mobile number')
                            "
                            v-on:click="verifyMobileNumber(items.item.id)"
                            >Verify Mobile Number</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-if="
                              items.item.account_verification_status ==
                                'completed' && show('generate invoice')
                            "
                            @click="
                              openModel(
                                items.item.company_id,
                                items.item.billing_cycle
                              )
                            "
                            >Generate Invoice
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.qrcode_url != null &&
                              items.item.account_verification_status ==
                                'completed' &&
                              show('download qr code')
                            "
                            @click="
                              empQrCode(items.item.qrcode_url, '', 'download')
                            "
                            >Download QR Code
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.qrcode_url != null &&
                              items.item.account_verification_status ==
                                'completed' &&
                              show('regenerate qr code')
                            "
                            @click="
                              empQrCode(
                                items.item.company_id,
                                items.item.id,
                                'generate'
                              )
                            "
                            >Regenerate QR Code
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.account_verification_status ==
                              'completed'
                            "
                            @click="openRecalModal(items.item.id)"
                            >Recalculate Wallet
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-else-if="
                              items.item.account_verification_status ==
                                'completed' && show('generate qr code')
                            "
                            @click="
                              empQrCode(
                                items.item.company_id,
                                items.item.id,
                                'generate'
                              )
                            "
                            >Generate QR Code
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-if="show('gallery')"
                            :to="{
                              name: 'emp-gallery-admin',
                              params: { id: encrypt(items.item.id) },
                            }"
                            >Gallery
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('company log')"
                            :to="{
                              name: 'emp-log-admin',
                              params: {
                                id: encrypt(items.item.id),
                                company_id: encrypt(items.item.company_id),
                              },
                            }"
                            >Company Log
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('transactions')"
                            :to="{
                              name: 'EmpTransactionAdmin',
                              params: {
                                id: encrypt(items.item.id),
                                company_id: encrypt(items.item.company_id),
                              },
                            }"
                            >Transactions
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="showBillingAdd('view')"
                            :to="{
                              name: 'BillingAddress',
                              params: {
                                id: encrypt(items.item.id),
                                company_id: encrypt(items.item.company_id),
                              },
                            }"
                            >Billing Address
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.has_department == 'yes' &&
                              showManageDepart('view')
                            "
                            :to="{
                              name: 'companyDepartments',
                              params: {
                                id: encrypt(items.item.id),
                                company_id: encrypt(items.item.company_id),
                              },
                            }"
                            >Manage Departments
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.account_verification_status ==
                                'incomplete' && show('convert to fl')
                            "
                            @click="
                              empConvertFL(items.item.id, items.item.company_id)
                            "
                            >Convert to FL
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.status == 'blacklisted' &&
                              show('reactive')
                            "
                            v-on:click="
                              openDisableModel(
                                items.item.id,
                                items.item.status,
                                'reactive'
                              )
                            "
                            >Reactive</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-else-if="show('blacklist')"
                            @click="
                              openDisableModel(items.item.id, '', 'blacklist')
                            "
                            >Blacklist</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-if="
                              items.item.subscribe_mail == 'yes' &&
                              show('subscribe/unsubscribe mailing list')
                            "
                            @click="subscribeUnsubscribeMail(items.item.id)"
                            >Unsubscribe Mailing List</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-if="
                              items.item.subscribe_mail == 'no' &&
                              show('subscribe/unsubscribe mailing list')
                            "
                            @click="subscribeUnsubscribeMail(items.item.id)"
                            >Subscribe Mailing List
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </template>
                  </b-table>
                  <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                      <b-form-group id="input-group-4">
                        <span class="d-inline-block">Rows per page</span>
                        <span class="d-inline-block">
                          <b-form-select
                            v-model="form.rowsPerPageCompanies"
                            @change="getCompanies"
                          >
                            <option>25</option>
                            <option>50</option>
                            <option>75</option>
                            <option>100</option>
                          </b-form-select></span
                        >
                      </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                      <ul>
                        <li>
                          <span v-if="totalRowsCompanies != 0"
                            >{{ fromCompanies }} - {{ toCompanies }} of
                            {{ totalRowsCompanies }}</span
                          >
                        </li>
                      </ul>
                    </div>
                    <b-pagination
                      :total-rows="totalRowsCompanies"
                      :per-page="perPageCompanies"
                      v-model="currentPageCompanies"
                      prev-text=""
                      next-text=""
                      hide-goto-end-buttons
                    />
                  </nav>
                </div>
              </div>
            </b-tab>
            <b-tab :title="'SUPERVISORS'" @click="getDatas('supervisors')">
              <div class="row">
                <div
                  class="col-xl-4 col-md-4 admin_listing_search mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div
                    class="search-box custom_search_admin desktop_margin_top_zero"
                  >
                    <!-- <b-input-group> -->
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchKeywordSupervisors"
                        @keyup.enter.native="
                          filterSupervisors = searchKeywordSupervisors
                        "
                        :value="searchKeywordSupervisors"
                        @change="(v) => (searchKeywordSupervisors = v)"
                        placeholder="Search by name, email, company, mobile"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i
                          class="fa fa-search"
                          @click="filterSupervisors = searchKeywordSupervisors"
                        ></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <span>DP Status:</span> -->
                  <b-form-select
                    v-model="userDPAccountStatusSupervisors"
                    @change="getDatas('supervisors')"
                    class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
                  >
                    <option value="all">DP Status</option>
                    <option :value="dp_state" v-for="dp_state in dpStatus">
                      {{ dp_state | capitalize }}
                    </option>
                  </b-form-select>
                </div>
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <span>DP Status:</span> -->
                  <b-form-select
                    v-model="userFTAccountStatusSupervisors"
                    @change="getDatas('supervisors')"
                    class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
                  >
                    <option value="all">FT Status</option>
                    <option
                      :value="ft_state"
                      v-for="ft_state in ftStatusSupervisors"
                    >
                      {{ ft_state | capitalize }}
                    </option>
                  </b-form-select>
                </div>
                <!-- <div class="big_screen_calendr_font search_common_margin_bottom_28 mobile_tab_max_width_flex  select-service-box date-picker-field clearfix col-xl-4 col-md-4 calender_z_index_9 calender_in_mobile  new_calender_section">
                                    <label class="new_style_label required_sign required desktop_margin_bottom_zero">Date Range</label>
                                <div class="">
                                <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                                    <date-range-picker ref="picker" :minDate="minDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRangeSupervisors" @update="getDatas('supervisors')" :ranges='range'  class="manage_job_date_claneder common_date_range_picker_new">
                                        <div slot="input" slot-scope="picker">
                                            {{ picker.startDate | datePicker1 }} - {{ picker.endDate | datePicker1 }}
                                        </div>
                                    </date-range-picker>
                                </b-form-group>
                                </div>
                                </div> -->
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div
                    class="search-box custom_search_admin desktop_margin_top_zero"
                  >
                    <b-form-input
                      id="input-1"
                      type="date"
                      placeholder=""
                      v-model="dateRangeSupervisors.startDate"
                      class="desktop_margin_bottom_zero date_style_size"
                    ></b-form-input>
                  </div>
                </div>
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div
                    class="search-box custom_search_admin desktop_margin_top_zero"
                  >
                    <!-- <datepicker v-model="end_date" @input="changeTab()" :format="customFormatterTwo" id="scheduled_post" class=""></datepicker> -->
                    <b-form-input
                      id="input-1"
                      type="date"
                      placeholder=""
                      v-model="dateRangeSupervisors.endDate"
                      class="desktop_margin_bottom_zero date_style_size"
                    ></b-form-input>
                  </div>
                </div>

                <div
                  class="col-xl-12 col-md-12 search_common_margin_bottom_28 mobile_tab_max_width_flex"
                >
                  <b-form-group
                    id="input-group-6"
                    class="ss desktop_margin_bottom_zero"
                  >
                    <button
                      class="ml-3 mobile_margin_left_10 common_small_font_thirteen customize_filter_btn button ripple-effect search_all_red_btn daily_deployment_red_btn"
                      @click="getDatas('supervisors')"
                      style="background-color: #4c8bf5; color: white"
                    >
                      Search
                    </button>
                    <b-button
                      v-if="
                        filterSupervisors ||
                        searchKeywordSupervisors ||
                        userDPAccountStatusSupervisors != 'all' ||
                        userFTAccountStatusSupervisors != 'all' ||
                        dateRangeSupervisors.startDate != start_date_filter ||
                        dateRangeSupervisors.endDate != end_date_filter
                      "
                      @click="resetFilterSupervisors()"
                      class="ml-3 new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                      style="background-color: red; margin-top: 20px"
                      ><span class="mobile_reset">Reset filters</span></b-button
                    >
                  </b-form-group>
                </div>
                <div class="container my_new_container">
                  <b-table
                    ref="datatableSupervisors"
                    show-empty
                    striped
                    hover
                    responsive
                    :items="getSupervisors"
                    :fields="fieldsSupervisorOm"
                    :current-page="currentPageSupervisors"
                    :per-page="perPageSupervisors"
                    :filter="filterSupervisors"
                    :sort-by.sync="sortBySupervisors"
                    :sort-desc.sync="sortDescSupervisors"
                    :sort-direction="sortDirectionSupervisors"
                    class="new_jbs_full_over_flow static_page new_layout_table_like_v1 common_small_font_table new_box_tbl_mrgn desktop_margin_top_zero"
                  >
                    <template #cell(last_name)="items">
                      <!-- <b-img :src="(items.item.profile_image != null ? items.item.profile_image: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp">
                                            </b-img> -->
                      <b-link
                        :to="
                          showComUser('view profile')
                            ? {
                                name: 'EmpManageUserViewAdmin',
                                params: { id: encrypt(items.item.id) },
                              }
                            : null
                        "
                        target="_blank"
                      >
                        <div class="whole_div_img_and_name">
                          <div class="manage_user_img">
                            <b-img
                              v-if="
                                items.item.thumbnail || items.item.profile_image
                              "
                              :src="
                                items.item.thumbnail
                                  ? items.item.thumbnail
                                  : items.item.profile_image
                                  ? items.item.profile_image
                                  : webUrl +
                                    'assets/images/user-avatar-small-01.png'
                              "
                              alt="User Icon"
                              height="100px"
                              width="100px"
                              class="square_img user_img_emp"
                            >
                            </b-img>
                            <b-img
                              v-else-if="
                                items.item.ft_profile_image_thumbnail ||
                                items.item.ft_profile_image
                              "
                              :src="
                                items.item.ft_profile_image_thumbnail
                                  ? items.item.ft_profile_image_thumbnail
                                  : items.item.ft_profile_image
                                  ? items.item.ft_profile_image
                                  : webUrl +
                                    'assets/images/user-avatar-small-01.png'
                              "
                              alt="User Icon"
                              height="100px"
                              width="100px"
                              class="square_img user_img_emp"
                            >
                            </b-img>

                            <b-img
                              v-else
                              :src="
                                webUrl +
                                'assets/images/user-avatar-small-01.png'
                              "
                              alt="User Icon"
                              height="100px"
                              width="100px"
                              class="square_img user_img_emp"
                            >
                            </b-img>
                          </div>
                          <div class="user_name_div">
                            <p style="color: #4c8bf5">
                              {{ items.item.last_name | capitalize }}
                              {{ items.item.first_name | capitalize }}
                            </p>
                          </div>
                        </div>
                      </b-link>
                      <p class="grey-text">
                        {{ items.item.email }}
                      </p>
                    </template>
                    <template #cell(mobile_number)="items">
                      <b-link
                        :href="'https://wa.me/+65' + items.item.mobile_number"
                        target="_blank"
                      >
                        <p style="color: #4c8bf5">
                          {{ items.item.country_code }}-{{
                            items.item.mobile_number
                          }}
                        </p>
                      </b-link>
                    </template>
                    <template #cell(name)="items">
                      {{ items.item.name | capitalize }}
                    </template>
                    <template #cell(created_at)="items">
                      {{ items.item.created_at | moment }}
                    </template>
                    <template #cell(status)="items">
                      <p
                        class="green_status"
                        v-if="items.item.status == 'active'"
                      >
                        {{ items.item.status | capitalize }}
                      </p>
                      <p
                        class="orange__status"
                        v-if="items.item.status == 'inactive'"
                      >
                        {{ items.item.status | capitalize }}
                      </p>
                      <p
                        class="red_status"
                        v-if="items.item.status == 'blacklisted'"
                      >
                        {{ items.item.status | capitalize }}
                      </p>
                    </template>
                    <template #cell(ft_account_status)="items">
                      <!-- {{items.item.ft_account_status | capitalize}} -->
                      <p
                        v-if="items.item.ft_account_status == 'incomplete'"
                        v-bind:class="{
                          red_status:
                            items.item.ft_account_status == 'incomplete',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                      <p
                        v-if="items.item.ft_account_status == 'completed'"
                        v-bind:class="{
                          green_status:
                            items.item.ft_account_status == 'completed',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                      <p
                        v-if="items.item.ft_account_status == 'pending'"
                        v-bind:class="{
                          orange__status:
                            items.item.ft_account_status == 'pending',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                      <p
                        v-if="items.item.ft_account_status == 'rejected'"
                        v-bind:class="{
                          red_status:
                            items.item.ft_account_status == 'rejected',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                    </template>
                    <template #cell(account_verification_status)="items">
                      <!-- {{items.item.account_verification_status | capitalize}} -->
                      <p
                        v-if="
                          items.item.account_verification_status == 'incomplete'
                        "
                        v-bind:class="{
                          red_status:
                            items.item.account_verification_status ==
                            'incomplete',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                      <p
                        v-if="
                          items.item.account_verification_status == 'completed'
                        "
                        v-bind:class="{
                          green_status:
                            items.item.account_verification_status ==
                            'completed',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                      <p
                        v-if="
                          items.item.account_verification_status == 'pending'
                        "
                        v-bind:class="{
                          orange__status:
                            items.item.account_verification_status == 'pending',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                      <p
                        v-if="
                          items.item.account_verification_status == 'rejected'
                        "
                        v-bind:class="{
                          red_status:
                            items.item.account_verification_status ==
                            'rejected',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                    </template>
                    <template #cell(toggleAction)="items">
                      <div class="toggle-action toggle_margin_unset top_100">
                        <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                        <b-dropdown
                          class="mx-1"
                          left
                          text="Action"
                          boundary="window"
                        >
                          <b-dropdown-item
                            v-if="showComUser('view profile')"
                            :to="{
                              name: 'EmpManageUserViewAdmin',
                              params: { id: encrypt(items.item.id) },
                            }"
                            >View Profile
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              (items.item.status == 'active' ||
                                items.item.status == 'inactive') &&
                              show('edit company')
                            "
                            :to="{
                              name: 'employer-edit',
                              params: {
                                id: encrypt(items.item.company_admin_id),
                              },
                            }"
                            >Edit Company
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              (items.item.status == 'active' ||
                                items.item.status == 'inactive') &&
                              showComUser('edit')
                            "
                            :to="{
                              name: 'EmpManageUserEditAdmin',
                              params: {
                                id: encrypt(items.item.id),
                                company_id: encrypt(items.item.company_id),
                              },
                            }"
                            >Edit User
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-on:click="
                              changeCompanyUserStatus(
                                items.item.id,
                                items.item.company_id,
                                items.item.status
                              )
                            "
                            v-if="
                              items.item.status == 'active' &&
                              items.item.account_verification_status ==
                                'completed' &&
                              showComUser('activate/inactivate')
                            "
                            >Inactivate</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-else-if="
                              items.item.status == 'inactive' &&
                              items.item.account_verification_status ==
                                'completed' &&
                              showComUser('activate/inactivate')
                            "
                            v-on:click="
                              changeCompanyUserStatus(
                                items.item.id,
                                items.item.company_id,
                                items.item.status
                              )
                            "
                            >Activate
                          </b-dropdown-item>
                          <!-- <b-dropdown-item
                            v-if="
                              items.item.ft_account_status != 'completed' && showComUser('delete')
                            "
                            v-on:click="deleteCompanyUser(items.item.id, items.item.company_id)"
                            >Delete</b-dropdown-item
                          > -->
                          <b-dropdown-item
                            v-if="
                              items.item.email &&
                              showComUser('send reset password link')
                            "
                            @click="sendResetPasswordLink(items.item.id)"
                            >Send Reset Password Link
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.email_verified == 'no' &&
                              items.item.email &&
                              showComUser('send email verification link')
                            "
                            @click="sendEmailVerificationLink(items.item.id)"
                            >Send Email Verification Link
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.email_verified == 'no' &&
                              items.item.email &&
                              showComUser('verify email')
                            "
                            v-on:click="verifyEmail(items.item.id)"
                            >Verify Email</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-if="
                              items.item.mobile_verified == 'no' &&
                              items.item.mobile_number &&
                              showComUser('verify mobile number')
                            "
                            v-on:click="verifyMobileNumber(items.item.id)"
                            >Verify Mobile Number</b-dropdown-item
                          >
                        </b-dropdown>
                      </div>
                    </template>
                  </b-table>
                  <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                      <b-form-group id="input-group-4">
                        <span class="d-inline-block">Rows per page</span>
                        <span class="d-inline-block">
                          <b-form-select
                            v-model="form.rowsPerPageSupervisors"
                            @change="getSupervisors"
                          >
                            <option>25</option>
                            <option>50</option>
                            <option>75</option>
                            <option>100</option>
                          </b-form-select></span
                        >
                      </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                      <ul>
                        <li>
                          <span v-if="totalRowsSupervisors != 0"
                            >{{ fromSupervisors }} - {{ toSupervisors }} of
                            {{ totalRowsSupervisors }}</span
                          >
                        </li>
                      </ul>
                    </div>
                    <b-pagination
                      :total-rows="totalRowsSupervisors"
                      :per-page="perPageSupervisors"
                      v-model="currentPageSupervisors"
                      prev-text=""
                      next-text=""
                      hide-goto-end-buttons
                    />
                  </nav>
                </div>
              </div>
            </b-tab>
            <b-tab :title="'OM'" @click="getDatas('om')">
              <div class="row">
                <div
                  class="col-xl-4 col-md-4 admin_listing_search mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div
                    class="search-box custom_search_admin desktop_margin_top_zero"
                  >
                    <!-- <b-input-group> -->
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchKeywordOm"
                        @keyup.enter.native="filterOm = searchKeywordOm"
                        :value="searchKeywordOm"
                        @change="(v) => (searchKeywordOm = v)"
                        placeholder="Search by name, email, company, mobile"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i
                          class="fa fa-search"
                          @click="filterOm = searchKeywordOm"
                        ></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <span>DP Status:</span> -->
                  <b-form-select
                    v-model="userDPAccountStatusOm"
                    @change="getDatas('om')"
                    class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
                  >
                    <option value="all">DP Status</option>
                    <option :value="dp_state" v-for="dp_state in dpStatus">
                      {{ dp_state | capitalize }}
                    </option>
                  </b-form-select>
                </div>
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <span>DP Status:</span> -->
                  <b-form-select
                    v-model="userFTAccountStatusOm"
                    @change="getDatas('om')"
                    class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
                  >
                    <option value="all">FT Status</option>
                    <option :value="ft_state" v-for="ft_state in ftStatusOm">
                      {{ ft_state | capitalize }}
                    </option>
                  </b-form-select>
                </div>
                <!-- <div class="big_screen_calendr_font search_common_margin_bottom_28 mobile_tab_max_width_flex  select-service-box date-picker-field clearfix col-xl-4 col-md-4 calender_z_index_9 calender_in_mobile  new_calender_section">
                                    <label class="new_style_label required_sign required desktop_margin_bottom_zero">Date Range</label>
                                    <div class="">
                                        <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                                            <date-range-picker ref="picker" :minDate="minDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRangeOm" @update="getDatas('om')" :ranges='range'  class="manage_job_date_claneder common_date_range_picker_new">
                                                <div slot="input" slot-scope="picker">
                                                    {{ picker.startDate | datePicker1 }} - {{ picker.endDate | datePicker1 }}
                                                </div>
                                            </date-range-picker>
                                        </b-form-group>
                                    </div>
                                </div> -->
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div
                    class="search-box custom_search_admin desktop_margin_top_zero"
                  >
                    <b-form-input
                      id="input-1"
                      type="date"
                      placeholder=""
                      v-model="dateRangeOm.startDate"
                      class="desktop_margin_bottom_zero date_style_size"
                    ></b-form-input>
                  </div>
                </div>
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div
                    class="search-box custom_search_admin desktop_margin_top_zero"
                  >
                    <!-- <datepicker v-model="end_date" @input="changeTab()" :format="customFormatterTwo" id="scheduled_post" class=""></datepicker> -->
                    <b-form-input
                      id="input-1"
                      type="date"
                      placeholder=""
                      v-model="dateRangeOm.endDate"
                      class="desktop_margin_bottom_zero date_style_size"
                    ></b-form-input>
                  </div>
                </div>

                <div
                  class="col-xl-12 col-md-12 search_common_margin_bottom_28 mobile_tab_max_width_flex"
                >
                  <b-form-group
                    id="input-group-6"
                    class="ss desktop_margin_bottom_zero"
                  >
                    <button
                      class="ml-3 mobile_margin_left_10 common_small_font_thirteen customize_filter_btn button ripple-effect search_all_red_btn daily_deployment_red_btn"
                      @click="getDatas('om')"
                      style="background-color: #4c8bf5; color: white"
                    >
                      Search
                    </button>
                    <b-button
                      v-if="
                        filterOm ||
                        searchKeywordOm ||
                        userDPAccountStatusOm != 'all' ||
                        userFTAccountStatusOm != 'all' ||
                        dateRangeOm.startDate != start_date_filter ||
                        dateRangeOm.endDate != end_date_filter
                      "
                      @click="resetFilterOm()"
                      class="ml-3 new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                      style="background-color: red; margin-top: 20px"
                      ><span class="mobile_reset">Reset filters</span></b-button
                    >
                  </b-form-group>
                </div>
                <div class="container my_new_container">
                  <b-table
                    ref="datatableOm"
                    show-empty
                    striped
                    hover
                    responsive
                    :items="getOm"
                    :fields="fieldsSupervisorOm"
                    :current-page="currentPageOm"
                    :per-page="perPageOm"
                    :filter="filterOm"
                    :sort-by.sync="sortByOm"
                    :sort-desc.sync="sortDescOm"
                    :sort-direction="sortDirectionOm"
                    class="new_jbs_full_over_flow static_page new_layout_table_like_v1 common_small_font_table new_box_tbl_mrgn desktop_margin_top_zero"
                  >
                    <template #cell(last_name)="items">
                      <!-- <b-img :src="(items.item.profile_image != null ? items.item.profile_image: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp">
                                            </b-img> -->
                      <b-link
                        :to="
                          showComUser('view profile')
                            ? {
                                name: 'EmpManageUserViewAdmin',
                                params: { id: encrypt(items.item.id) },
                              }
                            : null
                        "
                        target="_blank"
                      >
                        <div class="whole_div_img_and_name">
                          <div class="manage_user_img">
                            <b-img
                              v-if="
                                items.item.thumbnail || items.item.profile_image
                              "
                              :src="
                                items.item.thumbnail
                                  ? items.item.thumbnail
                                  : items.item.profile_image
                                  ? items.item.profile_image
                                  : webUrl +
                                    'assets/images/user-avatar-small-01.png'
                              "
                              alt="User Icon"
                              height="100px"
                              width="100px"
                              class="square_img user_img_emp"
                            >
                            </b-img>
                            <b-img
                              v-else-if="
                                items.item.ft_profile_image_thumbnail ||
                                items.item.ft_profile_image
                              "
                              :src="
                                items.item.ft_profile_image_thumbnail
                                  ? items.item.ft_profile_image_thumbnail
                                  : items.item.ft_profile_image
                                  ? items.item.ft_profile_image
                                  : webUrl +
                                    'assets/images/user-avatar-small-01.png'
                              "
                              alt="User Icon"
                              height="100px"
                              width="100px"
                              class="square_img user_img_emp"
                            >
                            </b-img>

                            <b-img
                              v-else
                              :src="
                                webUrl +
                                'assets/images/user-avatar-small-01.png'
                              "
                              alt="User Icon"
                              height="100px"
                              width="100px"
                              class="square_img user_img_emp"
                            >
                            </b-img>
                          </div>
                          <div class="user_name_div">
                            <p style="color: #4c8bf5">
                              {{ items.item.last_name | capitalize }}
                              {{ items.item.first_name | capitalize }}
                            </p>
                          </div>
                        </div>
                      </b-link>
                      <p class="grey-text">
                        {{ items.item.email }}
                      </p>
                    </template>
                    <template #cell(name)="items">
                      {{ items.item.name | capitalize }}
                    </template>
                    <template #cell(mobile_number)="items">
                      <b-link
                        :href="'https://wa.me/+65' + items.item.mobile_number"
                        target="_blank"
                      >
                        <p style="color: #4c8bf5">
                          {{ items.item.country_code }}-{{
                            items.item.mobile_number
                          }}
                        </p>
                      </b-link>
                    </template>
                    <template #cell(created_at)="items">
                      {{ items.item.created_at | moment }}
                    </template>
                    <template #cell(status)="items">
                      <p
                        class="green_status"
                        v-if="items.item.status == 'active'"
                      >
                        {{ items.item.status | capitalize }}
                      </p>
                      <p
                        class="orange__status"
                        v-if="items.item.status == 'inactive'"
                      >
                        {{ items.item.status | capitalize }}
                      </p>
                      <p
                        class="red_status"
                        v-if="items.item.status == 'blacklisted'"
                      >
                        {{ items.item.status | capitalize }}
                      </p>
                    </template>
                    <template #cell(ft_account_status)="items">
                      <!-- {{items.item.ft_account_status | capitalize}} -->
                      <p
                        v-if="items.item.ft_account_status == 'incomplete'"
                        v-bind:class="{
                          red_status:
                            items.item.ft_account_status == 'incomplete',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                      <p
                        v-if="items.item.ft_account_status == 'completed'"
                        v-bind:class="{
                          green_status:
                            items.item.ft_account_status == 'completed',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                      <p
                        v-if="items.item.ft_account_status == 'pending'"
                        v-bind:class="{
                          orange__status:
                            items.item.ft_account_status == 'pending',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                      <p
                        v-if="items.item.ft_account_status == 'rejected'"
                        v-bind:class="{
                          red_status:
                            items.item.ft_account_status == 'rejected',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                    </template>
                    <template #cell(account_verification_status)="items">
                      <!-- {{items.item.account_verification_status | capitalize}} -->
                      <p
                        v-if="
                          items.item.account_verification_status == 'incomplete'
                        "
                        v-bind:class="{
                          red_status:
                            items.item.account_verification_status ==
                            'incomplete',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                      <p
                        v-if="
                          items.item.account_verification_status == 'completed'
                        "
                        v-bind:class="{
                          green_status:
                            items.item.account_verification_status ==
                            'completed',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                      <p
                        v-if="
                          items.item.account_verification_status == 'pending'
                        "
                        v-bind:class="{
                          orange__status:
                            items.item.account_verification_status == 'pending',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                      <p
                        v-if="
                          items.item.account_verification_status == 'rejected'
                        "
                        v-bind:class="{
                          red_status:
                            items.item.account_verification_status ==
                            'rejected',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                    </template>
                    <template #cell(toggleAction)="items">
                      <div class="toggle-action toggle_margin_unset top_100">
                        <!-- <b-dropdown class="mx-1 common_toggle_menu_hide_from_top" right text="Action" boundary="window"> -->
                        <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                        <b-dropdown
                          class="mx-1"
                          left
                          text="Action"
                          boundary="window"
                        >
                          <b-dropdown-item
                            v-if="showComUser('view profile')"
                            :to="{
                              name: 'EmpManageUserViewAdmin',
                              params: { id: encrypt(items.item.id) },
                            }"
                            >View Profile
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="showComUser('attach user')"
                            @click="openAttachSupModal(items.item.id)"
                            >Attach User
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              (items.item.status == 'active' ||
                                items.item.status == 'inactive') &&
                              show('edit company')
                            "
                            :to="{
                              name: 'employer-edit',
                              params: {
                                id: encrypt(items.item.company_admin_id),
                              },
                            }"
                            >Edit Company
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              (items.item.status == 'active' ||
                                items.item.status == 'inactive') &&
                              showComUser('edit')
                            "
                            :to="{
                              name: 'EmpManageUserEditAdmin',
                              params: {
                                id: encrypt(items.item.id),
                                company_id: encrypt(items.item.company_id),
                              },
                            }"
                            >Edit User
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-on:click="
                              changeCompanyUserStatus(
                                items.item.id,
                                items.item.company_id,
                                items.item.status
                              )
                            "
                            v-if="
                              items.item.status == 'active' &&
                              items.item.account_verification_status ==
                                'completed' &&
                              showComUser('activate/inactivate')
                            "
                            >Inactivate</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-else-if="
                              items.item.status == 'inactive' &&
                              items.item.account_verification_status ==
                                'completed' &&
                              showComUser('activate/inactivate')
                            "
                            v-on:click="
                              changeCompanyUserStatus(
                                items.item.id,
                                items.item.company_id,
                                items.item.status
                              )
                            "
                            >Activate
                          </b-dropdown-item>
                          <!-- <b-dropdown-item
                            v-if="
                              items.item.ft_account_status != 'completed' && showComUser('delete')
                            "
                            v-on:click="deleteCompanyUser(items.item.id, items.item.company_id)"
                            >Delete</b-dropdown-item
                          > -->
                          <b-dropdown-item
                            v-if="
                              items.item.email &&
                              showComUser('send reset password link')
                            "
                            @click="sendResetPasswordLink(items.item.id)"
                            >Send Reset Password Link
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.email_verified == 'no' &&
                              items.item.email &&
                              showComUser('send email verification link')
                            "
                            @click="sendEmailVerificationLink(items.item.id)"
                            >Send Email Verification Link
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.email_verified == 'no' &&
                              items.item.email &&
                              showComUser('verify email')
                            "
                            v-on:click="verifyEmail(items.item.id)"
                            >Verify Email</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-if="
                              items.item.mobile_verified == 'no' &&
                              items.item.mobile_number &&
                              showComUser('verify mobile number')
                            "
                            v-on:click="verifyMobileNumber(items.item.id)"
                            >Verify Mobile Number</b-dropdown-item
                          >
                        </b-dropdown>
                      </div>
                    </template>
                  </b-table>
                  <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                      <b-form-group id="input-group-4">
                        <span class="d-inline-block">Rows per page</span>
                        <span class="d-inline-block">
                          <b-form-select
                            v-model="form.rowsPerPageOm"
                            @change="getOm"
                          >
                            <option>25</option>
                            <option>50</option>
                            <option>75</option>
                            <option>100</option>
                          </b-form-select></span
                        >
                      </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                      <ul>
                        <li>
                          <span v-if="totalRowsOm != 0"
                            >{{ fromOm }} - {{ toOm }} of
                            {{ totalRowsOm }}</span
                          >
                        </li>
                      </ul>
                    </div>
                    <b-pagination
                      :total-rows="totalRowsOm"
                      :per-page="perPageOm"
                      v-model="currentPageOm"
                      prev-text=""
                      next-text=""
                      hide-goto-end-buttons
                    />
                  </nav>
                </div>
              </div>
            </b-tab>
            <b-tab :title="'SALES'" @click="getDatas('sales')">
              <div class="row">
                <div
                  class="col-xl-4 col-md-4 admin_listing_search mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div
                    class="search-box custom_search_admin desktop_margin_top_zero"
                  >
                    <!-- <b-input-group> -->
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchKeywordSales"
                        @keyup.enter.native="filterSales = searchKeywordSales"
                        :value="searchKeywordSales"
                        @change="(v) => (searchKeywordSales = v)"
                        placeholder="Search by name, email, contact person, mobile"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i
                          class="fa fa-search"
                          @click="filterSales = searchKeywordSales"
                        ></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <span>DP Status:</span> -->
                  <b-form-select
                    v-model="salesStatus"
                    @change="getDatas('sales')"
                    class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
                  >
                    <option value="all">Sales Status</option>
                    <option :value="state" v-for="state in salesStatusArr">
                      {{ state | removeUnderscore }}
                    </option>
                  </b-form-select>
                </div>
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <span>DP Status:</span> -->
                  <b-form-select
                    v-model="userFTAccountStatusSales"
                    @change="getDatas('sales')"
                    class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
                  >
                    <option value="all">FT Status</option>
                    <option :value="ft_state" v-for="ft_state in ftStatusSales">
                      {{ ft_state | capitalize }}
                    </option>
                  </b-form-select>
                </div>
                <!-- <div class="big_screen_calendr_font search_common_margin_bottom_28 mobile_tab_max_width_flex  select-service-box date-picker-field clearfix col-xl-4 col-md-4 calender_z_index_9 calender_in_mobile  new_calender_section">
                                    <label class="new_style_label required_sign required desktop_margin_bottom_zero">Date Range</label>
                                    <div class="">
                                        <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                                            <date-range-picker ref="picker" :minDate="minDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRangeSales" @update="getDatas('sales')" :ranges='range'  class="manage_job_date_claneder common_date_range_picker_new">
                                                <div slot="input" slot-scope="picker">
                                                    {{ picker.startDate | datePicker1 }} - {{ picker.endDate | datePicker1 }}
                                                </div>
                                            </date-range-picker>
                                        </b-form-group>
                                    </div>
                                </div> -->
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div
                    class="search-box custom_search_admin desktop_margin_top_zero"
                  >
                    <b-form-input
                      id="input-1"
                      type="date"
                      placeholder=""
                      v-model="dateRangeSales.startDate"
                      class="desktop_margin_bottom_zero date_style_size"
                    ></b-form-input>
                  </div>
                </div>
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div
                    class="search-box custom_search_admin desktop_margin_top_zero"
                  >
                    <!-- <datepicker v-model="end_date" @input="changeTab()" :format="customFormatterTwo" id="scheduled_post" class=""></datepicker> -->
                    <b-form-input
                      id="input-1"
                      type="date"
                      placeholder=""
                      v-model="dateRangeSales.endDate"
                      class="desktop_margin_bottom_zero date_style_size"
                    ></b-form-input>
                  </div>
                </div>

                <div
                  class="col-xl-12 col-md-12 search_common_margin_bottom_28 mobile_tab_max_width_flex"
                >
                  <b-form-group
                    id="input-group-6"
                    class="ss desktop_margin_bottom_zero"
                  >
                    <button
                      class="ml-3 mobile_margin_left_10 common_small_font_thirteen customize_filter_btn button ripple-effect search_all_red_btn daily_deployment_red_btn"
                      @click="getDatas('sales')"
                      style="background-color: #4c8bf5; color: white"
                    >
                      Search
                    </button>
                    <b-button
                      v-if="
                        filterSales ||
                        searchKeywordSales ||
                        salesStatus != 'all' ||
                        userFTAccountStatusSales != 'all' ||
                        dateRangeSales.startDate != start_date_filter ||
                        dateRangeSales.endDate != end_date_filter
                      "
                      @click="resetFilterSales()"
                      class="ml-3 new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                      style="background-color: red; margin-top: 20px"
                      ><span class="mobile_reset">Reset filters</span></b-button
                    >
                  </b-form-group>
                </div>
                <div class="container my_new_container">
                  <b-table
                    ref="datatableSales"
                    show-empty
                    striped
                    hover
                    responsive
                    :items="getSales"
                    :fields="fieldsSales"
                    :current-page="currentPageSales"
                    :per-page="perPageSales"
                    :filter="filterSales"
                    :sort-by.sync="sortBySales"
                    :sort-desc.sync="sortDescSales"
                    :sort-direction="sortDirectionSales"
                    class="new_jbs_full_over_flow mobile_margin_top_20 common_border_top_right_left_radius_4px static_page new_layout_table_like_v1 common_small_font_table new_box_tbl_mrgn desktop_margin_top_zero"
                  >
                    <template #cell(name)="items">
                      <!-- <b-img :src="(items.item.profile_image != null ? items.item.profile_image: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp">
                                            </b-img> -->
                      <b-link
                        :to="
                          show('view profile')
                            ? {
                                name: 'employer-profile-view',
                                params: {
                                  id: encrypt(items.item.id),
                                  company_id: encrypt(items.item.company_id),
                                },
                              }
                            : null
                        "
                        target="_blank"
                      >
                        <div class="whole_div_img_and_name">
                          <div class="manage_user_img">
                            <b-img
                              v-if="
                                items.item.thumbnail || items.item.profile_image
                              "
                              :src="
                                items.item.thumbnail
                                  ? items.item.thumbnail
                                  : items.item.profile_image
                                  ? items.item.profile_image
                                  : webUrl +
                                    'assets/images/user-avatar-small-01.png'
                              "
                              alt="User Icon"
                              height="100px"
                              width="100px"
                              class="square_img user_img_emp"
                            >
                            </b-img>
                            <b-img
                              v-else-if="
                                items.item.ft_profile_image_thumbnail ||
                                items.item.ft_profile_image
                              "
                              :src="
                                items.item.ft_profile_image_thumbnail
                                  ? items.item.ft_profile_image_thumbnail
                                  : items.item.ft_profile_image
                                  ? items.item.ft_profile_image
                                  : webUrl +
                                    'assets/images/user-avatar-small-01.png'
                              "
                              alt="User Icon"
                              height="100px"
                              width="100px"
                              class="square_img user_img_emp"
                            >
                            </b-img>

                            <b-img
                              v-else
                              :src="
                                webUrl +
                                'assets/images/user-avatar-small-01.png'
                              "
                              alt="User Icon"
                              height="100px"
                              width="100px"
                              class="square_img user_img_emp"
                            >
                            </b-img>
                          </div>
                          <div class="user_name_div">
                            <p style="color: #4c8bf5">
                              {{ items.item.name | capitalize }}
                            </p>
                          </div>
                        </div>
                      </b-link>
                      <p class="grey-text">
                        {{ items.item.email }}
                      </p>
                    </template>
                    <template #cell(last_name)="items">
                      {{ items.item.last_name | capitalize }}
                      {{ items.item.first_name | capitalize }}
                    </template>
                    <template #cell(last_logged_in_at)="items">
                      {{ items.item.last_logged_in_at | moment }}
                    </template>
                    <template #cell(mobile_number)="items">
                      <b-link
                        :href="'https://wa.me/+65' + items.item.mobile_number"
                        target="_blank"
                      >
                        <p style="color: #4c8bf5">
                          {{ items.item.country_code }}-{{
                            items.item.mobile_number
                          }}
                        </p>
                      </b-link>
                    </template>
                    <template #cell(created_at)="items">
                      {{ items.item.created_at | moment }}
                    </template>
                    <template #cell(call_date)="items">
                      {{ items.item.call_date | moment2 }}
                    </template>

                    <template #cell(sales_lead)="items">
                      <p
                        class="btn green-bg-btn white-text green_bg_pops"
                        v-if="items.item.sales_lead == 'outbound'"
                      >
                        {{ items.item.sales_lead | capitalize }}
                      </p>
                      <p
                        class="btn blue-bg white-text bl_bg"
                        v-if="items.item.sales_lead == 'inbound'"
                      >
                        {{ items.item.sales_lead | capitalize }}
                      </p>
                    </template>

                    <template #cell(sales_status)="items">
                      <p
                        class="btn green-bg-btn white-text green_bg_pops"
                        v-if="items.item.sales_status == 'closed'"
                      >
                        {{ items.item.sales_status | capitalize }}
                      </p>
                      <p
                        class="btn yellow-bg black-text yellow_bg_pops"
                        v-if="items.item.sales_status == 'follow_up'"
                      >
                        {{ items.item.sales_status | capitalize }}
                      </p>
                      <p
                        class="btn red-bg black-text red_bg_pops"
                        v-if="items.item.sales_status == 'rejected'"
                      >
                        {{ items.item.sales_status | capitalize }}
                      </p>
                    </template>
                    <template #cell(closed_by)="items">
                      <p v-if="items.item.closed_by_last_name">
                        {{ items.item.closed_by_last_name | capitalize }}
                        {{ items.item.closed_by_first_name | capitalize }}
                      </p>
                      <p v-else>{{ "-" }}</p>
                    </template>
                    <template #cell(sales_remarks)="items">
                      {{ items.item.sales_remarks | capitalize }}
                    </template>
                    <template #cell(status)="items">
                      <p
                        class="green_status"
                        v-if="items.item.status == 'active'"
                      >
                        {{ items.item.status | capitalize }}
                      </p>
                      <p
                        class="orange__status"
                        v-if="items.item.status == 'inactive'"
                      >
                        {{ items.item.status | capitalize }}
                      </p>
                      <p
                        class="red_status"
                        v-if="items.item.status == 'blacklisted'"
                      >
                        {{ items.item.status | capitalize }}
                      </p>
                    </template>
                    <template #cell(ft_account_status)="items">
                      <!-- {{items.item.ft_account_status | capitalize}} -->
                      <p
                        v-if="items.item.ft_account_status == 'incomplete'"
                        v-bind:class="{
                          red_status:
                            items.item.ft_account_status == 'incomplete',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                      <p
                        v-if="items.item.ft_account_status == 'completed'"
                        v-bind:class="{
                          green_status:
                            items.item.ft_account_status == 'completed',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                      <p
                        v-if="items.item.ft_account_status == 'pending'"
                        v-bind:class="{
                          orange__status:
                            items.item.ft_account_status == 'pending',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                      <p
                        v-if="items.item.ft_account_status == 'rejected'"
                        v-bind:class="{
                          red_status:
                            items.item.ft_account_status == 'rejected',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                    </template>
                    <template #cell(account_verification_status)="items">
                      <!-- {{items.item.account_verification_status | capitalize}} -->
                      <p
                        v-if="
                          items.item.account_verification_status == 'incomplete'
                        "
                        v-bind:class="{
                          red_status:
                            items.item.account_verification_status ==
                            'incomplete',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                      <p
                        v-if="
                          items.item.account_verification_status == 'completed'
                        "
                        v-bind:class="{
                          green_status:
                            items.item.account_verification_status ==
                            'completed',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                      <p
                        v-if="
                          items.item.account_verification_status == 'pending'
                        "
                        v-bind:class="{
                          orange__status:
                            items.item.account_verification_status == 'pending',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                      <p
                        v-if="
                          items.item.account_verification_status == 'rejected'
                        "
                        v-bind:class="{
                          red_status:
                            items.item.account_verification_status ==
                            'rejected',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                    </template>
                    <template #cell(toggleAction)="items">
                      <div class="toggle-action toggle_margin_unset top_100">
                        <!-- <b-dropdown class="mx-1 common_toggle_menu_hide_from_top" right text="Action" boundary="window"> -->
                        <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                        <b-dropdown
                          class="mx-1"
                          left
                          text="Action"
                          boundary="window"
                        >
                          <b-dropdown-item
                            v-if="show('update sales status')"
                            @click="
                              openSalesModel(
                                items.item.id,
                                items.item.company_id,
                                items.item.name,
                                items.item.call_date,
                                items.item.sales_lead,
                                items.item.sales_status,
                                items.item.sales_remarks,
                                items.item.closed_by_user_id
                              )
                            "
                            >Update Sales Status
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('view profile')"
                            :to="{
                              name: 'employer-profile-view',
                              params: {
                                id: encrypt(items.item.id),
                                company_id: encrypt(items.item.company_id),
                              },
                            }"
                            >View Profile
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('all jobs')"
                            @click="allJobs(items.item.company_id)"
                            >All Jobs
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('audit jobs')"
                            :to="{
                              name: 'employer-audit-jobs',
                              params: {
                                id: encrypt(items.item.id),
                                company_id: encrypt(items.item.company_id),
                              },
                            }"
                            >Audit Jobs
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="showComUser('view')"
                            :to="{
                              name: 'EmpManageUserAdmin',
                              params: { id: encrypt(items.item.company_id) },
                            }"
                            >Company Users
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              (items.item.status == 'active' ||
                                items.item.status == 'inactive') &&
                              designation != 'Customer Service' &&
                              show('edit company')
                            "
                            :to="{
                              name: 'employer-edit',
                              params: { id: encrypt(items.item.id) },
                            }"
                            >Edit
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-on:click="
                              changeCompanyStatus(
                                items.item.id,
                                items.item.company_id,
                                items.item.status
                              )
                            "
                            v-if="
                              items.item.status == 'active' &&
                              items.item.account_verification_status ==
                                'completed' &&
                              show('activate/inactivate')
                            "
                            >Inactivate</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-else-if="
                              items.item.status == 'inactive' &&
                              items.item.account_verification_status ==
                                'completed' &&
                              show('activate/inactivate')
                            "
                            v-on:click="
                              changeCompanyStatus(
                                items.item.id,
                                items.item.company_id,
                                items.item.status
                              )
                            "
                            >Activate
                          </b-dropdown-item>
                          <!-- <b-dropdown-item
                            v-if="
                              items.item.ft_account_status != 'completed' && show('delete company')
                            "
                            v-on:click="deleteCompany(items.item.id, items.item.company_id)"
                            >Delete</b-dropdown-item
                          > -->
                          <b-dropdown-item
                            v-if="
                              items.item.email &&
                              show('send password reset link')
                            "
                            @click="sendResetPasswordLink(items.item.id)"
                            >Send Reset Password Link
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.email_verified == 'no' &&
                              items.item.email &&
                              show('send verify email link')
                            "
                            @click="sendEmailVerificationLink(items.item.id)"
                            >Send Email Verification Link
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('update password')"
                            @click="
                              openInfoModel(
                                items.item.id,
                                items.item.company_id,
                                'password'
                              )
                            "
                            >Update Password
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.email_verified == 'no' &&
                              items.item.email &&
                              show('verify email')
                            "
                            v-on:click="verifyEmail(items.item.id)"
                            >Verify Email</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-if="
                              items.item.mobile_verified == 'no' &&
                              items.item.mobile_number &&
                              show('verify mobile number')
                            "
                            v-on:click="verifyMobileNumber(items.item.id)"
                            >Verify Mobile Number</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-if="
                              items.item.account_verification_status ==
                                'completed' && show('generate invoice')
                            "
                            @click="
                              openModel(
                                items.item.company_id,
                                items.item.billing_cycle
                              )
                            "
                            >Generate Invoice
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.qrcode_url != null &&
                              items.item.account_verification_status ==
                                'completed' &&
                              show('download qr code')
                            "
                            @click="
                              empQrCode(items.item.qrcode_url, '', 'download')
                            "
                            >Download QR Code
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.qrcode_url != null &&
                              items.item.account_verification_status ==
                                'completed' &&
                              show('regenerate qr code')
                            "
                            @click="
                              empQrCode(
                                items.item.company_id,
                                items.item.id,
                                'generate'
                              )
                            "
                            >Regenerate QR Code
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-else-if="
                              items.item.account_verification_status ==
                                'completed' && show('generate qr code')
                            "
                            @click="
                              empQrCode(
                                items.item.company_id,
                                items.item.id,
                                'generate'
                              )
                            "
                            >Generate QR Code
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('gallery')"
                            :to="{
                              name: 'emp-gallery-admin',
                              params: { id: encrypt(items.item.id) },
                            }"
                            >Gallery
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('company log')"
                            :to="{
                              name: 'emp-log-admin',
                              params: {
                                id: encrypt(items.item.id),
                                company_id: encrypt(items.item.company_id),
                              },
                            }"
                            >Company Log
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="showBillingAdd('view')"
                            :to="{
                              name: 'BillingAddress',
                              params: {
                                id: encrypt(items.item.id),
                                company_id: encrypt(items.item.company_id),
                              },
                            }"
                            >Billing Address
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </template>
                  </b-table>
                  <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                      <b-form-group id="input-group-4">
                        <span class="d-inline-block">Rows per page</span>
                        <span class="d-inline-block">
                          <b-form-select
                            v-model="form.rowsPerPageSales"
                            @change="getSales"
                          >
                            <option>25</option>
                            <option>50</option>
                            <option>75</option>
                            <option>100</option>
                          </b-form-select></span
                        >
                      </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                      <ul>
                        <li>
                          <span v-if="totalRowsSales != 0"
                            >{{ fromSales }} - {{ toSales }} of
                            {{ totalRowsSales }}</span
                          >
                        </li>
                      </ul>
                    </div>
                    <b-pagination
                      :total-rows="totalRowsSales"
                      :per-page="perPageSales"
                      v-model="currentPageSales"
                      prev-text=""
                      next-text=""
                      hide-goto-end-buttons
                    />
                  </nav>
                </div>
              </div>
            </b-tab>
            <b-tab :title="'PRE-PAID'" @click="getDatas('prepaid')">
              <div class="row">
                <div
                  class="col-xl-4 col-md-4 admin_listing_search mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div
                    class="search-box custom_search_admin desktop_margin_top_zero"
                  >
                    <!-- <b-input-group> -->
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchKeywordPrepaid"
                        @keyup.enter.native="
                          filterPrepaid = searchKeywordPrepaid
                        "
                        :value="searchKeywordPrepaid"
                        @change="(v) => (searchKeywordPrepaid = v)"
                        placeholder="Search by name, email, contact person, mobile"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i
                          class="fa fa-search"
                          @click="filterPrepaid = searchKeywordPrepaid"
                        ></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <span>DP Status:</span> -->
                  <b-form-select
                    v-model="userDPAccountStatusPrepaid"
                    @change="getDatas('prepaid')"
                    class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
                  >
                    <option value="all">DP Status</option>
                    <option
                      :value="dp_state"
                      v-for="dp_state in dpStatusPrepaid"
                    >
                      {{ dp_state | removeUnderscore }}
                    </option>
                  </b-form-select>
                </div>

                <div class="container my_new_container">
                  <b-table
                    ref="datatablePrepaid"
                    show-empty
                    striped
                    hover
                    responsive
                    :items="getPrepaid"
                    :fields="fieldsPrepaid"
                    :current-page="currentPagePrepaid"
                    :per-page="perPagePrepaid"
                    :filter="filterPrepaid"
                    :sort-by.sync="sortByPrepaid"
                    :sort-desc.sync="sortDescPrepaid"
                    :sort-direction="sortDirectionPrepaid"
                    class="new_jbs_full_over_flow mobile_margin_top_20 common_border_top_right_left_radius_4px static_page new_layout_table_like_v1 common_small_font_table new_box_tbl_mrgn desktop_margin_top_zero"
                  >
                    <template #cell(name)="items">
                      <!-- <b-img :src="(items.item.profile_image != null ? items.item.profile_image: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp">
                                            </b-img> -->
                      <b-link
                        :to="
                          show('view profile')
                            ? {
                                name: 'employer-profile-view',
                                params: {
                                  id: encrypt(items.item.id),
                                  company_id: encrypt(items.item.company_id),
                                },
                              }
                            : null
                        "
                        target="_blank"
                      >
                        <div class="new-name-td-box-full-icon">
                          <i
                            v-if="items.item.posting_paused == 'yes'"
                            class="material-icons-outlined pause_icon_listing"
                            >pause_circle_outline_outlined</i
                          >
                        </div>
                        <div class="whole_div_img_and_name">
                          <div class="manage_user_img">
                            <b-img
                              v-if="
                                items.item.thumbnail || items.item.profile_image
                              "
                              :src="
                                items.item.thumbnail
                                  ? items.item.thumbnail
                                  : items.item.profile_image
                                  ? items.item.profile_image
                                  : webUrl +
                                    'assets/images/user-avatar-small-01.png'
                              "
                              alt="User Icon"
                              height="100px"
                              width="100px"
                              class="square_img user_img_emp"
                            >
                            </b-img>
                            <b-img
                              v-else-if="
                                items.item.ft_profile_image_thumbnail ||
                                items.item.ft_profile_image
                              "
                              :src="
                                items.item.ft_profile_image_thumbnail
                                  ? items.item.ft_profile_image_thumbnail
                                  : items.item.ft_profile_image
                                  ? items.item.ft_profile_image
                                  : webUrl +
                                    'assets/images/user-avatar-small-01.png'
                              "
                              alt="User Icon"
                              height="100px"
                              width="100px"
                              class="square_img user_img_emp"
                            >
                            </b-img>

                            <b-img
                              v-else
                              :src="
                                webUrl +
                                'assets/images/user-avatar-small-01.png'
                              "
                              alt="User Icon"
                              height="100px"
                              width="100px"
                              class="square_img user_img_emp"
                            >
                            </b-img>
                          </div>
                          <div class="user_name_div">
                            <p style="color: #4c8bf5">
                              {{ items.item.name | capitalize }}
                            </p>
                          </div>
                        </div>
                      </b-link>
                      <p class="grey-text">
                        {{ items.item.email }}
                      </p>
                    </template>
                    <template #cell(last_name)="items">
                      {{ items.item.last_name | capitalize }}
                      {{ items.item.first_name | capitalize }}
                    </template>
                    <template #cell(mobile_number)="items">
                      <b-link
                        :href="'https://wa.me/+65' + items.item.mobile_number"
                        target="_blank"
                      >
                        <p style="color: #4c8bf5">
                          {{ items.item.country_code }}-{{
                            items.item.mobile_number
                          }}
                        </p>
                      </b-link>
                    </template>
                    <template #cell(first_job_posted_date)="items">
                      {{ items.item.first_job_posted_date | moment }}
                    </template>
                    <template #cell(created_at)="items">
                      {{ items.item.created_at | moment }}
                    </template>
                    <template #cell(coins)="items">
                      {{
                        items.item.available_to_spend
                          ? "$" + items.item.available_to_spend
                          : "$0"
                      }}
                      <!-- {{items.item.available_to_spend ? items.item.available_to_spend : '0'}} -->
                    </template>
                    <template #cell(hold_coins)="items">
                      <!-- {{items.item.pending_amount ? items.item.pending_amount : '0'}} -->
                      {{
                        items.item.pending_amount
                          ? "$" + items.item.pending_amount
                          : "$0"
                      }}
                    </template>
                    <template #cell(last_trans_amount)="items">
                      <!-- {{items.item.last_trans_amount ? items.item.last_trans_amount : '0'}} -->
                      {{
                        items.item.last_trans_amount
                          ? "$" + items.item.last_trans_amount
                          : "$0"
                      }}
                    </template>
                    <template #cell(status)="items">
                      <p
                        class="green_status"
                        v-if="items.item.status == 'active'"
                      >
                        {{ items.item.status | capitalize }}
                      </p>
                      <p
                        class="orange__status"
                        v-if="items.item.status == 'inactive'"
                      >
                        {{ items.item.status | capitalize }}
                      </p>
                      <p
                        class="red_status"
                        v-if="items.item.status == 'blacklisted'"
                      >
                        {{ items.item.status | capitalize }}
                      </p>
                    </template>
                    <template #cell(ft_account_status)="items">
                      <!-- {{items.item.ft_account_status | capitalize}} -->
                      <p
                        v-if="items.item.ft_account_status == 'incomplete'"
                        v-bind:class="{
                          red_status:
                            items.item.ft_account_status == 'incomplete',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                      <p
                        v-if="items.item.ft_account_status == 'completed'"
                        v-bind:class="{
                          green_status:
                            items.item.ft_account_status == 'completed',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                      <p
                        v-if="items.item.ft_account_status == 'pending'"
                        v-bind:class="{
                          orange__status:
                            items.item.ft_account_status == 'pending',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                      <p
                        v-if="items.item.ft_account_status == 'rejected'"
                        v-bind:class="{
                          red_status:
                            items.item.ft_account_status == 'rejected',
                        }"
                      >
                        {{ items.item.ft_account_status | capitalize }}
                      </p>
                    </template>
                    <template #cell(account_verification_status)="items">
                      <!-- {{items.item.account_verification_status | capitalize}} -->
                      <p
                        v-if="
                          items.item.account_verification_status == 'incomplete'
                        "
                        v-bind:class="{
                          red_status:
                            items.item.account_verification_status ==
                            'incomplete',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                      <p
                        v-if="
                          items.item.account_verification_status == 'completed'
                        "
                        v-bind:class="{
                          green_status:
                            items.item.account_verification_status ==
                            'completed',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                      <p
                        v-if="
                          items.item.account_verification_status == 'pending'
                        "
                        v-bind:class="{
                          orange__status:
                            items.item.account_verification_status == 'pending',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                      <p
                        v-if="
                          items.item.account_verification_status == 'rejected'
                        "
                        v-bind:class="{
                          red_status:
                            items.item.account_verification_status ==
                            'rejected',
                        }"
                      >
                        {{
                          items.item.account_verification_status | capitalize
                        }}
                      </p>
                    </template>
                    <template #cell(toggleAction)="items">
                      <div class="toggle-action toggle_margin_unset top_100">
                        <!-- <b-dropdown class="mx-1 common_toggle_menu_hide_from_top" right text="Action" boundary="window"> -->
                        <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                        <b-dropdown
                          class="mx-1"
                          left
                          text="Action"
                          boundary="window"
                        >
                          <b-dropdown-item
                            v-if="show('view profile')"
                            :to="{
                              name: 'employer-profile-view',
                              params: {
                                id: encrypt(items.item.id),
                                company_id: encrypt(items.item.company_id),
                              },
                            }"
                            >View Profile
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('all jobs')"
                            @click="allJobs(items.item.company_id)"
                            >All Jobs
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('audit jobs')"
                            :to="{
                              name: 'employer-audit-jobs',
                              params: {
                                id: encrypt(items.item.id),
                                company_id: encrypt(items.item.company_id),
                              },
                            }"
                            >Audit Jobs
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="showComUser('view')"
                            :to="{
                              name: 'EmpManageUserAdmin',
                              params: { id: encrypt(items.item.company_id) },
                            }"
                            >Company Users
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-if="
                              (items.item.status == 'active' ||
                                items.item.status == 'inactive') &&
                              designation != 'Customer Service' &&
                              show('edit company')
                            "
                            :to="{
                              name: 'employer-edit',
                              params: { id: encrypt(items.item.id) },
                            }"
                            >Edit

                            <!-- <b-dropdown-item v-if="(items.item.status == 'active' || items.item.status == 'inactive') && designation != 'Customer Service'" :to="{name: 'employer-edit', params: {id: encrypt(items.item.id)}}">Edit -->
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-on:click="
                              changeCompanyStatus(
                                items.item.id,
                                items.item.company_id,
                                items.item.status
                              )
                            "
                            v-if="
                              items.item.status == 'active' &&
                              items.item.account_verification_status ==
                                'completed' &&
                              show('activate/inactivate')
                            "
                            >Inactivate</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-else-if="
                              items.item.status == 'inactive' &&
                              items.item.account_verification_status ==
                                'completed' &&
                              show('activate/inactivate')
                            "
                            v-on:click="
                              changeCompanyStatus(
                                items.item.id,
                                items.item.company_id,
                                items.item.status
                              )
                            "
                            >Activate
                          </b-dropdown-item>
                          <!-- <b-dropdown-item
                            v-if="
                              items.item.ft_account_status != 'completed' && show('delete company')
                            "
                            v-on:click="deleteCompany(items.item.id, items.item.company_id)"
                            >Delete</b-dropdown-item
                          > -->
                          <b-dropdown-item
                            v-if="
                              items.item.email &&
                              show('send password reset link')
                            "
                            @click="sendResetPasswordLink(items.item.id)"
                            >Send Reset Password Link
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.email_verified == 'no' &&
                              items.item.email &&
                              show('send verify email link')
                            "
                            @click="sendEmailVerificationLink(items.item.id)"
                            >Send Email Verification Link
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('update password')"
                            @click="
                              openInfoModel(
                                items.item.id,
                                items.item.company_id,
                                'password'
                              )
                            "
                            >Update Password
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.account_verification_status !=
                                'rejected' &&
                              items.item.status != 'deleted' &&
                              show('update email')
                            "
                            @click="
                              openInfoModel(
                                items.item.id,
                                items.item.company_id,
                                'email'
                              )
                            "
                            >Update Email
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.account_verification_status !=
                                'rejected' &&
                              items.item.status != 'deleted' &&
                              show('update mobile number')
                            "
                            @click="
                              openInfoModel(
                                items.item.id,
                                items.item.company_id,
                                'mobile'
                              )
                            "
                            >Update Mobile Number
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('update elc credits')"
                            @click="
                              openCoinModel(
                                items.item.id,
                                items.item.company_id,
                                items.item.coins,
                                'coins'
                              )
                            "
                            >Update ELC Credits
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('update free job ads')"
                            @click="
                              openJobAdsModel(
                                items.item.id,
                                items.item.company_id,
                                items.item.coins,
                                'coins'
                              )
                            "
                            >Update Free Job Ads
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.posting_paused == 'yes' &&
                              items.item.account_verification_status ==
                                'completed' &&
                              show('freeze/unfreeze Account')
                            "
                            v-on:click="
                              pausePosting(
                                items.item.id,
                                items.item.posting_paused
                              )
                            "
                            >Unfreeze Account</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-if="
                              items.item.posting_paused == 'no' &&
                              items.item.account_verification_status ==
                                'completed' &&
                              show('freeze/unfreeze Account')
                            "
                            v-on:click="
                              pausePosting(
                                items.item.id,
                                items.item.posting_paused
                              )
                            "
                            >Freeze Account</b-dropdown-item
                          >

                          <b-dropdown-item
                            v-if="
                              items.item.email_verified == 'no' &&
                              items.item.email &&
                              show('verify email')
                            "
                            v-on:click="verifyEmail(items.item.id)"
                            >Verify Email</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-if="
                              items.item.mobile_verified == 'no' &&
                              items.item.mobile_number &&
                              show('verify mobile number')
                            "
                            v-on:click="verifyMobileNumber(items.item.id)"
                            >Verify Mobile Number</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-if="
                              items.item.account_verification_status ==
                                'completed' && show('generate invoice')
                            "
                            @click="
                              openModel(
                                items.item.company_id,
                                items.item.billing_cycle
                              )
                            "
                            >Generate Invoice
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.qrcode_url != null &&
                              items.item.account_verification_status ==
                                'completed' &&
                              show('download qr code')
                            "
                            @click="
                              empQrCode(items.item.qrcode_url, '', 'download')
                            "
                            >Download QR Code
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.qrcode_url != null &&
                              items.item.account_verification_status ==
                                'completed' &&
                              show('regenerate qr code')
                            "
                            @click="
                              empQrCode(
                                items.item.company_id,
                                items.item.id,
                                'generate'
                              )
                            "
                            >Regenerate QR Code
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-else-if="
                              items.item.account_verification_status ==
                                'completed' && show('generate qr code')
                            "
                            @click="
                              empQrCode(
                                items.item.company_id,
                                items.item.id,
                                'generate'
                              )
                            "
                            >Generate QR Code
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('gallery')"
                            :to="{
                              name: 'emp-gallery-admin',
                              params: { id: encrypt(items.item.id) },
                            }"
                            >Gallery
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('company log')"
                            :to="{
                              name: 'emp-log-admin',
                              params: {
                                id: encrypt(items.item.id),
                                company_id: encrypt(items.item.company_id),
                              },
                            }"
                            >Company Log
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="show('transactions')"
                            :to="{
                              name: 'EmpTransactionAdmin',
                              params: {
                                id: encrypt(items.item.id),
                                company_id: encrypt(items.item.company_id),
                              },
                            }"
                            >Transactions
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="showBillingAdd('view')"
                            :to="{
                              name: 'BillingAddress',
                              params: {
                                id: encrypt(items.item.id),
                                company_id: encrypt(items.item.company_id),
                              },
                            }"
                            >Billing Address
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.account_verification_status ==
                                'incomplete' && show('convert to fl')
                            "
                            @click="
                              empConvertFL(items.item.id, items.item.company_id)
                            "
                            >Convert to FL
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.status == 'blacklisted' &&
                              show('reactive')
                            "
                            v-on:click="
                              openDisableModel(
                                items.item.id,
                                items.item.status,
                                'reactive'
                              )
                            "
                            >Reactive</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-else-if="show('blacklist')"
                            @click="
                              openDisableModel(items.item.id, '', 'blacklist')
                            "
                            >Blacklist</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-if="
                              items.item.subscribe_mail == 'yes' &&
                              show('subscribe/unsubscribe mailing list')
                            "
                            @click="subscribeUnsubscribeMail(items.item.id)"
                            >Unsubscribe Mailing List</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-if="
                              items.item.subscribe_mail == 'no' &&
                              show('subscribe/unsubscribe mailing list')
                            "
                            @click="subscribeUnsubscribeMail(items.item.id)"
                            >Subscribe Mailing List
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </template>
                  </b-table>
                  <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                      <b-form-group id="input-group-4">
                        <span class="d-inline-block">Rows per page</span>
                        <span class="d-inline-block">
                          <b-form-select
                            v-model="form.rowsPerPagePrepaid"
                            @change="getPrepaid"
                          >
                            <option>25</option>
                            <option>50</option>
                            <option>75</option>
                            <option>100</option>
                          </b-form-select></span
                        >
                      </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                      <ul>
                        <li>
                          <span v-if="totalRowsPrepaid != 0"
                            >{{ fromPrepaid }} - {{ toPrepaid }} of
                            {{ totalRowsPrepaid }}</span
                          >
                        </li>
                      </ul>
                    </div>
                    <b-pagination
                      :total-rows="totalRowsPrepaid"
                      :per-page="perPagePrepaid"
                      v-model="currentPagePrepaid"
                      prev-text=""
                      next-text=""
                      hide-goto-end-buttons
                    />
                  </nav>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>

    <b-modal
      ref="recalculate-wallet-modal"
      title="Recalculate Wallet"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class model_padding_left_zero inline_radio_common"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero">
        <div class="profile-repeate form-box-field">
          <b-col lg="12" md="12">
            <div class="form-map-box">
              <b-row>
                <b-col lg="6" md="6" class="model_submit_field">
                  <b-form-group
                    id="input-group-6"
                    label="Current Credits"
                    class="model_margin_bottom_zero required new_model_date_picker model_date_picker"
                  >
                    <b-form-input
                      v-model="current_coins"
                      type="text"
                      readonly
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6" class="model_submit_field">
                  <b-form-group
                    id="input-group-6"
                    label="Current Hold Credits"
                    class="model_margin_bottom_zero required new_model_date_picker model_date_picker"
                  >
                    <b-form-input
                      v-model="hold_coins"
                      type="number"
                      readonly
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6" class="model_submit_field">
                  <b-form-group
                    id="input-group-6"
                    label="Recalculated Credits"
                    class="model_margin_bottom_zero required new_model_date_picker model_date_picker"
                  >
                    <b-form-input
                      v-model="recalculated_value_old"
                      type="text"
                      readonly
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6" class="model_submit_field">
                  <b-form-group
                    id="input-group-6"
                    label="Recalculated Hold Credits"
                    class="model_margin_bottom_zero required new_model_date_picker model_date_picker"
                  >
                    <b-form-input
                      v-model="recalculated_hold_coins_old"
                      type="text"
                      readonly
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col lg="6" md="6" class="model_submit_field">
                  <b-form-group
                    id="input-group-6"
                    label="New Credits"
                    class="model_margin_bottom_zero required new_model_date_picker model_date_picker"
                  >
                    <b-form-input
                      v-model="recalculated_value"
                      type="text"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col lg="6" md="6" class="model_submit_field">
                  <b-form-group
                    id="input-group-6"
                    label="New Hold Credits"
                    class="model_margin_bottom_zero required new_model_date_picker model_date_picker"
                  >
                    <b-form-input
                      v-model="recalculated_hold_coins"
                      type="text"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <div slot="footer" class="form-btn common_crop_reset_btn">
                <b-button
                  type="submit"
                  variant="success"
                  class="model_common_bt_success_color"
                  @click="recalculateWallet()"
                  >Submit</b-button
                >
                <b-button
                  @click="hideModel()"
                  variant="light"
                  class="model_common_bt_lite_color"
                  >Cancel</b-button
                >
              </div>
            </div>
          </b-col>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>

    <!-- Page Content
        ================================================== -->
    <b-modal
      ref="invoice-modal"
      title="Select Month"
      content-class="common_model_header common_model_title"
      hide-footer
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box">
                <b-col
                  lg="6"
                  md="6"
                  class="model_padding_zero model_submit_field"
                >
                  <b-form-group
                    id="input-group-6"
                    label="Invoice Month & Year"
                    class="model_margin_bottom_zero required new_model_date_picker model_date_picker"
                  >
                    <datepicker
                      v-model="month"
                      :format="customFormatterTwo"
                      initial-view="year"
                      minimum-view="month"
                      :disabled-dates="disabledFromDateTwo"
                    ></datepicker>
                    <span class="input-icon"
                      ><span class="count"></span
                      ><b-img
                        v-bind:src="
                          webUrl + 'assets/images/calendar-grey-icon02.svg'
                        "
                        alt="Calander Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="0"
                  md="0"
                  class="model_submit_field employer_multiple_radio"
                >
                  <b-form-group
                    id="input-group-8"
                    label="Duration "
                    class="category-radio model_margin_bottom_zero"
                    v-if="billing_cycle == 'monthly'"
                  >
                    <b-form-radio v-model="duration" name="duration" value="3"
                      >Full Month</b-form-radio
                    >
                  </b-form-group>
                  <b-form-group
                    id="input-group-8"
                    label="Duration "
                    class="category-radio model_margin_bottom_zero"
                    v-else
                  >
                    <b-form-radio v-model="duration" name="duration" value="1"
                      >1-15</b-form-radio
                    >
                    <b-form-radio v-model="duration" name="duration" value="2"
                      >16-30</b-form-radio
                    >
                  </b-form-group>
                  <!-- <b-form-group id="input-group-8" label="Duration " class="category-radio model_margin_bottom_zero">
                                                <b-form-radio v-model="duration" name="duration" value="1">1-15</b-form-radio>
                                                <b-form-radio v-model="duration" name="duration" value="2">16-30</b-form-radio>

                                            </b-form-group> -->
                </b-col>
                <b-col
                  lg="0"
                  md="0"
                  class="model_submit_field employer_multiple_radio"
                >
                  <b-form-group>
                    <b-form-checkbox
                      v-model="withNoEmail"
                      @input="withNoEmailFunc($event)"
                      ><strong>With No Email</strong></b-form-checkbox
                    >
                  </b-form-group>
                </b-col>
                <div slot="footer" class="form-btn common_crop_reset_btn">
                  <b-button
                    type="submit"
                    variant="success"
                    class="model_common_bt_success_color"
                    @click="onGenerateInvoice()"
                    >Submit</b-button
                  >
                  <b-button
                    @click="hideModel()"
                    variant="light"
                    class="model_common_bt_lite_color"
                    >Cancel</b-button
                  >
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="sales-modal"
      title="Update Sales Status"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class model_padding_left_zero inline_radio_common"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <strong
                >Selected Company: {{ form.company_name | capitalize }}</strong
              >
            </b-col>
            <b-col lg="12" md="12">
              <div class="form-map-box">
                <!-- <b-col lg="12" md="12"> -->
                <b-form-group
                  id="input-group-6"
                  label="Call Date"
                  class="model_date_picker"
                >
                  <datepicker
                    v-model="form.call_date"
                    :disabled-dates="disabledToDates()"
                    :format="customFormatterSales"
                  ></datepicker>
                  <!-- <span class="input-icon"><b-img v-bind:src="webUrl+'assets/images/calendar-grey-icon02.svg'" alt="Calander Icon" class="model_vertical_align_bottom"></b-img></span> -->
                  <i
                    class="material-icons-outlined new_icon_sidebar_material common_vertical_align_sub model_material_icon_calender"
                    >calendar_month_outlined</i
                  >
                </b-form-group>
                <!-- </b-col> -->
                <!-- <b-col lg="12" md="12"> -->
                <b-form-group
                  id="input-group-7"
                  label="Sales Lead"
                  class="category-radio model_radio"
                >
                  <b-form-radio
                    v-model="form.sales_lead"
                    name="inbound"
                    value="inbound"
                    >Inbound</b-form-radio
                  >
                  <b-form-radio
                    v-model="form.sales_lead"
                    name="outbound"
                    value="outbound"
                    >Outbound</b-form-radio
                  >
                </b-form-group>
                <!-- </b-col> -->
                <!-- <b-col lg="12" md="12"> -->
                <b-form-group
                  id="input-group-8"
                  label="Sales Status "
                  class="category-radio model_radio"
                >
                  <b-form-radio
                    v-model="form.sales_status"
                    name="closed"
                    value="closed"
                    >Closed</b-form-radio
                  >
                  <b-form-radio
                    v-model="form.sales_status"
                    name="follow_up"
                    value="follow_up"
                    >Follow-up</b-form-radio
                  >
                  <b-form-radio
                    v-model="form.sales_status"
                    name="rejected"
                    value="rejected"
                    >Rejected</b-form-radio
                  >
                </b-form-group>
                <!-- </b-col> -->
                <!-- <b-col lg="12" md="12"> -->
                <b-form-group id="" label="Remarks" class="submit-field">
                  <b-form-textarea
                    id=""
                    class="textareaResizeMore model_box_shadow_unset model_margin_bottom_zero model_text_area"
                    :value="form.sales_remarks"
                    @change="(v) => (form.sales_remarks = v)"
                    rows="6"
                    type="textarea"
                  ></b-form-textarea>
                </b-form-group>
                <!-- </b-col> -->
                <!-- <b-col lg="12" md="12"> -->
                <b-form-group
                  id="input-group-8"
                  label="Closed By"
                  class="required"
                >
                  <multiselect
                    v-model="form.staffName"
                    id="ajax"
                    :custom-label="customLabel"
                    track-by="id"
                    placeholder="Type to search staff name"
                    open-direction="bottom"
                    :options="allStaff"
                    :searchable="true"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :allow-empty="true"
                    :multiple="false"
                  >
                    <span slot="noResult">No Staff Found.</span>
                  </multiselect>
                </b-form-group>
                <!-- </b-col> -->
                <div slot="footer" class="form-btn common_crop_reset_btn">
                  <b-button
                    type="submit"
                    variant="success"
                    @click="updateSales()"
                    class="model_margin_left_zero model_common_bt_success_color"
                    >Submit</b-button
                  >
                  <b-button
                    @click="hideModel()"
                    variant="light"
                    class="model_common_bt_lite_color"
                    >Cancel</b-button
                  >
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <!-- <b-modal hide-footer hide-header id="attach-sup-modal" ref="attach-sup-modal" centered> -->
    <b-modal
      hide-footer
      id="attach-sup-modal"
      ref="attach-sup-modal"
      title="Select User"
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <div class="edit-task">
        <b-form @submit.prevent="attachSupSubmit" method="post">
          <div class="popup-header model_legend">
            <!-- <h2 class="">Select User</h2> -->
            <div class="desktop_margin_top_zero">
              <div class="addUserForm">
                <div class="editForm mw-100 pl-0">
                  <b-row>
                    <b-col lg="12" md="12" class="submit-field">
                      <b-form-group
                        id="input-group-8"
                        label="All Users"
                        class="required model_margin_bottom_zero"
                      >
                        <multiselect
                          v-model="supervisorName"
                          :custom-label="customLabel"
                          tag-placeholder="Add this as new user"
                          placeholder="Enter to search user"
                          label="full_name"
                          track-by="id"
                          :options="selectedSupervisor"
                          :multiple="true"
                          class="job_list_multi_select model_multi_select"
                          ><span slot="noResult"
                            >No User Found.</span
                          ></multiselect
                        >
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <div slot="footer" class="form-btn common_crop_reset_btn">
                    <b-button
                      type="submit"
                      variant="success"
                      class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </b-modal>
    <b-modal
      ref="info-modal"
      :title="updateInfoTitle"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box model_legend">
                <b-col
                  lg="0"
                  md="0"
                  v-if="updateInfoTitle == 'Update Password'"
                  class="submit-field"
                >
                  <div id="show_hide_password_login" class="submit-field">
                    <b-form-group
                      id="input-group-0"
                      label="New Password"
                      class="required model_margin_bottom_zero"
                    >
                      <b-form-input
                        id="input-0"
                        type="password"
                        placeholder=""
                        :value="new_password"
                        @change="(v) => (new_password = v)"
                        class="input-with-border model_border_and_box_shadow"
                      ></b-form-input>
                    </b-form-group>
                    <div class="input-group-addon-custom update_pass_eye_div1">
                      <a @click="passwordClick">
                        <i
                          class="fa fa-eye-slash common_cusror_hand_pointer"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </div>
                  </div>
                  <div id="show_hide_repassword_login">
                    <b-form-group
                      id="input-group-0"
                      label="Repeat New Password"
                      class="required model_margin_bottom_zero"
                    >
                      <b-form-input
                        id="input-0"
                        type="password"
                        placeholder=""
                        :value="confirm_new_password"
                        @change="(v) => (confirm_new_password = v)"
                        class="input-with-border model_border_and_box_shadow"
                      ></b-form-input>
                    </b-form-group>
                    <div class="input-group-addon-custom update_pass_eye_div">
                      <a @click="repasswordClick">
                        <i
                          class="fa fa-eye-slash common_cusror_hand_pointer"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </div>
                  </div>
                </b-col>
                <b-col
                  lg="9"
                  md="9"
                  v-if="updateInfoTitle == 'Update Email'"
                  class="model_padding_left_zero model_padding_right_zero"
                >
                  <b-form-group
                    id="input-group-0"
                    label="Email"
                    class="required submit-field"
                  >
                    <b-form-input
                      id="input-0"
                      type="text"
                      placeholder=""
                      :value="update_email"
                      @change="(v) => (update_email = v)"
                      class="model_border_and_box_shadow"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="9"
                  md="9"
                  v-if="updateInfoTitle == 'Update Mobile'"
                  class="model_padding_left_zero model_padding_right_zero"
                >
                  <b-form-group
                    id="input-group-0"
                    label="Mobile Number"
                    class="required submit-field"
                  >
                    <b-form-input
                      id="input-0"
                      type="text"
                      placeholder=""
                      :value="update_mobile_number"
                      @change="(v) => (update_mobile_number = v)"
                      class="model_border_and_box_shadow"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0">
                  <div slot="footer" class="form-btn common_crop_reset_btn">
                    <b-button
                      type="submit"
                      variant="success"
                      v-if="updateInfoTitle == 'Update Password'"
                      @click="submitUpdateInfoPassword()"
                      class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      type="submit"
                      variant="success"
                      v-else
                      @click="submitUpdateInfo()"
                      class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </b-col>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="coin-modal"
      title="Update ELC Credits"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero update_coins_pop_up">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box model_legend model_label">
                <b-col lg="0" md="0" class="submit-field">
                  <div id="show_hide_password_login" class="submit-field">
                    <!-- <b-form-group id="input-group-0" label="Current ELC Credits" class="required model_margin_bottom_zero"> -->
                    <!-- <b-form-input id="input-0" type="text" placeholder="" :value="user_coins" @change="v => user_coins = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                    <label class="model_inline model_margin_bottom_zero"
                      ><b>Current ELC Credits</b></label
                    ><span class="model_font_16 model_float_right">
                      {{ user_coins }}</span
                    >
                    <!-- </b-form-group> -->
                  </div>
                </b-col>
                <b-col lg="0" md="0">
                  <b-form-group
                    id="input-group-8"
                    label=""
                    class="category-radio employer_multiple_radio"
                  >
                    <b-form-radio v-model="add_deduct_coins" value="add"
                      >Add</b-form-radio
                    >
                    <b-form-radio v-model="add_deduct_coins" value="deduct"
                      >Deduct</b-form-radio
                    >
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0" v-if="add_deduct_coins == 'add'"
                  ><label class="model_margin_bottom_zero single_label_margin"
                    ><b>Type of Transaction</b></label
                  >
                  <b-form-group
                    id="input-group-8"
                    label=""
                    class="category-radio employer_multiple_radio"
                  >
                    <b-form-radio v-model="add_topup_add_back" value="top_up"
                      >Top Up</b-form-radio
                    >
                    <b-form-radio v-model="add_topup_add_back" value="add_back"
                      >Add Back</b-form-radio
                    >
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0" v-if="add_deduct_coins == 'deduct'"
                  ><label class="model_margin_bottom_zero single_label_margin"
                    ><b>Type of Transaction</b></label
                  >
                  <b-form-group
                    id="input-group-8"
                    label=""
                    class="category-radio employer_multiple_radio"
                  >
                    <b-form-radio
                      v-model="deduct_job_comp_refund"
                      value="job_completed"
                      >Job Completed</b-form-radio
                    >
                    <b-form-radio
                      v-model="deduct_job_comp_refund"
                      value="refund"
                      >Refund</b-form-radio
                    >
                  </b-form-group>
                </b-col>
                <b-col
                  lg="0"
                  md="0"
                  class="submit-field"
                  v-if="add_deduct_coins == 'add'"
                >
                  <div id="show_hide_password_login" class="submit-field">
                    <!-- <b-form-group id="input-group-0" label="Add ELC Credits" class="required model_margin_bottom_zero"> -->
                    <!-- <b-form-input id="input-0" type="number" min="0" placeholder="" :value="new_user_coins" @change="v => new_user_coins = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                    <div class="row">
                      <div
                        class="col-xl-7 mobile_col_7 common_align_middle_text"
                      >
                        <label class="model_margin_bottom_zero"
                          ><b>Add ELC Credits</b></label
                        >
                      </div>
                      <div class="col-xl-5 mobile_col_5 submit-field">
                        <b-form-input
                          id="input-0"
                          type="text"
                          placeholder=""
                          :value="new_user_coins"
                          @change="(v) => (new_user_coins = v)"
                          class="input-with-border model_border_and_box_shadow"
                        ></b-form-input>
                      </div>
                    </div>
                    <!-- </b-form-group> -->
                  </div>
                </b-col>
                <b-col
                  lg="0"
                  md="0"
                  class="submit-field"
                  v-if="add_deduct_coins == 'deduct'"
                >
                  <div id="show_hide_password_login" class="">
                    <!-- <b-form-group id="input-group-0" label="Add ELC Credits" class="required model_margin_bottom_zero"> -->
                    <!-- <b-form-input id="input-0" type="number" min="0" placeholder="" :value="new_user_coins" @change="v => new_user_coins = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                    <div
                      class="row"
                      v-if="deduct_job_comp_refund == 'job_completed'"
                    >
                      <div
                        class="col-xl-7 mobile_col_7 submit-field common_align_middle_text"
                      >
                        <label class="model_margin_bottom_zero"
                          ><b>Job ID</b></label
                        >
                      </div>
                      <div class="col-xl-5 mobile_col_5 submit-field">
                        <b-form-input
                          id="input-0"
                          type="text"
                          placeholder=""
                          :value="job_id_coins"
                          @change="(v) => (job_id_coins = v)"
                          class="input-with-border model_border_and_box_shadow"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="col-xl-7 mobile_col_7 submit-field common_align_middle_text"
                      >
                        <label class="model_margin_bottom_zero"
                          ><b>Deduct ELC Credits</b></label
                        >
                      </div>
                      <div class="col-xl-5 mobile_col_5 submit-field">
                        <b-form-input
                          id="input-0"
                          type="text"
                          placeholder=""
                          :value="new_user_coins_deduct"
                          @change="(v) => (new_user_coins_deduct = v)"
                          class="input-with-border model_border_and_box_shadow"
                        ></b-form-input>
                      </div>
                    </div>

                    <!-- </b-form-group> -->
                  </div>
                </b-col>
                <b-col lg="0" md="0">
                  <div slot="footer" class="form-btn common_crop_reset_btn">
                    <b-button
                      type="submit"
                      variant="success"
                      @click="submitUpdateCoinInfo()"
                      class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </b-col>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="job-ads-modal"
      title="Update Free Job Ads"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero update_coins_pop_up">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box model_legend model_label">
                <b-col lg="0" md="0" class="submit-field">
                  <div id="show_hide_password_login" class="submit-field">
                    <!-- <b-form-group id="input-group-0" label="Current ELC Credits" class="required model_margin_bottom_zero"> -->
                    <!-- <b-form-input id="input-0" type="text" placeholder="" :value="user_coins" @change="v => user_coins = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                    <label class="model_inline model_margin_bottom_zero"
                      ><b>Current Free Job Ads</b></label
                    ><span class="model_font_16 model_float_right">
                      {{ free_jobs }}</span
                    >
                    <!-- </b-form-group> -->
                  </div>
                </b-col>

                <b-col lg="0" md="0" class="submit-field">
                  <div id="show_hide_password_login" class="submit-field">
                    <!-- <b-form-group id="input-group-0" label="Add ELC Credits" class="required model_margin_bottom_zero"> -->
                    <!-- <b-form-input id="input-0" type="number" min="0" placeholder="" :value="new_user_coins" @change="v => new_user_coins = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                    <div class="row">
                      <div
                        class="col-xl-7 mobile_col_7 common_align_middle_text"
                      >
                        <label class="model_margin_bottom_zero"
                          ><b>Add Free Job Ads</b></label
                        >
                      </div>
                      <div class="col-xl-5 mobile_col_5 submit-field">
                        <b-form-input
                          id="input-0"
                          type="text"
                          placeholder=""
                          :value="new_user_job_ads"
                          @change="(v) => (new_user_job_ads = v)"
                          class="input-with-border model_border_and_box_shadow"
                        ></b-form-input>
                      </div>
                    </div>
                    <!-- </b-form-group> -->
                  </div>
                </b-col>

                <b-col lg="0" md="0">
                  <div slot="footer" class="form-btn common_crop_reset_btn">
                    <b-button
                      type="submit"
                      variant="success"
                      @click="submitUpdateJobAdsInfo()"
                      class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </b-col>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="disable-modal"
      :title="updateInfoTitleFL"
      content-class="common_model_header common_model_title"
      hide-footer
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box">
                <b-col
                  lg="0"
                  md="0"
                  v-if="model_type == 'disable'"
                  class="submit-field"
                >
                  <b-form-group
                    id="input-group-6"
                    label="Re-enable Date"
                    class="model_margin_bottom_zero required model_date_picker"
                  >
                    <datepicker
                      v-model="renable_date"
                      :disabled-dates="disabledFromDate2()"
                      :format="customFormatterOne"
                      class=""
                    ></datepicker>
                    <span class="input-icon"
                      ><span class="count"></span
                      ><b-img
                        v-bind:src="
                          webUrl + 'assets/images/calendar-grey-icon02.svg'
                        "
                        alt="Calander Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0" class="submit-field">
                  <b-form-group
                    id="input-group-5"
                    label="Remarks"
                    class="model_margin_bottom_zero"
                  >
                    <b-form-textarea
                      id="textarea-3"
                      type="textarea"
                      placeholder=""
                      :value="remarks"
                      @change="(v) => (remarks = v)"
                      class="model_box_shadow_unset model_margin_bottom_zero model_text_area"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <!-- <b-col lg="12" md="12" v-if="model_type == 'blacklist'">
                                            <b-form-group>
                                                <b-form-checkbox v-model="withNoEmailBlacklist" @input="withNoEmailFuncBlacklist($event)"><strong>With No Email</strong></b-form-checkbox>
                                            </b-form-group>
                                        </b-col> -->
                <b-col lg="0" md="0" class="">
                  <div slot="footer" class="form-btn common_crop_reset_btn">
                    <b-button
                      type="submit"
                      variant="success"
                      v-if="model_type == 'disable'"
                      @click="disableJobseeker()"
                      class="model_margin_left_zero model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      type="submit"
                      variant="success"
                      v-else-if="model_type == 'blacklist'"
                      @click="blacklistJobseeker()"
                      class="model_margin_left_zero model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      type="submit"
                      variant="success"
                      v-else
                      @click="changeUserStatus(update_user_id, usrStatus)"
                      class="model_margin_left_zero model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </b-col>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";
import DateRangePicker from "vue2-daterange-picker";
import Bus from "../../../event-bus";
import permission from "../../../../../server/permission.js";

export default {
  data() {
    return {
      fieldsCompanies: [
        { key: "toggleAction", label: "Actions", thStyle: { width: "4%" } },
        {
          key: "name",
          label: "Name & Email",
          sortable: true,
          thStyle: { width: "10%" },
        },
        {
          key: "last_name",
          label: "Contact Person Name",
          sortable: true,
          thStyle: { width: "14%" },
        },
        {
          key: "last_logged_in_at",
          label: "Last Login",
          sortable: false,
          thStyle: { width: "12%" },
        },
        {
          key: "mobile_number",
          label: "Mobile Number",
          sortable: true,
          thStyle: { width: "8%" },
        },
        {
          key: "status",
          label: "Account Status",
          sortable: true,
          thStyle: { width: "8%" },
        },
        {
          key: "ft_account_status",
          label: "FT Status",
          sortable: true,
          thStyle: { width: "10%" },
        },
        {
          key: "account_verification_status",
          label: "DP Status",
          sortable: true,
          thStyle: { width: "10%" },
        },
        {
          key: "job_preference",
          label: "Posting Preference",
          sortable: true,
          thStyle: { width: "8%" },
        },
        {
          key: "created_at",
          label: "Register On",
          sortable: true,
          thStyle: { width: "8%" },
        },
        {
          key: "first_job_posted_date",
          label: "First Job Posted Date",
          sortable: true,
          thStyle: { width: "8%" },
        },
        // { key: 'toggleAction', label: 'Actions', }
      ],
      fieldsSupervisorOm: [
        { key: "toggleAction", label: "Actions", thStyle: { width: "4%" } },
        {
          key: "last_name",
          label: "Name & Email",
          sortable: true,
          thStyle: { width: "15%" },
        },
        {
          key: "name",
          label: "Company Name",
          sortable: true,
          thStyle: { width: "20%" },
        },
        {
          key: "mobile_number",
          label: "Mobile Number",
          sortable: true,
          thStyle: { width: "10%" },
        },
        {
          key: "status",
          label: "Account Status",
          sortable: true,
          thStyle: { width: "15%" },
        },
        {
          key: "ft_account_status",
          label: "FT Status",
          sortable: true,
          thStyle: { width: "12%" },
        },
        {
          key: "account_verification_status",
          label: "DP Status",
          sortable: true,
          thStyle: { width: "12%" },
        },
        {
          key: "created_at",
          label: "Register On",
          sortable: true,
          thStyle: { width: "12%" },
        },
        // { key: 'toggleAction', label: 'Actions', }
      ],
      fieldsSales: [
        { key: "toggleAction", label: "Actions", thStyle: { width: "4%" } },
        {
          key: "name",
          label: "Name & Email",
          sortable: true,
          thStyle: { width: "10%" },
        },
        {
          key: "last_name",
          label: "Contact Person Name",
          sortable: true,
          thStyle: { width: "10%" },
        },
        {
          key: "last_logged_in_at",
          label: "Last Login",
          sortable: false,
          thStyle: { width: "8%" },
        },
        {
          key: "mobile_number",
          label: "Mobile Number",
          sortable: true,
          thStyle: { width: "8%" },
        },
        {
          key: "call_date",
          label: "Call Date",
          sortable: true,
          thStyle: { width: "5%" },
        },
        {
          key: "sales_lead",
          label: "Sales Lead",
          sortable: true,
          thStyle: { width: "5%" },
        },
        {
          key: "sales_status",
          label: "Sales Status",
          sortable: true,
          thStyle: { width: "5%" },
        },
        {
          key: "sales_remarks",
          label: "Remarks",
          sortable: true,
          thStyle: { width: "8%" },
        },
        {
          key: "closed_by",
          label: "Closed By",
          sortable: true,
          thStyle: { width: "8%" },
        },
        {
          key: "status",
          label: "Account Status",
          sortable: true,
          thStyle: { width: "6%" },
        },
        {
          key: "ft_account_status",
          label: "FT Status",
          sortable: true,
          thStyle: { width: "8%" },
        },
        {
          key: "account_verification_status",
          label: "DP Status",
          sortable: true,
          thStyle: { width: "8%" },
        },
        {
          key: "created_at",
          label: "Register On",
          sortable: true,
          thStyle: { width: "7%" },
        },
        // { key: 'toggleAction', label: 'Actions', }
      ],
      fieldsPrepaid: [
        { key: "toggleAction", label: "Actions", thStyle: { width: "4%" } },
        {
          key: "name",
          label: "Name & Email",
          sortable: true,
          thStyle: { width: "10%" },
        },
        {
          key: "last_name",
          label: "Contact Person Name",
          sortable: true,
          thStyle: { width: "14%" },
        },
        {
          key: "mobile_number",
          label: "Mobile Number",
          sortable: true,
          thStyle: { width: "12%" },
        },
        {
          key: "first_job_posted_date",
          label: "First Job Posted Date",
          sortable: true,
          thStyle: { width: "12%" },
        },
        {
          key: "status",
          label: "Account Status",
          sortable: true,
          thStyle: { width: "12%" },
        },
        // { key: 'ft_account_status', label: 'FT Status', sortable: true },
        {
          key: "account_verification_status",
          label: "DP Status",
          sortable: true,
          thStyle: { width: "8%" },
        },
        {
          key: "last_trans_amount",
          label: "Last Transaction Amount",
          sortable: true,
          thStyle: { width: "8%" },
        },
        {
          key: "coins",
          label: "Available Balance",
          sortable: true,
          thStyle: { width: "10%" },
        },
        {
          key: "hold_coins",
          label: "Pending Amount",
          sortable: true,
          thStyle: { width: "10%" },
        },
        // { key: 'created_at', label: 'Register On', sortable: true}
        // { key: 'toggleAction', label: 'Actions', }
      ],
      pager: {},
      pageOfItems: [],
      withNoEmail: true,
      withNoEmailBlacklist: true,

      itemsCompanies: null,
      totalRowsCompanies: 0,
      fromCompanies: "",
      toCompanies: "",
      currentPageCompanies: 1,
      perPageCompanies: 25,
      pageOptionsCompanies: [25, 50, 75, 100],
      sortByCompanies: "companies.id",
      sortDirectionCompanies: "desc",
      filterCompanies: "",
      searchKeywordCompanies: "",
      sortDescCompanies: true,
      itemsSupervisors: null,
      totalRowsSupervisors: 0,
      fromSupervisors: "",
      toSupervisors: "",
      currentPageSupervisors: 1,
      perPageSupervisors: 25,
      pageOptionsSupervisors: [25, 50, 75, 100],
      sortBySupervisors: "companies.id",
      sortDirectionSupervisors: "desc",
      filterSupervisors: "",
      searchKeywordSupervisors: "",
      sortDescSupervisors: true,
      itemsOm: null,
      totalRowsOm: 0,
      fromOm: "",
      toOm: "",
      currentPageOm: 1,
      perPageOm: 25,
      pageOptionsOm: [25, 50, 75, 100],
      sortByOm: "companies.id",
      sortDirectionOm: "desc",
      filterOm: "",
      searchKeywordOm: "",
      sortDescOm: true,
      itemsSales: null,
      totalRowsSales: 0,
      fromSales: "",
      toSales: "",
      currentPageSales: 1,
      perPageSales: 25,
      pageOptionsSales: [25, 50, 75, 100],
      sortBySales: "companies.id",
      sortDirectionSales: "desc",
      filterSales: "",
      searchKeywordSales: "",
      sortDescSales: true,
      itemsPrepaid: null,
      totalRowsPrepaid: 0,
      fromPrepaid: "",
      toPrepaid: "",
      currentPagePrepaid: 1,
      perPagePrepaid: 25,
      pageOptionsPrepaid: [25, 50, 75, 100],
      sortByPrepaid: "coins",
      sortDirectionPrepaid: "desc",
      filterPrepaid: "",
      searchKeywordPrepaid: "",
      sortDescPrepaid: true,
      status: "active",
      webUrl: process.env.VUE_APP_URL,
      webUrlV1: process.env.VUE_APP_URL_V1_Admin,
      webUrlV1exp: process.env.VUE_APP_URL_V1,
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      model_type: "blacklist",
      remarks: "",
      recalculated_hold_coins_old: "",
      form: {
        rowsPerPageCompanies: 25,
        rowsPerPageSupervisors: 25,
        rowsPerPageOm: 25,
        rowsPerPageSales: 25,
        rowsPerPagePrepaid: 25,
        call_date: null,
        sales_remarks: "",
        sales_lead: null,
        sales_status: null,
        user_id: "",
        company_id: "",
        company_name: "",
        staffName: "",
        staffNameSelected: "",
      },
      disabledFromDateTwo: {
        from: new Date(),
      },
      month: "",
      year: "",
      duration: 1,
      userDPAccountStatusCompanies: "all",
      userFTAccountStatusCompanies: "all",
      userJobPreferenceCompanies: "all",
      userDPAccountStatusSupervisors: "all",
      userDPAccountStatusOm: "all",
      salesStatus: "all",
      userDPAccountStatusPrepaid: "all",
      userFTAccountStatusSupervisors: "all",
      userFTAccountStatusOm: "all",
      userFTAccountStatusSales: "all",
      dpStatus: ["pending", "incomplete", "completed"],
      dpStatusCompanies: [
        "pending",
        "incomplete",
        "completed",
        "posting_paused",
      ],
      ftStatusCompanies: ["pending", "incomplete", "completed"],
      ftStatusSupervisors: ["pending", "incomplete", "completed"],
      ftStatusOm: ["pending", "incomplete", "completed"],
      ftStatusSales: ["pending", "incomplete", "completed"],
      dpStatusPrepaid: ["pending", "incomplete", "completed"],

      currentTab: "companies",
      supervisorName: "",
      selectedSupervisor: [],
      updateInfoTitle: "Update Password",
      updateInfoTitleFL: "Temporary Suspend Jobseeker",

      new_password: "",
      confirm_new_password: "",
      designation: "",
      user_coins: "",
      new_user_coins: "",
      job_id_coins: "",
      new_user_coins_deduct: "",
      add_deduct_coins: "",
      add_topup_add_back: "",
      deduct_job_comp_refund: "",
      userData: [],
      update_user_id: "",
      update_email: "",
      update_mobile_number: "",
      update_coin_user_id: "",
      update_coin_company_id: "",
      billing_cycle: "bi_monthly",
      salesLead: ["inbound", "outbound"],
      salesStatusArr: ["closed", "follow_up", "rejected"],
      allStaff: [],
      multiple: false,
      // dateRangeCompanies: {
      //     startDate: moment().subtract(3, 'months').format('DD MMM YYYY'),
      //     endDate: moment().format('DD MMM YYYY')
      // },
      dateRangeCompanies: {
        startDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      dateRangeSupervisors: {
        startDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      dateRangeOm: {
        startDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      dateRangeSales: {
        startDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      start_date_filter: moment().subtract(3, "months").format("YYYY-MM-DD"),
      end_date_filter: moment().format("YYYY-MM-DD"),

      minDate: null,
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      range: false,
      date: moment().utc().format("YYYY-MM-DD"),
      format: "YYYY-MM-DD HH:mm",
      auto: true,
      center: "center",
      defaultDateCompanies: moment()
        .subtract(3, "months")
        .format("DD MMM YYYY"),
      defaultDate1Companies: moment().format("DD MMM YYYY"),
      defaultDateSupervisors: moment()
        .subtract(3, "months")
        .format("DD MMM YYYY"),
      defaultDate1Supervisors: moment().format("DD MMM YYYY"),
      defaultDateOm: moment().subtract(3, "months").format("DD MMM YYYY"),
      defaultDate1Om: moment().format("DD MMM YYYY"),
      defaultDateSales: moment().subtract(3, "months").format("DD MMM YYYY"),
      defaultDate1Sales: moment().format("DD MMM YYYY"),
      actions: "",
      actionsComUser: "",
      actionsBillingAdd: "",
      actionsManageDepart: "",
      free_jobs: 0,
      new_user_job_ads: 0,
      recalculated_value: 0,
      recalculated_hold_coins: 0,
      recalculated_value_old: 0,
      recalculated_value_hold_coins_old: 0,
      credit: 0,
      debit: 0,
      recalcData: [],
      current_coins: 0,
      hold_coins: 0,
      recalUserId: "",
    };
  },
  components: {
    Multiselect,
    Datepicker,
    DateRangePicker,
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    moment: function (date) {
      if (date != null) return moment(date).utc().format("DD MMM YYYY hh:mm A");
      else return "-";
    },
    moment2: function (date) {
      if (date != null) return moment(date).utc().format("DD-MMM-YYYY");
      else return "-";
    },
    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    datePicker1: function (value) {
      if (value == null) {
        return "";
      } else {
        return moment(value).format("DD MMM YYYY");
      }
    },
  },
  methods: {
    disabledToDates() {
      var d = new Date();
      return {
        from: new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1),
      };
    },
    customFormatterSales(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    showSuccessAlert(message) {
      return this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        this.datatableRefresh();
        // this.$router.push({ name: 'employer-list' });
      });
    },
    customFormatterTwo(date) {
      return moment(date).format("MMM,YYYY");
    },
    openSalesModel(
      user_id,
      company_id,
      name,
      date,
      lead,
      status,
      remarks,
      closed_by
    ) {
      console.log(closed_by, "closed_by");
      this.form.user_id = user_id;
      this.form.company_id = company_id;
      this.form.company_name = name;
      this.form.call_date = date;
      if (status != null) {
        this.form.sales_status = status;
      } else {
        this.form.sales_status = null;
      }
      if (lead != null) {
        this.form.sales_lead = lead;
      } else {
        this.form.sales_lead = null;
      }
      if (closed_by != null) {
        this.form.staffNameSelected = closed_by;
      } else {
        this.form.staffNameSelected = "";
      }
      this.form.sales_remarks = remarks;
      this.$refs["sales-modal"].show();
      this.allStaffList();
    },
    openAttachSupModal(id) {
      this.$refs["attach-sup-modal"].show();
      this.supervisor_id = id;

      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
          },
          api: "/api/list-supervisors-to-assign-om",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.selectedSupervisor = this.$store.getters.getResults.data;
            this.supervisorName = this.$store.getters.getResults.selected;
            // return this.selectedSupervisor;
          }
        });
    },
    getUserData(id) {
      this.$store
        .dispatch(POST_API, {
          data: {
            id: id,
          },
          api: "/api/user-data-email-mobile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.userData = this.$store.getters.getResults.data;
            this.update_mobile_number = this.userData.mobile_number;
            this.update_email = this.userData.email;
            this.update_user_id = this.userData.id;
            this.free_jobs = this.userData.free_job_posts;
          }
        });
    },
    submitUpdateInfo() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.update_user_id,
            company_id: this.update_company_id,
            email: this.update_email,
            mobile_number: this.update_mobile_number,
            user_type: "admin",
            type: this.updateInfoTitle == "Update Mobile" ? "mobile" : "email",
          },
          api: "/api/emp-save-email-mobile-adminomsupervisor",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.success_message = this.$store.getters.getResults.message;
            this.hideModel();
            this.showSuccessAlert(this.success_message);
            this.update_mobile_number = "";
            this.update_email = "";
            this.update_user_id = "";
            this.update_company_id = "";

            // this.refreshDataTable();
          }
        });
    },
    changeUserStatus(id, status) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      // var status = status != null ? status : this.usrStatus;

      if (status == "active") {
        var msg = "Are you sure you want to inactivate this record?";
        var message = "Jobseeker Inactivated Successfully.";
      } else if (status == "inactive" || status == "deleted") {
        var msg = "Are you sure you want to activate this record?";
        var message = "Jobseeker Activated Successfully.";
        this.remarks = this.remarks ? this.remarks : null;
      } else {
        var msg = "Are you sure you want to reactivate this record?";
        var message = "Employer Reactivated Successfully.";
        this.remarks = this.remarks ? this.remarks : null;
      }
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                  admin_id: this.form.user_id,
                  status: status,
                  remarks: this.remarks,
                },
                api: "/api/employer-active-inactive-admin",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.hideModel();
                  this.showSuccessAlert(message);
                  // this.refreshDataTable();
                  this.remarks = null;
                  this.update_user_id = null;
                  // this.getJobseekersAll();
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    allStaffList() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            selctedClosedBy: this.form.staffNameSelected,
          },
          api: "/api/update-sales-all-staff-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.allStaff = this.$store.getters.getResults.data;
            this.form.staffName =
              this.$store.getters.getResults.selctedClosedByStaff;

            // return this.selectedSupervisor;
          }
        });
    },
    passwordClick(event) {
      event.preventDefault();
      if ($("#show_hide_password_login input").attr("type") == "text") {
        $("#show_hide_password_login input").attr("type", "password");
        $("#show_hide_password_login i").addClass("fa-eye-slash");
        $("#show_hide_password_login i").removeClass("fa-eye");
      } else if (
        $("#show_hide_password_login input").attr("type") == "password"
      ) {
        $("#show_hide_password_login input").attr("type", "text");
        $("#show_hide_password_login i").removeClass("fa-eye-slash");
        $("#show_hide_password_login i").addClass("fa-eye");
      }
    },
    repasswordClick(event) {
      event.preventDefault();
      if ($("#show_hide_repassword_login input").attr("type") == "text") {
        $("#show_hide_repassword_login input").attr("type", "password");
        $("#show_hide_repassword_login i").addClass("fa-eye-slash");
        $("#show_hide_repassword_login i").removeClass("fa-eye");
      } else if (
        $("#show_hide_repassword_login input").attr("type") == "password"
      ) {
        $("#show_hide_repassword_login input").attr("type", "text");
        $("#show_hide_repassword_login i").removeClass("fa-eye-slash");
        $("#show_hide_repassword_login i").addClass("fa-eye");
      }
    },
    submitUpdateInfoPassword() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.update_user_id,
            new_password: this.new_password,
            confirm_new_password: this.confirm_new_password,
          },
          api: "/api/emp-company-update-password-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.success_message = this.$store.getters.getResults.message;
            this.hideModel();
            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: this.success_message,
              showConfirmButton: false,
              timer: 2500,
            }).then((result) => {
              this.new_password = "";
              this.confirm_new_password = "";
            });
            // this.showSuccessAlert(this.success_message);
            // this.refreshDataTable();
          }
        });
    },
    submitUpdateCoinInfo() {
      var job_id_format = "^[a-zA-Z]{2}[0-9]{6}$";
      var err = false;

      if (this.job_id_coins != "" && !this.job_id_coins.match(job_id_format)) {
        err = true;
        this.error_message = "Invalid Job ID";
        this.showAlert();
      }

      if (err == false) {
        return this.$store
          .dispatch(POST_API, {
            data: {
              user_id: this.update_coin_user_id,
              company_id: this.update_coin_company_id,
              prev_coins: this.user_coins,
              coins: this.new_user_coins,
              coins_deduct: this.new_user_coins_deduct,
              job_unique_id: this.job_id_coins,
              add_deduct_coins: this.add_deduct_coins,
              add_topup_add_back: this.add_topup_add_back,
              deduct_job_comp_refund: this.deduct_job_comp_refund,
              created_by: this.form.user_id,
            },
            api: "/api/emp-company-update-coin-admin",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              this.success_message = this.$store.getters.getResults.message;
              this.new_user_coins = "";
              this.new_user_coins_deduct = "";
              this.job_id_coins = "";
              this.add_deduct_coins = "";
              this.add_topup_add_back = "";
              this.deduct_job_comp_refund = "";

              this.hideModel();
              this.showSuccessAlert(this.success_message);
              // this.datatableRefresh();
            }
          });
      }
    },
    updateSales() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.form.user_id,
            company_id: this.form.company_id,
            sales_lead: this.form.sales_lead,
            call_date: this.form.call_date,
            sales_status: this.form.sales_status,
            sales_remarks: this.form.sales_remarks,
            closed_by:
              this.form.staffName != "" && this.form.staffName != null
                ? this.form.staffName.id
                  ? this.form.staffName.id
                  : this.form.staffName[0].id
                : "",
          },
          api: "/api/update-company-sales-info",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.success_message = this.$store.getters.getResults.message;
            // this.datatableRefresh();
            this.showSuccessAlert(this.success_message);
            // this.form.sales_lead = '',
            // this.form.call_date = null,
            // this.form.sales_status = null,
            // this.form.sales_remarks = ''
            this.hideModel();
            // this.$refso.datatableSales.refresh();
          }
        });
    },
    attachSupSubmit(evt) {
      evt.preventDefault();
      this.hideModel();
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.supervisorName,
            supervisor_id: this.supervisor_id,
          },
          api: "/api/attach-supervisor-to-om",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.success_message = this.$store.getters.getResults.message;
            //   this.datatableRefresh();
            this.showSuccessAlert(this.success_message);
          }
        });
    },
    openModel(id, billing_cycle) {
      this.invoice_generate_for_user = id;
      this.billing_cycle = billing_cycle;
      this.$refs["invoice-modal"].show();
    },

    openRecalModal(id) {
      this.recalUserId = id;
      this.$refs["recalculate-wallet-modal"].show();

      return this.$store
        .dispatch(POST_API, {
          data: {
            id: id,
          },
          api: "/api/recalculate-wallet-data-for-employer",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.recalcData = this.$store.getters.getResults.data;

            this.credit = this.recalcData.creditTransactions[0].credit
              ? parseFloat(this.recalcData.creditTransactions[0].credit)
              : 0;
            this.debit = this.recalcData.debitTransactions[0].debit
              ? parseFloat(this.recalcData.debitTransactions[0].debit)
              : 0;
            this.pending = this.recalcData.pendingTransactions[0].pending
              ? parseFloat(this.recalcData.pendingTransactions[0].pending)
              : 0;

            this.recalculated_value_old = (this.credit - this.debit).toFixed(2);
            // this.recalculated_hold_coins = (
            //   parseFloat(this.recalcData.userCoins.hold_coins) - this.pending
            // ).toFixed(2);

            this.recalculated_hold_coins_old = this.pending.toFixed(2);

            this.current_coins = parseFloat(this.recalcData.userCoins.coins);
            this.hold_coins = parseFloat(this.recalcData.userCoins.hold_coins);

            // return this.selectedSupervisor;
          }
        });
    },
    hideModel() {
      this.$refs["invoice-modal"].hide();
      this.$refs["attach-sup-modal"].hide();
      this.$refs["info-modal"].hide();
      this.$refs["sales-modal"].hide();
      this.$refs["coin-modal"].hide();
      this.$refs["disable-modal"].hide();
      this.$refs["job-ads-modal"].hide();
      this.$refs["recalculate-wallet-modal"].hide();
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    onGenerateInvoice(id) {
      this.hideModel();
      this.$store
        .dispatch(POST_API, {
          data: {
            month: this.month != "" ? moment(this.month).format("MM") : "",
            year: this.month != "" ? moment(this.month).format("YYYY") : "",
            company_id: this.invoice_generate_for_user,
            with_no_email: this.withNoEmail,
            type: "admin",
            duration: this.duration,
          },
          api: "/api/admin-generate-invoice",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.items = this.$store.getters.getResults.data;
            this.success_message = this.$store.getters.getResults.message;

            this.payslip_generate_for_user = "";
            this.month = "";

            Swal.fire({
              position: "top-center",
              type: "success",
              text: this.success_message,
              showConfirmButton: false,
              timer: 3000,
            });
            //this.hideModel();
            this.datatableRefresh();
          }
        });
    },
    recalculateWallet() {
      this.hideModel();
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.recalUserId,
            recalculated_value: this.recalculated_value,
            recalculated_hold_coins: this.recalculated_hold_coins,
          },
          api: "/api/admin-recalculate-emp",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.items = this.$store.getters.getResults.data;
            this.success_message = this.$store.getters.getResults.message;
            this.showSuccessAlert(this.success_message);

            this.datatableRefresh();
          }
        });
    },

    datatableRefresh() {
      this.$refs.datatableCompanies.refresh();
      this.$refs.datatableSupervisors.refresh();
      this.$refs.datatableOm.refresh();
      this.$refs.datatableSales.refresh();
    },
    getDatas(type) {
      if (type == "companies") {
        this.currentTab = "companies";
        this.$refs.datatableCompanies.refresh();
        this.getCompanies();
      } else if (type == "supervisors") {
        this.currentTab = "supervisors";
        this.$refs.datatableSupervisors.refresh();
        this.getSupervisors();
      } else if (type == "om") {
        this.currentTab = "om";
        this.$refs.datatableOm.refresh();
        this.getOm();
      } else if (type == "sales") {
        this.currentTab = "sales";
        this.$refs.datatableSales.refresh();
        this.getSales();
      } else {
        this.currentTab = "prepaid";
        this.$refs.datatablePrepaid.refresh();
        this.getPrepaid();
      }
    },
    // getEmployerOnStatusChange()
    // {
    //     this.getCompanies().then(() => {
    //         this.$refs.datatableCompanies.refresh();
    //     });
    // },
    resetFilterAll() {
      this.searchKeywordCompanies = "";
      this.dateRangeCompanies.startDate = moment()
        .subtract(3, "months")
        .format("YYYY-MM-DD");
      this.dateRangeCompanies.endDate = moment().format("YYYY-MM-DD");
      this.userDPAccountStatusCompanies = "all";
      this.userFTAccountStatusCompanies = "all";
      this.userJobPreferenceCompanies = "all";
      this.$refs.datatableCompanies.refresh();
    },
    resetFilterSupervisors() {
      this.searchKeywordSupervisors = "";
      this.dateRangeSupervisors.startDate = moment()
        .subtract(3, "months")
        .format("YYYY-MM-DD");
      this.dateRangeSupervisors.endDate = moment().format("YYYY-MM-DD");
      this.userDPAccountStatusSupervisors = "all";
      this.userFTAccountStatusSupervisors = "all";
      this.$refs.datatableSupervisors.refresh();
    },
    resetFilterOm() {
      this.searchKeywordOm = "";
      this.dateRangeOm.startDate = moment()
        .subtract(3, "months")
        .format("YYYY-MM-DD");
      this.dateRangeOm.endDate = moment().format("YYYY-MM-DD");
      this.userDPAccountStatusOm = "all";
      this.userFTAccountStatusOm = "all";
      this.$refs.datatableOm.refresh();
    },
    resetFilterSales() {
      this.searchKeywordSales = "";
      this.dateRangeSales.startDate = moment()
        .subtract(3, "months")
        .format("YYYY-MM-DD");
      this.dateRangeSales.endDate = moment().format("YYYY-MM-DD");
      this.salesStatus = "all";
      this.userFTAccountStatusSales = "all";
      this.$refs.datatableSales.refresh();
    },
    getCompanies() {
      var searchKeywordCompanies = $("#searchKeywordCompanies").val();
      this.defaultDateCompanies = this.dateRangeCompanies.startDate;
      this.defaultDate1Companies = this.dateRangeCompanies.endDate;
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPageCompanies,
            keyword: searchKeywordCompanies,
            rowsPerPage: this.form.rowsPerPageCompanies,
            sortBy: this.sortByCompanies
              ? this.sortByCompanies
              : "companies.id",
            sortDirection: this.sortDescCompanies ? "desc" : "asc",
            userDPAccountStatus: this.userDPAccountStatusCompanies,
            userFTAccountStatus: this.userFTAccountStatusCompanies,
            userJobPreferenceCompanies: this.userJobPreferenceCompanies,
            startDate:
              searchKeywordCompanies == ""
                ? this.defaultDateCompanies
                  ? moment(this.defaultDateCompanies).format("YYYY-MM-DD")
                  : ""
                : "",
            endDate:
              searchKeywordCompanies == ""
                ? this.defaultDate1Companies
                  ? moment(this.defaultDate1Companies).format("YYYY-MM-DD")
                  : ""
                : "",
          },
          api: "/api/companies-list-all",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            this.itemsCompanies = this.$store.getters.getResults.pageOfItems;
            this.totalRowsCompanies =
              this.$store.getters.getResults.pager.totalItems;
            this.perPageCompanies =
              this.$store.getters.getResults.pager.pageSize;
            this.fromCompanies = this.$store.getters.getResults.pager.from;
            this.toCompanies = this.$store.getters.getResults.pager.to;
            Bus.$emit("get_fl_emp_user_count");
            return this.itemsCompanies;
          }
        });
    },

    getSupervisors() {
      var searchKeywordSupervisors = $("#searchKeywordSupervisors").val();
      this.defaultDateSupervisors = this.dateRangeSupervisors.startDate;
      this.defaultDate1Supervisors = this.dateRangeSupervisors.endDate;
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPageSupervisors,
            keyword: searchKeywordSupervisors,
            rowsPerPage: this.form.rowsPerPageSupervisors,
            sortBy: this.sortBySupervisors
              ? this.sortBySupervisors
              : "companies.id",
            sortDirection: this.sortDescSupervisors ? "desc" : "asc",
            userDPAccountStatus: this.userDPAccountStatusSupervisors,
            userFTAccountStatus: this.userFTAccountStatusSupervisors,
            startDate:
              searchKeywordSupervisors == ""
                ? this.defaultDateSupervisors
                  ? moment(this.defaultDateSupervisors).format("YYYY-MM-DD")
                  : ""
                : "",
            endDate:
              searchKeywordSupervisors == ""
                ? this.defaultDate1Supervisors
                  ? moment(this.defaultDate1Supervisors).format("YYYY-MM-DD")
                  : ""
                : "",
            currentTab: "supervisors",
          },
          api: "/api/companies-list-supervisor-om",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            this.itemsSupervisors = this.$store.getters.getResults.pageOfItems;
            this.totalRowsSupervisors =
              this.$store.getters.getResults.pager.totalItems;
            this.perPageSupervisors =
              this.$store.getters.getResults.pager.pageSize;
            this.fromSupervisors = this.$store.getters.getResults.pager.from;
            this.toSupervisors = this.$store.getters.getResults.pager.to;
            Bus.$emit("get_fl_emp_user_count");
            return this.itemsSupervisors;
          }
        });
    },
    getOm() {
      var searchKeywordOm = $("#searchKeywordOm").val();
      this.defaultDateOm = this.dateRangeOm.startDate;
      this.defaultDate1Om = this.dateRangeOm.endDate;
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPageOm,
            keyword: searchKeywordOm,
            rowsPerPage: this.form.rowsPerPageOm,
            sortBy: this.sortByOm ? this.sortByOm : "companies.id",
            sortDirection: this.sortDescOm ? "desc" : "asc",
            userDPAccountStatus: this.userDPAccountStatusOm,
            userFTAccountStatus: this.userFTAccountStatusOm,
            startDate:
              searchKeywordOm == ""
                ? this.defaultDateOm
                  ? moment(this.defaultDateOm).format("YYYY-MM-DD")
                  : ""
                : "",
            endDate:
              searchKeywordOm == ""
                ? this.defaultDate1Om
                  ? moment(this.defaultDate1Om).format("YYYY-MM-DD")
                  : ""
                : "",
            currentTab: "om",
          },
          api: "/api/companies-list-supervisor-om",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            this.itemsOm = this.$store.getters.getResults.pageOfItems;
            this.totalRowsOm = this.$store.getters.getResults.pager.totalItems;
            this.perPageOm = this.$store.getters.getResults.pager.pageSize;
            this.fromOm = this.$store.getters.getResults.pager.from;
            this.toOm = this.$store.getters.getResults.pager.to;
            Bus.$emit("get_fl_emp_user_count");
            return this.itemsOm;
          }
        });
    },
    getSales() {
      var searchKeywordSales = $("#searchKeywordSales").val();
      this.defaultDateSales = this.dateRangeSales.startDate;
      this.defaultDate1Sales = this.dateRangeSales.endDate;
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPageSales,
            keyword: searchKeywordSales,
            rowsPerPage: this.form.rowsPerPageSales,
            sortBy: this.sortBySales ? this.sortBySales : "companies.id",
            sortDirection: this.sortDescSales ? "desc" : "asc",
            // userDPAccountStatus:'all',
            sales_status: this.salesStatus,
            userFTAccountStatus: this.userFTAccountStatusSales,
            startDate:
              searchKeywordSales == ""
                ? this.defaultDateSales
                  ? moment(this.defaultDateSales).format("YYYY-MM-DD")
                  : ""
                : "",
            endDate:
              searchKeywordSales == ""
                ? this.defaultDate1Sales
                  ? moment(this.defaultDate1Sales).format("YYYY-MM-DD")
                  : ""
                : "",
          },
          api: "/api/companies-list-sales",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            this.itemsSales = this.$store.getters.getResults.pageOfItems;
            this.totalRowsSales =
              this.$store.getters.getResults.pager.totalItems;
            this.perPageSales = this.$store.getters.getResults.pager.pageSize;
            this.fromSales = this.$store.getters.getResults.pager.from;
            this.toSales = this.$store.getters.getResults.pager.to;
            Bus.$emit("get_fl_emp_user_count");
            return this.itemsSales;
          }
        });
    },
    getPrepaid() {
      var searchKeywordPrepaid = $("#searchKeywordPrepaid").val();
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPagePrepaid,
            keyword: searchKeywordPrepaid,
            rowsPerPage: this.form.rowsPerPagePrepaid,
            sortBy: this.sortByPrepaid ? this.sortByPrepaid : "companies.id",
            sortDirection: this.sortDescPrepaid ? "desc" : "asc",
            userDPAccountStatus: this.userDPAccountStatusPrepaid,
          },
          api: "/api/companies-list-prepaid",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            this.itemsPrepaid = this.$store.getters.getResults.pageOfItems;
            this.totalRowsPrepaid =
              this.$store.getters.getResults.pager.totalItems;
            this.perPagePrepaid = this.$store.getters.getResults.pager.pageSize;
            this.fromPrepaid = this.$store.getters.getResults.pager.from;
            this.toPrepaid = this.$store.getters.getResults.pager.to;
            Bus.$emit("get_fl_emp_user_count");
            return this.itemsPrepaid;
          }
        });
    },

    allJobs(id) {
      var url =
        this.webUrlV1 + "/admin/employer/companies/company-jobs/" + btoa(id);
      window.open(url, "_blank");
    },

    // billingAddress(id){
    //     var url = this.webUrlV1 + "/admin/employer/companies/company-jobs/" + btoa(id);
    //     window.open(url, '_blank');

    // },
    sendResetPasswordLink(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
          },
          api: "/api/jobseekerEmp-reset-password-link-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Reset Password Link Sent Successfully.";
            this.showSuccessAlert(message);
            // this.datatableRefresh();
          }
        });
    },
    empQrCode(url, user_id, type) {
      if (type == "download") window.open(url, "_blank");
      else {
        this.$store
          .dispatch(POST_API, {
            data: {
              company_id: url,
              user_id: user_id,
            },
            api: "/api/employer-regenerate-qrcode",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              this.items = this.$store.getters.getResults.data;
              this.success_message = this.$store.getters.getResults.message;
              this.showSuccessAlert(this.success_message);
              // this.datatableRefresh();
            }
          });
      }
    },
    openJobAdsModel(id, company_id, user_coins, info = null) {
      this.update_coin_user_id = id;
      this.getUserData(id);

      this.$refs["job-ads-modal"].show();
      this.update_coin_company_id = company_id;
    },

    openInfoModel(id, company_id, type, info = null) {
      if (type == "mobile") {
        this.updateInfoTitle = "Update Mobile";
      } else if (type == "email") {
        this.updateInfoTitle = "Update Email";
      } else {
        this.updateInfoTitle = "Update Password";
      }
      this.new_password = "";
      this.confirm_new_password = "";
      this.update_user_id = id;
      this.update_company_id = company_id;
      if (type == "password") {
        this.$refs["info-modal"].show();
      } else {
        this.$refs["info-modal"].show();
        this.getUserData(id);
      }
    },
    openCoinModel(id, company_id, user_coins, info = null) {
      this.update_coin_user_id = id;
      this.getEmpCoin(id);
      this.update_coin_company_id = company_id;
    },
    getEmpCoin(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
          },
          api: "/api/emp-company-get-usercoin-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.user_coins = this.items = this.$store.getters.getResults.data
              .coins
              ? this.$store.getters.getResults.data.coins
              : 0;
            this.job_id_coins = "";
            this.new_user_coins_deduct = "";
            this.add_deduct_coins = "";
            this.add_topup_add_back = "";
            this.deduct_job_comp_refund = "";
            this.new_user_coins = "";
            this.$refs["coin-modal"].show();
          }
        });
    },
    sendEmailVerificationLink(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
          },
          api: "/api/jobseekerEmp-sendemail-verify-link-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Email Verification Link Sent Successfully.";
            this.showSuccessAlert(message);
            // this.datatableRefresh();
          }
        });
    },
    submitUpdateJobAdsInfo() {
      var job_id_format = "^[a-zA-Z]{2}[0-9]{6}$";
      var err = false;

      //   if (this.job_id_coins != "" && !this.job_id_coins.match(job_id_format)) {
      //     err = true;
      //     this.error_message = "Invalid Job ID";
      //     this.showAlert();
      //   }

      if (err == false) {
        return this.$store
          .dispatch(POST_API, {
            data: {
              user_id: this.update_coin_user_id,
              company_id: this.update_coin_company_id,
              prev_coins: this.user_coins,
              job_ads: this.new_user_job_ads,
              coins_deduct: this.new_user_coins_deduct,
              job_unique_id: this.job_id_coins,
              add_deduct_coins: this.add_deduct_coins,
              add_topup_add_back: this.add_topup_add_back,
              deduct_job_comp_refund: this.deduct_job_comp_refund,
              created_by: this.form.user_id,
            },
            api: "/api/emp-company-update-job-ads-admin",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              this.success_message = this.$store.getters.getResults.message;
              this.new_user_job_ads = 0;
              this.job_id_coins = "";
              this.add_deduct_coins = "";
              this.add_topup_add_back = "";
              this.deduct_job_comp_refund = "";

              this.hideModel();
              this.showSuccessAlert(this.success_message);
              // this.datatableRefresh();
            }
          });
      }
    },
    verifyEmail(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
          },
          api: "/api/employer-verify-email-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Email Verified successfully.";
            // this.datatableRefresh();
            this.showSuccessAlert(message);
          }
        });
    },
    empConvertFL(user_id, company_id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: user_id,
            company_id: company_id,
          },
          api: "/api/employer-convert-tofl-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Data updated successfully.";
            // this.datatableRefresh();
            this.showSuccessAlert(message);
            window.location.replace(
              "/v2/admin/database/jobseeker-dp-profile/view/" + btoa(user_id)
            );
          }
        });
    },
    openDisableModel(id, status = null, type) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      if (type == "disable") {
        this.model_type = "disable";
        this.updateInfoTitleFL = "Temporary Suspend Employer";
      } else if (type == "blacklist") {
        this.model_type = "blacklist";
        this.updateInfoTitleFL = "Blacklist Employer";
      } else if (
        type == "inactive" ||
        type == "reactive" ||
        type == "deleted"
      ) {
        this.updateInfoTitleFL = "Activate Employer";
        this.model_type = "inactive";
      } else {
        this.updateInfoTitleFL = "Inactivate Employer";
        this.model_type = "inactive";
      }
      this.usrStatus = status;
      this.remarks = "";
      this.update_user_id = id;
      this.renable_date = "";
      this.$refs["disable-modal"].show();
    },
    pausePosting(id, status) {
      if (status == "yes") {
        var message = "Unfreeze Account Successfully.";
      } else {
        var message = "Freeze Account Successfully.";
      }
      return this.$store
        .dispatch(POST_API, {
          data: {
            updated_by: this.form.user_id,
            user_id: id,
            status: status,
          },
          api: "/api/employer-pause-posting-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.showSuccessAlert(message);
          }
        });
    },
    verifyMobileNumber(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
          },
          api: "/api/employer-verify-mob-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Mobile Number Verified successfully.";
            // this.datatableRefresh();
            this.showSuccessAlert(message);
          }
        });
    },
    changeCompanyStatus(id, company_id, status) {
      // var status = status != null ? status : this.usrStatus;

      if (status == "active") {
        var msg = "Are you sure you want to inactivate this record?";
        var message = "Company Inactivated Successfully.";
      } else if (status == "deleted" || status == "inactive") {
        var msg = "Are you sure you want to activate this record?";
        var message = "Company Activated Successfully.";
        this.remarks = null;
      }
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: id,
                  company_id: company_id,
                  status: status,
                  updated_by: this.form.user_id,
                },
                api: "/api/emp-active-inactive-admin",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.showSuccessAlert(message);
                  // this.datatableRefresh();
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    changeCompanyUserStatus(id, status) {
      if (status == "active") {
        var msg = "Are you sure you want to inactivate this record?";
        var message = "Company Inactivated Successfully.";
      } else if (status == "deleted" || status == "inactive") {
        var msg = "Are you sure you want to activate this record?";
        var message = "Company Activated Successfully.";
        this.remarks = null;
      }
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                  status: status,
                },
                api: "/api/emp-active-inactive-supom-admin",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.showSuccessAlert(message);
                  // this.datatableRefresh();
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    deleteCompany(id, company_id) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: id,
                  company_id: company_id,
                },
                api: "/api/emp-company-delete-admin",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  var message = "Company Deleted successfully.";
                  // this.datatableRefresh();
                  this.showSuccessAlert(message);
                }
              });
          }
        })
        .catch((err) => {});
    },
    deleteCompanyUser(id) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                },
                api: "/api/admin-empjobseeker-delete",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  var message = "Employer Deleted successfully.";
                  // this.$refs.datatable.refresh();
                  this.showSuccessAlert(message);
                }
              });
          }
        })
        .catch((err) => {});
    },
    customLabel({ first_name, last_name }) {
      return `${last_name} ${first_name}`;
    },
    exportCompanies(currentTab) {
      var base_url = this.webServerUrl;
      if (currentTab == "companies") {
        var searchCompanies =
          this.filterCompanies != "" ? this.filterCompanies : "null";
        var startDate =
          this.filterCompanies == ""
            ? this.defaultDateCompanies
              ? moment(this.defaultDateCompanies).format("YYYY-MM-DD")
              : "null"
            : "null";
        var endDate =
          this.filterCompanies == ""
            ? this.defaultDate1Companies
              ? moment(this.defaultDate1Companies).format("YYYY-MM-DD")
              : "null"
            : "null";
        // var export_url = '/api/export-companies/'+this.userDPAccountStatusCompanies+'/'+searchCompanies+'/'+this.form.rowsPerPageCompanies+'/'+this.sortByCompanies+'/'+this.sortDirectionCompanies+'/'+this.sortDescCompanies+'/'+this.currentPageCompanies;
        var export_url =
          "/api/export-companies-list-all/" +
          this.userDPAccountStatusCompanies +
          "/" +
          this.userFTAccountStatusCompanies +
          "/" +
          this.userJobPreferenceCompanies +
          "/" +
          searchCompanies +
          "/" +
          startDate +
          "/" +
          endDate +
          "/" +
          this.currentPageCompanies +
          "/" +
          this.form.rowsPerPageCompanies +
          "/" +
          this.sortByCompanies +
          "/" +
          this.sortDirectionCompanies;
      } else if (currentTab == "sales") {
        var searchSales = this.filterSales != "" ? this.filterSales : "null";
        var startDate =
          this.filterSales == ""
            ? this.defaultDateSales
              ? moment(this.defaultDateSales).format("YYYY-MM-DD")
              : "null"
            : "null";
        var endDate =
          this.filterSales == ""
            ? this.defaultDate1Sales
              ? moment(this.defaultDate1Sales).format("YYYY-MM-DD")
              : "null"
            : "null";
        var export_url =
          "/api/export-companies-list-sales/" +
          this.salesStatus +
          "/" +
          this.userFTAccountStatusSales +
          "/" +
          searchSales +
          "/" +
          startDate +
          "/" +
          endDate +
          "/" +
          this.currentPageSales +
          "/" +
          this.form.rowsPerPageSales +
          "/" +
          this.sortBySales +
          "/" +
          this.sortDirectionSales;
      } else {
        var searchPrepaid =
          this.filterPrepaid != "" ? this.filterPrepaid : "null";
        var startDate =
          this.filterPrepaid == ""
            ? this.defaultDatePrepaid
              ? moment(this.defaultDatePrepaid).format("YYYY-MM-DD")
              : "null"
            : "null";
        var endDate =
          this.filterPrepaid == ""
            ? this.defaultDate1Prepaid
              ? moment(this.defaultDate1Prepaid).format("YYYY-MM-DD")
              : "null"
            : "null";
        var export_url =
          "/api/export-companies-list-prepaid/" +
          this.userDPAccountStatusPrepaid +
          "/" +
          searchPrepaid +
          "/" +
          startDate +
          "/" +
          endDate +
          "/" +
          this.currentPagePrepaid +
          "/" +
          this.form.rowsPerPagePrepaid +
          "/" +
          this.sortByPrepaid +
          "/" +
          this.sortDirectionPrepaid;
      }
      window.open(base_url + export_url, "_blank");
    },
    exportSupervisors() {
      // var base_url = this.webUrlV1exp;
      var base_url = this.webServerUrl;
      // var export_url = '/api/export-supervisors/'+this.userDPAccountStatusSupervisors+'/'+searchSupervisors+'/'+this.form.rowsPerPageSupervisors+'/'+this.sortBySupervisors+'/'+this.sortDirectionSupervisors+'/'+this.sortDescSupervisors+'/'+this.currentPageSupervisors;
      var searchSupervisors =
        this.filterSupervisors != "" ? this.filterSupervisors : "null";
      var startDate =
        this.filterSupervisors == ""
          ? this.defaultDateSupervisors
            ? moment(this.defaultDateSupervisors).format("YYYY-MM-DD")
            : "null"
          : "null";
      var endDate =
        this.filterSupervisors == ""
          ? this.defaultDate1Supervisors
            ? moment(this.defaultDate1Supervisors).format("YYYY-MM-DD")
            : "null"
          : "null";
      var export_url =
        "/api/export-companies-list-supervisor-om/" +
        this.userDPAccountStatusSupervisors +
        "/" +
        this.userFTAccountStatusSupervisors +
        "/" +
        searchSupervisors +
        "/" +
        startDate +
        "/" +
        endDate +
        "/" +
        this.currentPageSupervisors +
        "/" +
        this.form.rowsPerPageSupervisors +
        "/" +
        this.sortBySupervisors +
        "/" +
        this.sortDirectionSupervisors +
        "/" +
        this.currentTab;
      window.open(base_url + export_url, "_blank");
    },
    exportOm() {
      var base_url = this.webServerUrl;
      var searchOm = this.filterOm != "" ? this.filterOm : "null";
      // var export_url = '/api/export-companies/'+'om'+'/'+searchOm+'/'+this.form.rowsPerPageOm+'/'+this.sortByOm+'/'+this.sortDirectionOm+'/'+this.sortDescOm+'/'+this.currentPageOm;
      var startDate =
        this.filterOm == ""
          ? this.defaultDateOm
            ? moment(this.defaultDateOm).format("YYYY-MM-DD")
            : "null"
          : "null";
      var endDate =
        this.filterOm == ""
          ? this.defaultDate1Om
            ? moment(this.defaultDate1Om).format("YYYY-MM-DD")
            : "null"
          : "null";
      var export_url =
        "/api/export-companies-list-supervisor-om/" +
        this.userDPAccountStatusOm +
        "/" +
        this.userFTAccountStatusOm +
        "/" +
        searchOm +
        "/" +
        startDate +
        "/" +
        endDate +
        "/" +
        this.currentPageOm +
        "/" +
        this.form.rowsPerPageOm +
        "/" +
        this.sortByOm +
        "/" +
        this.sortDirectionOm +
        "/" +
        this.currentTab;
      window.open(base_url + export_url, "_blank");
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Companies");
          this.actionsComUser = permission.getPermissionStaff(
            menu,
            "Company Users"
          );
          this.actionsBillingAdd = permission.getPermissionStaff(
            menu,
            "Billing Address"
          );
          this.actionsManageDepart = permission.getPermissionStaff(
            menu,
            "Manage Department"
          );
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showComUser(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsComUser.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showBillingAdd(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsBillingAdd.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showManageDepart(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsManageDepart.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    subscribeUnsubscribeMail(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
            updated_by: this.form.user_id,
          },
          api: "/api/emp-jobseeker-subscribe-unsubscribe-mail-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = this.$store.getters.getResults.message;
            this.showSuccessAlert(message);
          }
        });
    },
    blacklistJobseeker() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.update_user_id,
            admin_id: this.form.user_id,
            status: "blacklisted",
            remarks: this.remarks,
            // with_no_email:this.withNoEmailBlacklist
          },
          api: "/api/employer-blacklist-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Employer blacklisted successfully.";
            this.$refs["disable-modal"].hide();
            this.showSuccessAlert(message);
          }
        });
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Admin - Employers List";
    this.form.user_id =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.id
        : "";
    this.form.user_type_id =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.user_type_id
        : "";
    this.designation =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.designation
        : "";
    Bus.$emit("get_fl_emp_user_count");
    this.permissionStaff();
  },
};
</script>
