<template>
    <div>
      <div class="row">
        <div class="container my_new_container">
          <!-- Dashboard Headline -->
          <div class="dashboard-headline">
            <h1>Pay Campaign Bonus</h1>
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
              <ul>
                <li><a href="#">Home</a></li>
                <li>Pay Campaign Bonus</li>
              </ul>
            </nav>
          </div>
          <div class="row">
            <div class="new_first_screen_max_width_and_flex col-xl-3  mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <b-input-group-prepend>
                        <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search"></b-form-input>
                        <b-button class="btn points_events"><i class="fa fa-search" @click="filter = searchKeyword"></i></b-button>
                    </b-input-group-prepend>
                </div>
            </div>
            <div class="new_first_screen_max_width_and_flex col-xl-3  mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                <div class="search-box custom_search_admin desktop_margin_top_zero start_end_calender_new ">
                  <div class="month-input-wrapper" style="position: relative;">
                    <!-- Placeholder Effect -->
                    <label 
                      v-if="!selectedMonth" 
                      class="placeholder-label" 
                      for="input-1"
                      style="position: absolute; top: 50%; left: 15px; transform: translateY(-50%); pointer-events: none; color: grey;"
                    >
                    Select Month
                    </label>

                    <!-- Month Picker -->
                    <b-form-input
                      id="input-1"
                      type="month"
                      v-model="selectedMonth"
                      class="desktop_margin_bottom_zero date_style_size"
                      @input="getCampaignUsers"
                    ></b-form-input>
                  </div>
                </div>
            </div>
            <div class="new_first_screen_max_width_and_flex col-xl-6 mobile_tab_max_width_flex campaign_btn invoices_btns search_common_margin_bottom_28">
                <b-link class="common_blue_border btn button new_add_btn_admin_e desktop_margin_left_zero common_on_hover_white_bg_with_for_blue_btn" @click="payBonus('')" v-if="show('pay') && (selectedUser.length > 0)">Pay</b-link>
                <b-link class="common_blue_border btn button new_add_btn_admin_e desktop_margin_left_zero common_on_hover_white_bg_with_for_blue_btn" @click="remove('')" v-if="show('pay') && (selectedUser.length > 0)">Remove</b-link>
                <b-link class="common_blue_border btn button new_add_btn_admin_e desktop_margin_left_zero common_on_hover_white_bg_with_for_blue_btn" @click="clickToAdd('add')" v-if="show('add')">+ Add Freelancer</b-link>
                <b-link class="common_blue_border btn button new_add_btn_admin_e desktop_margin_left_zero common_on_hover_white_bg_with_for_blue_btn" target="_blank" :to="{name:'campaign-create'}" v-if="showCampaign('add')">Add Campaign</b-link>
                <b-link class="common_blue_border btn button new_add_btn_admin_e desktop_margin_left_zero common_on_hover_white_bg_with_for_blue_btn" @click="exportCoinTransactions()" v-if="show('export')" >Export</b-link>
            </div>
        </div>
        <div class="row">
            <div class="new_first_screen_max_width_and_flex col-xl-12 mobile_max_widht_and_flex">
                <div class="select-service-box clearfix check_label_single">
                    <b-form-group>
                        <b-form-checkbox v-model = 'selectAll' @change="allSelected">SELECT ALL</b-form-checkbox>
                    </b-form-group>
                </div>
            </div>
            <!-- <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex text_align_end_desktop search_common_margin_bottom_28"> -->
                <!-- <b-link class="btn green-border-btn add_btn_admin_e" @click="payBonus('')" v-if="show('pay') && (selectedUser.length > 0)">Pay</b-link>
                <b-link class="btn green-border-btn add_btn_admin_e" @click="remove('')" v-if="show('pay') && (selectedUser.length > 0)">Remove</b-link> -->

            <!-- </div> -->
            <!-- <div class="col-xl-6 col-md-6">
            </div> -->
        </div>
          <div class="row">
            <div class="container my_new_container show_filter_arrow">    
               <!-- <b-table ref="datatable" show-empty striped hover responsive :items="getPromotions" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_field_of_study new_box_tbl_mrgn nationality_tbl_width promotion_tb_mbl"> -->
                <b-table ref="datatable" show-empty striped hover responsive 
                  :items="getCampaignUsers"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                 class="new_layout_table_like_v1">
                    <template #cell(select)="items">
                        <div class="select-service-box clearfix check_label_single">
                            <b-form-group>
                                <b-form-checkbox :value="items.item.id" v-model = 'selectedUser' v-if="items.item.payment_status!='paid'"></b-form-checkbox>
                            </b-form-group>
                        </div>
                    </template>
                    <template #cell(name)="items">
                      <b-link :to=" {name: 'jobseeker-dp-profile-view', params: {id: encrypt(items.item.user_id)}}" target="_blank" style="color: #4c8bf5;">
                          {{items.item.full_name | capitalize}}
                      </b-link>
                    </template>
                    <template #cell(nric)="items">
                        {{items.item.nric | capitalize}}
                    </template>
                    <template #cell(campaign)="items">
                        {{items.item.campaign_title | capitalize}}
                    </template>
                    <template #cell(amount)="items">
                        S${{(items.item.amount == null ? items.item.campaign_amount : items.item.amount)}}
                    </template>
                    <template  #cell(account_status)="items">
                    <p class="btn green-bg-btn desktop_margin_left_zero desktop_margin_right_zero" v-if="items.item.account_status == 'active'">{{items.item.account_status | capitalize}}</p>
                    <p class="btn yellow-bg desktop_margin_left_zero desktop_margin_right_zero" v-if="items.item.account_status == 'inactive'">{{items.item.account_status | capitalize}}</p>
                    </template>   
                    <template #cell(status)="items">
                      <p class="green_job_color" v-if="items.item.payment_status == 'paid'">{{items.item.payment_status | capitalize}}</p>
                      <p class="red_job_color" v-else>{{items.item.payment_status | capitalize}}</p>
                    </template>
                    <template #cell(release_date)="items">
                        {{items.item.release_date | date}}
                    </template>

                    <template #cell(toggleAction)="items">
                        <div class="toggle-action toggle_margin_unset" v-if="items.item.payment_status === 'unpaid'">
                            <b-dropdown class="mx-1" right text="Action" boundary="window">
                              <b-dropdown-item v-on:click="remove(items.item)" v-if="show('delete') && items.item.payment_status === 'unpaid'">Remove Freelancer</b-dropdown-item>
                                <b-dropdown-item @click="payBonus(items.item.id)" v-if="show('pay') && items.item.payment_status == 'unpaid'">Pay
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block">
                            <b-form-select v-model="form.rowsPerPage" @change='getCampaignUsers'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
        </div>
      </div>
      <b-modal
        ref="upload-modal"
        :title="modalTitle"
        hide-footer
        content-class="common_model_header common_model_title"
        modal-class="background_custom_class"
      >
        <b-form @submit="formSubmit">
          <div class="edit-form-box model_legend model_margin_top_zero">
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12">
                  <div class="form-map-box">
                    <b-col
                      lg="12"
                      md="12"
                    >
                      <b-form-group
                        id="input-group-3"
                        class="contact-fields mobile_margin_bottom_zero submit-field"
                      >
                        <span class="custom-dropdown w-100">
                          <b-form-select
                            v-model="campaign_type"
                            class="model_margin_bottom_zero"
                            @change="onCampaignChange()"
                          >
                            <option value="" disabled>Select Campaign</option>
                            <option :value="cam" v-for="cam in campaigns">
                              {{ cam.title | capitalize }}
                            </option>
                          </b-form-select>
                        </span>
                      </b-form-group>
                    </b-col>
                    <div class="col-xl-12">
                        <div class="submit-field ">
                            <h5>Freelancer <span class="required_star">*</span></h5>
                            <b-form-group id="input-group-8" class="required">
                            <multiselect id="ajax" v-model="freelancer_id" tag-placeholder="Add this as new Freelancer" placeholder="Enter to search Freelancer" :options="filteredFreelancerList" :custom-label="customLabel" track-by="id" :multiple="true" :searchable="true" :clear-on-select="true" :close-on-select="false" :allow-empty="true" deselect-label="Can't remove this value" @search-change="customSearch" :internal-search="false" class="job_list_multi_select model_multi_select" >
                              <template slot="selection" slot-scope="{ values, search, isOpen }">
                                <span class="multiselect__single" v-if="values.length && !isOpen">
                                  {{ values.length }} Freelancer(s) selected
                                </span>
                              </template>
                              
                              <span slot="noResult">No Freelancer Found.</span>
                            </multiselect>
                            </b-form-group>
                        </div>
                        <span class="error_x_white">Please Select Freelancer !</span>
                    </div>
                    <b-col lg="12" md="12">
                      <b-form-group id="input-group-10" label="Amount">
                            <b-form-input id="title" v-model="amount" type="text" ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col lg="12" md="12">
                      <b-form-group id="input-group-10" label="Release Payment Date" class="required promo_require">
                            <datepicker v-model="release_payment_date" :disabled-dates="disabledDate()" :format="customFormatterOne" @input="modifyDate()"></datepicker>
                            <!-- <span class="input-icon"><b-img v-bind:src="webUrl+'/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span> -->
                        </b-form-group>
                    </b-col>
                    <b-col lg="12" md="12">
                      <b-form-group id="input-group-10" label="Select Payslip Month" class="required promo_require">
                            <datepicker v-model="payment_month" minimum-view='month' maximum-view='year' :disabled-dates="disabledDateBefore()"  :format="customFormatterTwo"></datepicker>
                            <!-- <span class="input-icon"><b-img v-bind:src="webUrl+'/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span> -->
                        </b-form-group>
                    </b-col>
                    <b-col lg="12" md="12">
                      <b-form-group
                        id="input-group-3"
                        class="contact-fields mobile_margin_bottom_zero submit-field"
                      >
                        <span class="custom-dropdown w-100">
                          <b-form-select v-model="billing" class="model_margin_bottom_zero">
                            <option value="" disabled>Select Bill Type</option>
                            <option :value="bt.id" v-for="bt in billing_types">
                              {{ bt.name | capitalize }}
                            </option>
                          </b-form-select>
                        </span>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" md="12">
                      <b-form-group id="input-group-10" label="Remarks" class="required">
                        <b-form-textarea
                          id="input-10"
                          maxlength="500"
                          v-model="remarks"
                          type="text"
                          placeholder=""
                          class="model_border_and_box_shadow"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" md="12" class="model_submit_field employer_multiple_radio">
                      <b-form-group>
                        <b-form-checkbox v-model="copyRemarksVal" @input="clickToCopyRemark()"
                          ><strong>Internal Remark same as Remark</strong></b-form-checkbox
                        >
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" md="12">
                      <b-form-group id="input-group-10" label="Internal Remarks" class="required">
                        <b-form-textarea
                          id="input-10"
                          maxlength="500"
                          v-model="internal_remarks"
                          type="text"
                          placeholder=""
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                    <div
                      slot="footer"
                      class="form-btn col-md-12 col-lg-12 gallery_btn common_crop_reset_btn"
                    >
                      <b-button type="submit" variant="success" class="model_common_bt_success_color"
                        >Submit</b-button
                      >
                      <b-button
                        @click="hideModel()"
                        variant="light"
                        class="model_common_bt_lite_color"
                        >Cancel</b-button
                      >
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-form>
      </b-modal>
      
      <!-- Spacer / End-->
      <!-- Row / End -->
    </div>
</template>
  
<script>
  import { POST_API } from "../../../store/actions.type";
  import moment from "moment";
  import DateRangePicker from "vue2-daterange-picker";
  import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
  import Multiselect from "vue-multiselect";
  import permission from "../../../../../server/permission.js";
  import Datepicker from "vuejs-datepicker";
  import VueCropper from "vue-cropperjs";
  import "cropperjs/dist/cropper.css";
  import VueUploadMultipleImage from "vue-upload-multiple-image";
  
  export default {
    
    data() {
      return {
        fields: [
          { key: "select", label: "Select All", sortable: true, thStyle:{ width:"5%" } },
          { key: "name", label: "Name", sortable: true, thStyle:{ width:"12%" } },
         // { key: "amount", label: "Amount", sortable: true },
          { key: "nric", label: "NRIC", sortable: true, thStyle:{ width:"10%" }},
          { key: "campaign", label: "Campaign", sortable: true, thStyle:{ width:"17%" } },
          { key: "amount", label: "Amount", sortable: true, thStyle:{ width:"10%" } },
          { key: "account_status", label: "Account Status", sortable: true, thStyle:{ width:"10%" } },
          { key: "status", label: "Status", sortable: true, thStyle:{ width:"10%" }},
          { key: "release_date", label: "Release Payment Date", sortable: true, thStyle:{ width:"21%" } },
          { key: "toggleAction", label: "Actions", thStyle:{ width:"5%" } },
        ],
        pager: {},
        pageOfItems: [],
        minDate: null,
        center: "center",
        date: moment().format("YYYY-MM-DD"),
        items: null,
        totalRows: 0,
        format: "YYYY-MM-DD HH:mm",
        from: "",
        to: "",
        currentPage: 1,
        searchKeyword: "",
        perPage: 25,
        itemsJobs: null,
        itemsJobAds: null,
        totalRowsJobs: 0,
        fromJobs: "",
        toJobs: "",
        pageOptionsJobs: [25, 50, 75, 100],
        auto: true,
        range: false,
        locale: {
          direction: "ltr", //direction of text
          format: "DD-MMM-YYYY", //fomart of the dates displayed
          separator: " - ", //separator between the two ranges
          applyLabel: "Apply",
          cancelLabel: "Cancel",
          /*weekLabel: 'W',
                  customRangeLabel: 'Custom Range',*/
          daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
          monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
          firstDay: 0, //ISO first day of week - see moment documenations for details
          sameDateFormat: {
            from: "DD MM YYYY, hh:mm",
            to: "hh:mm",
          },
        },
  
        dateRange: {
          startDate: "",
          endDate: "",
        },
        dateRange1: {
          startDate: null,
          endDate: null,
        },
        pageOptions: [25, 50, 75, 100],

        // dateRange: {
        //   startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        //   endDate: moment().format("YYYY-MM-DD"),
        // },
        // dateRange1: {
        //   startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        //   endDate: moment().format("YYYY-MM-DD"),
        // },
  
        sortBy: null,
        sortDirection: "desc",
        filter: "",
        status: "active",
        sortDesc: true,
        filterJobs: "",
        sortDescJobs: true,
        webUrl: process.env.VUE_APP_URL,
        currentTab: "jobs",
        form: {
          rowsPerPage: 25,
          rowsPerPageJobs: 25,
          mode: "",
          type: "",
          status: "",
          tran_type: "",
          coins: "",
          sub_type: "",
        },
        modalTitle: "Add Freelancers",
        type: "add",
        transaction_id: "",
        update_coin_user_id: "",
        update_coin_transaction_id: "",
        transaction_prev_coins: "",
        transaction_coin_mode: "",
        transaction_new_coins: "",
        update_amount_user_id: "",
        update_amount_transaction_id: "",
        transaction_prev_amount: "",
        transaction_amount_mode: "",
        transaction_new_amount: "",
        company_id_filter: "",
        trans_type_filter: [],
        transactionTypesMultiselect: [
          { name: "Add Back", value: "add_back" },
          { name: "Top UP", value: "top_up" },
          { name: "Refund", value: "refund" },
          { name: "Deduct", value: "deduct" },
          { name: "ELC Credits", value: "elcoins" },
          { name: "Pending", value: "pending" },
          { name: "Featured ad", value: "featured_ad" },
          { name: "Job Completed", value: "job_completed" },
          { name: "Job Ad Posted", value: "job_ad_posted" },
        ],
        mode_filter: "",
        companies: [],
        start_date_filter: moment().subtract(1, "months").format("YYYY-MM-DD"),
        end_date_filter: moment().format("YYYY-MM-DD"),
        webServerUrl: process.env.VUE_APP_SERVER_URL,
        webUrlV1Admin: process.env.VUE_APP_URL_V1_Admin,
        webUrlV1: process.env.VUE_APP_URL_V1,
  
        uploads: [],
        actions: "",
        actionsCampaign:"",
        transactions: [],
        transaction_sub_types: [],
  
        transaction_type_val: "add_back",
        transaction_type: "add_back",
  
        sub_transactions: [],
        transaction_sub_type_val: "",
        billing_types: [],
        billing: "",
        freelancer_id: [],
        copyRemarksVal: false,
        remarks: "",          
        internal_remarks: "",
        amount:'',
        release_payment_date:moment().add(1, "months").format("YYYY-MM-07"),
        campaign_type:'',
        payment_month:'',
        freelancerList: [],
        filteredFreelancerList: [],
        selectedUserCounter:0,
        multiple: true,
        multi: false,
        deduct_amount: "",
        transaction_remarks: "",
        transaction_internal_remarks: "",
        transaction_type: "add_back",
        transaction_created_at: "",
        maxImage: 10,
        images: [],
        campaigns:[],
        user_id: "",
        selectAll:false,
        selectedUser:[],
        selectedMonth:''

      };
    },
    filters: {
      capitalize: function (value) {
        if (!value) return "";
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
  
      removeUnderscore: function (value) {
        if (!value) return "";
        var i,
          frags = value.split("_");
        for (i = 0; i < frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(" ");
      },
      date: function (value) {
        if (value == null) {
          return "";
        } else {
          return moment(value).format("DD MMM YYYY");
        }
      },
    },
  
    components: {
      Datepicker,
      DateRangePicker,
      Multiselect,
      VueCropper,
      VueUploadMultipleImage,
    },
    methods: {
      cropImage() {
        this.$refs["crop-modal"].hide();
        this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
      },
      setImage(e) {
        const file = e.target.files[0];
        if (file.type.indexOf("image/") === -1) {
          alert("Please select an image file");
          return;
        }
        if (typeof FileReader === "function") {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.cropImg = event.target.result;
            this.$refs.cropper.replace(event.target.result);
          };
          reader.readAsDataURL(file);
        } else {
          alert("Sorry, FileReader API not supported");
        }
      },
      zoom(percent) {
        this.$refs.cropper.relativeZoom(percent);
      },
      flipX() {
        const dom = this.$refs.flipX;
        let scale = dom.getAttribute("data-scale");
        scale = scale ? -scale : -1;
        this.$refs.cropper.scaleX(scale);
        dom.setAttribute("data-scale", scale);
      },
      flipY() {
        const dom = this.$refs.flipY;
        let scale = dom.getAttribute("data-scale");
        scale = scale ? -scale : -1;
        this.$refs.cropper.scaleY(scale);
        dom.setAttribute("data-scale", scale);
      },
      getCropBoxData() {
        this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
      },
      getData() {
        this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
      },
      move(offsetX, offsetY) {
        this.$refs.cropper.move(offsetX, offsetY);
      },
      reset() {
        this.$refs.cropper.reset();
      },
      rotate(deg) {
        this.$refs.cropper.rotate(deg);
      },
      setData() {
        if (!this.data) return;
        this.$refs.cropper.setData(JSON.parse(this.data));
      },
      showFileChooser() {
        this.$refs.input.click();
      },
      showImg() {
        this.$refs["crop-modal"].show();
      },
      onSubmitImg(evt) {
        evt.preventDefault();
        this.$refs["crop-modal"].hide();
        this.$refs["crop-modal"].refresh();
      },
      
        disabledDate(){
             var d = new Date();
           return {

               to: new Date(d.getFullYear(), d.getMonth(), d.getDate())
           };
        },
        disabledDateBefore(){
            var d = new Date(this.release_payment_date);
            d.setMonth(d.getMonth() - 1);

           return {
               from: new Date(d.getFullYear(), d.getMonth(), d.getDate())
           };
        },
        modifyDate(){
            
            var d = new Date(this.release_payment_date);
            d.setMonth(d.getMonth() - 1);
            this.payment_month = d;

        },
      onImageChange(event) {
        var input = event.target;
        if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          this.form.image = input.files[0];
          if (this.form.image.type != "image/jpeg" && this.form.image.type != "image/jpg") {
            this.form.selectedImage.image = this.webUrl + "/images/upload-icon.svg";
            this.error_message = "Please select only .jpg or .jpeg image.";
            this.form.selectedImage.image = "";
            this.showAlert();
          } else if (this.form.image.size > 5242880) {
            this.form.selectedImage.image = this.webUrl + "/images/upload-icon.svg";
            this.error_message = "Image should be less than 5 MB.";
            this.form.selectedImage.image = "";
            this.showAlert();
          } else {
            reader.onload = (e) => {
              this.tempLogo = e.target.result;
              this.form.selectedImage.name = this.form.image.name;
              this.showImg();
              event.target.value = "";
            };
          }
        }
      },
      uploadImageSuccess(formData, index, fileList) {
        for (var i = 0; i < fileList.length; i++) {
          var stringLength = fileList[i].path.length - "data:image/png;base64,".length;
          var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
          var sizeInMB = sizeInBytes / 1000000;
          var name = fileList[i].name.split(".");
          if (
            name[name.length - 1] != "png" &&
            name[name.length - 1] != "jpg" &&
            name[name.length - 1] != "jpeg"
          ) {
            this.error_message = "Please select only .png,.jpg or .jpeg image.";
            fileList.splice(index, 1);
            this.showAlert();
          } else if (sizeInMB > 5) {
            this.error_message = "Image should be less than 5 MB.";
            fileList.splice(index, 1);
            this.showAlert();
          } else {
            if (typeof fileList[i].url == "undefined") {
              var url = fileList[i].path;
              fileList[i].url = url;
            }
          }
        }
  
        this.imagesDoc = fileList;
      },
      beforeRemove(index, done, fileList) {
        fileList.splice(index, 1);
  
        this.imagesDoc = fileList;
      },
      editImage(formData, index, fileList) {
        for (var i = 0; i < fileList.length; i++) {
          var stringLength = fileList[i].path.length - "data:image/png;base64,".length;
          var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
          var sizeInMB = sizeInBytes / 1000000;
          var name = fileList[i].name.split(".");
          if (
            name[name.length - 1] != "png" &&
            name[name.length - 1] != "jpg" &&
            name[name.length - 1] != "jpeg"
          ) {
            this.error_message = "Please select only .png,.jpg or .jpeg image.";
            fileList.splice(index, 1);
            this.showAlert();
          } else if (sizeInMB > 5) {
            this.error_message = "Image should be less than 5 MB.";
            fileList.splice(index, 1);
            this.showAlert();
          } else {
            if (typeof fileList[i].url == "undefined") {
              var url = fileList[i].path;
              fileList[i].url = url;
            }
          }
        }
        this.imagesDoc = fileList;
      },
      customLabel({  full_name }) {
  return `${full_name}`;
},
      showSuccessAlert(message) {
        this.$swal({
          position: "center",
          icon: "success",
          iconColor: "#4c8bf5",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
            this.getCampaignUsers().then(() => {
              this.$refs.datatable.refresh();
            });
        });
      },
      showAlert() {
        this.$swal({
          position: "center",
          icon: "error",
          title: this.error_message,
          showConfirmButton: false,
          timer: 1500,
        });
      },
      downloadPdf(url) {
        window.open(url);
      },
      
        clickToCopyRemark() {
        if (this.copyRemarksVal) {
            this.internal_remarks = this.remarks;
        } else {
            this.internal_remarks = this.internal_remarks;
        }
        },
      getEmployers() {
        this.$store
          .dispatch(POST_API, {
            data: {},
            api: "/api/employer-job-list",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
  
              this.$swal({
                position: "center",
                icon: "error",
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              this.companies = this.$store.getters.getResults.data;
            }
          });
      },
      getCampaignUsers() {
        // Construct the filter object for the API call
        // Include the selected month in the filters if a month is selected
      
        return this.$store
          .dispatch(POST_API, {
            data: {
              page: this.currentPage,
              keyword: this.searchKeyword,
              rowsPerPage: this.form.rowsPerPage,
              sortBy: this.sortBy ? this.sortBy : "user_campaign.id",
              sortDirection: this.sortDesc ? "desc" : "asc",
              month : this.selectedMonth != null ? this.selectedMonth : null,
            },
            api: "/api/user-campaign-bonus",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              // this.showAlert();
              return [];
            } else {
              this.items = this.$store.getters.getResults.pageOfItems;
              this.totalRows = this.$store.getters.getResults.pager.totalItems;
              this.perPage = this.$store.getters.getResults.pager.pageSize;
              this.from = this.$store.getters.getResults.pager.from;
              this.to = this.$store.getters.getResults.pager.to;
              return this.items;

            }
          });
      },
      clickToAdd(typeVal) {
        this.getBillingTypes();
        this.copyRemarksVal = false;
        this.$refs["upload-modal"].show();
        this.modalTitle = typeVal == "add" ? "Add Freelancers" : "Update Freelancers";
        this.type = typeVal == "add" ? "Add" : "Update";
        this.transaction_sub_type_val = "";
        this.billing = "";
      },
      getBillingTypes() {
        this.$store
          .dispatch(POST_API, {
            data: {},
            api: "/api/billing-types",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              this.billing_types = this.$store.getters.getResults.data;
            }
          });
      },
      hideModel() {
        this.$refs["upload-modal"].hide();
        this.remarks = "";
        this.internal_remarks = "";
        this.copyRemarksVal = false;
        this.freelancer_id = "";
        this.campaign_type = "";
        // this.release_payment_date = "";
        // this.payment_month = "";
        this.amount = "";
        this.billing = "";

      },
      formSubmit(evt) {
        evt.preventDefault();
        this.$store
          .dispatch(POST_API, {
            data: {
              remarks: this.remarks,
              internal_remarks: this.internal_remarks,
              user_id: (this.freelancer_id != null ? this.freelancer_id.map(id => id.id) : []),
              billing_type: this.billing ? this.billing : null,
              amount: this.amount ? this.amount : null,
              payment_month: this.payment_month ? moment(this.payment_month).format('YYYY-MM-DD') : null,
              release_payment_date: this.release_payment_date ? moment(this.release_payment_date).format('YYYY-MM-DD') : null,
              campaign_type: this.campaign_type ? this.campaign_type.id : null,
              created_by: this.user_id,

            },
            api: "/api/create-user-campaign",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              if (this.type == "update") {
                var message = "Remark Updated successfully";
              } else {
                var message = "User Campaign Added successfully";
              }
              this.$refs.datatable.refresh();
              this.hideModel();
              this.showSuccessAlert(message);
            }
          });
      },
      exportCoinTransactions() {
        var base_url = this.webServerUrl;
        var page = this.currentPage;
        var rowsPerPage = this.form.rowsPerPage;
        var start_date = this.dateRange1.startDate
          ? moment(this.dateRange1.startDate).format("YYYY-MM-DD")
          : null;
        var end_date = this.dateRange1.endDate
          ? moment(this.dateRange1.endDate).format("YYYY-MM-DD")
          : null;
        var sortBy = this.sortBy ? this.sortBy : "user_campaign.id";
        var sortDirection = this.sortDesc ? "desc" : "asc";
        var type = this.currentTab ? this.currentTab : "coins";
        var company_id_filter = this.company_id_filter.company_id
          ? this.company_id_filter.company_id
          : null;
        // var trans_type_filter = this.trans_type_filter.length > 0 ? this.trans_type_filter : null;
        var trans_type_filter = null;
  
        var mode_filter = this.mode_filter ? this.mode_filter : null;
  
        // var keyword = this.filter ? this.filter : null;
        var keyword = this.searchKeyword ? this.searchKeyword : null;

        var month =this.selectedMonth ? this.selectedMonth :null;
  
        // var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.userDPAccountStatusFilterAll+'/'+this.userAccountStatusFilterAll+'/'+this.filterRejected+'/null/null'+'/'+this.form.user_id;
        var export_url =
          "/api/user-campaign-bonus-export/" +
          keyword +
          "/" +
          month +
          "/" +
          sortBy +
          "/" +
          sortDirection;
  
        window.open(base_url + export_url, "_blank");
        // window.open(export_url,'_blank')
      },
      customFormatterOne(date) {
        return moment(date).format("DD-MM-YYYY");
      },
      customFormatterTwo(date) {
        return moment(date).format("MMM,YYYY");
      },
      getFreelancerList() {
        this.$store
          .dispatch(POST_API, {
            api: "/api/completed-freelancer-list",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              this.freelancerList=this.$store.getters.getResults.data;
              this.filteredFreelancerList = this.$store.getters.getResults.data;
            }
          });
      },
      getBillingTypes() {
        this.$store
          .dispatch(POST_API, {
            data: {},
            api: "/api/billing-types",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              this.billing_types = this.$store.getters.getResults.data;
            }
          });
      },
      permissionStaff() {
        if (this.$store.getters.getCurrentUserPermission) {
          if (
            this.$store.getters.getCurrentUserPermission.data.length > 0 &&
            this.$store.getters.currentUser.user_type_id == 2
          ) {
            var menu = this.$store.getters.getCurrentUserPermission.data;
            this.actions = permission.getPermissionStaff(menu, "Pay Campaign Bonus");
            this.actionsCampaign = permission.getPermissionStaff(menu,'Campaigns');

          }
        }
      },
      show(action) {
        if (this.$store.getters.currentUser.user_type_id == 2) {
          return this.actions.indexOf(action) >= 0 ? true : false;
        } else {
          return true;
        }
      },
      showCampaign(action) {
        if (this.$store.getters.currentUser.user_type_id == 2) {
          return this.actionsCampaign.indexOf(action) >= 0 ? true : false;
        } else {
          return true;
        }
      },
      getCampaigns(type) {
        return this.$store
          .dispatch(POST_API, {
            data: {
              type: type,
            },
            api: "/api/active-campaigns-list",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              return [];
            } else {
                this.campaigns = this.$store.getters.getResults.data;
                return this.campaigns;
              
            }
          });
        },
        onCampaignChange(){
            console.log(this.campaign_type)
            this.remarks = this.campaign_type.title;
        },
        customSearch(searchQuery, id) {
            this.filteredFreelancerList = this.freelancerList.filter(freelancer => 
            freelancer.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            freelancer.mobile_number.includes(searchQuery)
          )
        },
        allSelected(e){
            this.selectedUser = [];
           
            if (e) {
                var i = [];
                this.items.map(item => { 
                   if(item.payment_status == 'unpaid'){
                    i.push(item.id);
                   }
                });
                this.selectedUser = i;
                this.multi = true;
                
            }else{
                this.selectedUser = [];
                this.multi = false;

            }
            console.log(this.selectedUser);
        }, 
        remove(item) {
    if (item && item.payment_status !== 'unpaid') {
        this.showErrorAlert("Only unpaid freelancers can be removed.");
        return;
    }
    let remmovedItems = Array.isArray(this.selectedUser) ? [...this.selectedUser] : [this.selectedUser];
    console.log(remmovedItems,[item.id])

    this.$swal({
        title: 'Please Confirm',
        text: 'Are you sure you want to delete this record?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
    })
    .then(result => {
        if (result.value) {
            return this.$store.dispatch(POST_API, {
                data: {
                    id: this.multi ? remmovedItems : [item.id],
                    multiple: this.multi,
                    created_by: this.user_id,
                },
                api: '/api/delete-campaign-bonus',
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    var message = 'Bonus removed successfully.';
                    this.$refs.datatable.refresh();
                    this.showSuccessAlert(message);
                    this.multi=false
                    this.selectedUser=[]
                }
            });
        }
    })
    .catch(err => {
        console.error(err);
    });
},
        payBonus(id)
        {
            return this.$store.dispatch(POST_API, {
                data:{
                    user_campaign_id: this.multi ? this.selectedUser : (id  != '' ? [id] : this.selectedUser),
                    multiple:this.multi,
                    created_by: this.user_id,
                },
                api:'/api/pay-campaign-bonus',
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    var message='Campaign paid successfully.';
                    this.selectAll = false;
                    this.selectedUser = [];
                    this.multi = false;

                    this.$refs.datatable.refresh();
                    this.showSuccessAlert(message);

                }
            });
               
        },

    },
    watch: {
    selectedUser(newValue) {
      // If there are selected items, set this.multi to true
      this.multi = newValue.length > 0;
    }
  },
    mounted() {
      $(".show_filter_arrow .table thead th div").contents().unwrap();
      $(".dashboard-content-container .simplebar-scroll-content").animate(
        {
          scrollTop: 0,
        },
        "fast"
      );
      //document.title = "EL Connect - Jobseeker - Transaction";
      this.user_id =
        this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
      this.designation =
        this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : "";
      this.permissionStaff();
      this.getCampaigns("all");
      this.getFreelancerList();
      this.modifyDate();
    },
    created() {
    this.filteredFreelancerList = this.freelancerList;
  },
  };
</script>
  
  <style scoped>
.placeholder-label {
  font-size: 16px;
  color: #000;
  background: #ffffff;
}
</style>