<template>
  <!-- Wrapper -->
  <div id="wrapper wrapper_pdng">
    <!-- <div class="back_arrow_icon new_bak_arr back_with_ft_btn"> -->
    <div class="breadcrumb_with_btn new_bak_arr back_with_ft_btn">
      <!-- <span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span> -->
      <div id="breadcrumb-v2" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb :items="breadCrumb()" />
        </div>
      </div>
      <div class="three_profile_btn_em">
        <b-link
          class="btn green-border-btn new_edit_ft_btn_emp desktop_margin_top_zero desktop_margin_right_12"
          v-if="items.qrcode_url != null && show('download qr code')"
          @click="empQrCode(items.qrcode_url, '', 'download')"
          >Download QR Code</b-link
        >
        <b-link
          class="btn green-border-btn new_edit_ft_btn_emp desktop_margin_top_zero desktop_margin_right_12"
          style="margin-left: 7px"
          v-if="showComUser('view')"
          :to="{ name: 'EmpManageUserAdmin', params: { id: this.$route.params.company_id } }"
          >Users</b-link
        >
        <b-link
          class="btn green-border-btn new_edit_ft_btn_emp desktop_margin_top_zero desktop_margin_right_12"
          v-if="show('edit company')"
          :to="{ name: 'employer-edit', params: { id: this.$route.params.id } }"
          >Edit</b-link
        >
        <b-link
          class="btn green-border-btn new_edit_ft_btn_emp desktop_margin_top_zero desktop_margin_right_12"
          v-if="show('gallery')"
          :to="{ name: 'emp-gallery-admin', params: { id: this.$route.params.id } }"
          >Gallery</b-link
        >
        <div class="toggle-action common_ul_radius toggle_margin_unset action-view-detail edit_action_view_detail_desktop">
        <b-dropdown class="mx-1" left text="Action" boundary="window">
            <b-dropdown-item
              v-if="show('all jobs')"
              @click="allJobs($route.params.company_id)"
              >All Jobs
            </b-dropdown-item>
            <b-dropdown-item
              v-if="show('audit jobs')"
              :to="{
                name: 'employer-audit-jobs',
                params: {
                  id: this.$route.params.id,
                  company_id: this.$route.params.company_id,
                },
              }"
            >Audit Jobs
            </b-dropdown-item>
            <b-dropdown-item
              v-if="showBillingAdd('view')"
              :to="{
                name: 'BillingAddress',
                params: {
                  id: this.$route.params.id,
                  company_id: this.$route.params.company_id,
                },
              }"
              >Billing Address
            </b-dropdown-item>
            <b-dropdown-item
              v-else-if="show('blacklist')"
              @click="openDisableModel(id, '', 'blacklist')"
              >Blacklist</b-dropdown-item
            >
            <b-dropdown-item
              v-if="
                items.account_verification_status == 'incomplete' &&
                show('convert to fl')
              "
              @click="empConvertFL(id, company_id)"
              >Convert to FL
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                this.ft_account_status != 'completed' && show('delete company')
              "
              v-on:click="deleteCompany(id, company_id)"
              >Delete</b-dropdown-item
            >
            <b-dropdown-item
              v-if="
                items.qrcode_url != null &&
                items.account_verification_status == 'completed' &&
                show('download qr code')
              "
              @click="empQrCode(items.qrcode_url, '', 'download')"
              >Download QR Code
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                items.posting_paused == 'no' &&
                items.account_verification_status == 'completed' &&
                show('freeze/unfreeze Account')
              "
              v-on:click="pausePosting(id, items.posting_paused)"
              >Freeze Account</b-dropdown-item
            >
            <b-dropdown-item
              v-if="
                items.account_verification_status == 'completed' &&
                show('generate invoice')
              "
              @click="openModelGenerate(company_id, items.billing_cycle)"
              >Generate Invoice
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                items.qrcode_url != null &&
                items.account_verification_status == 'completed' &&
                show('regenerate qr code')
              "
              @click="empQrCode(company_id, id, 'generate')"
              >Regenerate QR Code
            </b-dropdown-item>
            <b-dropdown-item
              v-else-if="
                items.account_verification_status == 'completed' &&
                show('generate qr code')
              "
              @click="empQrCode(company_id, id, 'generate')"
              >Generate QR Code
            </b-dropdown-item>
            <b-dropdown-item
              v-if="items.has_department == 'yes' && showManageDepart('view')"
              :to="{
                name: 'companyDepartments',
                params: {
                  id: encrypt(id),
                  company_id: encrypt(company_id),
                },
              }"
              >Manage Departments
            </b-dropdown-item>
            <b-dropdown-item
              v-if="items.status == 'blacklisted' && show('reactive')"
              v-on:click="
                openDisableModel(id, items.status, 'reactive')
              "
              >Reactive</b-dropdown-item
            >
            <b-dropdown-item
              v-if="items.account_verification_status == 'completed'"
              @click="openRecalModal(id)"
              >Recalculate Wallet
            </b-dropdown-item>



            <b-dropdown-item
              v-on:click="
                changeCompanyStatus(
                  id,
                  company_id,
                  items.status
                )
              "
              v-if="
                items.status == 'active' &&
                items.account_verification_status == 'completed' &&
                show('activate/inactivate')
              "
              >Inactivate</b-dropdown-item
            >
            <b-dropdown-item
              v-else-if="
                items.status == 'inactive' &&
                items.account_verification_status == 'completed' &&
                show('activate/inactivate')
              "
              v-on:click="
                changeCompanyStatus(
                  id,
                  company_id,
                  status
                )
              "
              >Activate
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                this.ft_account_status != 'completed' && show('delete company')
              "
              v-on:click="deleteCompany(this.$route.params.id, this.$route.params.company_id)"
              >Delete</b-dropdown-item
            >
            <b-dropdown-item
              v-if="items.email && show('send password reset link')"
              @click="sendResetPasswordLink(this.$route.params.id)"
              >Send Reset Password Link
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                items.email_verified == 'no' &&
                items.email &&
                show('send verify email link')
              "
              @click="sendEmailVerificationLink(id)"
              >Send Email Verification Link
            </b-dropdown-item>
            <b-dropdown-item
              v-if="items.email && show('send password reset link')"
              @click="sendResetPasswordLink(id)"
              >Send Reset Password Link
            </b-dropdown-item>
            <b-dropdown-item
              v-if="show('transactions')"
              :to="{
                name: 'EmpTransactionAdmin',
                params: {
                  id: this.$route.params.id,
                  company_id: this.$route.params.company_id,
                },
              }"
              >Transactions
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                items.posting_paused == 'yes' &&
                items.account_verification_status == 'completed' &&
                show('freeze/unfreeze Account')
              "
              v-on:click="pausePosting(id, items.posting_paused)"
              >Unfreeze Account</b-dropdown-item
            >
            <b-dropdown-item
              v-if="
                items.subscribe_mail == 'yes' &&
                show('subscribe/unsubscribe mailing list')
              "
              @click="subscribeUnsubscribeMail(id)"
              >Unsubscribe Mailing List</b-dropdown-item
            >
            <b-dropdown-item
              v-if="
                items.subscribe_mail == 'no' &&
                show('subscribe/unsubscribe mailing list')
              "
              @click="subscribeUnsubscribeMail(id)"
              >Subscribe Mailing List
            </b-dropdown-item>
            <b-dropdown-item
              v-if="show('update elc credits')"
              @click="
                openCoinModel(
                  id,
                  company_id,
                  items.coins,
                  'coins'
                )
              "
              >Update ELC Credits
            </b-dropdown-item>
            <b-dropdown-item
              v-if="show('update free job ads')"
              @click="
                openJobAdsModel(
                  id,
                  company_id,
                  items.coins,
                  'coins'
                )
              "
              >Update Free Job Ads
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                items.account_verification_status != 'rejected' &&
                items.status != 'deleted' &&
                show('update email')
              "
              @click="openInfoModel(id, company_id, 'email')"
              >Update Email
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                items.account_verification_status != 'rejected' &&
                items.status != 'deleted' &&
                show('update mobile number')
              "
              @click="openInfoModel(id, company_id, 'mobile')"
              >Update Mobile Number
            </b-dropdown-item>
            <b-dropdown-item
              v-if="show('update password')"
              @click="openInfoModel(id, company_id, 'password')"
              >Update Password
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                items.email_verified == 'no' &&
                items.email &&
                show('verify email')
              "
              v-on:click="verifyEmail(id)"
              >Verify Email</b-dropdown-item
            >
            <b-dropdown-item
              v-if="
                items.mobile_verified == 'no' &&
                items.mobile_number &&
                show('verify mobile number')
              "
              v-on:click="verifyMobileNumber(id)"
              >Verify Mobile Number</b-dropdown-item
            >
            <b-dropdown-item
              v-if="
                items.account_verification_status == 'completed' &&
                show('generate invoice')
              "
              @click="openModel(this.$route.params.company_id, items.billing_cycle)"
              >Generate Invoice
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                items.qrcode_url != null &&
                items.account_verification_status == 'completed' &&
                show('download qr code')
              "
              @click="empQrCode(items.qrcode_url, '', 'download')"
              >Download QR Code
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                items.qrcode_url != null &&
                items.account_verification_status == 'completed' &&
                show('regenerate qr code')
              "
              @click="empQrCode(this.$route.params.company_id, this.$route.params.id, 'generate')"
              >Regenerate QR Code
            </b-dropdown-item>
            <b-dropdown-item
              v-if="items.account_verification_status == 'completed'"
              @click="openRecalModal(this.$route.params.id)"
              >Recalculate Wallet
            </b-dropdown-item>
            <b-dropdown-item
              v-else-if="
                items.account_verification_status == 'completed' &&
                show('generate qr code')
              "
              @click="empQrCode(this.$route.params.company_id, this.$route.params.id, 'generate')"
              >Generate QR Code
            </b-dropdown-item>
            <b-dropdown-item
              v-if="show('transactions')"
              :to="{
                name: 'EmpTransactionAdmin',
                params: {
                  id: encrypt(this.$route.params.id),
                  company_id: encrypt(this.$route.params.company_id),
                },
              }"
              >Transactions
            </b-dropdown-item>
            <b-dropdown-item
              v-if="showBillingAdd('view')"
              :to="{
                name: 'BillingAddress',
                params: {
                  id: encrypt(this.$route.params.id),
                  company_id: encrypt(this.$route.params.company_id),
                },
              }"
              >Billing Address
            </b-dropdown-item>
            <b-dropdown-item
              v-if="items.has_department == 'yes' && showManageDepart('view')"
              :to="{
                name: 'companyDepartments',
                params: {
                  id: encrypt(this.$route.params.id),
                  company_id: encrypt(this.$route.params.company_id),
                },
              }"
              >Manage Departments
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                items.account_verification_status == 'incomplete' &&
                show('convert to fl')
              "
              @click="empConvertFL(this.$route.params.id, this.$route.params.company_id)"
              >Convert to FL
            </b-dropdown-item>
            <b-dropdown-item
              v-if="items.status == 'blacklisted' && show('reactive')"
              v-on:click="
                openDisableModel(this.$route.params.id, items.status, 'reactive')
              "
              >Reactive</b-dropdown-item
            >
            <b-dropdown-item
              v-else-if="show('blacklist')"
              @click="openDisableModel(this.$route.params.id, '', 'blacklist')"
              >Blacklist</b-dropdown-item
            >
            <b-dropdown-item
              v-if="
                items.subscribe_mail == 'yes' &&
                show('subscribe/unsubscribe mailing list')
              "
              @click="subscribeUnsubscribeMail(this.$route.params.id)"
              >Unsubscribe Mailing List</b-dropdown-item
            >
            <b-dropdown-item
              v-if="
                items.subscribe_mail == 'no' &&
                show('subscribe/unsubscribe mailing list')
              "
              @click="subscribeUnsubscribeMail(this.$route.params.id)"
              >Subscribe Mailing List
            </b-dropdown-item>
        </b-dropdown>
      </div>
      </div>
    </div>
    <!-- <b-link class="btn green-border-btn" :to="{name: 'employer-edit', params: {id: this.$route.params.id}}" >Edit Profile</b-link> -->

    <!-- Titlebar
================================================== -->
    <!--<div class="single-page-header freelancer-header single_top_bk">
	<div class="single_gradient">
	<div class="container mx_width">
		<div class="row ">
			<div class="col-md-12">
				<div class="single-page-header-inner">
					<div class="left-side">
						<div class="header-image"><img :src="items.company_logo ? items.company_logo: webUrl+'assets/images/company-logo-placeholders.png'" alt="profile_image"></div>
						<div class="header-details">
							<h3 class="name_mrgn">{{ items.name | capitalize }} </h3>
							<h4 class="new_h4">Short Description</h4>
							<ul>
								 <li class="emp_li_fst"><div class="star-rating" data-rating="4.9" v-if="items.average_rating != null"></div></li>
								<li><star-rating v-model="items.average_rating" v-bind:star-size="20" :show-rating="false"  v-if="items.average_rating != null"></star-rating></li>
								<li><img class="flag" src="images/flags/de.svg" alt=""> {{ items.country_name }}</li>
								<li><div class="verified-badge-with-title" v-if="items.mobile_verified == 'yes' && items.email_verified == 'yes'">Verified</div></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	</div>
</div>-->

    <div
      class="bg_img_top new_detail_bg_img new_job_detail_full_dvz new_bg_img_tp"
      v-bind:style="{
        backgroundImage:
          'url(' +
          (items.banner_image ? items.banner_image : webUrl + 'assets/images/single-job.jpg') +
          ')',
      }"
    >
      <div class="grd_div inside_grd height_three_zero_one">
        <div class="container my_new_container">
          <div class="row">
            <div class="col-md-12">
              <div class="inrs_main">
                <div class="left-side desktop_width_hundered updated_left_side_banner">
                  <div
                    class="header-image updated_header_image_banner updated_image_in_square_shape"
                  >
                    <viewer>
                      <img
                        :src="
                          items.company_logo
                            ? items.company_logo
                            : webUrl + 'assets/images/company-logo-placeholders.png'
                        "
                        alt="profile_image"
                      />
                    </viewer>
                  </div>
                  <div class="header-details updated_header_details_banner">
                    <h1 class="name_mrgn header_detail_new_h1 new_daily_paid_jjobs">
                      {{ items.name | capitalize }}
                    </h1>
                    <h4 class="new_h4" v-if="items.short_description">
                      {{ items.short_description | capitalize }}
                    </h4>
                    <ul
                      class="margin_top_12 mobile_header_ul"
                      v-if="items.mobile_verified == 'yes' && items.email_verified == 'yes'"
                    >
                      <!-- <li class="emp_li_fst"><div class="star-rating" data-rating="4.9" v-if="items.average_rating != null"></div></li>  -->
                      <!-- <li><star-rating v-model="items.average_rating" v-bind:star-size="20" :show-rating="false"  v-if="items.average_rating != null"></star-rating></li> -->
                      <!--  <li><img class="flag" src="images/flags/de.svg" alt="profile_image"> {{ items.country_name }}</li> -->
                      <li>
                        <div
                          class="verified-badge-with-title"
                          v-if="items.mobile_verified == 'yes' && items.email_verified == 'yes'"
                        >
                          Verified
                        </div>
                      </li>
                      <div class="new-name-td-box-full-icon employer_pause_icon">
                        <span v-if="items.posting_paused == 'yes'" class="full_span_pause_status"
                          ><span class="full_inside_span_pause_status"
                            ><i class="material-icons-outlined"
                              >pause_circle_outline_outlined</i
                            ></span
                          >Frozen</span
                        >
                      </div>
                    </ul>
                  </div>
                </div>
                <!-- <div class="right_daily_paid custom_salary_box">
                        <div class="right_inner_contents">
                            <div class="salary-box">
                                <li><i class="icon-material-outline-email"></i><span>Email</span><h5>{{items.email}}</h5></li>
                                <li><i class="icon-line-awesome-mobile-phone"></i><span>Mobile Number</span><h5>{{items.user_country_code}}-{{items.mobile_number}}</h5></li>
                            </div>
                        </div>
                    </div> -->
                <!--<div class="right-side" v-if="min_salary && max_salary">
                        <div class="right_inner_contents">
						<div class="salary-box">
							<div class="salary-type" v-if="job_type == 'part-time'">Per Hour Rate</div>
							<div class="salary-type" v-else>Salary</div>
						<div class="salary-amount">${{min_salary}}-${{max_salary}}</div>
						</div>
                        </div>
					</div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Page Content
================================================== -->
    <div class="container my_new_container job_detail_container">
      <div class="row">
        <!-- Content -->
        <div
          class="col-xl-8 col-lg-8 single_page_section_last_child single_left_content tab_max_width_and_flex emp_prf"
        >
          <!-- Page Content -->
          <div>
            <div
              class="add_on_new_single_page_section single-page-section desktop_margin_top_zero"
              v-if="items.description != null && items.description != ''"
            >
              <h3 class="margin-bottom-25 font_weight_600">About Company</h3>
              <p v-html="items.description"></p>
            </div>

            <!-- Boxed List -->
            <div
              class="add_on_new_single_page_section boxed-list single-page-section desktop_margin_top_zero"
              v-if="company_jobs.length > 0"
            >
              <div class="boxed-list-headline custom_box_border_radius_unset_top_bottom">
                <h3 class="desktop_margin_bottom_zero">
                  <i class="icon-material-outline-business-center"></i> Open Positions
                </h3>
              </div>

              <div class="listings-container compact-list-layout">
                <!-- <a href="" class="job-listing" v-for="company_job in company_jobs"> -->
                <a
                  href="#"
                  @click="handleClick(encrypt(company_job.jobId), $event)"
                  class="job-listing"
                  v-for="(company_job, index) in company_jobs"
                  v-if="index <= 1"
                >
                  ``
                  <div class="job-listing-details">
                    <div class="job-listing-description">
                      <h3 class="job-listing-title">{{ company_job.job_title }}</h3>

                      <div class="job-listing-footer">
                        <ul>
                          <li class="black_job_color">
                            <i class="icon-material-outline-location-on common_i_color"></i
                            >{{
                              company_job.job_address == "Other"
                                ? company_job.other_location
                                : company_job.job_address | capitalize
                            }}
                          </li>
                          <li class="black_job_color">
                            <i class="icon-material-outline-business-center common_i_color"></i>
                            {{ company_job.job_type | customizeRoleType | capitalize }}
                          </li>
                          <li class="black_job_color">
                            <i class="icon-material-outline-access-time common_i_color"></i>
                            {{ company_job.posted_date | dateTimeAgo | capitalize }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div
              class="add_on_new_single_page_section details_list_job single-page-section"
              v-if="company_jobs_count > 2 && showJobAds('view')"
            >
              <b-link
                :to="{ name: 'fulltime-emp-job-list', params: { id: encrypt(items.company_id) } }"
                target="_blank"
                class="headline-link"
                v-if="company_jobs_count > 2"
                >Browse All Listing</b-link
              >
            </div>
            <div
              class="add_on_new_single_page_section boxed-list single-page-section desktop_margin_top_zero"
            >
              <div class="boxed-list-headline custom_box_border_radius_unset_top_bottom">
                <h3 class="desktop_margin_bottom_zero">
                  <i class="icon-material-outline-business-center"></i>Temp. Staff Details
                </h3>
              </div>
              <div class="listings-container compact-list-layout">
                <div class="common_padding_30 whole_box_h5">
                  <h5 v-if="items.created_at">
                    <b>Registered On: </b><span>{{ items.created_at | moment }}</span>
                  </h5>
                  <h5 v-if="items.first_job_posted_date">
                    <b>First Job Posted Date: </b
                    ><span>{{ items.first_job_posted_date | moment }}</span>
                  </h5>
                  <h5>
                    <b>Free DP Job Posts: </b
                    ><span>{{ items.signup_free_post != null ? items.signup_free_post : 0 }}</span>
                  </h5>
                  <span class="add_on_h5_to_span"
                    ><b class="color_333">Free DP Job Posts(Months):&nbsp</b></span
                  ><span class="color_333 add_on_h5_to_span">{{
                    items.signup_free_post != null ? items.signup_free_post : 0
                  }}</span>
                  <!-- <h5 v-else> N/A</h5> -->
                  <h5 v-if="items.on_demand_charges">
                    <b>On Demand Charges: </b><span>{{ items.on_demand_charges }}%</span>
                  </h5>
                  <h5 v-if="items.charge_type == 'fixed_rate'">
                    <b>Fixed Rate: </b><span>${{ items.fixed_rate }}</span>
                  </h5>
                  <h5 v-if="items.charge_type == 'platform_fee'">
                    <b>Non-CPF Service Fee Percent: </b
                    ><span>{{ items.non_cpf_service_fee_percent }}%</span>
                  </h5>
                  <h5 v-if="items.min_late_minutes_for_fl">
                    <b>Min Late Minutes For FL: </b><span>{{ items.min_late_minutes_for_fl }}</span>
                  </h5>
                  <!-- <span v-if="items.created_at"><b>Registered On: </b></span><h5>{{items.created_at | moment}}</h5>
						<span><b>Discount Period in Days: </b></span><h5>{{items.signup_days_completion_for_employer_promotion != null ? items.signup_days_completion_for_employer_promotion: 0}} Days</h5>
						<span><b>Discount Period Expiry Date: </b></span><h5 v-if='items.signup_days_completion_for_employer_promotion != null'>{{expiry_date | moment}}</h5>
						<h5 v-else> -</h5>
						<span v-if="items.on_demand_charges"><b>On Demand Charges: </b></span><h5>{{items.on_demand_charges}}%</h5>
						<span v-if="items.non_cpf_service_fee_percent"><b>Non-CPF Service Fee Percent: </b></span><h5>{{items.non_cpf_service_fee_percent}}%</h5> -->

                  <!-- <span v-if="items.signup_days_completion_for_employer_promotion"><b>Signup Days Completion For Employer Promotion</b></span><h5>{{items.signup_days_completion_for_employer_promotion}}</h5> -->
                  <!-- <span v-if="items.short_description"><b>Short Description</b></span><h5>{{items.short_description}}</h5> -->
                </div>
              </div>
            </div>
            <div
              class="add_on_new_single_page_section boxed-list single-page-section desktop_margin_top_zero"
            >
              <div class="boxed-list-headline custom_box_border_radius_unset_top_bottom">
                <h3 class="desktop_margin_bottom_zero">
                  <i class="icon-material-outline-business-center"></i> Temp. Staff System Settings
                </h3>
              </div>
              <div class="listings-container compact-list-layout">
                <div class="common_padding_30 whole_box_h5">
                  <h5 v-if="items.receive_whatsapp_update">
                    <b>Receive Whatsapp Update: </b
                    ><span>{{ items.receive_whatsapp_update | capitalize }}</span>
                  </h5>
                  <h5 v-if="items.send_notification">
                    <b>Send Notifications To All Users: </b
                    ><span>{{ items.send_notification | capitalize }}</span>
                  </h5>
                  <h5 v-if="items.send_invoice">
                    <b>Send Invoice To All Users: </b
                    ><span>{{ items.send_invoice | capitalize }}</span>
                  </h5>
                  <h5 v-if="items.qr_based_arrival">
                    <b>QR Based Arrival: </b><span>{{ items.qr_based_arrival | capitalize }}</span>
                  </h5>
                  <h5 v-if="items.gps_check_enabled">
                    <b>GPS Check Enable: </b><span>{{ items.gps_check_enabled | capitalize }}</span>
                  </h5>
                  <h5 v-if="items.enable_email_for_job_assign">
                    <b>Enable Email for Job Assign: </b
                    ><span>{{ items.enable_email_for_job_assign | capitalize }}</span>
                  </h5>
                  <h5 v-if="items.exempted">
                    <b>Exempt from Early Cancellation: </b
                    ><span>{{ items.exempted | capitalize }}</span>
                  </h5>
                  <h5 v-if="items.show_applied_and_worked_before_logo">
                    <b>Show Applied & Worked Before Logo: </b
                    ><span>{{ items.show_applied_and_worked_before_logo | capitalize }}</span>
                  </h5>
                  <h5 v-if="items.receive_email_from_awarded_fl">
                    <b>Receive Email for Award FL Excel: </b
                    ><span>{{ items.receive_email_from_awarded_fl | capitalize }}</span>
                  </h5>
                  <h5
                    v-if="
                      items.receive_email_from_awarded_fl == 'yes' &&
                      items.receive_email_from_awarded_fl_hrs > 0
                    "
                  >
                    <b>Receive Email for Award FL Excel Hrs: </b
                    ><span>{{ items.receive_email_from_awarded_fl_hrs }}</span>
                  </h5>
                  <h5 v-if="items.billing_cycle">
                    <b>Billing Cycle: </b
                    ><span>{{ items.billing_cycle | customizeBillingType }}</span>
                  </h5>

                  <!-- <span v-if="items.receive_whatsapp_update"><b>Receive Whatsapp Update: </b></span><h5>{{items.receive_whatsapp_update | capitalize}}</h5>
						<span v-if="items.send_notification"><b>Send Notifications To All Users: </b></span><h5>{{items.send_notification | capitalize}}</h5>
						<span v-if="items.send_invoice"><b>Send Invoice To All Users: </b></span><h5>{{items.send_invoice | capitalize}}</h5>
						<span v-if="items.qr_based_arrival"><b>QR Based Arrival: </b></span><h5>{{items.qr_based_arrival | capitalize}}</h5>
						<span v-if="items.gps_check_enabled"><b>GPS Check Enable: </b></span><h5>{{items.gps_check_enabled | capitalize}}</h5>
						<span v-if="items.enable_email_for_job_assign"><b>Enable Email for Job Assign: </b></span><h5>{{items.enable_email_for_job_assign | capitalize}}</h5>
						<span v-if="items.exempted"><b>Exempt from Early Cancellation: </b></span><h5>{{items.exempted | capitalize}}</h5> -->
                </div>
              </div>
            </div>
            <div
              class="add_on_new_single_page_section sidebar-widget single-page-section"
              v-if="rejection_comments.length > 0"
            >
              <div class="job-overview boxed-list">
                <div class="boxed-list-headline">
                  <h3 class="font_weight_600 common_margin_bottom_zero">
                    <i class="material-icons-outlined new_icon_material">contact_page_outlined</i
                    >Rejection Remarks
                  </h3>
                </div>
                <span v-if="rejection_comments.length > 0">
                  <ul v-if="rejection_comments.length > 0">
                    <li v-for="rejection_comment in rejection_comments" class="text-danger">
                      <p>
                        Rejection Remarks - <i>{{ rejection_comment.comment | capitalize }}</i>
                      </p>
                      <p>
                        Rejected By -
                        <i
                          >{{ rejection_comment.first_name | capitalize }}
                          {{ rejection_comment.last_name | capitalize }}</i
                        >
                      </p>

                      <small class="text-secondary">{{
                        rejection_comment.created_at | moment
                      }}</small>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
            <div
              class="add_on_new_single_page_section boxed-list single-page-section desktop_margin_top_zero"
              v-if="monthly_billing"
            >
              <div class="boxed-list-headline custom_box_border_radius_unset_top_bottom">
                <h3 class="desktop_margin_bottom_zero">
                  <i class="icon-material-outline-business-center"></i> Monthly Billing
                </h3>
              </div>
              <div class="listings-container compact-list-layout">
                <div class="common_padding_30 whole_box_h5">
                  <h5 v-if="monthly_billing.name">
                    <b>Name: </b><span>{{ monthly_billing.name | capitalize }}</span>
                  </h5>
                  <h5 v-if="monthly_billing.city">
                    <b>Address: </b><span>{{ monthly_billing.city | capitalize }}</span>
                  </h5>
                  <h5 v-if="monthly_billing.unit">
                    <b>Unit: </b><span>{{ monthly_billing.unit | capitalize }}</span>
                  </h5>
                  <h5 v-if="monthly_billing.postal_code">
                    <b>Postal Code: </b><span>{{ monthly_billing.postal_code }}</span>
                  </h5>
                  <h5 v-if="monthly_billing.country_name">
                    <b>Country: </b><span>{{ monthly_billing.country_name | capitalize }}</span>
                  </h5>
                  <h5 v-if="monthly_billing.mobile_number">
                    <b>Country Number</b><span>+65-{{ monthly_billing.mobile_number }}</span>
                  </h5>
                  <h5 v-if="monthly_billing.billing_email">
                    <b>Email: </b><span>{{ monthly_billing.billing_email }}</span>
                  </h5>

                  <!-- <span v-if="monthly_billing.name"><b>Name: </b></span><h5>{{monthly_billing.name | capitalize}}</h5>
						<span v-if="monthly_billing.city"><b>Address: </b></span><h5>{{monthly_billing.city | capitalize}}</h5>
						<span v-if="monthly_billing.unit"><b>Unit: </b></span><h5>{{monthly_billing.unit | capitalize}}</h5>
						<span v-if="monthly_billing.postal_code"><b>Postal Code: </b></span><h5>{{monthly_billing.postal_code}}</h5>
						<span v-if="monthly_billing.country_name"><b>Country: </b></span><h5>{{monthly_billing.country_name | capitalize}}</h5>
                        <span v-if="monthly_billing.mobile_number"><b>Country Number</b><h5>+65-{{monthly_billing.mobile_number}}</h5></span>
						<span v-if="monthly_billing.billing_email"><b>Email: </b></span><h5>{{monthly_billing.billing_email}}</h5> -->
                </div>
              </div>
            </div>
            <!-- Boxed List / End -->

            <!-- Boxed List -->
            <!-- <div class="boxed-list margin-bottom-60">
				<div class="boxed-list-headline">
					<h3><i class="icon-material-outline-thumb-up"></i> Reviews</h3>
				</div>
				<ul class="boxed-list-ul">
					<li>
						<div class="boxed-list-item">
							<div class="item-content">
								<h4>Doing things the right way <span>Anonymous Employee</span></h4>
								<div class="item-details margin-top-10">
									<div class="star-rating" data-rating="5.0">
										<span><i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
									</span>
									</div>
									<div class="detail-item"><i class="icon-material-outline-date-range"></i> August 2019</div>
								</div>
								<div class="item-description">
									<p>Great company and especially ideal for the career-minded individual. The company is large enough to offer a variety of jobs in all kinds of interesting locations. Even if you never change roles, your job changes and evolves as the company grows, keeping things fresh.</p>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div class="boxed-list-item">
							<div class="item-content">
								<h4>Outstanding Work Environment <span>Anonymous Employee</span></h4>
								<div class="item-details margin-top-10">
									<div class="star-rating" data-rating="5.0">
									<span><i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
									</span></div>
									<div class="detail-item"><i class="icon-material-outline-date-range"></i> May 2019</div>
								</div>
								<div class="item-description">
									<p>They do business with integrity and rational thinking. Overall, it's an excellent place to work, with products that are winning in the marketplace.</p>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div> -->
          </div>

          <div class="centered-button" v-if="items.account_verification_status == 'pending'">
            <b-button
              variant="success"
              class="text-uppercase x-md btn-shadow approved_reject_btn desktop_margin_left_zero"
              v-if="items.account_verification_status == 'pending'"
              @click="changeUserStatus('approve', items.user_id, items.company_id)"
              >Approve</b-button
            >
            <b-button
              variant="danger"
              class="text-uppercase x-md btn-shadow approved_reject_btn desktop_margin_left_zero"
              v-if="items.account_verification_status == 'pending'"
              @click="openModel('reject', items.user_id, items.company_id)"
              >Reject</b-button
            >

            <!-- <a href="#small-dialog"  class="popup-with-zoom-anim button button-sliding-icon">Leave a Review <i class="icon-material-outline-arrow-right-alt review_btn"></i></a>  -->
          </div>

          <!-- Boxed List / End -->
        </div>

        <!-- Sidebar -->
        <div
          class="col-xl-4 col-lg-4 right_side_single_right single_right_sidebar tab_max_width_and_flex emp_prf"
        >
          <div
            class="sidebar-container mobile_tab_margin_bottom_zero margin-bottom-25 mobile_margin_top_zero"
          >
            <!-- <div class="sidebar-widget">
					<a href="#small-dialog" class="apply-now-button popup-with-zoom-anim margin-bottom-50 msg_btn"><i class="fa fa-envelope new_letter"></i>Send a Message </a>
				</div>

				<div class="sidebar-widget nw_side" v-if="items.address">
					<h3>Company Address</h3>
					{{items.address}}

					 <div id="single-job-map-container">
						<div id="singleListingMap" data-latitude="52.520007" data-longitude="13.404954" data-map-icon="im im-icon-Hamburger"></div>
						<a href="#" id="streetView">Street View</a>
					</div>
				</div>

				<div class="sidebar-widget nw_side" v-if="items.address">
					<h3>Company BRN/UEN</h3>
					{{items.registration_id}}
				</div>  -->
            <div
              class="sidebar-widget common_box_border margin-bottom-25 desktop_margin_bottom_zero"
            >
              <div class="job-overview">
                <div class="job-overview-headline font_weight_600">Company Details</div>
                <div class="job-overview-inner">
                  <ul>
                    <li v-if="items.address">
                      <i class="icon-material-outline-email"></i><span>Company Address</span>
                      <h5>{{ items.address }}</h5>
                    </li>
                    <li v-if="items.registration_id">
                      <i class="icon-material-outline-business-center"></i
                      ><span>Company BRN/UEN</span>
                      <h5>{{ items.registration_id }}</h5>
                    </li>
                    <li v-if="items.email">
                      <i class="icon-material-outline-email"></i><span>Email</span>
                      <h5 class="common_word_break">
                        {{ items.email }}
                        <b-badge
                          variant="danger"
                          v-if="items.email_verified == 'no'"
                          class="profile_danger_badge"
                          >!</b-badge
                        >
                      </h5>
                    </li>
                    <li v-if="items.mobile_number">
                      <i class="icon-line-awesome-mobile-phone"></i><span>Mobile Number</span
                      ><b-link :href="'https://wa.me/+65' + items.mobile_number" target="_blank"
                        ><h5 style="color: #4c8bf5">
                          {{ items.user_country_code }}-{{ items.mobile_number }}
                          <b-badge
                            variant="danger"
                            v-if="items.mobile_verified == 'no'"
                            class="profile_danger_badge"
                            >!</b-badge
                          >
                        </h5></b-link
                      >
                    </li>
                    <li v-if="items.industry_name">
                      <i class="material-icons-outlined">home_repair_service_outlined</i
                      ><span>Company Industry</span>
                      <h5>
                        {{
                          items.industry_name == "Other"
                            ? items.specified_company_industry
                            : items.industry_name | capitalize
                        }}
                      </h5>
                    </li>
                    <li v-if="items.coins">
                      <i class="material-icons-outlined">monetization_on_outlined</i
                      ><span>Available Balance</span>
                      <h5>{{ items.coins }}</h5>
                    </li>
                    <li v-if="items.hold_coins">
                      <i class="material-icons-outlined">monetization_on_outlined</i
                      ><span>Pending Balance</span>
                      <h5>{{ items.hold_coins }}</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="sidebar-widget margin-top-25 desktop_margin_bottom_zero"
              v-if="acraUrl != ''"
            >
              <h3 class="font_weight_600">Attachments</h3>
              <div class="attachments-container desktop_margin_bottom_zero">
                <a :href="acraUrl" target="_blank">
                  <div
                    class="attachment-box desktop_margin_bottom_zero ripple-effect"
                    v-show="showACRA"
                  >
                    <span>ACRA</span>
                    <i>PDF</i>
                  </div>
                </a>
                <!-- <a :href="acraUrl" class="attachment-box ripple-effect"><span>Cover Letter</span><i>PDF</i></a> -->
                <!-- <a :href="acraUrl" class="attachment-box ripple-effect"><span>Contract</span><i>DOCX</i></a> -->
              </div>
            </div>

            <!--	<div v-if="userLog">

					<div class="sidebar-widget single-page-section" v-if="userLog.length > 0">
						<div class="job-overview boxed-list">
						<div class="boxed-list-headline boxed_border_unset"><h3 class="font_weight_600 common_margin_bottom_zero"><i class="material-icons-outlined new_icon_material">contact_page_outlined</i>Approved and Edit Logs</h3></div>
							<span v-if="userLog.length > 0">
								<ul v-if="userLog.length > 0" class="common_padding_30 desktop_margin_bottom_zero">
									<li v-for="logs in userLog" class="text-danger" style="margin-left: 17px;">
										<p v-if="logs.process == 'jobseeker_account_verification_status_approved'"><b>Approved By:</b> {{logs.editor_first_name | capitalize }} {{ logs.editor_last_name | capitalize }} {{ logs.created_at | moment }}</p>
										<p  v-else><b>Edited By:</b> {{logs.editor_first_name | capitalize }} {{ logs.editor_last_name |capitalize }} {{ logs.created_at | moment }}</p><br>
									</li>
								</ul>
							</span>
						</div>
					</div>
					</div>-->

            <!-- <div class="sidebar-widget nw_side" v-if='docs.length != 0'>
                                                         <div class="profile-content-detail" >
															<h3>Certificates</h3>

                                                            <ul  v-if='docs.length != 0'>
                                                                <li v-for="doc in docs">
                                                                    <b-img v-if="doc.image_type == 'image'" :src="doc.document" alt="certification img"></b-img>
                                                                    <b-img v-if="doc.image_type == 'pdf'" :src="webUrl+ 'assets/images/pdf.png'" alt="certification img"></b-img>
                                                                    <iframe v-if="doc.image_type == 'pdf'" :src="doc.document"></iframe>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        </div> -->

            <!-- Widget -->
            <!-- <div class="sidebar-widget" v-if="social_profile.length > 0">
					<h3>Social Profiles</h3>
					<div class="freelancer-socials margin-top-25">
						<ul>
                            <li><a :href="social_profile[0].url" target="_blank"  title="Visit Website" data-tippy-placement="top"><i class="icon-brand-dribbble"></i></a></li>
                            <li><a :href="social_profile[1].url" target="_blank"  title="Visit Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                            <li><a :href="social_profile[2].url" target="_blank"  title="Visit Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
                            <li><a :href="social_profile[3].url" target="_blank"  title="Visit Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
                            <li><a :href="social_profile[4].url" target="_blank"  title="Visit LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
						</ul>
					</div>
				</div> -->

            <div
              class="sidebar-widget margin-top-25 desktop_margin_bottom_zero"
              v-if="
                social_profile.length > 0
                  ? social_profile_website_url ||
                    social_profile_fb_url ||
                    social_profile_link_url ||
                    social_profile_twitter_url
                  : false
              "
            >
              <h3 class="font_weight_600">Company Social Profiles</h3>
              <div class="freelancer-socials">
                <ul class="new_social_share_emp">
                  <li v-if="social_profile_website_url">
                    <a
                      :href="social_profile_website_url"
                      target="_blank"
                      title="Visit Website"
                      data-tippy-placement="top"
                      ><i class="icon-brand-dribbble"></i
                    ></a>
                  </li>
                  <li v-if="social_profile_fb_url">
                    <a
                      :href="social_profile_fb_url"
                      target="_blank"
                      title="Visit Facebook"
                      data-tippy-placement="top"
                      ><i class="icon-brand-facebook-f"></i
                    ></a>
                  </li>
                  <!-- <li v-if="social_profile_google_url"><a :href="social_profile_google_url" target="_blank"  title="Visit Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li> -->
                  <li v-if="social_profile_link_url">
                    <a
                      :href="social_profile_link_url"
                      target="_blank"
                      title="Visit LinkedIn"
                      data-tippy-placement="top"
                      ><i class="icon-brand-linkedin-in"></i
                    ></a>
                  </li>
                  <li v-if="social_profile_twitter_url">
                    <a
                      :href="social_profile_twitter_url"
                      target="_blank"
                      title="Visit Twitter"
                      data-tippy-placement="top"
                      ><i class="icon-brand-twitter"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- <div class="sidebar-widget  margin-top-25 desktop_margin_bottom_zero" v-if="social_profile.length > 0 ? social_profile[0].url || social_profile[1].url ||  social_profile[2].url||  social_profile[3].url || social_profile[4].url : false">
					<h3 class="font_weight_600">Company Social Profiles</h3>
					<div class="freelancer-socials">
						<ul class="new_social_share_emp">
                            <li v-if="social_profile[0].url"><a :href="social_profile[0].url" target="_blank"  title="Visit Website" data-tippy-placement="top"><i class="icon-brand-dribbble"></i></a></li>
                            <li v-if="social_profile[1].url"><a :href="social_profile[1].url" target="_blank"  title="Visit Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                            <li v-if="social_profile[2].url"><a :href="social_profile[2].url" target="_blank"  title="Visit Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
                            <li v-if="social_profile[3].url"><a :href="social_profile[3].url" target="_blank"  title="Visit LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
							<li v-if="social_profile[4].url"><a :href="social_profile[4].url" target="_blank"  title="Visit Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
						</ul>
					</div>
				</div> -->
            <!-- <div class="sidebar-widget" v-if="social_profile[0].url || social_profile[1].url || social_profile[3].url || social_profile[4].url">
					<h3>Social Profiles</h3>
					<div class="freelancer-socials margin-top-25">
						<ul class="new_social_share_emp">
							<div v-for="sc_profile in social_profile">
                            <li v-if="sc_profile.acconunt_type='website'"><a :href="sc_profile.url" target="_blank"  title="Visit Website" data-tippy-placement="top"><i class="icon-brand-dribbble"></i></a></li>
                            <li v-if="sc_profile.acconunt_type='fb'"><a :href="sc_profile.url" target="_blank"  title="Visit Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                            <li v-if="sc_profile.acconunt_type='twitter'"><a :href="sc_profile.url" target="_blank"  title="Visit Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
                            <li v-if="sc_profile.acconunt_type='link'"><a :href="sc_profile.url" target="_blank"  title="Visit LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
						</ul>
					</div>
				</div> -->
            <!-- Sidebar Widget -->
            <!-- <div class="sidebar-widget nw_side">
					<h3>Share</h3>
					<div class="copy-url">
						<input id="copy-url" type="text" value="" class="with-border">
						<button class="copy-url-button ripple-effect" data-clipboard-target="#copy-url" @click="copyUrl()" title="Copy to Clipboard" data-tippy-placement="top"><i class="icon-material-outline-file-copy"></i></button>
					</div>

					<div class="share-buttons margin-top-25">
						<div class="share-buttons-trigger"><i class="icon-feather-share-2"></i></div>
						<div class="share-buttons-content">
							<span>Interesting? <strong>Share It!</strong></span>
							<ul class="share-buttons-icons">
								<li><a href="#" data-button-color="#3b5998" title="Visit Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
								<li><a href="#" data-button-color="#1da1f2" title="Visit Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
								<li><a href="#" data-button-color="#dd4b39" title="Visit Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
								<li><a href="#" data-button-color="#0077b5" title="Visit LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
							</ul>
						</div>
					</div>
				</div>  -->
          </div>
        </div>
        <b-modal
      ref="info-modal"
      :title="updateInfoTitle"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box model_legend">
                <b-col
                  lg="0"
                  md="0"
                  v-if="updateInfoTitle == 'Update Password'"
                  class="submit-field"
                >
                  <div id="show_hide_password_login" class="submit-field">
                    <b-form-group
                      id="input-group-0"
                      label="New Password"
                      class="required model_margin_bottom_zero"
                    >
                      <b-form-input
                        id="input-0"
                        type="password"
                        placeholder=""
                        :value="new_password"
                        @change="(v) => (new_password = v)"
                        class="input-with-border model_border_and_box_shadow"
                      ></b-form-input>
                    </b-form-group>
                    <div class="input-group-addon-custom update_pass_eye_div1">
                      <a @click="passwordClick">
                        <i class="fa fa-eye-slash common_cusror_hand_pointer" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                  <div id="show_hide_repassword_login">
                    <b-form-group
                      id="input-group-0"
                      label="Repeat New Password"
                      class="required model_margin_bottom_zero"
                    >
                      <b-form-input
                        id="input-0"
                        type="password"
                        placeholder=""
                        :value="confirm_new_password"
                        @change="(v) => (confirm_new_password = v)"
                        class="input-with-border model_border_and_box_shadow"
                      ></b-form-input>
                    </b-form-group>
                    <div class="input-group-addon-custom update_pass_eye_div">
                      <a @click="repasswordClick">
                        <i class="fa fa-eye-slash common_cusror_hand_pointer" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </b-col>
                <b-col
                  lg="9"
                  md="9"
                  v-if="updateInfoTitle == 'Update Email'"
                  class="model_padding_left_zero model_padding_right_zero"
                >
                  <b-form-group id="input-group-0" label="Email" class="required submit-field">
                    <b-form-input
                      id="input-0"
                      type="text"
                      placeholder=""
                      :value="update_email"
                      @change="(v) => (update_email = v)"
                      class="model_border_and_box_shadow"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="9"
                  md="9"
                  v-if="updateInfoTitle == 'Update Mobile'"
                  class="model_padding_left_zero model_padding_right_zero"
                >
                  <b-form-group
                    id="input-group-0"
                    label="Mobile Number"
                    class="required submit-field"
                  >
                    <b-form-input
                      id="input-0"
                      type="text"
                      placeholder=""
                      :value="update_mobile_number"
                      @change="(v) => (update_mobile_number = v)"
                      class="model_border_and_box_shadow"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0">
                  <div slot="footer" class="form-btn common_crop_reset_btn">
                    <b-button
                      type="submit"
                      variant="success"
                      v-if="updateInfoTitle == 'Update Password'"
                      @click="submitUpdateInfoPassword()"
                      class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      type="submit"
                      variant="success"
                      v-else
                      @click="submitUpdateInfo()"
                      class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </b-col>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="recalculate-wallet-modal"
      title="Recalculate Wallet"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class model_padding_left_zero inline_radio_common"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero">
        <div class="profile-repeate form-box-field">
          <b-col lg="12" md="12">
            <div class="form-map-box">
              <b-row>
                <b-col lg="6" md="6" class="model_submit_field">
                  <b-form-group
                    id="input-group-6"
                    label="Current Credits"
                    class="model_margin_bottom_zero required new_model_date_picker model_date_picker"
                  >
                    <b-form-input v-model="current_coins" type="text" readonly></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6" class="model_submit_field">
                  <b-form-group
                    id="input-group-6"
                    label="Current Hold Credits"
                    class="model_margin_bottom_zero required new_model_date_picker model_date_picker"
                  >
                    <b-form-input v-model="hold_coins" type="number" readonly></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6" class="model_submit_field">
                  <b-form-group
                    id="input-group-6"
                    label="Recalculated Credits"
                    class="model_margin_bottom_zero required new_model_date_picker model_date_picker"
                  >
                    <b-form-input
                      v-model="recalculated_value_old"
                      type="text"
                      readonly
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6" class="model_submit_field">
                  <b-form-group
                    id="input-group-6"
                    label="Recalculated Hold Credits"
                    class="model_margin_bottom_zero required new_model_date_picker model_date_picker"
                  >
                    <b-form-input
                      v-model="recalculated_hold_coins_old"
                      type="text"
                      readonly
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col lg="6" md="6" class="model_submit_field">
                  <b-form-group
                    id="input-group-6"
                    label="New Credits"
                    class="model_margin_bottom_zero required new_model_date_picker model_date_picker"
                  >
                    <b-form-input v-model="recalculated_value" type="text"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col lg="6" md="6" class="model_submit_field">
                  <b-form-group
                    id="input-group-6"
                    label="New Hold Credits"
                    class="model_margin_bottom_zero required new_model_date_picker model_date_picker"
                  >
                    <b-form-input v-model="recalculated_hold_coins" type="text"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <div slot="footer" class="form-btn common_crop_reset_btn">
                <b-button
                  type="submit"
                  variant="success"
                  class="model_common_bt_success_color"
                  @click="recalculateWallet()"
                  >Submit</b-button
                >
                <b-button @click="hideModel()" variant="light" class="model_common_bt_lite_color"
                  >Cancel</b-button
                >
              </div>
            </div>
          </b-col>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="invoice-modal"
      title="Select Month"
      content-class="common_model_header common_model_title"
      hide-footer
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box">
                <b-col lg="6" md="6" class="model_padding_zero model_submit_field">
                  <b-form-group
                    id="input-group-6"
                    label="Invoice Month & Year"
                    class="model_margin_bottom_zero required new_model_date_picker model_date_picker"
                  >
                    <datepicker
                      v-model="month"
                      :format="customFormatterTwo"
                      initial-view="year"
                      minimum-view="month"
                      :disabled-dates="disabledFromDateTwo"
                    ></datepicker>
                    <span class="input-icon"
                      ><span class="count"></span
                      ><b-img
                        v-bind:src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                        alt="Calander Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0" class="model_submit_field employer_multiple_radio">
                  <b-form-group
                    id="input-group-8"
                    label="Duration "
                    class="category-radio model_margin_bottom_zero"
                    v-if="billing_cycle == 'monthly'"
                  >
                    <b-form-radio v-model="duration" name="duration" value="3"
                      >Full Month</b-form-radio
                    >
                  </b-form-group>
                  <b-form-group
                    id="input-group-8"
                    label="Duration "
                    class="category-radio model_margin_bottom_zero"
                    v-else
                  >
                    <b-form-radio v-model="duration" name="duration" value="1">1-15</b-form-radio>
                    <b-form-radio v-model="duration" name="duration" value="2">16-30</b-form-radio>
                  </b-form-group>
                  <!-- <b-form-group id="input-group-8" label="Duration " class="category-radio model_margin_bottom_zero">
                                                <b-form-radio v-model="duration" name="duration" value="1">1-15</b-form-radio>
                                                <b-form-radio v-model="duration" name="duration" value="2">16-30</b-form-radio>

                                            </b-form-group> -->
                </b-col>
                <b-col lg="0" md="0" class="model_submit_field employer_multiple_radio">
                  <b-form-group>
                    <b-form-checkbox v-model="withNoEmail" @input="withNoEmailFunc($event)"
                      ><strong>With No Email</strong></b-form-checkbox
                    >
                  </b-form-group>
                </b-col>
                <div slot="footer" class="form-btn common_crop_reset_btn">
                  <b-button
                    type="submit"
                    variant="success"
                    class="model_common_bt_success_color"
                    @click="onGenerateInvoice()"
                    >Submit</b-button
                  >
                  <b-button @click="hideModel()" variant="light" class="model_common_bt_lite_color"
                    >Cancel</b-button
                  >
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="coin-modal"
      title="Update ELC Credits"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero update_coins_pop_up">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box model_legend model_label">
                <b-col lg="0" md="0" class="submit-field">
                  <div id="show_hide_password_login" class="submit-field">
                    <!-- <b-form-group id="input-group-0" label="Current ELC Credits" class="required model_margin_bottom_zero"> -->
                    <!-- <b-form-input id="input-0" type="text" placeholder="" :value="user_coins" @change="v => user_coins = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                    <label class="model_inline model_margin_bottom_zero"
                      ><b>Current ELC Credits</b></label
                    ><span class="model_font_16 model_float_right"> {{ user_coins }}</span>
                    <!-- </b-form-group> -->
                  </div>
                </b-col>
                <b-col lg="0" md="0">
                  <b-form-group
                    id="input-group-8"
                    label=""
                    class="category-radio employer_multiple_radio"
                  >
                    <b-form-radio v-model="add_deduct_coins" value="add">Add</b-form-radio>
                    <b-form-radio v-model="add_deduct_coins" value="deduct">Deduct</b-form-radio>
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0" v-if="add_deduct_coins == 'add'"
                  ><label class="model_margin_bottom_zero single_label_margin"
                    ><b>Type of Transaction</b></label
                  >
                  <b-form-group
                    id="input-group-8"
                    label=""
                    class="category-radio employer_multiple_radio"
                  >
                    <b-form-radio v-model="add_topup_add_back" value="top_up">Top Up</b-form-radio>
                    <b-form-radio v-model="add_topup_add_back" value="add_back"
                      >Add Back</b-form-radio
                    >
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0" v-if="add_deduct_coins == 'deduct'"
                  ><label class="model_margin_bottom_zero single_label_margin"
                    ><b>Type of Transaction</b></label
                  >
                  <b-form-group
                    id="input-group-8"
                    label=""
                    class="category-radio employer_multiple_radio"
                  >
                    <b-form-radio v-model="deduct_job_comp_refund" value="job_completed"
                      >Job Completed</b-form-radio
                    >
                    <b-form-radio v-model="deduct_job_comp_refund" value="refund"
                      >Refund</b-form-radio
                    >
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0" class="submit-field" v-if="add_deduct_coins == 'add'">
                  <div id="show_hide_password_login" class="submit-field">
                    <!-- <b-form-group id="input-group-0" label="Add ELC Credits" class="required model_margin_bottom_zero"> -->
                    <!-- <b-form-input id="input-0" type="number" min="0" placeholder="" :value="new_user_coins" @change="v => new_user_coins = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                    <div class="row">
                      <div class="col-xl-7 mobile_col_7 common_align_middle_text">
                        <label class="model_margin_bottom_zero"><b>Add ELC Credits</b></label>
                      </div>
                      <div class="col-xl-5 mobile_col_5 submit-field">
                        <b-form-input
                          id="input-0"
                          type="text"
                          placeholder=""
                          :value="new_user_coins"
                          @change="(v) => (new_user_coins = v)"
                          class="input-with-border model_border_and_box_shadow"
                        ></b-form-input>
                      </div>
                    </div>
                    <!-- </b-form-group> -->
                  </div>
                </b-col>
                <b-col lg="0" md="0" class="submit-field" v-if="add_deduct_coins == 'deduct'">
                  <div id="show_hide_password_login" class="">
                    <!-- <b-form-group id="input-group-0" label="Add ELC Credits" class="required model_margin_bottom_zero"> -->
                    <!-- <b-form-input id="input-0" type="number" min="0" placeholder="" :value="new_user_coins" @change="v => new_user_coins = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                    <div class="row" v-if="deduct_job_comp_refund == 'job_completed'">
                      <div class="col-xl-7 mobile_col_7 submit-field common_align_middle_text">
                        <label class="model_margin_bottom_zero"><b>Job ID</b></label>
                      </div>
                      <div class="col-xl-5 mobile_col_5 submit-field">
                        <b-form-input
                          id="input-0"
                          type="text"
                          placeholder=""
                          :value="job_id_coins"
                          @change="(v) => (job_id_coins = v)"
                          class="input-with-border model_border_and_box_shadow"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-7 mobile_col_7 submit-field common_align_middle_text">
                        <label class="model_margin_bottom_zero"><b>Deduct ELC Credits</b></label>
                      </div>
                      <div class="col-xl-5 mobile_col_5 submit-field">
                        <b-form-input
                          id="input-0"
                          type="text"
                          placeholder=""
                          :value="new_user_coins_deduct"
                          @change="(v) => (new_user_coins_deduct = v)"
                          class="input-with-border model_border_and_box_shadow"
                        ></b-form-input>
                      </div>
                    </div>

                    <!-- </b-form-group> -->
                  </div>
                </b-col>
                <b-col lg="0" md="0">
                  <div slot="footer" class="form-btn common_crop_reset_btn">
                    <b-button
                      type="submit"
                      variant="success"
                      @click="submitUpdateCoinInfo()"
                      class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </b-col>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="job-ads-modal"
      title="Update Free Job Ads"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero update_coins_pop_up">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box model_legend model_label">
                <b-col lg="0" md="0" class="submit-field">
                  <div id="show_hide_password_login" class="submit-field">
                    <!-- <b-form-group id="input-group-0" label="Current ELC Credits" class="required model_margin_bottom_zero"> -->
                    <!-- <b-form-input id="input-0" type="text" placeholder="" :value="user_coins" @change="v => user_coins = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                    <label class="model_inline model_margin_bottom_zero"
                      ><b>Current Free Job Ads</b></label
                    ><span class="model_font_16 model_float_right"> {{ free_jobs }}</span>
                    <!-- </b-form-group> -->
                  </div>
                </b-col>

                <b-col lg="0" md="0" class="submit-field">
                  <div id="show_hide_password_login" class="submit-field">
                    <!-- <b-form-group id="input-group-0" label="Add ELC Credits" class="required model_margin_bottom_zero"> -->
                    <!-- <b-form-input id="input-0" type="number" min="0" placeholder="" :value="new_user_coins" @change="v => new_user_coins = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                    <div class="row">
                      <div class="col-xl-7 mobile_col_7 common_align_middle_text">
                        <label class="model_margin_bottom_zero"><b>Add Free Job Ads</b></label>
                      </div>
                      <div class="col-xl-5 mobile_col_5 submit-field">
                        <b-form-input
                          id="input-0"
                          type="text"
                          placeholder=""
                          :value="new_user_job_ads"
                          @change="(v) => (new_user_job_ads = v)"
                          class="input-with-border model_border_and_box_shadow"
                        ></b-form-input>
                      </div>
                    </div>
                    <!-- </b-form-group> -->
                  </div>
                </b-col>

                <b-col lg="0" md="0">
                  <div slot="footer" class="form-btn common_crop_reset_btn">
                    <b-button
                      type="submit"
                      variant="success"
                      @click="submitUpdateJobAdsInfo()"
                      class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </b-col>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="disable-modal"
      :title="updateInfoTitleFL"
      content-class="common_model_header common_model_title"
      hide-footer
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box">
                <b-col lg="0" md="0" v-if="model_type == 'disable'" class="submit-field">
                  <b-form-group
                    id="input-group-6"
                    label="Re-enable Date"
                    class="model_margin_bottom_zero required model_date_picker"
                  >
                    <datepicker
                      v-model="renable_date"
                      :disabled-dates="disabledFromDate2()"
                      :format="customFormatterOne"
                      class=""
                    ></datepicker>
                    <span class="input-icon"
                      ><span class="count"></span
                      ><b-img
                        v-bind:src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                        alt="Calander Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0" class="submit-field">
                  <b-form-group id="input-group-5" label="Remarks" class="model_margin_bottom_zero">
                    <b-form-textarea
                      id="textarea-3"
                      type="textarea"
                      placeholder=""
                      :value="remarks"
                      @change="(v) => (remarks = v)"
                      class="model_box_shadow_unset model_margin_bottom_zero model_text_area"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <!-- <b-col lg="12" md="12" v-if="model_type == 'blacklist'">
                                            <b-form-group>
                                                <b-form-checkbox v-model="withNoEmailBlacklist" @input="withNoEmailFuncBlacklist($event)"><strong>With No Email</strong></b-form-checkbox>
                                            </b-form-group>
                                        </b-col> -->
                <b-col lg="0" md="0" class="">
                  <div slot="footer" class="form-btn common_crop_reset_btn">
                    <b-button
                      type="submit"
                      variant="success"
                      v-if="model_type == 'disable'"
                      @click="disableJobseeker()"
                      class="model_margin_left_zero model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      type="submit"
                      variant="success"
                      v-else-if="model_type == 'blacklist'"
                      @click="blacklistJobseeker()"
                      class="model_margin_left_zero model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      type="submit"
                      variant="success"
                      v-else
                      @click="changeUserStatus(update_user_id, usrStatus)"
                      class="model_margin_left_zero model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </b-col>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
        <b-modal ref="rejection-modal" title="Rejection Remark" hide-footer>
          <!-- <b-form> -->
          <template>
            <div>
              <textarea
                type="text"
                class="with-border"
                value=""
                v-model="rejection_remark"
              ></textarea>
            </div>
            <div class="pt-2 mt-3 text-md-left text-center">
              <b-button
                variant="success"
                class="text-uppercase x-md btn-shadow"
                @click="changeUserStatus('reject', '', '')"
                >Submit</b-button
              >
              &nbsp;
              <!-- <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button> -->
            </div>
          </template>
          <!-- </b-form> -->
        </b-modal>
      </div>
    </div>

    <!-- Spacer -->
    <!-- <div class="margin-top-15"></div> -->
    <!-- Spacer / End-->
  </div>
  <!-- Wrapper / End -->
</template>

<script>
import { POST_API } from "../../../store/actions.type";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import StarRating from "vue-star-rating";
import permission from "../../../../../server/permission.js";

export default {
  data() {
    return {
      fields: [
        { key: "name", label: "NAME & EMAIL", sortable: true },
        { key: "mobile_number", label: "MOBILE NUMBER", sortable: true },
        { key: "created_at", label: "CREATED ON", sortable: true },
        { key: "status", label: "STATUS", sortable: true },
        { key: "toggleAction", label: "ACTION" },
      ],
      id: atob(this.$route.params.id),
      company_id:atob(this.$route.params.company_id),
      docs: [],
      pager: {},
      pageOfItems: [],
      userLog: [],
      company_jobs: [],
      rejection_comments: [],
      monthly_billing: "",
      company_jobs_count: "",
      items: [],
      social_profile: [],
      social_profile_website_url: "",
      social_profile_fb_url: "",
      social_profile_google_url: "",
      social_profile_link_url: "",
      social_profile_twitter_url: "",
      totalRows: 0,
      from: "",
      to: "",
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      sortBy: null,
      sortDirection: "desc",
      filter: "",
      status: "active",
      sortDesc: false,
      webUrl: process.env.VUE_APP_URL,
      webUrlV1: process.env.VUE_APP_URL_V1_Admin,
      form: {
        rowsPerPage: 25,
      },
      disabledFromDateTwo: {
        from: new Date()
      },
      designation:'',
      ft_account_status: "",
      recalUserId: "",
      rejection_remark: "",
      rejected_by: "",
      user_company_id: "",
      rejected_user: "",
      noClose: true,
      showACRA: "",
      acraUrl: "",
      expiry_date: "",
      actions: "",
      actionsComUser: "",
      actionsJobAds: "",
      actionsBillingAdd: "",
      actionsManageDepart: "",

      new_password: "",
      confirm_new_password: "",
      withNoEmail: true,
      designation: "",
      coins:0,
      user_coins: "",
      new_user_coins: "",
      job_id_coins: "",
      new_user_coins_deduct: "",
      new_user_job_ads: "",
      add_deduct_coins: "",
      add_topup_add_back: "",
      deduct_job_comp_refund: "",
      free_jobs: 0,
      userData: [],
      update_user_id: "",
      update_email: "",
      update_mobile_number: "",
      update_coin_user_id: "",
      update_coin_company_id: "",
      updateInfoTitle: "Update Password",
      updateInfoTitleFL: "Temporary Suspend Jobseeker",
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    moment: function (date) {
      return moment(date).utc().format("DD MMM YYYY");
    },

    dateTimeAgo: function (value) {
      var m = moment(value); // or whatever start date you have
      // var today = moment().startOf('day');
      var today = moment();
      var days = Math.round(moment.duration(today - m).asDays());
      if (days > 0) {
        return days + " Days Ago";
      } else {
        return "today";
      }
      // if (!value) return ''
      // const dateTimeAgo = moment(value).fromNow();
      // return dateTimeAgo
    },

    customizeRoleType: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("-");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    customizeBillingType: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join("-");
    },
    dateFormat: function (date) {
      return moment(date).format("DD MMM YYYY");
    },
  },
  components: {
    StarRating,
    Datepicker,
  },
  methods: {
    breadCrumb() {
      var item = [
        {
          to: { name: "employer-list" },
          text: "Employers",
        },
        {
          to: null,
          text: "View Profile",
        },
      ];
      return item;
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    showSuccessAlert(message) {
      return this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        this.datatableRefresh();
        // this.$router.push({ name: 'employer-list' });
      });
    },
    async copyURL() {
      try {
        let copyText = document.querySelector("#copy-url");
        copyText.select();
        document.execCommand("copy");
        // var mytext = $('#copy-url').val();
        // navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
        //     if (result.state == "granted" || result.state == "prompt") {
        //         navigator.clipboard.writeText(mytext);
        //     }
        // });

        Snackbar.show({
          text: "Copied to clipboard!",
        });
        // alert('Copied to Clipboard.');
      } catch ($e) {
        console.log($e);

        // alert('Cannot copy');
      }
    },
    sendResetPasswordLink(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
          },
          api: "/api/jobseekerEmp-reset-password-link-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Reset Password Link Sent Successfully.";
            this.showSuccessAlert(message);
            // this.datatableRefresh();
          }
        });
    },
    getEmployers() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.id,
            page_name: "admin",
            page: this.currentPage,
            keyword: this.filter,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "id",
            sortDirection: this.sortDesc ? "desc" : "asc",
          },
          api: "/api/employer-profile-view-web-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            this.items = this.$store.getters.getResults.company;
            this.expiry_date = this.$store.getters.getResults.expiry_date;
            this.company_jobs = this.$store.getters.getResults.company_jobs;
            this.monthly_billing = this.$store.getters.getResults.monthly_billing[0];
            this.company_jobs_count = this.$store.getters.getResults.company_jobs_count;
            this.social_profile = this.$store.getters.getResults.social_profile;
            if (this.social_profile.length > 0) {
              this.social_profile.forEach((element) => {
                if (element.account_type == "website") {
                  this.social_profile_website_url = element.url;
                }
                if (element.account_type == "fb") {
                  this.social_profile_fb_url = element.url;
                }
                if (element.account_type == "google") {
                  this.social_profile_google_url = element.url;
                }
                if (element.account_type == "link") {
                  this.social_profile_link_url = element.url;
                }
                if (element.account_type == "twitter") {
                  this.social_profile_twitter_url = element.url;
                }
              });
            }
            this.rejection_comments = this.$store.getters.getResults.rejection_comments;
            this.userLog = this.$store.getters.getResults.user_logs;
            this.ft_account_status = this.$store.getters.getResults.ft_account_status;
            this.showACRA =
              this.$store.getters.getResults.company.acra != "" &&
              this.$store.getters.getResults.company.acra != null
                ? true
                : false;
            this.acraUrl =
              this.$store.getters.getResults.company.acra != "" &&
              this.$store.getters.getResults.company.acra != null
                ? this.$store.getters.getResults.company.acra
                : "";
            // this.totalRows = this.$store.getters.getResults.pager.totalItems;
            // this.perPage = this.$store.getters.getResults.pager.pageSize;
            // this.from = this.$store.getters.getResults.pager.from;
            // this.to = this.$store.getters.getResults.pager.to;
            // return this.items;
          }
        });
    },
    passwordClick(event) {
      event.preventDefault();
      if ($("#show_hide_password_login input").attr("type") == "text") {
        $("#show_hide_password_login input").attr("type", "password");
        $("#show_hide_password_login i").addClass("fa-eye-slash");
        $("#show_hide_password_login i").removeClass("fa-eye");
      } else if ($("#show_hide_password_login input").attr("type") == "password") {
        $("#show_hide_password_login input").attr("type", "text");
        $("#show_hide_password_login i").removeClass("fa-eye-slash");
        $("#show_hide_password_login i").addClass("fa-eye");
      }
    },
    repasswordClick(event) {
      event.preventDefault();
      if ($("#show_hide_repassword_login input").attr("type") == "text") {
        $("#show_hide_repassword_login input").attr("type", "password");
        $("#show_hide_repassword_login i").addClass("fa-eye-slash");
        $("#show_hide_repassword_login i").removeClass("fa-eye");
      } else if ($("#show_hide_repassword_login input").attr("type") == "password") {
        $("#show_hide_repassword_login input").attr("type", "text");
        $("#show_hide_repassword_login i").removeClass("fa-eye-slash");
        $("#show_hide_repassword_login i").addClass("fa-eye");
      }
    },
    getDocuments() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.id,
          },
          api: "/api/employee-document",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.docs = this.$store.getters.getResults.data;
          }
        });
    },
    hideModel() {
      this.$refs["info-modal"]?.hide();
      this.$refs["coin-modal"]?.hide();
      this.$refs["disable-modal"]?.hide();
      this.$refs["job-ads-modal"]?.hide();
      this.$refs["recalculate-wallet-modal"]?.hide();
      this.$refs["rejection-modal"]?.hide();
    },
    allJobs(id) {
      var url = this.webUrlV1 + "/admin/employer/companies/company-jobs/" + id;
      window.open(url, "_blank");
    },

    empQrCode(url, user_id, type) {
      if (type == "download") window.open(url, "_blank");
      else {
        this.$store
          .dispatch(POST_API, {
            data: {
              company_id: url,
              user_id: user_id,
            },
            api: "/api/employer-regenerate-qrcode",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              this.items = this.$store.getters.getResults.data;
              this.success_message = this.$store.getters.getResults.message;
              this.showSuccessAlert(this.success_message);
              // this.datatableRefresh();
            }
          });
      }
    },
    changeUserStatus(status, id, company_id) {
      if (status == "approve") {
        var status = status;
        var id = id;
        var rejected_by = "";
        var company_id = company_id;
      } else {
        var status = this.status;
        var id = this.rejected_user;
        var rejected_by = this.rejected_by;
        var company_id = this.user_company_id;
      }

      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
            company_id: company_id,
            status: status,
            rejected_by: rejected_by,
            rejection_remark: this.rejection_remark,
            updated_by: this.form.user_id,
          },
          api: "/api/change-emp-account-dpstatus-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            if (status == "approve") {
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "User approved successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.$refs["rejection-modal"].hide();
                this.getEmployers();
                this.getDocuments();
                // window.location.replace('/v2/admin/jobseekers');
                $(".dashboard-content-container .simplebar-scroll-content").animate(
                  {
                    scrollTop: 0,
                  },
                  "slow"
                );
                // window.location.replace('/v2/admin/employer');
                // window.scrollTo(0,0);
              });
            } else {
              this.rejection_remark = "";
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "User rejected successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.$refs["rejection-modal"].hide();
                this.getEmployers();
                this.getDocuments();
                // window.location.replace('/v2/admin/jobseekers');
                $(".dashboard-content-container .simplebar-scroll-content").animate(
                  {
                    scrollTop: 0,
                  },
                  "slow"
                );
                // window.location.replace('/v2/admin/employer');
                // window.scrollTo(0,0);
              });
            }
          }
        });
    },
    onGenerateInvoice(id) {
      this.hideModel();
      this.$store
        .dispatch(POST_API, {
          data: {
            month: this.month != "" ? moment(this.month).format("MM") : "",
            year: this.month != "" ? moment(this.month).format("YYYY") : "",
            company_id: this.invoice_generate_for_user,
            with_no_email: this.withNoEmail,
            type: "admin",
            duration: this.duration,
          },
          api: "/api/admin-generate-invoice",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.items = this.$store.getters.getResults.data;
            this.success_message = this.$store.getters.getResults.message;

            this.payslip_generate_for_user = "";
            this.month = "";

            this.$swal.fire({
              position: "top-center",
              type: "success",
              text: this.success_message,
              showConfirmButton: false,
              timer: 3000,
            });
            //this.hideModel();
            this.datatableRefresh();
          }
        });
    },
    changeCompanyStatus(id, company_id, status) {
      // var status = status != null ? status : this.usrStatus;

      if (status == "active") {
        var msg = "Are you sure you want to inactivate this record?";
        var message = "Company Inactivated Successfully.";
      } else if (status == "deleted" || status == "inactive") {
        var msg = "Are you sure you want to activate this record?";
        var message = "Company Activated Successfully.";
        this.remarks = null;
      }
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: id,
                  company_id: company_id,
                  status: status,
                },
                api: "/api/emp-active-inactive-admin",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.showSuccessAlert(message);
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    datatableRefresh() {
      window.location.reload();
    },
    submitUpdateCoinInfo() {
      var job_id_format = "^[a-zA-Z]{2}[0-9]{6}$";
      var err = false;

      if (this.job_id_coins != "" && !this.job_id_coins.match(job_id_format)) {
        err = true;
        this.error_message = "Invalid Job ID";
        this.showAlert();
      }

      if (err == false) {
        return this.$store
          .dispatch(POST_API, {
            data: {
              user_id: this.update_coin_user_id,
              company_id: this.update_coin_company_id,
              prev_coins: this.user_coins,
              coins: this.new_user_coins,
              coins_deduct: this.new_user_coins_deduct,
              job_unique_id: this.job_id_coins,
              add_deduct_coins: this.add_deduct_coins,
              add_topup_add_back: this.add_topup_add_back,
              deduct_job_comp_refund: this.deduct_job_comp_refund,
              created_by: this.form.user_id,
            },
            api: "/api/emp-company-update-coin-admin",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              this.success_message = this.$store.getters.getResults.message;
              this.new_user_coins = "";
              this.new_user_coins_deduct = "";
              this.job_id_coins = "";
              this.add_deduct_coins = "";
              this.add_topup_add_back = "";
              this.deduct_job_comp_refund = "";

              this.hideModel();
              this.showSuccessAlert(this.success_message);
            }
          });
      }
    },
    submitUpdateInfo() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.update_user_id,
            company_id: this.update_company_id,
            email: this.update_email,
            mobile_number: this.update_mobile_number,
            user_type: "admin",
            type: this.updateInfoTitle == "Update Mobile" ? "mobile" : "email",
          },
          api: "/api/emp-save-email-mobile-adminomsupervisor",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.success_message = this.$store.getters.getResults.message;
            this.hideModel();
            this.showSuccessAlert(this.success_message);
            this.update_mobile_number = "";
            this.update_email = "";
            this.update_user_id = "";
            this.update_company_id = "";
            // this.refreshDataTable();
          }
        });
    },
    submitUpdateInfoPassword() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.update_user_id,
            new_password: this.new_password,
            confirm_new_password: this.confirm_new_password,
          },
          api: "/api/emp-company-update-password-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.success_message = this.$store.getters.getResults.message;
            this.hideModel();
            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: this.success_message,
              showConfirmButton: false,
              timer: 2500,
            }).then((result) => {
              this.new_password = "";
              this.confirm_new_password = "";
            });
            // this.showSuccessAlert(this.success_message);
            this.datatableRefresh();
            // this.refreshDataTable();
          }
        });
    },
    deleteCompany(id, company_id) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: id,
                  company_id: company_id,
                },
                api: "/api/emp-company-delete-admin",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  var message = "Company Deleted successfully.";
                  // this.datatableRefresh();
                  this.showSuccessAlert(message);
                  this.datatableRefresh()
                }
              });
          }
        })
        .catch((err) => {});
    },
    recalculateWallet() {
      this.hideModel();
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.recalUserId,
            recalculated_value: this.recalculated_value,
            recalculated_hold_coins: this.recalculated_hold_coins,
          },
          api: "/api/admin-recalculate-emp",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.items = this.$store.getters.getResults.data;
            this.success_message = this.$store.getters.getResults.message;
            this.showSuccessAlert(this.success_message);

            this.datatableRefresh();
          }
        });
    },
    submitUpdateJobAdsInfo() {
      var job_id_format = "^[a-zA-Z]{2}[0-9]{6}$";
      var err = false;

      //   if (this.job_id_coins != "" && !this.job_id_coins.match(job_id_format)) {
      //     err = true;
      //     this.error_message = "Invalid Job ID";
      //     this.showAlert();
      //   }

      if (err == false) {
        return this.$store
          .dispatch(POST_API, {
            data: {
              user_id: this.update_coin_user_id,
              company_id: this.update_coin_company_id,
              prev_coins: this.user_coins,
              job_ads: this.new_user_job_ads,
              coins_deduct: this.new_user_coins_deduct,
              job_unique_id: this.job_id_coins,
              add_deduct_coins: this.add_deduct_coins,
              add_topup_add_back: this.add_topup_add_back,
              deduct_job_comp_refund: this.deduct_job_comp_refund,
              created_by: this.form.user_id,
            },
            api: "/api/emp-company-update-job-ads-admin",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              this.success_message = this.$store.getters.getResults.message;
              this.new_user_job_ads = 0;
              this.job_id_coins = "";
              this.add_deduct_coins = "";
              this.add_topup_add_back = "";
              this.deduct_job_comp_refund = "";

              this.hideModel();
              this.showSuccessAlert(this.success_message);
              this.datatableRefresh();
            }
          });
      }
    },
    getUserData(id) {
      //const decodedId = atob(id);
      this.$store
        .dispatch(POST_API, {
          data: {
            id: id,
          },
          api: "/api/user-data-email-mobile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.userData = this.$store.getters.getResults.data;
            this.update_mobile_number = this.userData.mobile_number;
            this.update_email = this.userData.email;
            this.update_user_id = this.userData.id;
            this.free_jobs = this.userData.free_job_posts;
          }
        });
    },
    sendEmailVerificationLink(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
          },
          api: "/api/jobseekerEmp-sendemail-verify-link-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Email Verification Link Sent Successfully.";
            this.showSuccessAlert(message);
            // this.datatableRefresh();
          }
        });
    },
    openInfoModel(id, company_id, type, info = null) {
      console.log(id)
      //const decodedId = atob(id);
      if (type == "mobile") {
        this.updateInfoTitle = "Update Mobile";
      } else if (type == "email") {
        this.updateInfoTitle = "Update Email";
      } else {
        this.updateInfoTitle = "Update Password";
      }
      this.new_password = "";
      this.confirm_new_password = "";
      this.update_user_id = id;
      this.update_company_id = company_id;
      if (type == "password") {
        this.$refs["info-modal"].show();
      } else {
        this.$refs["info-modal"].show();

        this.getUserData(id);
      }
    },
    openCoinModel(id, company_id, user_coins, info = null) {
      
      this.update_coin_user_id = id;
      this.getEmpCoin(id);
      this.update_coin_company_id = company_id;
    },
    getEmpCoin(id) {
      //const decodedId = atob(id);
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
          },
          api: "/api/emp-company-get-usercoin-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.user_coins = this.$store.getters.getResults.data.coins
              ? this.$store.getters.getResults.data.coins
              : 0;
            this.job_id_coins = "";
            this.new_user_coins_deduct = "";
            this.add_deduct_coins = "";
            this.add_topup_add_back = "";
            this.deduct_job_comp_refund = "";
            this.new_user_coins = "";
            this.$refs["coin-modal"].show();
          }
        });
    },
    openJobAdsModel(id, company_id, user_coins, info = null) {
      this.update_coin_user_id = id;
      this.getUserData(id);

      this.$refs["job-ads-modal"].show();
      this.update_coin_company_id = company_id;
    },
    pausePosting(id, status) {
      if (status == "yes") {
        var message = "Unfreeze Account Successfully.";
      } else {
        var message = "Freeze Account Successfully.";
      }
      return this.$store
        .dispatch(POST_API, {
          data: {
            updated_by: this.form.user_id,
            user_id: id,
            status: status,
          },
          api: "/api/employer-pause-posting-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.showSuccessAlert(message);
            this.datatableRefresh()
          }
        });
    },
    verifyEmail(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
          },
          api: "/api/employer-verify-email-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Email Verified successfully.";
            // this.datatableRefresh();
            this.showSuccessAlert(message);
          }
        });
    },
    verifyMobileNumber(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
          },
          api: "/api/employer-verify-mob-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Mobile Number Verified successfully.";
            // this.datatableRefresh();
            this.showSuccessAlert(message);
          }
        });
    },
    openRecalModal(id) {
      this.recalUserId = id;
      this.$refs["recalculate-wallet-modal"].show();

      return this.$store
        .dispatch(POST_API, {
          data: {
            id: id,
          },
          api: "/api/recalculate-wallet-data-for-employer",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.recalcData = this.$store.getters.getResults.data;

            this.credit = this.recalcData.creditTransactions[0].credit
              ? parseFloat(this.recalcData.creditTransactions[0].credit)
              : 0;
            this.debit = this.recalcData.debitTransactions[0].debit
              ? parseFloat(this.recalcData.debitTransactions[0].debit)
              : 0;
            this.pending = this.recalcData.pendingTransactions[0].pending
              ? parseFloat(this.recalcData.pendingTransactions[0].pending)
              : 0;

            this.recalculated_value_old = (this.credit - this.debit).toFixed(2);
            // this.recalculated_hold_coins = (
            //   parseFloat(this.recalcData.userCoins.hold_coins) - this.pending
            // ).toFixed(2);

            this.recalculated_hold_coins_old = this.pending.toFixed(2);

            this.current_coins = parseFloat(this.recalcData.userCoins.coins);
            this.hold_coins = parseFloat(this.recalcData.userCoins.hold_coins);

            // return this.selectedSupervisor;
          }
        });
    },
    showBillingAdd(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsBillingAdd.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showManageDepart(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsManageDepart.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    empConvertFL(user_id, company_id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: user_id,
            company_id: company_id,
          },
          api: "/api/employer-convert-tofl-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Data updated successfully.";
            // this.datatableRefresh();
            this.showSuccessAlert(message)
            window.location.replace("/v2/admin/database/jobseeker-dp-profile/view/" + btoa(user_id))
          }
        });
    },
    openDisableModel(id, status = null, type) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      if (type == "disable") {
        this.model_type = "disable";
        this.updateInfoTitleFL = "Temporary Suspend Employer";
      } else if (type == "blacklist") {
        this.model_type = "blacklist";
        this.updateInfoTitleFL = "Blacklist Employer";
      } else if (type == "inactive" || type == "reactive" || type == "deleted") {
        this.updateInfoTitleFL = "Activate Employer";
        this.model_type = "inactive";
      } else {
        this.updateInfoTitleFL = "Inactivate Employer";
        this.model_type = "inactive";
      }
      this.usrStatus = status;
      this.remarks = "";
      this.update_user_id = id;
      this.renable_date = "";
      this.$refs["disable-modal"].show();
    },

    openModel(status, id, company_id) {
      this.$refs["rejection-modal"].show();
      this.rejection_remark = "";
      this.rejected_user = id;
      this.user_company_id = company_id;
      this.status = status;
      this.rejected_by = this.form.user_id;
    },
    openModelGenerate(id, billing_cycle) {
      this.invoice_generate_for_user = id;
      this.billing_cycle = billing_cycle;
      this.$refs["invoice-modal"].show();
    },
    subscribeUnsubscribeMail(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
            updated_by: this.form.user_id
          },
          api: "/api/emp-jobseeker-subscribe-unsubscribe-mail-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = this.$store.getters.getResults.message;
            this.showSuccessAlert(message);
          }
        });
    },
    jobDetail(id, event) {
      if (event) {
        event.stopPropagation();
      }
      window.location.replace("/v2/admin/fulltime/job-detail/" + id);
    },
    handleClick(jobId, event) {
      if (this.showJobAds("view job Detail")) {
        // this.jobDetail(this.encrypt(jobId), event);
        this.jobDetail(jobId, event);
      } else {
        event.preventDefault();
      }
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Companies");
          this.actionsComUser = permission.getPermissionStaff(menu, "Company Users");
          this.actionsJobAds = permission.getPermissionStaff(menu, "Job Ads");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showComUser(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsComUser.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showJobAds(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsJobAds.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Admin - Employer Profile";
    this.getEmployers();
    this.getDocuments();
    $("#copy-url").val(process.env.VUE_APP_URL + "job?jobId=" + btoa(this.id));
    this.designation =
    this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : "";
    this.form.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.permissionStaff();
  },
};
</script>