<template>
  <div>
    <!-- Wrapper -->
    <div id="wrapper">
      <loading
        :active.sync="isLoading"
        :is-full-page="fullPage"
        loader="dots"
        :background-color="backgroundColor"
        :color="color"
        :height="height"
        :width="width"
        :opacity="opacity"
      >
      </loading>
      <!-- Header Container
            ================================================== -->
      <header id="header-container" class="fullwidth dashboard-header not-sticky">
        <!-- Header -->
        <div
          id="header"
          :class="this.user_id != null && this.user_id != '' ? 'after_login_heads' : 'hst'"
        >
          <div class="container">
            <!-- Left Side Content -->
            <div class="left-side">
              <!-- Logo -->
              <div id="logo" :class="user_type == '3' ? 'common_hide_only_for_mobile_view' : 'hst'">
                <!-- <b-link href="" :to="{name: 'homepage'}" ><img :src="webUrl+ 'assets/images/logo.png'" :data-sticky-logo="webUrl+ 'assets/images/logo.png'" :data-transparent-logo="webUrl+ 'assets/images/logo2.png'" alt="logo"></b-link>-->
                <img
                  v-if="get_header.length > 0"
                  :src="
                    (get_header[0].option_key = 'website_logo'
                      ? get_header[0].option_value
                      : get_header[0].option_value)
                  "
                  alt="website_logo"
                />
              </div>

              <div class="find_wrk">
                <b-link v-if="user_type == '5'" href="" :to="{ name: 'jobList' }" class="non_active"
                  >Full Time</b-link
                >
              </div>

              <div class="find_wrk" style="margin-left: 20px">
                <b-link
                  v-if="user_type == '5'"
                  href=""
                  :to="{ name: 'dailyjobList' }"
                  class="non_active"
                  >Daily Paid</b-link
                >
              </div>
              <div
                v-if="(user_type == '3' || user_type == 4) && showPermissionEMPurchaseCoin('view')"
                class="coins_purchase_div_mobile"
              >
                <div class="mobile_coins">
                  <!-- <div class="cn_img">
                            <img :src="webUrl+'assets/images/Icons_Coin.png'" alt="icon_coin" />
                        </div> -->
                  <!-- <div class=""> -->
                  <!-- <p class="" v-if="coins.length > 0">
                          {{ coins[0].total_coins ? coins[0].total_coins : 0 }} ELC Credits
                        </p>
                        <p class="" v-else>0 ELC Credits</p>

                        <p
                          class="purchase_more"
                          v-if="
                            (user_type == '3' || user_type == 4) &&
                            showPermissionEMPurchaseCoin('view')
                          "
                        >
                          <a href="/v2/employer/elcoins">Purchase More</a>
                        </p> -->
                  <!-- <p class="purchase_more" v-else><a href="/v2/jobseeker/elcoins">Purchase More</a></p> -->
                  <!-- </div> -->
                  <div class="coins_img_nav">
                    <!-- <div class="cn_img">
                          <img :src="webUrl + 'assets/images/Icons_Coin.png'" alt="icon_coin" />
                        </div> -->
                    <div class="cn_txt main_cn">
                      <!-- <div class="row"> -->
                      <div class="daily_paid_mobile_div">
                        <span class="daily_paid_nav_span">Temp. Staff</span>
                        <a class="img-align_span2 daily_paid_nav_anchor" v-if="coins.length > 0">
                          {{
                            coins.length > 0
                              ? coins[0].total_coins.toLocaleString()
                                ? coins[0].total_coins.toLocaleString()
                                : 0
                              : 0
                          }}
                          ELC Credits
                        </a>
                        <a class="img-align_span2 daily_paid_nav_anchor" v-else>0 ELC Credits</a>
                      </div>
                      <div class="full_time_mobile_div">
                        <span class="daily_paid_nav_span">Full Time</span>
                        <a
                          class="img-align_span2 daily_paid_nav_anchor full_time_nav"
                          href="/v2/employer/elcoins"
                        >
                          3 Job Ads
                        </a>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- Main Navigation -->

              <div class="clearfix"></div>
              <!-- Main Navigation / End -->
            </div>
            <!-- Left Side Content / End -->

            <!-- Right Side Content / End -->
            <div class="right-side new_right_side">
              <!-- <div v-if="(user_type == '3' || user_type == 4) || user_type == '5'" class="header-widget hide-on-mobile coins_purchase_div " > -->
              <div
                v-if="(user_type == '3' || user_type == 4) && showPermissionEMPurchaseCoin('view')"
                class="header-widget hide-on-mobile coins_purchase_div new_coins_purchase_div"
              >
                <!-- <div class="pdng_div_coin" v-if="parseInt(this.elxp) != ''">
                                    <div class="crown_img_nav">
                                    <img :src="webUrl+'assets/images/Icons_Crown_Bronze.png'" alt="gold_icon" v-if="parseInt(this.elxp) < 1000" />
                                    <img :src="webUrl+'assets/images/Icons_Crown_Silver.png'" alt="gold_icon" v-if="parseInt(this.elxp) >= 1000 && parseInt(this.elxp) < 2000" />
                                    <img :src="webUrl+'assets/images/Icons_Crown_Gold   .png'" alt="gold_icon" v-if="parseInt(this.elxp) >= 2000 && parseInt(this.elxp) < 4000" />
                                    <img :src="webUrl+'assets/images/Icons_Crown_Platinum.png'" alt="gold_icon" v-if="parseInt(this.elxp) >= 4000" />

                                    </div>
                                    <div class="crown_txt_nav" >
                                    <p >{{remaining}} ELXP more to next tier</p>
                                    </div>
                                </div> -->
                <!-- <div class="coins_img_nav">
                  <div class="cn_img">
                    <img :src="webUrl + 'assets/images/Icons_Coin.png'" alt="icon_coin" />
                  </div>
                  <div class="cn_txt">
                    <p class="img-align_span2" v-if="coins.length > 0">
                      {{ coins[0].total_coins ? coins[0].total_coins : 0 }} ELC Credits
                    </p>
                    <p class="img-align_span2" v-else>0 ELC Credits</p>

                    <p
                      class="purchase_more"
                      v-if="
                        (user_type == '3' || user_type == 4) && showPermissionEMPurchaseCoin('view')
                      "
                    >
                      <a href="/v2/employer/elcoins">Purchase More</a>
                    </p>
                    <p class="purchase_more" v-if="user_type == '5'">
                      <a href="/v2/jobseeker/elcoins">Purchase More</a>
                    </p>
                  </div>
                </div> -->

                <div class="coins_img_nav">
                  <!-- <div class="cn_img">
                    <img :src="webUrl + 'assets/images/Icons_Coin.png'" alt="icon_coin" />
                  </div> -->
                  <div class="cn_txt main_cn">
                    <div class="row">
                      <div
                        class="col-xl-6 col-md-6"
                        v-if="account_verification_status != 'incomplete'"
                      >
                        <span class="daily_paid_nav_span">Temp. Staff</span>
                        <a class="img-align_span2 daily_paid_nav_anchor" v-if="coins.length > 0">
                          {{
                            coins[0].total_coins.toLocaleString()
                              ? coins[0].total_coins.toLocaleString()
                              : 0
                          }}
                          ELC Credits
                        </a>
                        <a class="img-align_span2 daily_paid_nav_anchor" v-else>0 ELC Credits</a>
                      </div>
                      <div class="col-xl-6 col-md-6">
                        <span class="daily_paid_nav_span">Full Time</span>
                        <a
                          class="img-align_span2 daily_paid_nav_anchor full_time_nav"
                          href="/v2/employer/elcoins"
                        >
                          {{
                            coins.length > 0
                              ? coins[0].total_ads
                                ? parseInt(coins[0].total_ads) + parseInt(this.free_job_posts)
                                : 0 + parseInt(this.free_job_posts)
                              : 0
                          }}
                          Job Ads
                        </a>
                      </div>
                    </div>

                    <!-- <p
                      class="purchase_more"
                      v-if="
                        (user_type == '3' || user_type == 4) && showPermissionEMPurchaseCoin('view')
                      "
                    >
                      <a href="/v2/employer/elcoins">Purchase More</a>
                    </p> -->
                    <!-- <p class="purchase_more" v-if="user_type == '5'">
                      <a href="/v2/jobseeker/elcoins">Purchase More</a>
                    </p> -->
                  </div>
                </div>
              </div>
              <!--  User Notifications -->
              <!-- <div class="header-widget hide-on-mobile">
                                <div class="header-notifications">

                                    <div class="header-notifications-trigger">
                                        <a href="#"><i class="icon-feather-bell"></i><span>4</span></a>
                                    </div>

                                    <div class="header-notifications-dropdown">

                                        <div class="header-notifications-headline">
                                            <h4>Notifications</h4>
                                            <button class="mark-as-read ripple-effect-dark" title="Mark all as read" data-tippy-placement="left">
                                                <i class="icon-feather-check-square"></i>
                                            </button>
                                        </div>

                                        <div class="header-notifications-content">
                                            <div class="header-notifications-scroll" data-simplebar>
                                                <ul>
                                                    <li class="notifications-not-read">
                                                        <a href="dashboard-manage-candidates.html">
                                                            <span class="notification-icon"><i class="icon-material-outline-group"></i></span>
                                                            <span class="notification-text">
                                                                <strong>Michael Shannah</strong> applied for a job <span class="color">Full Stack Software Engineer</span>
                                                            </span>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="dashboard-manage-bidders.html">
                                                            <span class="notification-icon"><i class=" icon-material-outline-gavel"></i></span>
                                                            <span class="notification-text">
                                                                <strong>Gilbert Allanis</strong> placed a bid on your <span class="color">iOS App Development</span> project
                                                            </span>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="dashboard-manage-jobs.html">
                                                            <span class="notification-icon"><i class="icon-material-outline-autorenew"></i></span>
                                                            <span class="notification-text">
                                                                Your job listing <span class="color">Full Stack PHP Developer</span> is expiring.
                                                            </span>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="dashboard-manage-candidates.html">
                                                            <span class="notification-icon"><i class="icon-material-outline-group"></i></span>
                                                            <span class="notification-text">
                                                                <strong>Sindy Forrest</strong> applied for a job <span class="color">Full Stack Software Engineer</span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div class="header-notifications">
                                    <div class="header-notifications-trigger">
                                        <a href="#"><i class="icon-feather-mail"></i><span>3</span></a>
                                    </div>

                                    <div class="header-notifications-dropdown">

                                        <div class="header-notifications-headline">
                                            <h4>Messages</h4>
                                            <button class="mark-as-read ripple-effect-dark" title="Mark all as read" data-tippy-placement="left">
                                                <i class="icon-feather-check-square"></i>
                                            </button>
                                        </div>

                                        <div class="header-notifications-content">
                                            <div class="header-notifications-scroll" data-simplebar>
                                                <ul>
                                                    <li class="notifications-not-read">
                                                        <a href="dashboard-messages.html">
                                                            <span class="notification-avatar status-online"></span>
                                                            <div class="notification-text">
                                                                <strong>David Peterson</strong>
                                                                <p class="notification-msg-text">Thanks for reaching out. I'm quite busy right now on many...</p>
                                                                <span class="color">4 hours ago</span>
                                                            </div>
                                                        </a>
                                                    </li>

                                                    <li class="notifications-not-read">
                                                        <a href="dashboard-messages.html">
                                                            <span class="notification-avatar status-offline"></span>
                                                            <div class="notification-text">
                                                                <strong>Sindy Forest</strong>
                                                                <p class="notification-msg-text">Hi Tom! Hate to break it to you, but I'm actually on vacation until...</p>
                                                                <span class="color">Yesterday</span>
                                                            </div>
                                                        </a>
                                                    </li>

                                                    <li class="notifications-not-read">
                                                        <a href="dashboard-messages.html">
                                                            <span class="notification-avatar status-online"></span>
                                                            <div class="notification-text">
                                                                <strong>Marcin Kowalski</strong>
                                                                <p class="notification-msg-text">I received payment. Thanks for cooperation!</p>
                                                                <span class="color">Yesterday</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <a href="dashboard-messages.html" class="header-notifications-button ripple-effect button-sliding-icon">View All Messages<i class="icon-material-outline-arrow-right-alt"></i></a>
                                    </div>
                                </div>

                            </div> -->
              <!--  User Notifications / End -->

              <!-- User Menu -->
              <div class="header-widget">
                <!-- Messages -->
                <div class="header-notifications user-menu">
                  <div class="header-notifications-trigger">
                    <a href="javascript:void(0);">
                      <div
                        class="user-avatar status-online new_round_image"
                        :class="user_type != '3' ? '' : 'mobile_tab_margin_top_0'"
                      >
                        <img
                          :src="
                            profile_image
                              ? profile_image
                              : webUrl + 'assets/images/user-avatar-small-01.png'
                          "
                          alt="user_profile"
                          class="user_image_pofile common_max_width_unset"
                          v-if="user_type != '3'"
                        />
                        <img
                          :src="
                            profile_image
                              ? profile_image
                              : webUrl + 'assets/images/user-avatar-small-01.png'
                          "
                          alt="user_profile"
                          class="user_image_pofile common_max_width_unset emp_imz"
                          v-if="user_type == '3'"
                        />
                      </div>
                    </a>
                  </div>
                  <!-- <div v-if="user_type == '3' || user_type == 4" class="coins_purchase_div_mobile">
                    <div class="mobile_coins"> -->
                  <!-- <div class="cn_img">
                                                        <img :src="webUrl+'assets/images/Icons_Coin.png'" alt="icon_coin" />
                                                        </div> -->
                  <!-- <div class=""> -->
                  <!-- <p class="" v-if="coins.length > 0">
                          {{ coins[0].total_coins ? coins[0].total_coins : 0 }} ELC Credits
                        </p>
                        <p class="" v-else>0 ELC Credits</p>

                        <p
                          class="purchase_more"
                          v-if="
                            (user_type == '3' || user_type == 4) &&
                            showPermissionEMPurchaseCoin('view')
                          "
                        >
                          <a href="/v2/employer/elcoins">Purchase More</a>
                        </p> -->
                  <!-- <p class="purchase_more" v-else><a href="/v2/jobseeker/elcoins">Purchase More</a></p> -->
                  <!-- </div> -->
                  <!-- </div>
                  </div> -->

                  <!-- Dropdown -->
                  <div class="header-notifications-dropdown">
                    <!-- User Status -->
                    <div class="user-status">
                      <!-- User Name / Avatar -->
                      <div class="user-details">
                        <div class="user-avatar status-online new_round_image">
                          <img
                            :src="
                              profile_image
                                ? profile_image
                                : webUrl + 'assets/images/user-avatar-small-01.png'
                            "
                            alt="user_profile"
                            class="common_max_width_unset"
                          />
                        </div>
                        <div
                          class="user-name"
                          v-if="user_type == 3 || user_type == 4 || user_type == 5"
                        >
                          <!-- {{name | capitalize}} <span>{{user_type == 5 ? 'Jobseeker': ((user_type == 1 || user_type == 2) ? 'Admin' : 'Company Admin')}}</span> -->
                          {{ name | capitalize }}
                          <span>{{
                            user_type == 5
                              ? "Jobseeker"
                              : user_type == 4
                              ? "Supervisor"
                              : user_type == 3 && is_company_head == "yes"
                              ? "Company Admin"
                              : "Om"
                          }}</span>
                        </div>
                        <div class="user-name" v-else>
                          <!-- {{name | capitalize}} <span>{{user_type == 5 ? 'Jobseeker': ((user_type == 1 || user_type == 2) ? 'Admin' : 'Company Admin')}}</span> -->
                          {{ name | capitalize }}
                          <span>{{ user_type == 1 ? "Admin" : "Staff" }}</span>
                        </div>
                      </div>
                    </div>

                    <ul class="user-menu-small-nav">
                      <!-- <li><b-link href="" :to="{name: 'admin-dashboard'}"><i class="icon-material-outline-dashboard"></i> Dashboard</b-link></li> -->
                      <!-- <li><b-link href="" :to="{name: 'employer-setting'}"> <i class="icon-material-outline-settings"></i>Settings</b-link></li> -->
                      <li @click="logout">
                        <a href="#"
                          ><i class="icon-material-outline-power-settings-new"></i>Logout</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- User Menu / End -->

              <!-- Mobile Navigation Button -->
              <span class="mmenu-trigger">
                <button class="hamburger hamburger--collapse" type="button">
                  <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                  </span>
                </button>
              </span>
            </div>
            <!-- Right Side Content / End -->
          </div>
        </div>
        <!-- Header / End -->
      </header>
      <div class="clearfix"></div>
      <!-- Header Container / End -->

      <!-- Dashboard Container -->
      <div class="dashboard-container">
        <!-- Dashboard Sidebar
                ================================================== -->
        <div class="dashboard-sidebar sidebar">
          <div class="logo_content">
            <div class="logo">
              <h3 class="logo-name">
                {{ name | capitalize }}
              </h3>
            </div>
            <div class="btn-toggle">
              <i class="bx bx-menu"></i>
            </div>
          </div>
          <div class="dashboard-sidebar-inner mobile_content_overlap" data-simplebar>
            <div class="dashboard-nav-container">
              <!-- Responsive Navigation Trigger -->
              <a href="#" class="dashboard-responsive-nav-trigger">
                <span class="hamburger hamburger--collapse">
                  <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                  </span>
                </span>
                <span class="trigger-title">Dashboard Navigation</span>
              </a>

              <!-- Navigation -->
              <div class="dashboard-nav">
                <div class="dashboard-nav-inner">
                  <div class="whole_ul">
                    <ul v-if="user_type == '1' && designation != 'External Agent'">
                      <li :class="this.$route.name === 'admin-dashboard-list' ? 'active' : ''">
                        <b-link
                          href=""
                          :to="{ name: 'admin-dashboard-list' }"
                          class="dashboard_a_ul dash_ico remove_active_from_humberger"
                          ><span class="fold_span back_span"> Dashboard</span></b-link
                        >
                      </li>
                      <li>
                        <a
                          :href="webUrlV1Admin"
                          target="_blank"
                          class="remove_before_a remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >admin_panel_settings_outlined</i
                          ><span class="fold_span back_span1">DP Jobs Admin</span></a
                        >
                      </li>

                      <li
                        :class="[
                          this.$route.name == 'jobseekers-list' ||
                          this.$route.name == 'jobseeker-profile-view'
                            ? 'active'
                            : '',
                        ]"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'jobseekers-list' }"
                          class="remove_before_a remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >account_circle_outlined</i
                          ><span class="fold_span back_span1">Jobseekers List</span
                          ><b-badge
                            variant="danger"
                            style="background-color: rgba(239, 26, 167, 1) !important"
                            class="customBadge custom_side_badge"
                            v-if="fl_user_count > 0"
                            >{{ fl_user_count }}</b-badge
                          ></b-link
                        >
                      </li>
                      <!-- <li :class="[(this.$route.name == 'DailyJobManageAdmin' )  || (this.$route.name == 'DailyJobManageAdmin' )  ? 'active' : '']"><b-link href="" :to="{name: 'DailyJobManageAdmin'}" class="jobseekes_a_side remove_before_a new_jb_list"><span class="fold_span back_span1">Manage Daily Paid Jobs</span></b-link></li> -->

                      <li
                        :class="[
                          this.$route.name == 'employer-list' ||
                          this.$route.name == 'employer-profile-view'
                            ? 'active'
                            : '',
                        ]"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'employer-list' }"
                          class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >domain_outlined</i
                          ><span class="fold_span back_span">Employers List</span
                          ><b-badge
                            variant="danger"
                            style="background-color: rgba(239, 26, 167, 1) !important"
                            class="customBadge custom_side_badge"
                            v-if="emp_user_count > 0"
                            >{{ emp_user_count }}</b-badge
                          ></b-link
                        >
                      </li>

                      <li
                        v-if="designation != 'Customer Service'"
                        :class="[this.$route.name == 'fulltime-job-list' ? 'active' : '']"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'fulltime-job-list' }"
                          class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                        >
                          <i class="material-icons-outlined new_icon_sidebar_material"
                            >business_center_outlined</i
                          ><span class="fold_span back_span">Job Ads</span
                          ><b-badge
                            variant="danger"
                            style="background-color: rgba(239, 26, 167, 1) !important"
                            class="customBadge custom_side_badge"
                            v-if="ft_job_count_admin > 0"
                            >{{ ft_job_count_admin }}</b-badge
                          ></b-link
                        >
                      </li>
                      <li
                        v-if="designation != 'Customer Service'"
                        :class="[this.$route.name == 'transaction-list' ? 'active' : '']"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'transaction-list' }"
                          class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >receipt_long_outlined</i
                          ><span class="fold_span back_span">Transactions</span></b-link
                        >
                      </li>
                      <li :class="[this.$route.name == 'payout-list' ? 'active' : '']">
                        <b-link
                          href=""
                          :to="{ name: 'payout-list' }"
                          class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >paid_outlined</i
                          ><span class="fold_span back_span">Payout</span
                          ><b-badge
                            variant="danger"
                            style="background-color: rgba(239, 26, 167, 1) !important"
                            class="customBadge custom_side_badge"
                            v-if="pending_payout_count > 0"
                            :class="
                              hold_payout_count > 0
                                ? 'hold_payout_count_exist'
                                : 'hold_payout_count_not_exist'
                            "
                            >{{ pending_payout_count }}</b-badge
                          ><b-badge
                            variant="danger"
                            style="background-color: rgba(239, 26, 167, 1) !important"
                            class="customBadge custom_side_badge exclamation_nav"
                            v-if="hold_payout_count > 0"
                          >
                            !</b-badge
                          ></b-link
                        >
                      </li>

                      <li :class="[this.$route.name == 'penalty-management-list' ? 'active' : '']">
                        <b-link
                          href=""
                          :to="{ name: 'penalty-management-list' }"
                          class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >backspace_outlined</i
                          ><span class="fold_span back_span">Penalty Management</span></b-link
                        >
                      </li>
                      <li
                        :class="[this.$route.name == 'broadcast-notification-list' ? 'active' : '']"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'broadcast-notification-list' }"
                          class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >schedule_send_outlined</i
                          ><span class="fold_span back_span">Broadcast Notification</span></b-link
                        >
                      </li>
                      <li :class="[this.$route.name == 'referral-history-list' ? 'active' : '']">
                        <b-link
                          href=""
                          :to="{ name: 'referral-history-list' }"
                          class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >family_restroom_outlined</i
                          ><span class="fold_span back_span">Referral History</span></b-link
                        >
                      </li>

                      <!--  <li :class="[(this.$route.name == 'el-coin-list' ) || (this.$route.name == 'el-coin-create' ) || (this.$route.name == 'el-coin-edit' )  ? 'active' : '']"><b-link href="" :to="{name: 'el-coin-list'}" class="coins_icon coins remove_active_from_humberger common_remove_active_sub_menu"><i class="material-icons-outlined new_icon_sidebar_material">currency_bitcoin_outlined</i><span class="fold_span">ELC Credits</span></b-link></li> -->
                      <!-- <li :class="[(this.$route.name == 'el-membership-list' ) || (this.$route.name == 'el-membership-create' ) || (this.$route.name == 'el-membership-edit' )  ? 'active' : '']"><b-link href="" :to="{name: 'el-membership-list'}" class="coins_icon coins remove_active_from_humberger common_remove_active_sub_menu"><i class="material-icons-outlined new_icon_sidebar_material">emoji_events_outlined</i><span class="fold_span">EL Memberships</span></b-link></li> -->
                      <li :class="[this.$route.name == 'per-hour-cost-list' ? 'active' : '']">
                        <b-link
                          href=""
                          :to="{ name: 'per-hour-cost-list' }"
                          class="coins_icon coins remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >paid_outlined</i
                          ><span class="fold_span">Per Hour Cost</span></b-link
                        >
                      </li>
                      <li>
                        <b-link
                          href=""
                          @click="isSupportShow = !isSupportShow"
                          :class="
                            this.$route.name == 'admin-support-ticket-list' ||
                            this.$route.name == 'admin-support-ticket-view' ||
                            this.$route.name == 'admin-support-message-list' ||
                            this.$route.name == 'admin-support-message-pending' ||
                            this.$route.name == 'admin-support-message-solved'
                              ? 'myactive'
                              : ''
                          "
                          class="db"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >support_agent_outlined</i
                          ><span class="fold_span back_span"
                            >Support<b-badge
                              variant="danger"
                              style="background-color: rgba(239, 26, 167, 1) !important"
                              class="customBadge custom_side_badge support_badge"
                              v-if="support_total_count > 0"
                              >{{ support_total_count }}</b-badge
                            ></span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul db_sub_ul list">
                            <li
                              :class="[
                                this.$route.name == 'admin-support-ticket-list' ||
                                this.$route.name == 'admin-support-ticket-view'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'admin-support-ticket-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>Ticket<b-badge
                                  variant="danger"
                                  style="background-color: rgba(239, 26, 167, 1) !important"
                                  class="customBadge custom_side_badge"
                                  v-if="support_ticket_count > 0"
                                  >{{ support_ticket_count }}</b-badge
                                ></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'admin-support-message-list' ||
                                this.$route.name == 'admin-support-message-pending'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'admin-support-message-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i
                                >Message<b-badge
                                  variant="danger"
                                  style="background-color: rgba(239, 26, 167, 1) !important"
                                  class="customBadge custom_side_badge"
                                  v-if="support_message_count > 0"
                                  >{{ support_message_count }}</b-badge
                                ></b-link
                              >
                            </li>
                          </ul>
                        </transition>
                      </li>
                      <li
                        :class="[
                          this.$route.name == 'staff-list' ||
                          this.$route.name == 'staff-create' ||
                          this.$route.name == 'staff-edit'
                            ? 'active'
                            : '',
                        ]"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'staff-list' }"
                          class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >more_outlined</i
                          ><span class="fold_span back_span">Manage Staff</span></b-link
                        >
                      </li>
                      <li>
                        <b-link
                          href=""
                          @click="isDeploymnetShow = !isDeploymnetShow"
                          :class="
                            this.$route.name == 'daily-deployment-list' ||
                            this.$route.name == 'company-posting-list'
                              ? 'myactive'
                              : ''
                          "
                          class="website_content"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >price_change_outlined</i
                          ><span class="fold_span back_span">Deployment</span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul db_sub_ul list">
                            <li
                              :class="[this.$route.name == 'daily-deployment-list' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'daily-deployment-list' }"
                                class="remove_active_from_humberger"
                                ><span class="fold_span back_span">Daily Deployment</span></b-link
                              >
                            </li>
                            <li
                              :class="[this.$route.name == 'company-posting-list' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'company-posting-list' }"
                                class="remove_active_from_humberger"
                                ><span class="fold_span back_span">Company Posting</span></b-link
                              >
                            </li>
                          </ul>
                        </transition>
                      </li>

                      <li :class="[this.$route.name == 'heat-map-list' ? 'active' : '']">
                        <b-link
                          href=""
                          :to="{ name: 'heat-map-list' }"
                          class="remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >add_chart_outlined</i
                          ><span class="fold_span back_span">Heat Map</span></b-link
                        >
                      </li>
                      <li v-if="designation != 'Customer Service'">
                        <b-link
                          href=""
                          @click="isDatabaseShow = !isDatabaseShow"
                          :class="
                            this.$route.name == 'nric-initial-list' ||
                            this.$route.name == 'nric-initial-create' ||
                            this.$route.name == 'country-list' ||
                            this.$route.name == 'nationality-list' ||
                            this.$route.name == 'bank-list' ||
                            this.$route.name == 'bank-create' ||
                            this.$route.name == 'bank-edit' ||
                            this.$route.name == 'postal-district-list' ||
                            this.$route.name == 'postal-district-create' ||
                            this.$route.name == 'postal-district-edit' ||
                            this.$route.name == 'reward-list' ||
                            this.$route.name == 'reward-create' ||
                            this.$route.name == 'reward-edit' ||
                            this.$route.name == 'ticket-type-list' ||
                            this.$route.name == 'ticket-type-edit' ||
                            this.$route.name == 'ticket-type-create' ||
                            this.$route.name == 'service-category-list' ||
                            this.$route.name == 'service-category-create' ||
                            this.$route.name == 'service-category-edit' ||
                            this.$route.name == 'service-category-dp-list' ||
                            this.$route.name == 'service-category-dp-create' ||
                            this.$route.name == 'service-category-dp-edit' ||
                            this.$route.name == 'emailtemplate-list' ||
                            this.$route.name == 'emailtemplate-edit' ||
                            this.$route.name == 'whatsapp-template-list' ||
                            this.$route.name == 'whatsapp-template-create' ||
                            this.$route.name == 'whatsapp-template-edit' ||
                            this.$route.name == 'race-list' ||
                            this.$route.name == 'race-create' ||
                            this.$route.name == 'race-edit' ||
                            this.$route.name == 'educational-qualification-list' ||
                            this.$route.name == 'educational-qualification-create' ||
                            this.$route.name == 'educational-qualification-edit' ||
                            this.$route.name == 'field-of-study-list' ||
                            this.$route.name == 'field-of-study-create' ||
                            this.$route.name == 'field-of-study-edit' ||
                            this.$route.name == 'university-list' ||
                            this.$route.name == 'university-create' ||
                            this.$route.name == 'university-edit' ||
                            this.$route.name == 'position-level-list' ||
                            this.$route.name == 'position-level-create' ||
                            this.$route.name == 'position-level-edit' ||
                            this.$route.name == 'location-list' ||
                            this.$route.name == 'location-create' ||
                            this.$route.name == 'location-edit' ||
                            this.$route.name == 'area-list' ||
                            this.$route.name == 'area-create' ||
                            this.$route.name == 'area-edit' ||
                            this.$route.name == 'certificate-list' ||
                            this.$route.name == 'certificate-create' ||
                            this.$route.name == 'certificate-edit' ||
                            this.$route.name == 'certificate-type-list' ||
                            this.$route.name == 'certificate-type-create' ||
                            this.$route.name == 'certificate-type-edit' ||
                            this.$route.name == 'employment-status-list' ||
                            this.$route.name == 'employment-status-create' ||
                            this.$route.name == 'employment-status-edit' ||
                            this.$route.name == 'el-membership-list' ||
                            this.$route.name == 'el-membership-edit' ||
                            this.$route.name == 'subscribed-email-list' ||
                            this.$route.name == 'el-coin-list' ||
                            this.$route.name == 'el-coin-create' ||
                            this.$route.name == 'el-coin-edit' ||
                            this.$route.name == 'TransactionSubTypes' ||
                            this.$route.name == 'TransactionSubTypeCreate' ||
                            this.$route.name == 'TransactionSubTypeEdit' ||
                            this.$route.name == 'BillingTypes' ||
                            this.$route.name == 'BillingTypeCreate' ||
                            this.$route.name == 'BillingTypeEdit' ||
                            this.$route.name == 'penalty-remark-list' ||
                            this.$route.name == 'medical-history-list' ||
                            this.$route.name == 'medical-history-create' ||
                            this.$route.name == 'greeting-messages-list' ||
                            this.$route.name == 'user-logs' ||
                            this.$route.name == 'broadcast-notification-email-list'
                              ? 'myactive'
                              : ''
                          "
                          class="db"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >cloud_download_outlined</i
                          ><span class="fold_span back_span">Database</span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul db_sub_ul list">
                            <!-- Alphabetical order -->
                            <li
                              :class="[
                                this.$route.name == 'area-list' ||
                                this.$route.name == 'area-create' ||
                                this.$route.name == 'area-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'area-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Areas</b-link
                              >
                            </li>
                            <li>
                              <b-link href="" :to="{ name: 'badge-list' }"
                                ><i class="icon-material-outline-business-center"></i>Badge
                                Master</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'bank-list' ||
                                this.$route.name == 'bank-create' ||
                                this.$route.name == 'bank-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'bank-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Banks</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'BillingTypes' ||
                                this.$route.name == 'BillingTypeCreate' ||
                                this.$route.name == 'BillingTypeEdit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'BillingTypes' }"
                                class="coins_icon coins remove_active_from_humberger common_remove_active_sub_menu"
                                ><span class="fold_span">Billing Types</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'certificate-list' ||
                                this.$route.name == 'certificate-create' ||
                                this.$route.name == 'certificate-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'certificate-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Certificates
                                Master</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'certificate-type-list' ||
                                this.$route.name == 'certificate-type-create' ||
                                this.$route.name == 'certificate-type-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'certificate-type-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Certificates
                                Type</b-link
                              >
                            </li>
                            <li>
                              <b-link
                                href=""
                                :to="{ name: 'country-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                Countries</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'educational-qualification-list' ||
                                this.$route.name == 'educational-qualification-create' ||
                                this.$route.name == 'educational-qualification-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'educational-qualification-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Educational
                                Qualifications</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'el-coin-list' ||
                                this.$route.name == 'el-coin-create' ||
                                this.$route.name == 'el-coin-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'el-coin-list' }"
                                class="coins_icon coins remove_active_from_humberger common_remove_active_sub_menu"
                                ><span class="fold_span">ELC Credits</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'elcy-greeting-messages-list' ||
                                this.$route.name == 'greeting-message-store' ||
                                this.$route.name == 'greeting-messages-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'elcy-greeting-messages-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">ELCY Greeting Messages</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'el-membership-list' ||
                                this.$route.name == 'el-membership-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'el-membership-list' }"
                                class="coins_icon coins remove_active_from_humberger common_remove_active_sub_menu"
                                ><span class="fold_span">EL Memberships</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'emailtemplate-list' ||
                                this.$route.name == 'emailtemplate-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'emailtemplate-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Email
                                Templates</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'employment-status-list' ||
                                this.$route.name == 'employment-status-create' ||
                                this.$route.name == 'employment-status-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'employment-status-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Employment
                                Status</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'field-of-study-list' ||
                                this.$route.name == 'field-of-study-create' ||
                                this.$route.name == 'field-of-study-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'field-of-study-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Field Of
                                Studies</b-link
                              >
                            </li>
                            <li>
                              <b-link href="" :to="{ name: 'holidays-list' }"
                                ><i class="icon-material-outline-business-center"></i
                                >Holidays</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'location-list' ||
                                this.$route.name == 'location-create' ||
                                this.$route.name == 'location-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'location-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                Locations</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'medical-historu-list' ||
                                this.$route.name == 'medical-history-create'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'medical-history-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Medical History</span></b-link
                              >
                            </li>
                            <li>
                              <b-link
                                href=""
                                :to="{ name: 'nationality-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                Nationalities</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'nric-initial-list' ||
                                this.$route.name == 'nric-initial-create'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'nric-initial-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> NRIC
                                Initials</b-link
                              >
                            </li>
                            <li>
                              <b-link href="" :to="{ name: 'penalty-remark-list' }"
                                ><i class="icon-material-outline-business-center"></i> Penalty
                                Remark</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'position-level-list' ||
                                this.$route.name == 'position-level-create' ||
                                this.$route.name == 'position-level-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'position-level-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Position
                                Levels</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'postal-district-list' ||
                                this.$route.name == 'postal-district-create' ||
                                this.$route.name == 'postal-district-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'postal-district-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Postal
                                Districts</b-link
                              >
                            </li>
                            <!-- <li :class="[(this.$route.name == 'designation-list' )  || (this.$route.name == 'designation-create' ) || (this.$route.name == 'designation-edit' )  ? 'active' : '']"><b-link href="" :to="{name: 'designation-list'}"><i class="icon-material-outline-business-center"></i> Designation</b-link></li> -->
                            <li
                              :class="[
                                this.$route.name == 'race-list' ||
                                this.$route.name == 'race-create' ||
                                this.$route.name == 'race-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'race-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Race</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'reward-list' ||
                                this.$route.name == 'reward-create' ||
                                this.$route.name == 'reward-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'reward-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Reward</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'service-category-dp-list' ||
                                this.$route.name == 'service-category-dp-create' ||
                                this.$route.name == 'service-category-dp-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'service-category-dp-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Service
                                Categories(DP)</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'service-category-list' ||
                                this.$route.name == 'service-category-create' ||
                                this.$route.name == 'service-category-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'service-category-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Service
                                Categories(FT)</b-link
                              >
                            </li>
                            <li
                              :class="[this.$route.name == 'subscribed-email-list' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'subscribed-email-list' }"
                                class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                                ><span class="fold_span back_span">Subscribed Emails</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'ticket-type-list' ||
                                this.$route.name == 'ticket-type-create' ||
                                this.$route.name == 'ticket-type-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'ticket-type-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Ticket Types</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'TransactionSubTypes' ||
                                this.$route.name == 'TransactionSubTypeCreate' ||
                                this.$route.name == 'TransactionSubTypeEdit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'TransactionSubTypes' }"
                                class="coins_icon coins remove_active_from_humberger common_remove_active_sub_menu"
                                ><span class="fold_span">Transaction Sub Types</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'university-list' ||
                                this.$route.name == 'university-create' ||
                                this.$route.name == 'university-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'university-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                Universities</b-link
                              >
                            </li>
                            <li>
                              <b-link href="" :to="{ name: 'user-logs' }"
                                ><i class="icon-material-outline-business-center"></i>User
                                Logs</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'whatsapp-template-list' ||
                                this.$route.name == 'whatsapp-template-create' ||
                                this.$route.name == 'whatsapp-template-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'whatsapp-template-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Whatsapp
                                Templates</b-link
                              >
                            </li>

                            <li
                              :class="[
                                this.$route.name == 'broadcast-notification-email-list'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'broadcast-notification-email-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Broadcast
                                Notification Email</b-link
                              >
                            </li>
                          </ul>
                        </transition>
                      </li>
                      <li>
                        <b-link
                          href=""
                          @click="isBalanceShow = !isBalanceShow"
                          :class="
                            this.$route.name == 'ServiceReport' ||
                            this.$route.name == 'EmpInvoices' ||
                            this.$route.name == 'EmpBalance' ||
                            this.$route.name == 'CampaignBonus' ||
                            this.$route.name == 'BonusUsers' ||
                            this.$route.name == 'PayIncentive' ||
                            this.$route.name == 'FLBalance' ||
                            this.$route.name == 'FLPayslip' ||
                            this.$route.name == 'RevenueReport'
                              ? 'myactive'
                              : ''
                          "
                          class="website_content"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >price_change_outlined</i
                          ><span class="fold_span back_span">Bills and Reports</span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul db_sub_ul list">
                            <li :class="[this.$route.name == 'ServiceReport' ? 'active' : '']">
                              <b-link
                                href=""
                                :to="{ name: 'ServiceReport' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Employer SR</span></b-link
                              >
                            </li>
                            <li :class="[this.$route.name == 'EmpInvoices' ? 'active' : '']">
                              <b-link
                                href=""
                                :to="{ name: 'EmpInvoices' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Employer Invoices</span></b-link
                              >
                            </li>
                            <li :class="[this.$route.name == 'RevenueReport' ? 'active' : '']">
                              <b-link
                                href=""
                                :to="{ name: 'RevenueReport' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Revenue Reporting</span></b-link
                              >
                            </li>
                            <li :class="[this.$route.name == 'EmpBalance' ? 'active' : '']">
                              <b-link
                                href=""
                                :to="{ name: 'EmpBalance' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Employer Balance</span></b-link
                              >
                            </li>

                            <li :class="[this.$route.name == 'CampaignBonus' ? 'active' : '']">
                              <b-link
                                href=""
                                :to="{ name: 'CampaignBonus' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Pay Campaign Bonus</span></b-link
                              >
                            </li>

                            <li :class="[this.$route.name == 'PayIncentive' ? 'active' : '']">
                              <b-link
                                href=""
                                :to="{ name: 'PayIncentive' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Pay Incentive & Referral</span></b-link
                              >
                            </li>

                            <li :class="[this.$route.name == 'BonusUsers' ? 'active' : '']">
                              <b-link
                                href=""
                                :to="{ name: 'BonusUsers' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Bonus Users List</span></b-link
                              >
                            </li>

                            <li :class="[this.$route.name == 'CreditNote' ? 'active' : '']">
                              <b-link
                                href=""
                                :to="{ name: 'CreditNote' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Credit Note</span></b-link
                              >
                            </li>

                            <li :class="[this.$route.name == 'FLTimesheet' ? 'active' : '']">
                              <b-link
                                href=""
                                :to="{ name: 'FLTimesheet' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">FL Timesheet</span></b-link
                              >
                            </li>

                            <li :class="[this.$route.name == 'FLBalance' ? 'active' : '']">
                              <b-link
                                :to="{ name: 'FLBalance' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">FL Balance</span></b-link
                              >
                            </li>
                            <li :class="[this.$route.name == 'FLPayslip' ? 'active' : '']">
                              <b-link
                                :to="{ name: 'FLPayslip' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">FL Payslip</span></b-link
                              >
                            </li>
                          </ul>
                        </transition>
                      </li>
                      <li>
                        <b-link
                          href=""
                          @click="isTableShow = !isTableShow"
                          :class="
                            this.$route.name == 'food-hygiene-cert-list' ||
                            this.$route.name == 'survey-results' ||
                            this.$route.name == 'emp-service-agreement-list' ||
                            this.$route.name == 'company-whitelists' ||
                            this.$route.name == 'chatbot-conversations' ||
                            this.$route.name == 'upload-image'
                              ? 'myactive'
                              : ''
                          "
                          class="website_content"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >table_chart_outlined</i
                          ><span class="fold_span back_span">Table</span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul db_sub_ul list">
                            <li
                              :class="[
                                this.$route.name == 'food-hygiene-cert-list' ? 'active' : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'food-hygiene-cert-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Food Safety Cert</span></b-link
                              >
                            </li>
                            <li :class="[this.$route.name == 'survey-results' ? 'active' : '']">
                              <b-link
                                href=""
                                :to="{ name: 'survey-results' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Survey Results</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'emp-service-agreement-list' ? 'active' : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'emp-service-agreement-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Service Agreement</span></b-link
                              >
                            </li>
                            <li :class="[this.$route.name == 'company-whitelists' ? 'active' : '']">
                              <b-link
                                href=""
                                :to="{ name: 'company-whitelists' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Company Whitelists</span></b-link
                              >
                            </li>
                            <li
                              :class="[this.$route.name == 'chatbot-conversations' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'chatbot-conversations' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Chatbot Conversations</span></b-link
                              >
                            </li>
                            <li :class="[this.$route.name == 'upload-image' ? 'active' : '']">
                              <b-link
                                href=""
                                :to="{ name: 'upload-image' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Upload Image</span></b-link
                              >
                            </li>
                          </ul>
                        </transition>
                      </li>
                      <li>
                        <b-link
                          href=""
                          @click="isIncentiveShow = !isIncentiveShow"
                          :class="
                            this.$route.name == 'user-incentive-list' ||
                            this.$route.name == 'incentive-management-list'
                              ? 'myactive'
                              : ''
                          "
                          class="website_content"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >attach_money_outlined</i
                          ><span class="fold_span back_span">Incentives</span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul db_sub_ul list">
                            <li
                              :class="[this.$route.name == 'user-incentive-list' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'user-incentive-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">User Incentives</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'incentive-management-list' ? 'active' : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'incentive-management-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Incentives Management</span></b-link
                              >
                            </li>
                          </ul>
                        </transition>
                      </li>
                      <li>
                        <b-link
                          href=""
                          @click="isIncentiveShow = !isIncentiveShow"
                          :class="
                            this.$route.name == 'employer-rating-view' ||
                            this.$route.name == 'freelancer-rating-view'
                              ? 'myactive'
                              : ''
                          "
                          class="website_content"
                          ><i class="material-icons-outlined new_icon_sidebar_material">domain</i
                          ><span class="fold_span back_span">Reviews and Ratings</span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul db_sub_ul list">
                            <li
                              :class="[this.$route.name == 'employer-rating-view' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'employer-rating-view' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Employer Rating</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'freelancer-rating-view' ? 'active' : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'freelancer-rating-view' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Freelancer Rating</span></b-link
                              >
                            </li>
                          </ul>
                        </transition>
                      </li>
                      <li v-if="designation != 'Customer Service'">
                        <b-link
                          href=""
                          @click="isWebsiteContentsShow = !isWebsiteContentsShow"
                          :class="
                            this.$route.name == 'static-page-list' ||
                            this.$route.name == 'static-page-edit' ||
                            this.$route.name == 'process-description-list' ||
                            this.$route.name == 'process-description-edit' ||
                            this.$route.name == 'app-content-list' ||
                            this.$route.name == 'app-content-edit' ||
                            this.$route.name == 'blog-list' ||
                            this.$route.name == 'blog-create' ||
                            this.$route.name == 'blog-edit' ||
                            this.$route.name == 'past-winner-list' ||
                            this.$route.name == 'past-winner-create' ||
                            this.$route.name == 'past-winner-edit' ||
                            this.$route.name == 'faq-list' ||
                            this.$route.name == 'faq-create' ||
                            this.$route.name == 'faq-edit' ||
                            this.$route.name == 'banner-list' ||
                            this.$route.name == 'banner-create' ||
                            this.$route.name == 'banner-edit' ||
                            this.$route.name == 'app-banner-list' ||
                            this.$route.name == 'app-banner-create' ||
                            this.$route.name == 'app-banner-edit' ||
                            this.$route.name == 'partner-list' ||
                            this.$route.name == 'partner-create' ||
                            this.$route.name == 'partner-edit' ||
                            this.$route.name == 'marketing-popup-list' ||
                            this.$route.name == 'marketing-popup-create' ||
                            this.$route.name == 'marketing-popup-edit' ||
                            this.$route.name == 'marketing-popup-employer-list' ||
                            this.$route.name == 'marketing-popup-employer-create' ||
                            this.$route.name == 'marketing-popup-employer-edit' ||
                            this.$route.name == 'job-poster-list' ||
                            this.$route.name == 'job-poster-create' ||
                            this.$route.name == 'job-poster-edit' ||
                            this.$route.name == 'permanent-pt-job-list' ||
                            this.$route.name == 'permanent-pt-job-create' ||
                            this.$route.name == 'permanent-pt-job-edit' ||
                            this.$route.name == 'promotion-list' ||
                            this.$route.name == 'promotion-create' ||
                            this.$route.name == 'promotion-edit' ||
                            this.$route.name == 'campaign-list' ||
                            this.$route.name == 'campaign-create' ||
                            this.$route.name == 'campaign-edit' ||
                            this.$route.name == 'gigplace-list' ||
                            this.$route.name == 'gigplace-edit' ||
                            this.$route.name == 'testimonial-list' ||
                            this.$route.name == 'ticket-type-list' ||
                            this.$route.name == 'ticket-type-edit' ||
                            this.$route.name == 'ticket-type-create' ||
                            this.$route.name == 'testimonial-create' ||
                            this.$route.name == 'testimonial-edit' ||
                            this.$route.name == 'qr-code-list' ||
                            this.$route.name == 'qr-code-edit' ||
                            this.$route.name == 'landingpage-list' ||
                            this.$route.name == 'admin-online-training'
                              ? 'myactive'
                              : ''
                          "
                          class="website_content"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >computer_outlined</i
                          ><span class="fold_span back_span">Website Contents</span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul db_sub_ul list">
                            <li
                              :class="[
                                this.$route.name == 'app-banner-list' ||
                                this.$route.name == 'app-banner-create' ||
                                this.$route.name == 'app-banner-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'app-banner-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">App Banners</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'app-content-list' ||
                                this.$route.name == 'app-content-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'app-content-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">App Content</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'banner-list' ||
                                this.$route.name == 'banner-create' ||
                                this.$route.name == 'banner-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'banner-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Banners</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'blog-list' ||
                                this.$route.name == 'blog-create' ||
                                this.$route.name == 'blog-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'blog-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Blog</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'campaign-list' ||
                                this.$route.name == 'campaign-create' ||
                                this.$route.name == 'campaign-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'campaign-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Campaigns</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'faq-list' ||
                                this.$route.name == 'faq-create' ||
                                this.$route.name == 'faq-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'faq-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">FAQs</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'gigplace-list' ||
                                this.$route.name == 'gigplace-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'gigplace-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Gigplaces</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'job-poster-list' ||
                                this.$route.name == 'job-poster-create' ||
                                this.$route.name == 'job-poster-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'job-poster-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Job Poster</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'landingpage-list' ||
                                this.$route.name == 'landingpage-add' ||
                                this.$route.name == 'landingpage-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'landingpage-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>Landing
                                Page</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'marketing-popup-employer-list' ||
                                this.$route.name == 'marketing-popup-employer-create' ||
                                this.$route.name == 'marketing-popup-employer-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'marketing-popup-employer-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Marketing Popup(Employer)</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'marketing-popup-list' ||
                                this.$route.name == 'marketing-popup-create' ||
                                this.$route.name == 'marketing-popup-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'marketing-popup-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Marketing Popup(Jobseeker)</span></b-link
                              >
                            </li>
                            <li
                              :class="[this.$route.name == 'admin-online-training' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'admin-online-training' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>Online
                                Training</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'partner-list' ||
                                this.$route.name == 'partner-create' ||
                                this.$route.name == 'partner-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'partner-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Partners</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'past-winner-list' ||
                                this.$route.name == 'past-winner-create' ||
                                this.$route.name == 'past-winner-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'past-winner-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Past
                                Winner</b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'permanent-pt-job-list' ||
                                this.$route.name == 'permanent-pt-job-create' ||
                                this.$route.name == 'permanent-pt-job-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'permanent-pt-job-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Permanent PT Job</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'process-description-list' ||
                                this.$route.name == 'process-description-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'process-description-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Process Descriptions</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'promotion-list' ||
                                this.$route.name == 'promotion-create' ||
                                this.$route.name == 'promotion-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'promotion-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Promotions</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'qr-code-list' ||
                                this.$route.name == 'qr-code-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'qr-code-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">QR Code</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'static-page-list' ||
                                this.$route.name == 'static-page-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'static-page-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Static Pages</span></b-link
                              >
                            </li>
                            <li
                              :class="[
                                this.$route.name == 'testimonial-list' ||
                                this.$route.name == 'testimonial-create' ||
                                this.$route.name == 'testimonial-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'testimonial-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Testimonials</span></b-link
                              >
                            </li>

                            <!-- <li :class="[(this.$route.name == 'service-agreement' )  ||  (this.$route.name == 'service-agreement-edit' )  ? 'active' : '']"><b-link href="" :to="{name: 'service-agreement'}" class="remove_active_from_humberger"><i class="icon-material-outline-business-center"></i> <span class="fold_span">Service Agreement</span></b-link></li> -->

                            <!-- <li><b-link href="" :to="{name: 'gallery-list'}"><i class="icon-material-outline-business-center"></i> Gallery</b-link></li> -->
                            <!-- <li><b-link href="" :to="{name: 'jobseeker-content-list'}"><i class="icon-material-outline-business-center"></i> Jobseeker Contents</b-link></li> -->
                          </ul>
                        </transition>
                      </li>

                      <!--     <li ><b-link href="" @click="isTableShow = !isTableShow"  :class= "(this.$route.name == 'subscribed-email-list' ) || (this.$route.name == 'subscribed-email-list' ) ? 'myactive' : ''" class="db "><i class="material-icons-outlined new_icon_sidebar_material">table</i><span class="fold_span back_span">Table</span><i class="fa fa-angle-right"></i></b-link>
                                          <transition name="expand">
                                          <ul class="child_hide_ul db_sub_ul list" >
                                            <li :class="[(this.$route.name == 'subscribed-email-list' )  ? 'active' : '']"><b-link href="" :to="{name: 'subscribed-email-list'}" class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"><span class="fold_span back_span">Subscribed Emails</span></b-link></li>
                                            <li :class="[(this.$route.name == 'subscribed-email-list' )  ? 'active' : '']"><b-link href="" :to="{name: 'subscribed-email-list'}" class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"><span class="fold_span back_span">Subscribed Emails</span></b-link></li>
                                          </ul>
                                          </transition>
                                        </li> -->

                      <li
                        :class="[
                          this.$route.name == 'general-setting-list' ||
                          this.$route.name == 'general-setting-edit'
                            ? 'active'
                            : '',
                        ]"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'general-setting-list' }"
                          class="remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >settings_outlined</i
                          ><span class="fold_span back_span">General Settings</span></b-link
                        >
                      </li>
                    </ul>
                    <ul v-if="user_type == '2' && designation == 'External Agent'">
                      <li :class="[this.$route.name == 'daily-deployment-list' ? 'active' : '']">
                        <b-link
                          href=""
                          :to="{ name: 'daily-deployment-list' }"
                          class="remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >support_agent_outlined</i
                          ><span class="fold_span back_span">Daily Deployment</span></b-link
                        >
                      </li>
                    </ul>
                    <ul v-if="user_type == '2' && designation != 'External Agent'">
                      <li
                        v-if="this.permissionStaffDashboard.indexOf('view') >= 0"
                        :class="this.$route.name === 'admin-dashboard-list' ? 'active' : ''"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'admin-dashboard-list' }"
                          class="dashboard_a_ul dash_ico remove_active_from_humberger"
                          ><span class="fold_span back_span"> Dashboard</span></b-link
                        >
                      </li>
                      <li v-if="this.permissionStaffDPAdmin.indexOf('view') >= 0">
                        <a
                          :href="webUrlV1Admin"
                          target="_blank"
                          class="remove_before_a remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >admin_panel_settings_outlined</i
                          ><span class="fold_span back_span1">DP jobs Admin</span></a
                        >
                      </li>

                      <li
                        v-if="
                          this.permissionStaffFL.indexOf('view') >= 0 ||
                          this.permissionStaffManageBadge.indexOf('view') >= 0 ||
                          this.permissionStaffManageCert.indexOf('view') >= 0
                        "
                        :class="[
                          this.$route.name == 'jobseekers-list' ||
                          this.$route.name == 'jobseeker-profile-view'
                            ? 'active'
                            : '',
                        ]"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'jobseekers-list' }"
                          class="remove_before_a remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >account_circle_outlined</i
                          ><span class="fold_span back_span1">Jobseekers List</span
                          ><b-badge
                            variant="danger"
                            style="background-color: rgba(239, 26, 167, 1) !important"
                            class="customBadge custom_side_badge"
                            v-if="fl_user_count > 0"
                            >{{ fl_user_count }}</b-badge
                          ></b-link
                        >
                      </li>

                      <li
                        v-if="
                          this.permissionStaffEmp.indexOf('view') >= 0 ||
                          this.permissionStaffComUsers.indexOf('view') >= 0 ||
                          this.permissionStaffBilling.indexOf('view') >= 0 ||
                          this.permissionStaffManageDepart.indexOf('view') >= 0
                        "
                        :class="[
                          this.$route.name == 'employer-list' ||
                          this.$route.name == 'employer-profile-view'
                            ? 'active'
                            : '',
                        ]"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'employer-list' }"
                          class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >domain_outlined</i
                          ><span class="fold_span back_span">Employers List</span
                          ><b-badge
                            variant="danger"
                            style="background-color: rgba(239, 26, 167, 1) !important"
                            class="customBadge custom_side_badge"
                            v-if="emp_user_count > 0"
                            >{{ emp_user_count }}</b-badge
                          ></b-link
                        >
                      </li>

                      <li
                        v-if="
                          designation != 'Customer Service' &&
                          (this.permissionStaffFTJobs.indexOf('view') >= 0 ||
                            this.permissionStaffFTJobsCandidates.indexOf('view') >= 0)
                        "
                        :class="[this.$route.name == 'fulltime-job-list' ? 'active' : '']"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'fulltime-job-list' }"
                          class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                        >
                          <i class="material-icons-outlined new_icon_sidebar_material"
                            >business_center_outlined</i
                          ><span class="fold_span back_span">Job Ads</span
                          ><b-badge
                            variant="danger"
                            style="background-color: rgba(239, 26, 167, 1) !important"
                            class="customBadge custom_side_badge"
                            v-if="ft_job_count_admin > 0"
                            >{{ ft_job_count_admin }}</b-badge
                          ></b-link
                        >
                      </li>
                      <li
                        v-if="
                          designation != 'Customer Service' &&
                          this.permissionStaffTransaction.indexOf('view') >= 0
                        "
                        :class="[this.$route.name == 'transaction-list' ? 'active' : '']"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'transaction-list' }"
                          class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >receipt_long_outlined</i
                          ><span class="fold_span back_span">Transactions</span></b-link
                        >
                      </li>
                      <li
                        v-if="this.permissionStaffPayout.indexOf('view') >= 0"
                        :class="[this.$route.name == 'payout-list' ? 'active' : '']"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'payout-list' }"
                          class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >paid_outlined</i
                          ><span class="fold_span back_span">Payout</span
                          ><b-badge
                            variant="danger"
                            style="background-color: rgba(239, 26, 167, 1) !important"
                            class="customBadge custom_side_badge"
                            v-if="pending_payout_count > 0"
                            :class="
                              hold_payout_count > 0
                                ? 'hold_payout_count_exist'
                                : 'hold_payout_count_not_exist'
                            "
                            >{{ pending_payout_count }}</b-badge
                          ><b-badge
                            variant="danger"
                            style="background-color: rgba(239, 26, 167, 1) !important"
                            class="customBadge custom_side_badge exclamation_nav"
                            v-if="hold_payout_count > 0"
                          >
                            !</b-badge
                          ></b-link
                        >
                      </li>

                      <li
                        v-if="this.permissionStaffPenalty.indexOf('view') >= 0"
                        :class="[this.$route.name == 'penalty-management-list' ? 'active' : '']"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'penalty-management-list' }"
                          class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >backspace_outlined</i
                          ><span class="fold_span back_span">Penalty Management</span></b-link
                        >
                      </li>
                      <li
                        v-if="this.permissionStaffNotification.indexOf('view') >= 0"
                        :class="[this.$route.name == 'broadcast-notification-list' ? 'active' : '']"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'broadcast-notification-list' }"
                          class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >schedule_send_outlined</i
                          ><span class="fold_span back_span">Broadcast Notification</span></b-link
                        >
                      </li>
                      <li
                        v-if="this.permissionStaffReferralHistory.indexOf('view') >= 0"
                        :class="[this.$route.name == 'referral-history-list' ? 'active' : '']"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'referral-history-list' }"
                          class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >family_restroom_outlined</i
                          ><span class="fold_span back_span">Referral History</span></b-link
                        >
                      </li>
                      <li
                        :class="[this.$route.name == 'per-hour-cost-list' ? 'active' : '']"
                        v-if="this.permissionStaffPerHourCost.indexOf('view') >= 0"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'per-hour-cost-list' }"
                          class="coins_icon coins remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >paid_outlined</i
                          ><span class="fold_span">Per Hour Cost</span></b-link
                        >
                      </li>

                      <li
                        v-if="
                          this.permissionStaffSupportTicket.indexOf('view') >= 0 ||
                          this.permissionStaffSupportMsg.indexOf('view') >= 0
                        "
                      >
                        <b-link
                          href=""
                          @click="isSupportShow = !isSupportShow"
                          :class="
                            this.$route.name == 'admin-support-ticket-list' ||
                            this.$route.name == 'admin-support-ticket-view' ||
                            this.$route.name == 'admin-support-message-list' ||
                            this.$route.name == 'admin-support-message-pending' ||
                            this.$route.name == 'admin-support-message-solved'
                              ? 'myactive'
                              : ''
                          "
                          class="db"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >support_agent_outlined</i
                          ><span class="fold_span back_span"
                            >Support<b-badge
                              variant="danger"
                              style="background-color: rgba(239, 26, 167, 1) !important"
                              class="customBadge custom_side_badge support_badge"
                              v-if="support_total_count > 0"
                              >{{ support_total_count }}</b-badge
                            ></span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul db_sub_ul list">
                            <li
                              v-if="this.permissionStaffSupportTicket.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'admin-support-ticket-list' ||
                                this.$route.name == 'admin-support-ticket-view'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'admin-support-ticket-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>Ticket<b-badge
                                  variant="danger"
                                  style="background-color: rgba(239, 26, 167, 1) !important"
                                  class="customBadge custom_side_badge"
                                  v-if="support_ticket_count > 0"
                                  >{{ support_ticket_count }}</b-badge
                                ></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffSupportMsg.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'admin-support-message-list' ||
                                this.$route.name == 'admin-support-message-pending'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'admin-support-message-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i
                                >Message<b-badge
                                  variant="danger"
                                  style="background-color: rgba(239, 26, 167, 1) !important"
                                  class="customBadge custom_side_badge"
                                  v-if="support_message_count > 0"
                                  >{{ support_message_count }}</b-badge
                                ></b-link
                              >
                            </li>
                          </ul>
                        </transition>
                      </li>
                      <li
                        v-if="
                          this.permissionStaffDeploymentCompanyPosting.indexOf('view') >= 0 ||
                          this.permissionStaffDeploymentDailyDeploy.indexOf('view') >= 0
                        "
                      >
                        <b-link
                          href=""
                          @click="isDeploymnetShow = !isDeploymnetShow"
                          :class="
                            this.$route.name == 'daily-deployment-list' ||
                            this.$route.name == 'company-posting-list'
                              ? 'myactive'
                              : ''
                          "
                          class="website_content"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >price_change_outlined</i
                          ><span class="fold_span back_span">Deployment</span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul db_sub_ul list">
                            <li
                              v-if="this.permissionStaffDeploymentDailyDeploy.indexOf('view') >= 0"
                              :class="[this.$route.name == 'daily-deployment-list' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'daily-deployment-list' }"
                                class="remove_active_from_humberger"
                                ><span class="fold_span back_span">Daily Deployment</span></b-link
                              >
                            </li>
                            <li
                              v-if="
                                this.permissionStaffDeploymentCompanyPosting.indexOf('view') >= 0
                              "
                              :class="[this.$route.name == 'company-posting-list' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'company-posting-list' }"
                                class="remove_active_from_humberger"
                                ><span class="fold_span back_span">Company Posting</span></b-link
                              >
                            </li>
                          </ul>
                        </transition>
                      </li>
                      <li
                        v-if="this.permissionStaffHeatMap.indexOf('view') >= 0"
                        :class="[this.$route.name == 'heat-map-list' ? 'active' : '']"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'heat-map-list' }"
                          class="remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >add_chart_outlined</i
                          ><span class="fold_span back_span">Heat Map</span></b-link
                        >
                      </li>

                      <li
                        v-if="this.permissionStaffManageStaff.indexOf('view') >= 0"
                        :class="[
                          this.$route.name == 'staff-list' ||
                          this.$route.name == 'staff-create' ||
                          this.$route.name == 'staff-edit'
                            ? 'active'
                            : '',
                        ]"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'staff-list' }"
                          class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >more_outlined</i
                          ><span class="fold_span back_span">Manage Staff</span></b-link
                        >
                      </li>

                      <li
                        v-if="
                          designation != 'Customer Service' &&
                          (this.permissionStaffDBNric.indexOf('view') >= 0 ||
                            this.permissionStaffDBCountries.indexOf('view') >= 0 ||
                            this.permissionStaffDBNationality.indexOf('view') >= 0 ||
                            this.permissionStaffDBBanks.indexOf('view') >= 0 ||
                            this.permissionStaffDBPostalDistrict.indexOf('view') >= 0 ||
                            this.permissionStaffDBReward.indexOf('view') >= 0 ||
                            this.permissionStaffDBSCFT.indexOf('view') >= 0 ||
                            this.permissionStaffDBSCDP.indexOf('view') >= 0 ||
                            this.permissionStaffDBEmail.indexOf('view') >= 0 ||
                            this.permissionStaffDBWhatsapp.indexOf('view') >= 0 ||
                            this.permissionStaffDBRace.indexOf('view') >= 0 ||
                            this.permissionStaffDBEQ.indexOf('view') >= 0 ||
                            this.permissionStaffDBFOS.indexOf('view') >= 0 ||
                            this.permissionStaffDBUniversity.indexOf('view') >= 0 ||
                            this.permissionStaffDBPositionlevel.indexOf('view') >= 0 ||
                            this.permissionStaffDBLocations.indexOf('view') >= 0 ||
                            this.permissionStaffDBAreas.indexOf('view') >= 0 ||
                            this.permissionStaffDBCM.indexOf('view') >= 0 ||
                            this.permissionStaffDBCT.indexOf('view') >= 0 ||
                            this.permissionStaffDBES.indexOf('view') >= 0 ||
                            this.permissionStaffDBPR.indexOf('view') >= 0 ||
                            this.permissionStaffDBUserLogs.indexOf('view') >= 0 ||
                            this.permissionStaffDBBadgeMaster.indexOf('view') >= 0 ||
                            this.permissionStaffDBHolidays.indexOf('view') >= 0 ||
                            this.permissionStaffDBTicketType.indexOf('view') >= 0 ||
                            this.permissionStaffDBSubscribeMails.indexOf('view') >= 0 ||
                            this.permissionStaffDBELCCredits.indexOf('view') >= 0 ||
                            this.permissionStaffDBELMembership.indexOf('view') >= 0 ||
                            this.permissionStaffDBTranSubTypes.indexOf('view') >= 0 ||
                            this.permissionStaffDBBillingTypes.indexOf('view') >= 0 ||
                            this.permissionStaffDBBroadcastNotificationEmail.indexOf('view') >= 0)
                        "
                      >
                        <b-link
                          href=""
                          @click="isDatabaseShow = !isDatabaseShow"
                          :class="
                            this.$route.name == 'nric-initial-list' ||
                            this.$route.name == 'nric-initial-create' ||
                            this.$route.name == 'country-list' ||
                            this.$route.name == 'nationality-list' ||
                            this.$route.name == 'bank-list' ||
                            this.$route.name == 'bank-create' ||
                            this.$route.name == 'bank-edit' ||
                            this.$route.name == 'postal-district-list' ||
                            this.$route.name == 'postal-district-create' ||
                            this.$route.name == 'postal-district-edit' ||
                            this.$route.name == 'reward-list' ||
                            this.$route.name == 'reward-create' ||
                            this.$route.name == 'reward-edit' ||
                            this.$route.name == 'medical-history-list' ||
                            this.$route.name == 'medical-history-create' ||
                            this.$route.name == 'ticket-type-list' ||
                            this.$route.name == 'ticket-type-edit' ||
                            this.$route.name == 'ticket-type-create' ||
                            this.$route.name == 'service-category-list' ||
                            this.$route.name == 'service-category-create' ||
                            this.$route.name == 'service-category-edit' ||
                            this.$route.name == 'service-category-dp-list' ||
                            this.$route.name == 'service-category-dp-create' ||
                            this.$route.name == 'service-category-dp-edit' ||
                            this.$route.name == 'emailtemplate-list' ||
                            this.$route.name == 'emailtemplate-edit' ||
                            this.$route.name == 'whatsapp-template-list' ||
                            this.$route.name == 'whatsapp-template-create' ||
                            this.$route.name == 'whatsapp-template-edit' ||
                            this.$route.name == 'race-list' ||
                            this.$route.name == 'race-create' ||
                            this.$route.name == 'race-edit' ||
                            this.$route.name == 'educational-qualification-list' ||
                            this.$route.name == 'educational-qualification-create' ||
                            this.$route.name == 'educational-qualification-edit' ||
                            this.$route.name == 'field-of-study-list' ||
                            this.$route.name == 'field-of-study-create' ||
                            this.$route.name == 'field-of-study-edit' ||
                            this.$route.name == 'university-list' ||
                            this.$route.name == 'university-create' ||
                            this.$route.name == 'university-edit' ||
                            this.$route.name == 'position-level-list' ||
                            this.$route.name == 'position-level-create' ||
                            this.$route.name == 'position-level-edit' ||
                            this.$route.name == 'location-list' ||
                            this.$route.name == 'location-create' ||
                            this.$route.name == 'location-edit' ||
                            this.$route.name == 'area-list' ||
                            this.$route.name == 'area-create' ||
                            this.$route.name == 'area-edit' ||
                            this.$route.name == 'certificate-list' ||
                            this.$route.name == 'certificate-create' ||
                            this.$route.name == 'certificate-edit' ||
                            this.$route.name == 'certificate-type-list' ||
                            this.$route.name == 'certificate-type-create' ||
                            this.$route.name == 'certificate-type-edit' ||
                            this.$route.name == 'employment-status-list' ||
                            this.$route.name == 'employment-status-create' ||
                            this.$route.name == 'employment-status-edit' ||
                            this.$route.name == 'el-membership-list' ||
                            this.$route.name == 'el-membership-edit' ||
                            this.$route.name == 'subscribed-email-list' ||
                            this.$route.name == 'el-coin-list' ||
                            this.$route.name == 'el-coin-create' ||
                            this.$route.name == 'el-coin-edit' ||
                            this.$route.name == 'TransactionSubTypes' ||
                            this.$route.name == 'TransactionSubTypeCreate' ||
                            this.$route.name == 'TransactionSubTypeEdit' ||
                            this.$route.name == 'BillingTypes' ||
                            this.$route.name == 'BillingTypeCreate' ||
                            this.$route.name == 'BillingTypeEdit' ||
                            this.$route.name == 'penalty-remark-list' ||
                            this.$route.name == 'user-logs' ||
                            this.$route.name == 'broadcast-notification-email-list'
                              ? 'myactive'
                              : ''
                          "
                          class="db"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >cloud_download_outlined</i
                          ><span class="fold_span back_span">Database</span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul db_sub_ul list">
                            <li
                              v-if="this.permissionStaffDBNric.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'nric-initial-list' ||
                                this.$route.name == 'nric-initial-create'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'nric-initial-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> NRIC
                                Initials</b-link
                              >
                            </li>
                            <li v-if="this.permissionStaffDBCountries.indexOf('view') >= 0">
                              <b-link
                                href=""
                                :to="{ name: 'country-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                Countries</b-link
                              >
                            </li>
                            <li v-if="this.permissionStaffDBNationality.indexOf('view') >= 0">
                              <b-link
                                href=""
                                :to="{ name: 'nationality-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                Nationalities</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBBanks.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'bank-list' ||
                                this.$route.name == 'bank-create' ||
                                this.$route.name == 'bank-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'bank-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Banks</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBSCFT.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'service-category-list' ||
                                this.$route.name == 'service-category-create' ||
                                this.$route.name == 'service-category-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'service-category-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Service
                                Categories(FT)</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBSCDP.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'service-category-dp-list' ||
                                this.$route.name == 'service-category-dp-create' ||
                                this.$route.name == 'service-category-dp-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'service-category-dp-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Service
                                Categories(DP)</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBEmail.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'emailtemplate-list' ||
                                this.$route.name == 'emailtemplate-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'emailtemplate-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Email
                                Templates</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBWhatsapp.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'whatsapp-template-list' ||
                                this.$route.name == 'whatsapp-template-create' ||
                                this.$route.name == 'whatsapp-template-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'whatsapp-template-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Whatsapp
                                Templates</b-link
                              >
                            </li>

                            <li
                              v-if="this.permissionStaffDBRace.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'race-list' ||
                                this.$route.name == 'race-create' ||
                                this.$route.name == 'race-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'race-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Race</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBEQ.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'educational-qualification-list' ||
                                this.$route.name == 'educational-qualification-create' ||
                                this.$route.name == 'educational-qualification-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'educational-qualification-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Educational
                                Qualifications</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBFOS.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'field-of-study-list' ||
                                this.$route.name == 'field-of-study-create' ||
                                this.$route.name == 'field-of-study-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'field-of-study-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Field Of
                                Studies</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBUniversity.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'university-list' ||
                                this.$route.name == 'university-create' ||
                                this.$route.name == 'university-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'university-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                Universities</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBPositionlevel.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'position-level-list' ||
                                this.$route.name == 'position-level-create' ||
                                this.$route.name == 'position-level-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'position-level-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Position
                                Levels</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBLocations.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'location-list' ||
                                this.$route.name == 'location-create' ||
                                this.$route.name == 'location-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'location-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                Locations</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBAreas.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'area-list' ||
                                this.$route.name == 'area-create' ||
                                this.$route.name == 'area-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'area-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Areas</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBCM.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'certificate-list' ||
                                this.$route.name == 'certificate-create' ||
                                this.$route.name == 'certificate-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'certificate-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Certificates
                                Master</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBCT.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'certificate-type-list' ||
                                this.$route.name == 'certificate-type-create' ||
                                this.$route.name == 'certificate-type-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'certificate-type-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Certificates
                                Type</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBES.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'employment-status-list' ||
                                this.$route.name == 'employment-status-create' ||
                                this.$route.name == 'employment-status-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'employment-status-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Employment
                                Status</b-link
                              >
                            </li>
                            <li v-if="this.permissionStaffDBPR.indexOf('view') >= 0">
                              <b-link href="" :to="{ name: 'penalty-remark-list' }"
                                ><i class="icon-material-outline-business-center"></i> Penalty
                                Remark</b-link
                              >
                            </li>
                            <li v-if="this.permissionStaffDBUserLogs.indexOf('view') >= 0">
                              <b-link href="" :to="{ name: 'user-logs' }"
                                ><i class="icon-material-outline-business-center"></i>User
                                Logs</b-link
                              >
                            </li>
                            <li v-if="this.permissionStaffDBBadgeMaster.indexOf('view') >= 0">
                              <b-link href="" :to="{ name: 'badge-list' }"
                                ><i class="icon-material-outline-business-center"></i>Badge
                                Master</b-link
                              >
                            </li>
                            <li v-if="this.permissionStaffDBHolidays.indexOf('view') >= 0">
                              <b-link href="" :to="{ name: 'holidays-list' }"
                                ><i class="icon-material-outline-business-center"></i
                                >Holidays</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBTicketType.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'ticket-type-list' ||
                                this.$route.name == 'ticket-type-create' ||
                                this.$route.name == 'ticket-type-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'ticket-type-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Ticket Types</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBSubscribeMails.indexOf('view') >= 0"
                              :class="[this.$route.name == 'subscribed-email-list' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'subscribed-email-list' }"
                                class="jobseekes_a_side employer_icon remove_active_from_humberger common_remove_active_sub_menu"
                                ><span class="fold_span back_span">Subscribed Emails</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBELCCredits.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'el-coin-list' ||
                                this.$route.name == 'el-coin-create' ||
                                this.$route.name == 'el-coin-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'el-coin-list' }"
                                class="coins_icon coins remove_active_from_humberger common_remove_active_sub_menu"
                                ><span class="fold_span">ELC Credits</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBELMembership.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'el-membership-list' ||
                                this.$route.name == 'el-membership-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'el-membership-list' }"
                                class="coins_icon coins remove_active_from_humberger common_remove_active_sub_menu"
                                ><span class="fold_span">EL Memberships</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBTranSubTypes.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'TransactionSubTypes' ||
                                this.$route.name == 'TransactionSubTypeCreate' ||
                                this.$route.name == 'TransactionSubTypeEdit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'TransactionSubTypes' }"
                                class="coins_icon coins remove_active_from_humberger common_remove_active_sub_menu"
                                ><span class="fold_span">Transaction Sub Types</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBBillingTypes.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'BillingTypes' ||
                                this.$route.name == 'BillingTypeCreate' ||
                                this.$route.name == 'BillingTypeEdit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'BillingTypes' }"
                                class="coins_icon coins remove_active_from_humberger common_remove_active_sub_menu"
                                ><span class="fold_span">Billing Types</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBPostalDistrict.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'postal-district-list' ||
                                this.$route.name == 'postal-district-create' ||
                                this.$route.name == 'postal-district-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'postal-district-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Postal
                                Districts</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBMedicalHistory.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'medical-historu-list' ||
                                this.$route.name == 'medical-history-create'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'medical-history-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Medical History</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBReward.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'reward-list' ||
                                this.$route.name == 'reward-create' ||
                                this.$route.name == 'reward-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'reward-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Reward</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffDBGreetingMessage.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'elcy-greeting-messages-list' ||
                                this.$route.name == 'greeting-message-store' ||
                                this.$route.name == 'greeting-messages-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'elcy-greeting-messages-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">ELCY Greeting Messages</span></b-link
                              >
                            </li>
                            <li
                              v-if="
                                this.permissionStaffDBBroadcastNotificationEmail.indexOf('view') >=
                                0
                              "
                              :class="[
                                this.$route.name == 'broadcast-notification-email-list'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'broadcast-notification-email-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Broadcast
                                Notification Email</b-link
                              >
                            </li>
                          </ul>
                        </transition>
                      </li>
                      <li
                        v-if="
                          this.permissionStaffEmpBalance.indexOf('view') >= 0 ||
                          this.permissionStaffCampaignBonus.indexOf('view') >= 0 ||
                          this.permissionStaffPayIncentive.indexOf('view') >= 0 ||
                          this.permissionStaffBonusUsersList.indexOf('view') >= 0 ||
                          this.permissionStaffTimesheetList.indexOf('view') >= 0 ||
                          this.permissionStaffFLBalance.indexOf('view') >= 0 ||
                          this.permissionStaffServiceReport.indexOf('view') >= 0 ||
                          this.permissionStaffEmpInvoices.indexOf('view') >= 0 ||
                          this.permissionStaffCreditNoteList.indexOf('view') >= 0 ||
                          this.permissionStaffRevenueReport.indexOf('view') >= 0
                        "
                      >
                        <b-link
                          href=""
                          @click="isBalanceShow = !isBalanceShow"
                          :class="
                            this.$route.name == 'ServiceReport' ||
                            this.$route.name == 'EmpInvoices' ||
                            this.$route.name == 'EmpBalance' ||
                            this.$route.name == 'CampaignBonus' ||
                            this.$route.name == 'BonusUsers' ||
                            this.$route.name == 'PayIncentive' ||
                            this.$route.name == 'FLBalance' ||
                            this.$route.name == 'FLPayslip' ||
                            this.$route.name == 'RevenueReport'
                              ? 'myactive'
                              : ''
                          "
                          class="website_content"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >price_change_outlined</i
                          ><span class="fold_span back_span">Bills and Reports</span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul db_sub_ul list">
                            <li
                              v-if="this.permissionStaffServiceReport.indexOf('view') >= 0"
                              :class="[this.$route.name == 'ServiceReport' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'ServiceReport' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Employer SR</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffEmpInvoices.indexOf('view') >= 0"
                              :class="[this.$route.name == 'EmpInvoices' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'EmpInvoices' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Employer Invoices</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffRevenueReport.indexOf('view') >= 0"
                              :class="[this.$route.name == 'RevenueReport' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'RevenueReport' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Revenue Reporting</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffEmpBalance.indexOf('view') >= 0"
                              :class="[this.$route.name == 'EmpBalance' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'EmpBalance' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Employer Balance</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffCampaignBonus.indexOf('view') >= 0"
                              :class="[this.$route.name == 'CampaignBonus' ? 'active' : '']"
                            >
                              <b-link
                                :to="{ name: 'CampaignBonus' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Pay Campaign Bonus</span></b-link
                              >
                            </li>

                            <li
                              v-if="this.permissionStaffPayIncentive.indexOf('view') >= 0"
                              :class="[this.$route.name == 'PayIncentive' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'PayIncentive' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Pay Incentive & Referral</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffBonusUsersList.indexOf('view') >= 0"
                              :class="[this.$route.name == 'BonusUsersList' ? 'active' : '']"
                            >
                              <b-link
                                :to="{ name: 'BonusUsers' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Bonus Users List</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffCreditNoteList.indexOf('view') >= 0"
                              :class="[this.$route.name == 'CreditNote' ? 'active' : '']"
                            >
                              <b-link
                                :to="{ name: 'Credit Note' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Credit Note</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffTimesheetList.indexOf('view') >= 0"
                              :class="[this.$route.name == 'FLTimesheet' ? 'active' : '']"
                            >
                              <b-link
                                :to="{ name: 'FLTimesheet' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">FL Timesheet</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffFLBalance.indexOf('view') >= 0"
                              :class="[this.$route.name == 'FLBalance' ? 'active' : '']"
                            >
                              <b-link
                                :to="{ name: 'FLBalance' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">FL Balance</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffFLPayslip.indexOf('view') >= 0"
                              :class="[this.$route.name == 'FLPayslip' ? 'active' : '']"
                            >
                              <b-link
                                :to="{ name: 'FLPayslip' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">FL Payslip</span></b-link
                              >
                            </li>
                          </ul>
                        </transition>
                      </li>
                      <li
                        v-if="
                          this.permissionStaffTableFSC.indexOf('view') >= 0 ||
                          this.permissionStaffTableFSurvey.indexOf('view') >= 0 ||
                          this.permissionStaffTableSA.indexOf('view') >= 0 ||
                          this.permissionStaffTableComWhitelist.indexOf('view') >= 0 ||
                          this.permissionStaffTableChatbotConver.indexOf('view') >= 0
                        "
                      >
                        <b-link
                          href=""
                          @click="isTableShow = !isTableShow"
                          :class="
                            this.$route.name == 'food-hygiene-cert-list' ||
                            this.$route.name == 'survey-results' ||
                            this.$route.name == 'emp-service-agreement-list' ||
                            this.$route.name == 'company-whitelists' ||
                            this.$route.name == 'chatbot-conversations' ||
                            this.$route.name == 'upload-image'
                              ? 'myactive'
                              : ''
                          "
                          class="website_content"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >table_chart_outlined</i
                          ><span class="fold_span back_span">Table</span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul db_sub_ul list">
                            <li
                              v-if="this.permissionStaffTableFSC.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'food-hygiene-cert-list' ? 'active' : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'food-hygiene-cert-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Food Safety Cert</span></b-link
                              >
                            </li>

                            <li
                              v-if="this.permissionStaffTableFSurvey.indexOf('view') >= 0"
                              :class="[this.$route.name == 'survey-results' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'survey-results' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Survey Results</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffTableSA.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'emp-service-agreement-list' ? 'active' : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'emp-service-agreement-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Service Agreement</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffTableComWhitelist.indexOf('view') >= 0"
                              :class="[this.$route.name == 'company-whitelists' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'company-whitelists' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Company Whitelists</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffTableChatbotConver.indexOf('view') >= 0"
                              :class="[this.$route.name == 'chatbot-conversations' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'chatbot-conversations' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Chatbot Conversations</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffTableUploadImage.indexOf('view') >= 0"
                              :class="[this.$route.name == 'upload-image' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'upload-image' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Upload Image</span></b-link
                              >
                            </li>
                          </ul>
                        </transition>
                      </li>
                      <li
                        v-if="
                          this.permissionStaffIncentivesUser.indexOf('view') >= 0 ||
                          this.permissionStaffIncentivesMngmnt.indexOf('view') >= 0
                        "
                      >
                        <b-link
                          href=""
                          @click="isIncentiveShow = !isIncentiveShow"
                          :class="
                            this.$route.name == 'user-incentive-list' ||
                            this.$route.name == 'incentive-management-list'
                              ? 'myactive'
                              : ''
                          "
                          class="website_content"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >attach_money_outlined</i
                          ><span class="fold_span back_span">Incentives</span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul db_sub_ul list">
                            <li
                              v-if="this.permissionStaffIncentivesUser.indexOf('view') >= 0"
                              :class="[this.$route.name == 'user-incentive-list' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'user-incentive-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">User Incentives</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffIncentivesMngmnt.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'incentive-management-list' ? 'active' : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'incentive-management-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Incentives Management</span></b-link
                              >
                            </li>
                          </ul>
                        </transition>
                      </li>
                      <li
                        v-if="
                          this.permissionStaffFLRatings.indexOf('view') >= 0 ||
                          this.permissionStaffEMRatings.indexOf('view') >= 0
                        "
                      >
                        <b-link
                          href=""
                          @click="isRatingsShow = !isRatingsShow"
                          :class="
                            this.$route.name == 'employer-rating-view' ||
                            this.$route.name == 'freelancer-rating-view'
                              ? 'myactive'
                              : ''
                          "
                          class="website_content"
                          ><i class="material-icons-outlined new_icon_sidebar_material">domain</i
                          ><span class="fold_span back_span">Reviews and Ratings</span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul db_sub_ul list">
                            <li
                              v-if="this.permissionStaffEMRatings.indexOf('view') >= 0"
                              :class="[this.$route.name == 'employer-rating-view' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'employer-rating-view' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Employer Rating</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffFLRatings.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'freelancer-rating-view' ? 'active' : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'freelancer-rating-view' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Freelancer Rating</span></b-link
                              >
                            </li>
                          </ul>
                        </transition>
                      </li>
                      <li
                        v-if="
                          designation != 'Customer Service' &&
                          (this.permissionStaffWebsiteStatic.indexOf('view') >= 0 ||
                            this.permissionStaffWebsiteProcess.indexOf('view') >= 0 ||
                            this.permissionStaffWebsiteAppCont.indexOf('view') >= 0 ||
                            this.permissionStaffWebsiteBlog.indexOf('view') >= 0 ||
                            this.permissionStaffWebsitePastWin.indexOf('view') >= 0 ||
                            this.permissionStaffWebsiteFAQ.indexOf('view') >= 0 ||
                            this.permissionStaffWebsiteBanners.indexOf('view') >= 0 ||
                            this.permissionStaffWebsiteAppBanners.indexOf('view') >= 0 ||
                            this.permissionStaffWebsitePartners.indexOf('view') >= 0 ||
                            this.permissionStaffWebsiteMPFL.indexOf('view') >= 0 ||
                            this.permissionStaffWebsiteMPEmp.indexOf('view') >= 0 ||
                            this.permissionStaffWebsitePoster.indexOf('view') >= 0 ||
                            this.permissionStaffWebsitePermanentPTJobs.indexOf('view') >= 0 ||
                            this.permissionStaffWebsitePromotions.indexOf('view') >= 0 ||
                            this.permissionStaffWebsiteGigplace.indexOf('view') >= 0 ||
                            this.permissionStaffWebsiteCampaigns.indexOf('view') >= 0 ||
                            this.permissionStaffWebsiteTestimonial.indexOf('view') >= 0 ||
                            this.permissionStaffWebsiteQR.indexOf('view') >= 0 ||
                            this.permissionStaffWebsiteLanding.indexOf('view') >= 0 ||
                            this.permissionStaffWebsiteOnlineTrain.indexOf('view') >= 0)
                        "
                      >
                        <b-link
                          href=""
                          @click="isWebsiteContentsShow = !isWebsiteContentsShow"
                          :class="
                            this.$route.name == 'static-page-list' ||
                            this.$route.name == 'static-page-edit' ||
                            this.$route.name == 'process-description-list' ||
                            this.$route.name == 'process-description-edit' ||
                            this.$route.name == 'app-content-list' ||
                            this.$route.name == 'app-content-edit' ||
                            this.$route.name == 'blog-list' ||
                            this.$route.name == 'blog-create' ||
                            this.$route.name == 'blog-edit' ||
                            this.$route.name == 'past-winner-list' ||
                            this.$route.name == 'past-winner-create' ||
                            this.$route.name == 'past-winner-edit' ||
                            this.$route.name == 'faq-list' ||
                            this.$route.name == 'faq-create' ||
                            this.$route.name == 'faq-edit' ||
                            this.$route.name == 'banner-list' ||
                            this.$route.name == 'banner-create' ||
                            this.$route.name == 'banner-edit' ||
                            this.$route.name == 'app-banner-list' ||
                            this.$route.name == 'app-banner-create' ||
                            this.$route.name == 'app-banner-edit' ||
                            this.$route.name == 'partner-list' ||
                            this.$route.name == 'partner-create' ||
                            this.$route.name == 'partner-edit' ||
                            this.$route.name == 'marketing-popup-list' ||
                            this.$route.name == 'marketing-popup-create' ||
                            this.$route.name == 'marketing-popup-edit' ||
                            this.$route.name == 'marketing-popup-employer-list' ||
                            this.$route.name == 'marketing-popup-employer-create' ||
                            this.$route.name == 'marketing-popup-employer-edit' ||
                            this.$route.name == 'job-poster-list' ||
                            this.$route.name == 'job-poster-create' ||
                            this.$route.name == 'job-poster-edit' ||
                            this.$route.name == 'permanent-pt-job-list' ||
                            this.$route.name == 'permanent-pt-job-create' ||
                            this.$route.name == 'permanent-pt-job-edit' ||
                            this.$route.name == 'promotion-list' ||
                            this.$route.name == 'promotion-create' ||
                            this.$route.name == 'promotion-edit' ||
                            this.$route.name == 'campaign-list' ||
                            this.$route.name == 'campaign-create' ||
                            this.$route.name == 'campaign-edit' ||
                            this.$route.name == 'gigplace-list' ||
                            this.$route.name == 'gigplace-edit' ||
                            this.$route.name == 'testimonial-list' ||
                            this.$route.name == 'ticket-type-list' ||
                            this.$route.name == 'ticket-type-edit' ||
                            this.$route.name == 'ticket-type-create' ||
                            this.$route.name == 'testimonial-create' ||
                            this.$route.name == 'testimonial-edit' ||
                            this.$route.name == 'qr-code-list' ||
                            this.$route.name == 'qr-code-edit' ||
                            this.$route.name == 'landingpage-list' ||
                            this.$route.name == 'admin-online-training'
                              ? 'myactive'
                              : ''
                          "
                          class="website_content"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >computer_outlined</i
                          ><span class="fold_span back_span">Website Contents</span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul db_sub_ul list">
                            <li
                              v-if="this.permissionStaffWebsiteStatic.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'static-page-list' ||
                                this.$route.name == 'static-page-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'static-page-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Static Pages</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsiteProcess.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'process-description-list' ||
                                this.$route.name == 'process-description-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'process-description-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Process Descriptions</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsiteAppCont.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'app-content-list' ||
                                this.$route.name == 'app-content-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'app-content-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">App Content</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsiteBlog.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'blog-list' ||
                                this.$route.name == 'blog-create' ||
                                this.$route.name == 'blog-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'blog-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Blog</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsitePastWin.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'past-winner-list' ||
                                this.$route.name == 'past-winner-create' ||
                                this.$route.name == 'past-winner-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'past-winner-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i> Past
                                Winner</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsiteFAQ.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'faq-list' ||
                                this.$route.name == 'faq-create' ||
                                this.$route.name == 'faq-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'faq-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">FAQs</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsiteBanners.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'banner-list' ||
                                this.$route.name == 'banner-create' ||
                                this.$route.name == 'banner-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'banner-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Banners</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsiteAppBanners.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'app-banner-list' ||
                                this.$route.name == 'app-banner-create' ||
                                this.$route.name == 'app-banner-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'app-banner-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">App Banners</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsitePartners.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'partner-list' ||
                                this.$route.name == 'partner-create' ||
                                this.$route.name == 'partner-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'partner-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Partners</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsiteMPFL.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'marketing-popup-list' ||
                                this.$route.name == 'marketing-popup-create' ||
                                this.$route.name == 'marketing-popup-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'marketing-popup-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Marketing Popup(Jobseeker)</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsiteMPEmp.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'marketing-popup-employer-list' ||
                                this.$route.name == 'marketing-popup-employer-create' ||
                                this.$route.name == 'marketing-popup-employer-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'marketing-popup-employer-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Marketing Popup(Employer)</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsitePoster.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'job-poster-list' ||
                                this.$route.name == 'job-poster-create' ||
                                this.$route.name == 'job-poster-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'job-poster-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Job Poster</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsitePermanentPTJobs.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'permanent-pt-job-list' ||
                                this.$route.name == 'permanent-pt-job-create' ||
                                this.$route.name == 'permanent-pt-job-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'permanent-pt-job-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Permanent PT Job</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsitePromotions.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'promotion-list' ||
                                this.$route.name == 'promotion-create' ||
                                this.$route.name == 'promotion-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'promotion-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Promotions</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsiteCampaigns.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'campaign-list' ||
                                this.$route.name == 'campaign-create' ||
                                this.$route.name == 'campaign-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'campaign-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Campaigns</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsiteGigplace.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'gigplace-list' ||
                                this.$route.name == 'gigplace-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'gigplace-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Gigplaces</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsiteTestimonial.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'testimonial-list' ||
                                this.$route.name == 'testimonial-create' ||
                                this.$route.name == 'testimonial-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'testimonial-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">Testimonials</span></b-link
                              >
                            </li>

                            <li
                              v-if="this.permissionStaffWebsiteQR.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'qr-code-list' ||
                                this.$route.name == 'qr-code-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'qr-code-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>
                                <span class="fold_span">QR Code</span></b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsiteLanding.indexOf('view') >= 0"
                              :class="[
                                this.$route.name == 'landingpage-list' ||
                                this.$route.name == 'landingpage-add' ||
                                this.$route.name == 'landingpage-edit'
                                  ? 'active'
                                  : '',
                              ]"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'landingpage-list' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>Landing
                                Page</b-link
                              >
                            </li>
                            <li
                              v-if="this.permissionStaffWebsiteOnlineTrain.indexOf('view') >= 0"
                              :class="[this.$route.name == 'admin-online-training' ? 'active' : '']"
                            >
                              <b-link
                                href=""
                                :to="{ name: 'admin-online-training' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i>Online
                                Training</b-link
                              >
                            </li>
                          </ul>
                        </transition>
                      </li>

                      <li
                        v-if="this.permissionStaffSettings.indexOf('view') >= 0"
                        :class="[
                          this.$route.name == 'general-setting-list' ||
                          this.$route.name == 'general-setting-edit'
                            ? 'active'
                            : '',
                        ]"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'general-setting-list' }"
                          class="remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >settings_outlined</i
                          ><span class="fold_span back_span">General Settings</span></b-link
                        >
                      </li>
                    </ul>
                    <ul class="stng_ul">
                      <!-- <li :class="(this.$route.name === 'employer-dashboard') ? 'active' : '' " v-if="(user_type == '3' || user_type == 4)"><b-link href="" :to="{name: 'employer-dashboard'}" class="dashboard_a_ul remove_before_a dash_ico"><span class="fold_span back_span1">Dashboard</span></b-link></li> -->
                      <!-- <li :class="(this.$route.name === 'jobseeker-dashboard') ? 'active' : '' " v-if="user_type == '5'"><b-link href="" :to="{name: 'jobseeker-dashboard'}" class="dashboard_a_ul remove_before_a dash_ico"><span class="fold_span back_span1">Dashboard</span></b-link></li> -->
                      <!-- <li :class="(this.$route.name === 'jobseeker-profile') ? 'active' : '' " v-if="user_type == '5'"><b-link href="" :to="{name: 'jobseeker-profile'}" class="user_back remove_before_a mag_prf user_prfile"> <span class="fold_span back_span">Account</span></b-link></li> -->
                      <li v-if="user_type == '5'">
                        <b-link
                          href=""
                          @click="isAccountShowJobseeker = !isAccountShowJobseeker"
                          :class="[
                            this.$route.name == 'jobseeker-profile' ||
                            this.$route.name == 'jobseeker-daily-job-profile' ||
                            this.$route.name == 'manage-certificate' ||
                            this.$route.name == 'jobseekerBillingAddress' ||
                            this.$route.name == 'jobseekerCardList' ||
                            this.$route.name == 'add-certificate' ||
                            this.$route.name == 'jobseeker-change-password' ||
                            this.$route.name == 'jobseeker-deactivate-account'
                              ? 'myactive'
                              : '',
                          ]"
                          class="profile_drop remove_before_a hello"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >account_circle_outlined</i
                          ><span class="fold_span back_span1">Account</span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul list">
                            <li>
                              <b-link
                                href=""
                                :to="{ name: 'jobseeker-daily-job-profile' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i
                                ><span
                                  class="fold_span"
                                  v-bind:class="{
                                    red_status_job:
                                      account_verification_status == 'incomplete' ||
                                      account_verification_status == 'rejected',
                                  }"
                                  >Manage Profile
                                  <!-- <b-badge variant="danger" class="customBadge custom_side_badge" v-if="account_verification_status == 'incomplete' || account_verification_status == 'rejected'">!</b-badge> -->
                                </span></b-link
                              >
                            </li>
                            <li>
                              <b-link
                                href=""
                                :to="{ name: 'jobseeker-profile' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i
                                ><span
                                  class="fold_span"
                                  v-bind:class="{
                                    red_status_job:
                                      ft_account_status == 'incomplete' ||
                                      account_verification_status == 'rejected',
                                  }"
                                  >Full Time Profile
                                  <!-- <b-badge variant="danger" class="customBadge custom_side_badge" v-if="ft_account_status == 'incomplete' || account_verification_status == 'rejected'">!</b-badge> -->
                                </span></b-link
                              >
                            </li>
                            <li>
                              <b-link
                                href=""
                                :to="{ name: 'manage-certificate' }"
                                class="remove_active_from_humberger"
                                :class="[
                                  this.$route.name == 'manage-certificate' ||
                                  this.$route.name == 'add-certificate'
                                    ? 'common_active_color'
                                    : '',
                                ]"
                                ><i class="icon-material-outline-business-center"></i
                                ><span class="fold_span"
                                  >Manage Certificates
                                  <!-- <b-badge variant="success" class="customBadge custom_side_badge" v-if="cert_data.length > 0">{{cert_data.length}}</b-badge> -->
                                </span></b-link
                              >
                            </li>
                            <li>
                              <b-link
                                href=""
                                :to="{ name: 'jobseeker-change-password' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i
                                ><span class="fold_span">Change Password</span></b-link
                              >
                            </li>
                            <li>
                              <b-link
                                href=""
                                :to="{ name: 'jobseeker-deactivate-account' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i
                                ><span class="fold_span">Delete Account</span></b-link
                              >
                            </li>

                            <!-- <li ><b-link href="" :to="{name: 'jobseekerBillingAddress'}"><i class="icon-material-outline-business-center"></i><span class="fold_span">Manage Billing Address</span></b-link></li> -->
                            <!-- <li ><b-link href="" :to="{name: 'jobseekerCardList'}"><i class="icon-material-outline-business-center"></i><span class="fold_span">Manage Cards</span></b-link></li> -->
                          </ul>
                        </transition>
                      </li>

                      <li
                        :class="this.$route.name === 'jobList' ? 'active' : ''"
                        v-if="user_type == '5'"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'jobList' }"
                          class="remove_before_a remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >business_center_outlined</i
                          ><span class="fold_span back_span1"
                            >Full Time Jobs
                            <b-badge
                              variant="danger"
                              style="background-color: rgb(144 140 144 / 75%) !important"
                              class="customBadge custom_side_badge"
                              v-if="fl_ft_job_count > 0"
                              >{{ fl_ft_job_count }}</b-badge
                            ></span
                          >
                          <!-- <b-badge variant="danger" class="customBadge" v-if="fl_ft_job_count > 0">{{fl_ft_job_count}}</b-badge> -->
                        </b-link>
                      </li>
                      <li
                        :class="this.$route.name === 'dailyjobList' ? 'active' : ''"
                        v-if="user_type == '5'"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'dailyjobList' }"
                          class="remove_before_a remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >currency_exchange_outlined</i
                          ><span class="fold_span back_span1"
                            >Daily Paid Jobs
                            <b-badge
                              variant="danger"
                              style="background-color: rgb(144 140 144 / 75%) !important"
                              class="customBadge custom_side_badge"
                              v-if="fl_dp_job_count > 0"
                              >{{ fl_dp_job_count }}</b-badge
                            ></span
                          >
                          <!-- <b-badge variant="danger" class="customBadge" v-if="fl_dp_job_count > 0">{{fl_dp_job_count}}</b-badge> -->
                        </b-link>
                      </li>

                      <li
                        :class="this.$route.name === 'applied-jobs-list' ? 'active' : ''"
                        v-if="user_type == '5'"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'applied-jobs-list' }"
                          class="remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >work_history_outlined</i
                          ><span class="fold_span">Applied Jobs</span></b-link
                        >
                      </li>

                      <li
                        :class="this.$route.name === 'jobseeker-bookmark-job-list' ? 'active' : ''"
                        v-if="user_type == '5'"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'jobseeker-bookmark-job-list' }"
                          class="remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >bookmarks_outlined</i
                          ><span class="fold_span">Bookmarked Jobs</span></b-link
                        >
                      </li>
                      <li
                        :class="this.$route.name === 'jobseeker-bookmark-emp-list' ? 'active' : ''"
                        v-if="user_type == '5'"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'jobseeker-bookmark-emp-list' }"
                          class="remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >bookmarks_outlined</i
                          ><span class="fold_span">Bookmarked Employers</span></b-link
                        >
                      </li>

                      <!-- <li v-if="(user_type == '3' || user_type == 4)"> -->
                      <!-- <b-link href="" :to="{name: 'employee-profile'}" class="user_back remove_before_a mag_prf user_prfile"><span class="fold_span back_span1">Account</span></b-link></li> -->

                      <li
                        v-if="user_type == '3' || user_type == 4"
                        :class="this.$route.name === 'employer-dashboard' ? 'active' : ''"
                      >
                        <b-link
                          href=""
                          :to="{ name: 'employer-dashboard' }"
                          class="remove_active_from_humberger common_remove_active_sub_menu common_remove_active_sub_menu"
                          ><i class="material-icons-outlined icon-material-outline-dashboard"></i
                          ><span class="fold_span">Dashboard</span></b-link
                        >
                      </li>

                      <li v-if="user_type == '3' || user_type == 4">
                        <b-link
                          href=""
                          @click="isAccountShow = !isAccountShow"
                          :class="[
                            this.$route.name == 'employee-profile' ||
                            this.$route.name == 'billingAddress' ||
                            this.$route.name == 'cardList' ||
                            this.$route.name == 'editbillingAddress' ||
                            this.$route.name == 'om-supervisor-profile'
                              ? 'myactive'
                              : '',
                            ft_account_status == 'incomplete' ||
                            account_verification_status == 'rejected'
                              ? 'account_emp_red'
                              : 'account_emp_not_red',
                          ]"
                          class="profile_drop remove_before_a hello user_back remove_before_a mag_prf"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >account_circle_outlined</i
                          ><span class="fold_span back_span1">Account</span
                          ><i class="fa fa-angle-right"></i
                        ></b-link>

                        <transition name="expand">
                          <ul class="child_hide_ul list">
                            <li
                              v-if="
                                (user_type == '3' || user_type == 4) && is_company_head == 'yes'
                              "
                            >
                              <b-link
                                href=""
                                :to="{ name: 'employee-profile' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i
                                ><span
                                  class="fold_span"
                                  v-bind:class="{
                                    red_status_job:
                                      ft_account_status == 'incomplete' ||
                                      account_verification_status == 'rejected',
                                  }"
                                  >My Account</span
                                ></b-link
                              >
                            </li>
                            <!-- <li ><b-link href="" :to="{name: 'manage-jobs'}"><i class="icon-material-outline-business-center"></i><span class="fold_span">Manage Jobs</span><span class="job_count">3</span></b-link></li> -->
                            <!-- <li v-if="(user_type == '3' || user_type == 4) && is_company_head == 'yes'"><b-link href="" :to="{name: 'billingAddress'}" class="remove_active_from_humberger" :class="[(this.$route.name == 'billingAddress' ) || (this.$route.name == 'editbillingAddress' )   ? 'common_active_color' : '']" ><i class="icon-material-outline-business-center"></i><span class="fold_span" v-bind:class="{ 'red_status_job' : billing_address.length == 0 }">Manage Billing Address</span></b-link></li> -->
                            <li
                              v-if="
                                (user_type == '3' || user_type == 4) && is_company_head == 'yes'
                              "
                            >
                              <b-link
                                href=""
                                :to="{ name: 'empDailyPaidProfile' }"
                                class="remove_active_from_humberger"
                                :class="[
                                  this.$route.name == 'empDailyPaidProfile'
                                    ? 'common_active_color'
                                    : '',
                                ]"
                                ><i class="icon-material-outline-business-center"></i
                                ><span
                                  class="fold_span"
                                  v-bind:class="{
                                    red_status_job: billing_address.length == 0 || !company_acra,
                                  }"
                                  >Upload ACRA</span
                                ></b-link
                              >
                            </li>

                            <!-- <li ><b-link href="" :to="{name: 'cardList'}"><i class="icon-material-outline-business-center"></i><span class="fold_span">Manage Cards</span></b-link></li> -->
                            <li
                              v-if="
                                (user_type == '3' || user_type == 4 || user_type == '4') &&
                                is_company_head == 'no'
                              "
                            >
                              <b-link
                                href=""
                                :to="{ name: 'om-supervisor-profile' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i
                                ><span
                                  class="fold_span"
                                  v-bind:class="{
                                    red_status_job:
                                      ft_account_status == 'incomplete' ||
                                      account_verification_status == 'rejected',
                                  }"
                                  >My Account</span
                                ></b-link
                              >
                            </li>
                            <li
                              v-if="user_type == '3' || user_type == 4"
                              :class="
                                this.$route.name === 'employer-change-password' ? 'active' : ''
                              "
                            >
                              <b-link
                                href=""
                                :to="{ name: 'employer-change-password' }"
                                class="remove_active_from_humberger common_remove_active_sub_menu"
                                ><span class="fold_span back_span">Change Password</span></b-link
                              >
                            </li>
                          </ul>
                        </transition>
                      </li>

                      <!--<li v-if="(user_type == '3' || user_type == 4)"> <b-link href="" @click="isAccountShow = !isAccountShow" :class="[(this.$route.name == 'employee-profile' )  ? 'myactive' : '']" class="profile_drop new_jb_list remove_before_a hello"><span class="fold_span back_span1">Account</span><i class="fa fa-angle-right toggle_arrow3"></i></b-link>-->
                      <li
                        id="dpJobMenu"
                        v-if="
                          (user_type == '3' || user_type == 4) &&
                          (showPermissionEMManageJobs('create job') ||
                            showPermissionEMManageJobs('view') ||
                            showPermissionEMRatings('view') ||
                            showPermissionEMServiceRe('view') ||
                            showPermissionEMInvoice('view'))
                        "
                        :class="
                          account_verification_status == 'completed'
                            ? 'completed_verification_status'
                            : 'incomplete_verification_status'
                        "
                      >
                        <b-link
                          href=""
                          @click="isDailyJobShow = !isDailyJobShow"
                          :class="[
                            this.$route.name == 'DailyJobManage' ||
                            this.$route.name == 'DailyJobPost' ||
                            this.$route.name == 'approveJob'
                              ? 'myactive'
                              : '',
                          ]"
                          class="custm_job remove_before_a"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >business_center_outlined</i
                          ><span class="fold_span back_span1 staff_posting"
                            >Temp. Staff Posting</span
                          >
                          <b-badge
                            variant="danger"
                            style="background-color: rgb(144 140 144 / 75%) !important"
                            class="customBadge custom_side_badge main_ul_nav_badge"
                            v-if="emp_dp_job_count > 0"
                            >{{ emp_dp_job_count }}</b-badge
                          >
                          <i class="fa fa-angle-right"></i
                        ></b-link>

                        <transition name="expand">
                          <ul class="child_hide_ul list">
                            <li v-if="showPermissionEMManageJobs('create job')">
                              <b-link
                                href=""
                                :to="{ name: 'DailyJobPost' }"
                                class="scrl_job_post remove_active_from_humberger post_job_click"
                                ><i class="icon-material-outline-business-center"></i
                                ><span class="fold_span">Post Job</span></b-link
                              >
                            </li>
                            <li
                              v-if="
                                showPermissionEMManageJobs('view') &&
                                account_verification_status == 'completed'
                              "
                            >
                              <b-link
                                href=""
                                :to="{ name: 'DailyJobManage' }"
                                class="scrl_job_post remove_active_from_humberger post_job_click"
                                ><i class="icon-material-outline-business-center"></i
                                ><span class="fold_span staff_posting2">Manage Posting </span>
                                <b-badge
                                  variant="danger"
                                  style="background-color: rgb(144 140 144 / 75%) !important"
                                  class="customBadge custom_side_badge main_ul_nav_badge2"
                                  v-if="emp_dp_job_count > 0"
                                  >{{ emp_dp_job_count }}</b-badge
                                >
                                <!-- <b-badge variant="danger" class="customBadge" v-if="emp_dp_job_count > 0">{{emp_dp_job_count}}</b-badge> -->
                              </b-link>
                            </li>
                            <li v-if="account_verification_status == 'completed'">
                              <b-link
                                v-if="
                                  user_type == '3' &&
                                  is_company_head == 'yes' &&
                                  approval_require == 'yes'
                                "
                                href=""
                                :to="{ name: 'approveJob' }"
                                class="scrl_job_post remove_active_from_humberger post_job_click"
                                ><i class="icon-material-outline-business-center"></i
                                ><span class="fold_span">Jobs For Approval</span></b-link
                              >
                            </li>

                            <li
                              v-if="
                                showPermissionEMRatings('view') &&
                                account_verification_status == 'completed'
                              "
                            >
                              <b-link
                                href=""
                                :to="{ name: 'employer-ratings-reviews' }"
                                class="scrl_job_post remove_active_from_humberger post_job_click"
                                ><i class="icon-material-outline-business-center"></i
                                ><span class="fold_span">Reviews and Ratings</span></b-link
                              >
                            </li>
                            <li
                              v-if="
                                showPermissionEMServiceRe('view') &&
                                account_verification_status == 'completed'
                              "
                            >
                              <b-link
                                href=""
                                :to="{ name: 'employer-service-report' }"
                                class="scrl_job_post remove_active_from_humberger post_job_click"
                                ><i class="icon-material-outline-business-center"></i
                                ><span class="fold_span">Service Reports</span></b-link
                              >
                            </li>
                            <!-- <li v-if="user_type == '3' && showPermissionEMInvoice('view')"><b-link href="" :to="{name: 'employer-invoice'}" class="scrl_job_post remove_active_from_humberger post_job_click"><i class="icon-material-outline-business-center"></i><span class="fold_span">Invoice</span></b-link></li> -->
                            <li
                              v-if="
                                showPermissionEMInvoice('view') &&
                                account_verification_status == 'completed'
                              "
                            >
                              <b-link
                                href=""
                                :to="{ name: 'employer-invoice' }"
                                class="scrl_job_post remove_active_from_humberger post_job_click"
                                ><i class="icon-material-outline-business-center"></i
                                ><span class="fold_span">Invoice</span></b-link
                              >
                            </li>

                            <!-- <li ><b-link href="" :to="{name: 'manage-jobs'}"><i class="icon-material-outline-business-center"></i><span class="fold_span">Manage Jobs</span><span class="job_count">3</span></b-link></li> -->
                            <!-- <li ><b-link href="" :to="{name: 'manage-jobs'}"><i class="icon-material-outline-business-center"></i><span class="fold_span">Manage Jobs</span></b-link></li>
                                            <li ><b-link href="" :to="{name: 'saved-jobs'}"><i class="icon-material-outline-business-center"></i><span class="fold_span">Drafted Jobs</span></b-link></li>
                                            <li ><b-link href="" :to="{name: 'manage-all-jobs-candidates'}"><i class="icon-material-outline-business-center"></i><span class="fold_span">Manage Candidates</span></b-link></li> -->
                          </ul>
                        </transition>
                      </li>

                      <li
                        v-if="
                          (user_type == '3' || user_type == 4) &&
                          (showPermissionEMManageJobAds('view') ||
                            showPermissionEMDraftedJobAds('view'))
                        "
                      >
                        <b-link
                          href=""
                          @click="isJobShow = !isJobShow"
                          :class="[
                            this.$route.name == 'manage-jobs' ||
                            this.$route.name == 'saved-jobs' ||
                            this.$route.name == 'manage-candidates' ||
                            this.$route.name == 'postJob' ||
                            this.$route.name == 'manage-all-jobs-candidates' ||
                            this.$route.name == 'elcoins'
                              ? 'myactive'
                              : '',
                          ]"
                          class="custm_job remove_before_a"
                        >
                          <i class="material-icons-outlined new_icon_sidebar_material"
                            >business_center_outlined</i
                          >
                          <span class="fold_span back_span1">Job Ads</span>
                          <i class="fa fa-angle-right"></i>
                        </b-link>
                        <transition name="expand">
                          <ul class="child_hide_ul list">
                            <!-- <li ><b-link href="" :to="{name: 'manage-jobs'}"><i class="icon-material-outline-business-center"></i><span class="fold_span">Manage Jobs</span><span class="job_count">3</span></b-link></li> -->
                            <li
                              v-if="
                                (user_type == '3' || user_type == 4) &&
                                showPermissionEMPurchaseCoin('view')
                              "
                            >
                              <b-link
                                href=""
                                :to="{ name: 'elcoins' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i
                                ><span class="fold_span">Purchase Job Ads</span></b-link
                              >
                            </li>
                            <li v-if="user_type == '3' || user_type == 4">
                              <b-link
                                href=""
                                :to="{ name: 'postJob' }"
                                class="scrl_job_post remove_active_from_humberger post_job_click"
                                ><i class="icon-material-outline-business-center"></i
                                ><span class="fold_span">Post Job Ads</span></b-link
                              >
                            </li>
                            <li v-if="showPermissionEMManageJobAds('view')">
                              <b-link
                                href=""
                                :to="{ name: 'manage-jobs' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i
                                ><span class="fold_span"
                                  >Manage Job Ads
                                  <b-badge
                                    variant="danger"
                                    style="background-color: rgb(144 140 144 / 75%) !important"
                                    class="customBadge custom_side_badge"
                                    v-if="emp_ft_job_count > 0"
                                    >{{ emp_ft_job_count }}</b-badge
                                  ></span
                                >
                                <!-- <b-badge variant="danger" class="customBadge" v-if="emp_ft_job_count > 0">{{emp_ft_job_count}}</b-badge> -->
                              </b-link>
                            </li>
                            <li v-if="showPermissionEMDraftedJobAds('view')">
                              <b-link
                                href=""
                                :to="{ name: 'saved-jobs' }"
                                class="remove_active_from_humberger"
                                ><i class="icon-material-outline-business-center"></i
                                ><span class="fold_span"
                                  >Drafted Job Ads
                                  <b-badge
                                    variant="danger"
                                    style="background-color: #ed7c04 !important"
                                    class="customBadge custom_side_badge"
                                    v-if="emp_ft_draft_job_count > 0"
                                    >{{ emp_ft_draft_job_count }}</b-badge
                                  ></span
                                ></b-link
                              >
                            </li>

                            <!--  <li ><b-link href="" :to="{name: 'manage-all-jobs-candidates'}" class="remove_active_from_humberger"><i class="icon-material-outline-business-center"></i><span class="fold_span">Manage Candidates  </span> -->
                            <!-- <span class="red_status" v-if="candidateCount > 0">{{candidateCount}}</span></span></b-link></li> -->
                            <!--      <b-badge variant="danger" class="customBadge custom_side_badge" style="background-color: rgb(40, 167, 69) !important;" v-if="candidateCount > 0"> {{candidateCount}}</b-badge>
                                            </b-link></li> -->
                          </ul>
                        </transition>
                      </li>

                      <!-- <li v-if="((user_type == '3' || user_type == 4) && account_verification_status == 'completed' && companyAdminDPStatus == 'completed' && (showPermissionEMManageJobs('create job') || showPermissionEMManageJobs('view') || showPermissionEMRatings('view') || showPermissionEMServiceRe('view') || showPermissionEMInvoice('view')))" :class="account_verification_status == 'completed' ? 'completed_verification_status' : 'incomplete_verification_status'"> <b-link href="" @click="isDailyJobShow = !isDailyJobShow" :class="[(this.$route.name == 'DailyJobManage' ) || (this.$route.name == 'DailyJobPost' ) || (this.$route.name == 'approveJob' ) ? 'myactive' : '']" class="custm_job  remove_before_a"><i class="material-icons-outlined new_icon_sidebar_material">currency_exchange_outlined</i><span class="fold_span back_span1">Daily Paid Jobs</span><i class="fa fa-angle-right "></i></b-link> -->

                      <!--<li v-if="(user_type == '3' || user_type == 4)"></li>-->
                      <!-- <b-link href="" :to="{name: 'userCoins'}" class="user_back remove_before_a mag_prf"><i class="manage_profile_icon"></i><span class="fold_span back_span1">User Coins</span></b-link></li> -->

                      <!-- <li v-if="(user_type == '3' || user_type == 4)"> -->
                      <!-- <b-link href="" :to="{name: 'transactions'}" class="user_back remove_before_a mag_prf credit_card_a"><span class="fold_span back_span1">Transactions</span></b-link></li> -->
                      <li
                        v-if="
                          (user_type == '3' || user_type == 4) &&
                          showPermissionEMTransactionH('view')
                        "
                      >
                        <b-link
                          href=""
                          :to="{ name: 'jobTransactions' }"
                          class="user_back remove_before_a mag_prf"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >receipt_long_outlined</i
                          ><span class="fold_span back_span1">Transactions History</span></b-link
                        >
                      </li>
                      <li
                        v-if="
                          (user_type == '3' || user_type == 4) && showPermissionEMRewards('view')
                        "
                      >
                        <b-link
                          href=""
                          :to="{ name: 'emp-reward-list' }"
                          class="remove_active_from_humberger user_back remove_before_a mag_prf common_remove_active_sub_menu"
                          :class="[
                            this.$route.name == 'emp-reward-list' ||
                            this.$route.name == 'emp-reward-view' ||
                            this.$route.name == 'emp-reward-enquiry'
                              ? 'myactive'
                              : '',
                          ]"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >manage_accounts_outlined</i
                          ><span class="fold_span back_span1">Rewards</span></b-link
                        >
                      </li>

                      <!-- <li v-if="(user_type == '3' || user_type == 4) && showPermissionEMManageUser('view') && ft_account_status == 'completed' && account_verification_status == 'completed' && is_company_head == 'yes' && company_status == 'active'"><b-link href="" :to="{name: 'empManageUserList'}" class="remove_active_from_humberger user_back remove_before_a mag_prf common_remove_active_sub_menu" :class="[(this.$route.name == 'empManageUserList' ) || (this.$route.name == 'empManageUserCreate' ) || (this.$route.name == 'empManageUserEdit' ) || (this.$route.name == 'emp-manage-user-view' ) ? 'myactive' : '']"><i class="material-icons-outlined new_icon_sidebar_material">manage_accounts_outlined</i><span class="fold_span back_span1">Manage User</span></b-link></li> -->
                      <!-- <li v-if="((user_type == '3' || user_type == 4) && showPermissionEMManageUser('view') && company_status == 'active')"><b-link href="" :to="{name: 'empManageUserList'}" class="remove_active_from_humberger user_back remove_before_a mag_prf common_remove_active_sub_menu" :class="[(this.$route.name == 'empManageUserList' ) || (this.$route.name == 'empManageUserCreate' ) || (this.$route.name == 'empManageUserEdit' ) || (this.$route.name == 'emp-manage-user-view' ) ? 'myactive' : '']"><i class="material-icons-outlined new_icon_sidebar_material">manage_accounts_outlined</i><span class="fold_span back_span1">Manage User</span></b-link></li> -->
                      <li
                        v-if="
                          user_type == '3' &&
                          is_company_head == 'yes' &&
                          company_status == 'active' &&
                          (ft_account_status == 'completed' ||
                            account_verification_status == 'completed')
                        "
                      >
                        <b-link
                          href=""
                          :to="{ name: 'empManageUserList' }"
                          class="remove_active_from_humberger user_back remove_before_a mag_prf common_remove_active_sub_menu"
                          :class="[
                            this.$route.name == 'empManageUserList' ||
                            this.$route.name == 'empManageUserCreate' ||
                            this.$route.name == 'empManageUserEdit' ||
                            this.$route.name == 'emp-manage-user-view'
                              ? 'myactive'
                              : '',
                          ]"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >manage_accounts_outlined</i
                          ><span class="fold_span back_span1">Manage User</span></b-link
                        >
                      </li>

                      <!-- {{user_type}}{{showPermissionEMManageUser('view')}}{{ft_account_status}}{{account_verification_status}}{{company_status}} -->
                      <!-- {{(user_type == '3' || user_type == 4) && showPermissionEMManageUser('view') && ft_account_status == 'completed' && account_verification_status == 'completed' && company_status == 'active'}} -->
                      <!-- {{(user_type == '3' || user_type == 4) && showPermissionEMManageUser('view') && ft_account_status == 'completed' && account_verification_status == 'completed' && company_status == 'active'}} -->

                      <!-- <li v-if="(user_type == '3' || user_type == 4)"> -->
                      <!-- <b-link href="" :to="{name: 'userCoins'}" class="user_back remove_before_a mag_prf"><i class="manage_profile_icon"></i><span class="fold_span back_span1">User Coins</span></b-link></li> -->
                      <!-- <li v-if="user_type == '5'">
                                        <b-link href="" :to="{name: 'jobseekerElcoins'}" class="user_back remove_before_a mag_prf purchase_coin"><span class="fold_span back_span1">Purchase ELC Credits</span></b-link></li>
                                       <li v-if="user_type == '5'">
                                        <b-link href="" :to="{name: 'jobseekerTransactions'}" class="user_back remove_before_a mag_prf credit_card_a"><span class="fold_span back_span1">Transactions</span></b-link></li> -->

                      <!--<li v-if="(user_type == '3' || user_type == 4)"><b-link href="" :to="{name: 'jobTransactions'}" class="user_back remove_before_a mag_prf"><i class="manage_profile_icon"></i><span class="fold_span back_span1">Jobs Transaction</span></b-link></li>-->

                      <!-- <li v-if="(user_type == '3' || user_type == 4)" :class="(this.$route.name === 'employer-setting') ? 'active' : '' " ><b-link href="" :to="{name: 'employer-setting'}" class="setting_a general_icon"><span class="fold_span back_span">Settings</span></b-link></li> -->
                      <!-- <li v-if="user_type == '5'" :class="(this.$route.name === 'jobseeker-settings') ? 'active' : '' " ><b-link href="" :to="{name: 'jobseeker-settings'}" class="setting_a general_icon"><span class="fold_span back_span">Settings</span></b-link></li> -->
                      <li
                        v-if="user_type == 5"
                        :class="
                          this.$route.name === 'jobseeker-ticket-list' ||
                          this.$route.name === 'jobseeker-ticket-create' ||
                          this.$route.name === 'jobseeker-ticket-view'
                            ? 'active'
                            : ''
                        "
                      >
                        <b-link
                          href=""
                          :to="{ name: 'jobseeker-ticket-list' }"
                          class="remove_active_from_humberger common_remove_active_sub_menu common_remove_active_sub_menu"
                          ><i
                            class="material-icons-outlined new_icon_sidebar_material common_vertical_align_sub"
                            >message_outlined</i
                          ><span class="fold_span back_span"
                            >Contact Support<b-badge
                              variant="danger"
                              style="background-color: rgba(239, 26, 167, 1) !important"
                              class="customBadge custom_side_badge"
                              v-if="emfl_unread_message_count > 0"
                              >{{ emfl_unread_message_count }}</b-badge
                            ></span
                          ></b-link
                        >
                      </li>
                      <!-- <li v-if="((user_type == '3' || user_type == 4) && showPermissionEMBookmarkFL('view'))" :class="(this.$route.name === 'employer-bookmark-jobseeker-list') ? 'active' : '' " ><b-link href="" :to="{name: 'employer-bookmark-jobseeker-list'}" class="  remove_active_from_humberger common_remove_active_sub_menu"><i class="material-icons-outlined new_icon_sidebar_material">bookmarks_outlined</i><span class="fold_span back_span">Bookmarked Jobseekers</span></b-link></li> -->
                      <li
                        v-if="user_type == '3' || user_type == 4"
                        :class="
                          this.$route.name === 'employer-bookmark-jobseeker-list' ? 'active' : ''
                        "
                      >
                        <b-link
                          href=""
                          :to="{ name: 'employer-bookmark-jobseeker-list' }"
                          class="remove_active_from_humberger common_remove_active_sub_menu"
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >bookmarks_outlined</i
                          ><span class="fold_span back_span">Bookmarked Jobseekers</span></b-link
                        >
                      </li>

                      <!-- <li v-if="user_type == '5'" :class="(this.$route.name === 'jobseeker-change-password') ? 'active' : '' " ><b-link href="" :to="{name: 'jobseeker-change-password'}" class=" remove_active_from_humberger common_remove_active_sub_menu"><i class="material-icons new_icon_sidebar_material">lock</i><span class="fold_span back_span">Change Password</span></b-link></li> -->
                      <!-- <li v-if="(user_type == '3' || user_type == 4)" :class="(this.$route.name === 'employer-change-password') ? 'active' : '' " ><b-link href="" :to="{name: 'employer-change-password'}" class=" remove_active_from_humberger common_remove_active_sub_menu"><i class="material-icons new_icon_sidebar_material">lock</i><span class="fold_span back_span">Change Password</span></b-link></li> -->
                      <li
                        v-if="user_type == '3' || user_type == 4"
                        :class="
                          this.$route.name === 'employer-ticket-list' ||
                          this.$route.name === 'employer-ticket-create' ||
                          this.$route.name === 'employer-ticket-view'
                            ? 'active'
                            : ''
                        "
                      >
                        <b-link
                          href=""
                          :to="{ name: 'employer-ticket-list' }"
                          class="remove_active_from_humberger common_remove_active_sub_menu common_remove_active_sub_menu"
                          ><i
                            class="material-icons-outlined new_icon_sidebar_material common_vertical_align_sub"
                            >message_outlined</i
                          ><span class="fold_span back_span"
                            >Contact Support<b-badge
                              variant="danger"
                              style="background-color: rgba(239, 26, 167, 1) !important"
                              class="customBadge custom_side_badge"
                              v-if="emfl_unread_message_count > 0"
                              >{{ emfl_unread_message_count }}</b-badge
                            ></span
                          ></b-link
                        >
                      </li>
                      <!--<li v-if="(user_type == '3' || user_type == 4)" :class="(this.$route.name === 'employer-ratings-reviews') ? 'active' : '' " ><b-link href="" :to="{name: 'employer-ratings-reviews'}" class=" remove_active_from_humberger common_remove_active_sub_menu"><i class="material-icons-outlined new_icon_sidebar_material">star_purple_500_outlined</i><span class="fold_span back_span">Ratings and Reviews</span></b-link></li>
                                        <li v-if="(user_type == '3' || user_type == 4)" :class="(this.$route.name === 'employer-service-report') ? 'active' : '' " ><b-link href="" :to="{name: 'employer-service-report'}" class="remove_active_from_humberger common_remove_active_sub_menu"><i class="material-icons-outlined new_icon_sidebar_material">text_snippet_outlined</i><span class="fold_span back_span">Service Reports</span></b-link></li>
                                        <li v-if="(user_type == '3' || user_type == 4)" :class="(this.$route.name === 'employer-invoice') ? 'active' : '' " ><b-link href="" :to="{name: 'employer-invoice'}" class=" remove_active_from_humberger common_remove_active_sub_menu"><i class="material-icons-outlined new_icon_sidebar_material">request_quote_outlined</i><span class="fold_span back_span">Invoice</span></b-link></li> -->
                      <li @click="logout">
                        <a href="#" class=""
                          ><i class="material-icons-outlined new_icon_sidebar_material"
                            >power_settings_new</i
                          ><span class="fold_span">Logout</span></a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- Navigation / End -->
            </div>
          </div>
        </div>
        <!-- Dashboard Sidebar / End -->
        <!-- Dashboard Content ================================================== -->
        <div class="dashboard-content-container" data-simplebar>
          <div
            class="dashboard-content-inner mobile_content_overlap inner_content_common_padding"
            :class="$route.fullPath == '/v2/employer/candidate-search' ? 'candidate_padding' : ''"
          >
            <router-view></router-view>

            <!-- Footer -->
            <div
              class="dashboard-footer-spacer custom_footer_spacer"
              v-bind:class="{ 'jobList-footer': $route.path == '/v2/jobseeker/joblist' }"
            ></div>

            <!-- Footer / End -->
          </div>
          <div class="small-footer desktop_footer">
            <div class="small-footer-copyrights">
              Ⓡ 2024 <strong>EL Connect</strong>. All Rights Reserved.
            </div>
            <ul class="footer-social-links">
              <li>
                <a
                  v-if="get_facebook_link.length > 0"
                  v-bind:href="
                    (get_facebook_link[0].option_key = 'facebook_link'
                      ? get_facebook_link[0].option_value
                      : get_facebook_link[0].option_value)
                  "
                  target="_blank"
                  title="Facebook"
                  data-tippy-placement="top"
                >
                  <i class="icon-brand-facebook-f"></i>
                </a>
              </li>
              <li>
                <a
                  v-if="get_instagram_link.length > 0"
                  v-bind:href="
                    (get_instagram_link[0].option_key = 'instagram_link'
                      ? get_instagram_link[0].option_value
                      : get_instagram_link[0].option_value)
                  "
                  target="_blank"
                  title="Instagram"
                  data-tippy-placement="top"
                >
                  <i class="icon-brand-instagram"></i>
                </a>
              </li>
              <!-- <li>
                                    <a herf="#" title="Google Plus" data-tippy-placement="top">
                                        <i class="icon-brand-google-plus-g"></i>
                                    </a>
                                </li> -->
              <li v-if="get_tiktok_link.length > 0">
                <a
                  v-bind:href="
                    (get_tiktok_link[0].option_key = 'tiktok_link'
                      ? get_tiktok_link[0].option_value
                      : get_tiktok_link[0].option_value)
                  "
                  title="TikTok"
                  data-tippy-placement="top"
                  target="_blank"
                  ><i class="fa-brands fa-tiktok"></i
                ></a>
              </li>
              <li>
                <a
                  v-if="get_youtube_link.length > 0"
                  v-bind:href="
                    (get_youtube_link[0].option_key = 'youtube_link'
                      ? get_youtube_link[0].option_value
                      : get_youtube_link[0].option_value)
                  "
                  target="_blank"
                  title="Youtube"
                  data-tippy-placement="top"
                >
                  <i class="icon-brand-youtube"></i>
                </a>
              </li>
            </ul>
            <div class="clearfix"></div>
          </div>
        </div>
        <!-- Dashboard Content / End -->
      </div>
      <div class="small-footer mobile_footer">
        <div class="small-footer-copyrights">
          Ⓡ 2024 <strong>EL Connect</strong>. All Rights Reserved.
        </div>
        <ul class="footer-social-links">
          <li>
            <a
              v-if="get_facebook_link.length > 0"
              v-bind:href="
                (get_facebook_link[0].option_key = 'facebook_link'
                  ? get_facebook_link[0].option_value
                  : get_facebook_link[0].option_value)
              "
              target="_blank"
              title="Facebook"
              data-tippy-placement="top"
            >
              <i class="icon-brand-facebook-f"></i>
            </a>
          </li>
          <li>
            <a
              v-if="get_instagram_link.length > 0"
              v-bind:href="
                (get_instagram_link[0].option_key = 'instagram_link'
                  ? get_instagram_link[0].option_value
                  : get_instagram_link[0].option_value)
              "
              target="_blank"
              title="Instagram"
              data-tippy-placement="top"
            >
              <i class="icon-brand-instagram"></i>
            </a>
          </li>
          <!-- <li>
                                    <a  herf="#" title="Google Plus" data-tippy-placement="top">
                                        <i class="icon-brand-google-plus-g"></i>
                                    </a>
                                </li> -->
          <li v-if="get_tiktok_link.length > 0">
            <a
              v-bind:href="
                (get_tiktok_link[0].option_key = 'tiktok_link'
                  ? get_tiktok_link[0].option_value
                  : get_tiktok_link[0].option_value)
              "
              title="Tik Tok"
              data-tippy-placement="top"
              target="_blank"
              ><i class="fa-brands fa-tiktok"></i
            ></a>
          </li>
          <li>
            <a
              v-if="get_youtube_link.length > 0"
              v-bind:href="
                (get_youtube_link[0].option_key = 'youtube_link'
                  ? get_youtube_link[0].option_value
                  : get_youtube_link[0].option_value)
              "
              target="_blank"
              title="Youtube"
              data-tippy-placement="top"
            >
              <i class="icon-brand-youtube"></i>
            </a>
          </li>
        </ul>
        <div class="clearfix"></div>
      </div>
      <!-- Dashboard Container / End -->
    </div>
    <!-- Wrapper / End -->

    <!-- Apply for a job popup
        ================================================== -->
    <div id="small-dialog" class="zoom-anim-dialog mfp-hide dialog-with-tabs">
      <!--Tabs -->
      <div class="sign-in-form">
        <ul class="popup-tabs-nav">
          <li><a href="#tab">Add Note</a></li>
        </ul>
        <div class="popup-tabs-container">
          <!-- Tab -->
          <div class="popup-tab-content" id="tab">
            <!-- Welcome Text -->
            <div class="welcome-text">
              <h3>Do Not Forget 😎</h3>
            </div>

            <!-- Form -->
            <form method="post" id="add-note">
              <select
                class="selectpicker with-border default margin-bottom-20"
                data-size="7"
                title="Priority"
              >
                <option>Low Priority</option>
                <option>Medium Priority</option>
                <option>High Priority</option>
              </select>

              <textarea name="textarea" cols="10" placeholder="Note" class="with-border"></textarea>
            </form>

            <!-- Button -->
            <button
              class="button full-width button-sliding-icon ripple-effect"
              type="submit"
              form="add-note"
            >
              Add Note <i class="icon-material-outline-arrow-right-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Apply for a job popup / End -->
  </div>
</template>

<script>
import { LOGOUT } from "../store/actions.type";
import { POST_API, PERMISSION_DATA } from "../store/actions.type";
import Bus from "../event-bus";
import permission from "../../../server/permission.js";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      user_type: "",
      is_company_head: "",
      profile_image: "",
      ft_account_status: "",
      account_verification_status: "",
      account_verification_status_user: "",
      company_acra: "",
      company_status: "",
      companyAdminDPStatus: "",
      approval_require: "",
      free_job_posts: "",
      free_feature_job_posts: "",

      user_data: [],
      cert_data: [],
      name: "",
      webUrl: process.env.VUE_APP_URL,
      webUrlV1Admin: process.env.VUE_APP_URL_V1_Admin + "/admin-login",
      billing_address: [],
      isDatabaseShow: false,
      isSupportShow: false,
      isWebsiteContentsShow: false,
      isTableShow: false,
      isBalanceShow: false,
      isDeploymnetShow: false,

      feature_jobs: 0,
      isIncentiveShow: false,
      isRatingsShow: false,
      isJobShow: false,
      isDailyJobShow: false,
      get_facebook_link: [],
      get_instagram_link: [],
      get_youtube_link: [],
      get_tiktok_link: [],
      isAccountShow: false,
      isAccountShowJobseeker: false,
      remaining: "",
      coins: [],
      isLoading: false,
      color: "#FFD700",
      backgroundColor: "#808080",
      width: 140,
      height: 100,
      fullPage: true,
      elxp: null,
      opacity: 0.6,
      get_header: [],
      emp_ft_job_count: "",
      emp_ft_draft_job_count: "",
      candidateCount: "",
      emp_dp_job_count: "",
      fl_ft_job_count: "",
      fl_dp_job_count: "",
      fl_user_count: "",
      ft_job_count_admin: "",
      pending_payout_count: 0,
      hold_payout_count: 0,
      emp_user_count: "",
      support_ticket_count: "",
      support_message_count: "",
      support_total_count: "",
      emfl_unread_message_count: "",
      permissionEmpManageJob: "",
      permissionEmpServiceRe: "",
      permissionEmpRating: "",
      permissionEmpInvoice: "",
      permissionEmpManageJobAds: "",
      permissionEmpDraftedJobAds: "",
      permissionEmpPurchaseCoin: "",
      permissionEmpTransactionH: "",
      permissionEmpRewards: "",
      permissionEmpManageUser: "",
      permissionEmpBookmarkFL: "",
      permissionEmpContactSupport: "",

      permissionStaffDashboard: "",
      permissionStaffDPAdmin: "",
      permissionStaffFL: "",
      permissionStaffManageBadge: "",
      permissionStaffManageCert: "",
      permissionStaffEmp: "",
      permissionStaffComUsers: "",
      permissionStaffBilling: "",
      permissionStaffManageDepart: "",
      permissionStaffHeatMap: "",
      permissionStaffManageStaff: "",
      permissionStaffFTJobs: "",
      permissionStaffFTJobsCandidates: "",
      permissionStaffTransaction: "",
      permissionStaffPayout: "",
      permissionStaffPenalty: "",
      permissionStaffNotification: "",
      permissionStaffReferralHistory: "",
      permissionStaffDeploymentDailyDeploy: "",
      permissionStaffDeploymentCompanyPosting: "",
      permissionStaffSettings: "",
      permissionStaffTableUploadImage: "",

      permissionStaffPerHourCost: "",

      permissionStaffSupportTicket: "",
      permissionStaffSupportMsg: "",
      permissionStaffEmpBalance: "",
      permissionStaffBonusUsersList: "",
      permissionStaffTimesheetList: "",
      permissionStaffFLBalance: "",
      permissionStaffFLPayslip: "",
      permissionStaffRevenueReport: "",
      permissionStaffCampaignBonus: "",
      permissionStaffPayIncentive: "",
      permissionStaffTableFSC: "",
      permissionStaffTableFSurvey: "",
      permissionStaffTableSA: "",
      permissionStaffTableComWhitelist: "",
      permissionStaffIncentivesUser: "",
      permissionStaffIncentivesMngmnt: "",
      permissionStaffEMRatings: "",
      permissionStaffFLRatings: "",
      permissionStaffServiceReport: "",
      permissionStaffEmpInvoices: "",
      permissionStaffDBNric: "",
      permissionStaffDBCountries: "",
      permissionStaffDBNationality: "",
      permissionStaffDBBanks: "",
      permissionStaffDBPostalDistrict: "",
      permissionStaffDBMedicalHistory: "",
      permissionStaffDBReward: "",
      permissionStaffDBGreetingMessage: "",
      permissionStaffDBBroadcastNotificationEmail: "",

      permissionStaffDBSCFT: "",
      permissionStaffDBSCDP: "",
      permissionStaffDBEmail: "",
      permissionStaffDBWhatsapp: "",
      permissionStaffDBRace: "",
      permissionStaffDBEQ: "",
      permissionStaffDBFOS: "",
      permissionStaffDBUniversity: "",
      permissionStaffDBPositionlevel: "",
      permissionStaffDBLocations: "",
      permissionStaffDBAreas: "",
      permissionStaffDBCM: "",
      permissionStaffDBCT: "",
      permissionStaffDBES: "",
      permissionStaffDBPR: "",
      permissionStaffDBUserLogs: "",
      permissionStaffDBBadgeMaster: "",
      permissionStaffDBHolidays: "",
      permissionStaffDBTicketType: "",
      permissionStaffDBSubscribeMails: "",
      permissionStaffDBELCCredits: "",
      permissionStaffDBELMembership: "",
      permissionStaffDBTranSubTypes: "",
      permissionStaffDBBillingTypes: "",

      permissionStaffWebsiteStatic: "",
      permissionStaffWebsiteProcess: "",
      permissionStaffWebsiteAppCont: "",
      permissionStaffWebsiteBlog: "",
      permissionStaffWebsitePastWin: "",
      permissionStaffWebsiteFAQ: "",
      permissionStaffWebsiteBanners: "",
      permissionStaffWebsiteAppBanners: "",
      permissionStaffWebsitePartners: "",
      permissionStaffWebsiteMPEmp: "",
      permissionStaffWebsiteMPFL: "",
      permissionStaffWebsitePoster: "",
      permissionStaffWebsitePermanentPTJobs: "",
      permissionStaffWebsitePromotions: "",
      permissionStaffWebsiteCampaigns: "",
      permissionStaffWebsiteGigplace: "",
      permissionStaffWebsiteTestimonial: "",
      permissionStaffWebsiteQR: "",
      permissionStaffWebsiteLanding: "",
      permissionStaffWebsiteOnlineTrain: "",
      // permissionStaffWebsiteCampaign: "",

      menus: null,
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  components: {
    Loading,
  },
  methods: {
    logout() {
      var user =
        this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : "";

      // if (user == 1) {
      //     var routeName = 'adminlogin'
      // }else{
      //     var routeName = 'login'
      // }
      if (user == 1) {
        var routeName = "jobseekers2";
      } else {
        var routeName = "jobseekers2";
      }

      this.$store
        .dispatch(LOGOUT, {
          data: {},
          api: "/api/logout",
        })
        .then(() => {
          if (!this.$store.getters.containsErrors) {
            localStorage.clear();
            this.$router.push({ name: routeName });
          }
        });
    },

    getFooterData() {
      return this.$store
        .dispatch(POST_API, {
          api: "/api/footer-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            //this.contact_us = this.$store.getters.getResults.contact_us;
            this.get_header = this.$store.getters.getResults.get_header;
            this.get_facebook_link = this.$store.getters.getResults.get_facebook_link;
            this.get_instagram_link = this.$store.getters.getResults.get_instagram_link;
            this.get_youtube_link = this.$store.getters.getResults.get_youtube_link;
            this.get_tiktok_link = this.$store.getters.getResults.get_tiktok_link;
            // this.about_us = this.$store.getters.getResults.about_us;
          }
        });
    },
    getDPFTEmpJobCount() {
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.user_id,
            company_id: this.company_id,
          },
          api: "/api/getDPFTEmpJobCount",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.emp_ft_job_count = this.$store.getters.getResults.total_ft_count;
            this.emp_ft_draft_job_count = this.$store.getters.getResults.total_ft_draft_count;
            this.emp_dp_job_count = this.$store.getters.getResults.total_dp_count;
            this.candidateCount = this.$store.getters.getResults.candidateCount;
          }
        });
    },

    getDPFTFLJobCount() {
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.user_id,
            // company_id:this.company_id
          },
          api: "/api/getDPFTFLJobCount",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.fl_ft_job_count = this.$store.getters.getResults.total_ft_count;
            this.fl_dp_job_count = this.$store.getters.getResults.total_dp_count;
          }
        });
    },
    getIsUserActive() {
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.user_id,
          },
          api: "/api/isUserActive",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.isUserActive = this.$store.getters.getResults.data;

            if (this.isUserActive.length == 0) {
              this.logout();
            }
          }
        });
    },

    getcertdata() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.user_id,
          },
          api: "/api/user-certificate",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.cert_data = this.$store.getters.getResults.data;
          }
        });
    },

    getbillingdata() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            userId: this.user_id,
          },
          api: "/api/billingAddress",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.billing_address = this.$store.getters.getResults.data;
          }
        });
    },

    getuserdata() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.user_id,
          },
          api: "/api/user-data-email-mobile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.user_data = this.$store.getters.getResults.data;
          }
        });
    },
    getElCoins() {
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.company_admin_id ? this.company_admin_id : this.user_id,
          },
          api: "/api/userCoins",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.coins = this.$store.getters.getResults.data;

            this.feature_jobs = this.$store.getters.getResults.data[0].featured_jobs
              ? this.$store.getters.getResults.data[0].featured_jobs
              : 0;

            if (this.elxp != null) {
              if (parseInt(this.elxp) < 1000) {
                this.remaining = 1000 - parseInt(this.elxp);
              } else if (parseInt(this.elxp) >= 1000 && parseInt(this.elxp) < 2000) {
                this.remaining = 2000 - parseInt(this.elxp);
              } else if (parseInt(this.elxp) >= 2000 && parseInt(this.elxp) < 4000) {
                this.remaining = 4000 - parseInt(this.elxp);
              } else if (parseInt(this.elxp) >= 4000) {
                this.remaining = "";
              }
            }
          }
        });
    },

    getprofile() {
      this.$store
        .dispatch(POST_API, {
          data: {
            id: this.user_id,
          },
          // api:'/api/employee-profile-nav'
          api: "/api/employee-profile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            if (this.user_type == 3) {
              this.profile_image =
                this.$store.getters.getResults.data.company_logo != null &&
                this.$store.getters.getResults.data.company_logo != ""
                  ? this.$store.getters.getResults.data.company_logo
                  : this.webUrl + "assets/images/user-avatar-small-01.png";
            } else {
              this.profile_image =
                this.$store.getters.getResults.user != null
                  ? this.$store.getters.getResults.user["ft_profile_image"] != null &&
                    this.$store.getters.getResults.user["ft_profile_image"] != ""
                    ? this.$store.getters.getResults.user["ft_profile_image"]
                    : this.webUrl + "assets/images/user-avatar-small-01.png"
                  : this.webUrl + "assets/images/user-avatar-small-01.png";
            }
            this.ft_account_status = this.$store.getters.getResults.user.ft_account_status;
            this.approval_require = this.$store.getters.getResults.user.require_job_approval;

            this.free_job_posts = this.$store.getters.getResults.user.free_job_posts;
            this.free_feature_job_posts = this.$store.getters.getResults.user.free_featured;

            this.account_verification_status =
              this.$store.getters.getResults.user.account_verification_status;
            if (this.account_verification_status == "completed") {
              $("#dpJobMenu").show();
            } else {
              $("#dpJobMenu").hide();
            }
            this.company_acra = this.$store.getters.getResults.data
              ? this.$store.getters.getResults.data.acra
              : "";

            this.company_status = this.$store.getters.getResults.company_status
              ? this.$store.getters.getResults.company_status
              : "";
            this.companyAdminDPStatus =
              this.$store.getters.getResults.companyAdminData.length > 0
                ? this.$store.getters.getResults.companyAdminData[0].account_verification_status
                : "";

            this.elxp = this.$store.getters.getResults.user.elxp;
          }
        });
    },

    pollData() {
      setInterval(() => {
        this.getUsersCount();
      }, 500000);
    },

    pollData2() {
      setInterval(() => {
        this.getDPFTFLJobCount();
      }, 600000);
    },

    pollData3() {
      setInterval(() => {
        this.getDPFTEmpJobCount();
      }, 700000);
    },
    isActiveUserCheck() {
      setInterval(() => {
        this.getIsUserActive();
      }, 50000);
    },

    userData() {
      setInterval(() => {
        this.getprofile();
      }, 800000);
    },

    getcertdata1() {
      setInterval(() => {
        this.getcertdata();
      }, 900000);
    },
    getDynamicCoinValEm() {
      setInterval(() => {
        this.getElCoins();
      }, 1000000);
    },
    getUsersCount() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/getUsersCount",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.fl_user_count = this.$store.getters.getResults.total_fl_count;
            this.emp_user_count = this.$store.getters.getResults.total_em_count;
            this.ft_job_count_admin = this.$store.getters.getResults.total_job_count;
          }
        });
    },
    getPendingHoldPayoutCount() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/pending-hold-payout-count",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.hold_payout_count = this.$store.getters.getResults.hold_payout_count;
            this.pending_payout_count = this.$store.getters.getResults.pending_payout_count;
          }
        });
    },
    getMenuPermissionStaffNav() {
      this.$store
        .dispatch(PERMISSION_DATA, {
          data: {
            user_id: this.$store.getters.currentUser.id,
          },
          api: "/api/staff-menu-permissions",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.menus = this.$store.getters.getResults.data;
          }
        });
    },
    getAdminSupportCount() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/get-support-ticket-message-count",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.support_ticket_count = this.$store.getters.getResults.support_ticket_count;
            this.support_message_count = this.$store.getters.getResults.support_message_count;
            this.support_total_count = this.$store.getters.getResults.support_total_count;
          }
        });
    },
    getContactSupportEmpFlMesaageCount() {
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.user_id,
          },
          api: "/api/get-contact-support-emfl-message-count",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.emfl_unread_message_count = this.$store.getters.getResults.unread_message_count;
          }
        });
    },
    permissionStaff() {
      console.log(this.$store.getters, "this.$store.getters.");
      if (this.$store.getters.getCurrentUserPermission) {
        if (this.$store.getters.currentUser.user_type_id == 2) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.permissionStaffDashboard = permission.getPermissionStaff(menu, "Dashboard");
          this.permissionStaffDPAdmin = permission.getPermissionStaff(menu, "DP Jobs Admin");

          this.permissionStaffFL = permission.getPermissionStaff(menu, "Jobseekers");
          this.permissionStaffManageBadge = permission.getPermissionStaff(menu, "Manage Badge");
          this.permissionStaffManageCert = permission.getPermissionStaff(
            menu,
            "Manage Certificate"
          );

          this.permissionStaffEmp = permission.getPermissionStaff(menu, "Companies");
          this.permissionStaffComUsers = permission.getPermissionStaff(menu, "Company Users");
          this.permissionStaffBilling = permission.getPermissionStaff(menu, "Billing Address");
          this.permissionStaffManageDepart = permission.getPermissionStaff(
            menu,
            "Manage Department"
          );

          this.permissionStaffManageStaff = permission.getPermissionStaff(menu, "Manage Staff");
          this.permissionStaffFTJobs = permission.getPermissionStaff(menu, "Job Ads");
          this.permissionStaffFTJobsCandidates = permission.getPermissionStaff(
            menu,
            "View Candidates"
          );
          this.permissionStaffTransaction = permission.getPermissionStaff(menu, "Transactions");
          this.permissionStaffPayout = permission.getPermissionStaff(menu, "Payout");
          this.permissionStaffPenalty = permission.getPermissionStaff(menu, "Penalty Management");
          this.permissionStaffNotification = permission.getPermissionStaff(
            menu,
            "Broadcast Notification"
          );
          this.permissionStaffReferralHistory = permission.getPermissionStaff(
            menu,
            "Referral History"
          );
          this.permissionStaffDeploymentDailyDeploy = permission.getPermissionStaff(
            menu,
            "Daily Deployment"
          );
          this.permissionStaffDeploymentCompanyPosting = permission.getPermissionStaff(
            menu,
            "Company Posting"
          );

          this.permissionStaffHeatMap = permission.getPermissionStaff(menu, "Heat Map");

          this.permissionStaffSettings = permission.getPermissionStaff(menu, "General Settings");

          this.permissionStaffPerHourCost = permission.getPermissionStaff(menu, "Per Hour Cost");

          this.permissionStaffSupportTicket = permission.getPermissionStaff(menu, "Tickets");
          this.permissionStaffSupportMsg = permission.getPermissionStaff(menu, "Messages");
          this.permissionStaffServiceReport = permission.getPermissionStaff(menu, "Service Report");
          this.permissionStaffEmpInvoices = permission.getPermissionStaff(
            menu,
            "Employer Invoices"
          );
          this.permissionStaffEmpBalance = permission.getPermissionStaff(menu, "Employer Balance");
          this.permissionStaffCampaignBonus = permission.getPermissionStaff(
            menu,
            "Pay Campaign Bonus"
          );
          this.permissionStaffPayIncentive = permission.getPermissionStaff(menu, "Pay Incentive");
          this.permissionStaffBonusUsersList = permission.getPermissionStaff(
            menu,
            "Bonus Users List"
          );
          this.permissionStaffTimesheetList = permission.getPermissionStaff(menu, "FL Timesheet");
          this.permissionStaffCreditNoteList = permission.getPermissionStaff(menu, "Credit Note");
          this.permissionStaffFLBalance = permission.getPermissionStaff(menu, "FL Balance");
          this.permissionStaffFLPayslip = permission.getPermissionStaff(menu, "FL Payslip");
          this.permissionStaffRevenueReport = permission.getPermissionStaff(
            menu,
            "EL Revenue Reporting"
          );
          this.permissionStaffTableFSC = permission.getPermissionStaff(menu, "Food Safety Cert");
          this.permissionStaffTableFSurvey = permission.getPermissionStaff(menu, "Survey Results");
          this.permissionStaffTableSA = permission.getPermissionStaff(menu, "Service Aggremnet");
          this.permissionStaffTableComWhitelist = permission.getPermissionStaff(
            menu,
            "Company Whitelists"
          );
          this.permissionStaffTableChatbotConver = permission.getPermissionStaff(
            menu,
            "Chatbot Conversations"
          );
          this.permissionStaffTableUploadImage = permission.getPermissionStaff(
            menu,
            "Upload Image"
          );

          this.permissionStaffIncentivesUser = permission.getPermissionStaff(
            menu,
            "User Incentives"
          );
          this.permissionStaffIncentivesMngmnt = permission.getPermissionStaff(
            menu,
            "Incentives Management"
          );
          this.permissionStaffFLRatings = permission.getPermissionStaff(menu, "Freelancer Rating");
          this.permissionStaffEMRatings = permission.getPermissionStaff(menu, "Employer Rating");
          this.permissionStaffDBNric = permission.getPermissionStaff(menu, "NRIC Initials");
          this.permissionStaffDBCountries = permission.getPermissionStaff(menu, "Countries");
          this.permissionStaffDBNationality = permission.getPermissionStaff(menu, "Nationalities");
          this.permissionStaffDBBanks = permission.getPermissionStaff(menu, "Banks");
          this.permissionStaffDBSCFT = permission.getPermissionStaff(
            menu,
            "Service Categories(FT)"
          );
          this.permissionStaffDBSCDP = permission.getPermissionStaff(menu, "Service Categories");
          this.permissionStaffDBEmail = permission.getPermissionStaff(menu, "Email Templates");
          this.permissionStaffDBWhatsapp = permission.getPermissionStaff(
            menu,
            "Whatsapp Templates"
          );
          this.permissionStaffDBRace = permission.getPermissionStaff(menu, "Race");
          this.permissionStaffDBEQ = permission.getPermissionStaff(
            menu,
            "Educational Qualifications"
          );
          this.permissionStaffDBFOS = permission.getPermissionStaff(menu, "Field Of Studies");
          this.permissionStaffDBUniversity = permission.getPermissionStaff(menu, "Universities");
          this.permissionStaffDBPositionlevel = permission.getPermissionStaff(
            menu,
            "Position Levels"
          );
          this.permissionStaffDBLocations = permission.getPermissionStaff(menu, "Locations");
          this.permissionStaffDBAreas = permission.getPermissionStaff(menu, "Areas");
          this.permissionStaffDBCM = permission.getPermissionStaff(menu, "Certificates Master");
          this.permissionStaffDBCT = permission.getPermissionStaff(menu, "Certificates Type");
          this.permissionStaffDBES = permission.getPermissionStaff(menu, "Employment Status");
          this.permissionStaffDBPR = permission.getPermissionStaff(menu, "Penalty Remark");
          this.permissionStaffDBUserLogs = permission.getPermissionStaff(menu, "User Logs");
          this.permissionStaffDBBadgeMaster = permission.getPermissionStaff(menu, "Badges");
          this.permissionStaffDBHolidays = permission.getPermissionStaff(menu, "Holidays");
          this.permissionStaffDBTicketType = permission.getPermissionStaff(menu, "Ticket Type");
          this.permissionStaffDBSubscribeMails = permission.getPermissionStaff(
            menu,
            "Subscribed Emails"
          );
          this.permissionStaffDBELCCredits = permission.getPermissionStaff(menu, "ELC Credits");
          this.permissionStaffDBELMembership = permission.getPermissionStaff(
            menu,
            "EL Memberships"
          );

          this.permissionStaffDBTranSubTypes = permission.getPermissionStaff(
            menu,
            "Transaction Sub Types"
          );
          this.permissionStaffDBBillingTypes = permission.getPermissionStaff(menu, "Billing Types");

          this.permissionStaffDBPostalDistrict = permission.getPermissionStaff(
            menu,
            "Postal Districts"
          );
          this.permissionStaffDBMedicalHistory = permission.getPermissionStaff(
            menu,
            "Medical History"
          );
          this.permissionStaffDBReward = permission.getPermissionStaff(menu, "Rewards");
          this.permissionStaffDBGreetingMessage = permission.getPermissionStaff(
            menu,
            "ELCY Greeting Messages"
          );
          this.permissionStaffDBBroadcastNotificationEmail = permission.getPermissionStaff(
            menu,
            "Broadcast Notification Email"
          );
          this.permissionStaffWebsiteStatic = permission.getPermissionStaff(menu, "Static Pages");
          this.permissionStaffWebsiteProcess = permission.getPermissionStaff(
            menu,
            "Process Description"
          );
          this.permissionStaffWebsiteAppCont = permission.getPermissionStaff(menu, "App Content");
          this.permissionStaffWebsiteBlog = permission.getPermissionStaff(menu, "Blog");
          this.permissionStaffWebsitePastWin = permission.getPermissionStaff(menu, "Past Winner");
          this.permissionStaffWebsiteFAQ = permission.getPermissionStaff(menu, "FAQs");
          this.permissionStaffWebsiteBanners = permission.getPermissionStaff(menu, "Banners");
          this.permissionStaffWebsiteAppBanners = permission.getPermissionStaff(
            menu,
            "App Banners"
          );
          this.permissionStaffWebsitePartners = permission.getPermissionStaff(menu, "Partners");
          this.permissionStaffWebsiteMPFL = permission.getPermissionStaff(menu, "Marketing PopUp");
          this.permissionStaffWebsiteMPEmp = permission.getPermissionStaff(
            menu,
            "Marketing Popup(Employer)"
          );
          this.permissionStaffWebsitePoster = permission.getPermissionStaff(menu, "Job Poster");
          this.permissionStaffWebsitePermanentPTJobs = permission.getPermissionStaff(
            menu,
            "Permanent PT Job"
          );
          this.permissionStaffWebsitePromotions = permission.getPermissionStaff(menu, "Promotions");
          this.permissionStaffWebsiteCampaigns = permission.getPermissionStaff(menu, "Campaigns");
          this.permissionStaffWebsiteGigplace = permission.getPermissionStaff(menu, "Gigplaces");
          this.permissionStaffWebsiteTestimonial = permission.getPermissionStaff(
            menu,
            "Testimonials"
          );

          this.permissionStaffWebsiteQR = permission.getPermissionStaff(menu, "QR Data");
          this.permissionStaffWebsiteLanding = permission.getPermissionStaff(menu, "Landing Page");
          this.permissionStaffWebsiteOnlineTrain = permission.getPermissionStaff(
            menu,
            "Online Training"
          );

          // console.log(menu, "staff", this.permissionStaffFL.indexOf("view") >= 0);
        }
      }
    },
    permissionEM() {
      console.log(this.$store.getters, "this.$store.getters.");
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.is_company_head == "no" &&
          (this.$store.getters.currentUser.user_type_id == 3 ||
            this.$store.getters.currentUser.user_type_id == 4)
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.permissionEmpManageJob = permission.getPermission(menu, "Manage Jobs");
          this.permissionEmpRating = permission.getPermission(menu, "Ratings and Reviews");
          this.permissionEmpServiceRe = permission.getPermission(menu, "Service Reports");
          this.permissionEmpInvoice = permission.getPermission(menu, "Invoice");
          this.permissionEmpManageJobAds = permission.getPermission(menu, "Manage Job Ads");
          this.permissionEmpDraftedJobAds = permission.getPermission(menu, "Drafted Job Ads");
          this.permissionEmpPurchaseCoin = permission.getPermission(menu, "Purchase ELC Credits");
          this.permissionEmpTransactionH = permission.getPermission(menu, "Transaction History");
          this.permissionEmpRewards = permission.getPermission(menu, "Rewards");

          this.permissionEmpManageUser = permission.getPermission(menu, "Manage User");
          this.permissionEmpBookmarkFL = permission.getPermission(menu, "Bookmark Jobseekers");
          this.permissionEmpContactSupport = permission.getPermission(menu, "Contact Support");
        }
      }
    },
    showDPIfDPStatus() {
      if (this.account_verification_status == "completed") {
        return true;
      } else {
        return false;
      }
    },
    showPermissionEMManageJobs(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionEmpManageJob.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showPermissionEMManageJobs(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionEmpManageJob.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showPermissionEMRatings(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionEmpRating.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showPermissionEMServiceRe(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionEmpServiceRe.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },

    showPermissionEMInvoice(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionEmpInvoice.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showPermissionEMManageJobAds(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionEmpManageJobAds.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showPermissionEMDraftedJobAds(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionEmpDraftedJobAds.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showPermissionEMPurchaseCoin(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionEmpPurchaseCoin.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showPermissionEMTransactionH(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionEmpTransactionH.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showPermissionEMRewards(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionEmpRewards.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showPermissionEMManageUser(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionEmpManageUser.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showPermissionEMBookmarkFL(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionEmpBookmarkFL.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showPermissionEMContactSupport(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionEmpContactSupport.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    // emJobCount(){
    //     setInterval(() => {
    //         this.getDPFTEmpJobCount();
    //     },300000);
    // },
    // flJobCount(){
    //     setInterval(() => {
    //         this.getDPFTFLJobCount();
    //     },300000);
    // },
  },
  mounted() {
    this.user_type =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : "";
    this.designation =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : "";

    this.is_company_head =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.is_company_head
        : "";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.company_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : "";

    this.name =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.last_name +
          " " +
          this.$store.getters.currentUser.first_name
        : "";
    ///this.profile_image = (this.$store.getters.currentUser != null ? ((this.$store.getters.currentUser.profile_image != null && this.$store.getters.currentUser.profile_image != '') ? this.$store.getters.currentUser.profile_image : this.webUrl+'assets/images/user-avatar-small-01.png') : this.webUrl+'assets/images/user-avatar-small-01.png');
    this.company_admin_id =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.company_admin_id
        : "";
    this.account_verification_status_user =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.account_verification_status
        : "";
    this.getprofile();
    if (
      this.$store.getters.currentUser.user_type_id == 2 &&
      this.$store.getters.getCurrentUserPermission == null
    ) {
      this.getMenuPermissionStaffNav();
      this.permissionStaff();
    } else {
      this.permissionStaff();
    }
    // this.getIsUserActive();

    this.permissionEM();

    // this.getElCoins();
    this.getFooterData();
    this.getcertdata();

    // document.addEventListener("contextmenu", function(e){
    //     e.preventDefault();
    // }, false);
    // document.addEventListener("keydown", function(e) {
    // //document.onkeydown = function(e) {
    // // "I" key
    // if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
    //   disabledEvent(e);
    // }
    // // "J" key
    // if (e.ctrlKey && e.shiftKey && e.keyCode == 74) {
    //   disabledEvent(e);
    // }
    // // "S" key + macOS
    // if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
    //   disabledEvent(e);
    // }
    // // "U" key
    // if (e.ctrlKey && e.keyCode == 85) {
    //   disabledEvent(e);
    // }
    // // "F12" key
    // if (event.keyCode == 123) {
    //   disabledEvent(e);
    // }
    // // "C" key
    // if (e.ctrlKey && event.keyCode == 67) {
    //   disabledEvent(e);
    // }
    // }, false);
    // function disabledEvent(e){
    //     if (e.stopPropagation){
    //     e.stopPropagation();
    //     } else if (window.event){
    //     window.event.cancelBubble = true;
    //     }
    //     e.preventDefault();
    //     return false;
    // }

    Bus.$on("showLoader", () => {
      this.isLoading = true;
    });

    Bus.$on("get_em_job_count", () => {
      //    this.getDPFTEmpJobCount();
    });

    Bus.$on("get_fl_job_count", () => {
      //    this.getDPFTFLJobCount();
    });

    Bus.$on("active_users", () => {
      //    this.getIsUserActive();
    });

    Bus.$on("get_pending_hold_payout_count", () => {
      this.getPendingHoldPayoutCount();
    });

    Bus.$on("get_fl_emp_user_count", () => {
      //    this.getUsersCount();
    });
    Bus.$on("get_admin_support_count", () => {
      //    this.getAdminSupportCount();
    });

    Bus.$on("hideLoader", () => {
      this.isLoading = false;
    });
    Bus.$on("em_fl_unread_msg_count", () => {
      this.getContactSupportEmpFlMesaageCount();
    });
    if (
      this.$store.getters.currentUser.user_type_id == 3 ||
      this.$store.getters.currentUser.user_type_id == 4 ||
      this.$store.getters.currentUser.user_type_id == 6
    ) {
      // this.emJobCount();
      this.getDPFTEmpJobCount();
      this.getbillingdata();
      // this.pollData3();
      this.isActiveUserCheck();
      this.getDynamicCoinValEm();
      this.getElCoins();
      this.getContactSupportEmpFlMesaageCount();
    }

    if (this.$store.getters.currentUser.user_type_id == 5) {
      // this.flJobCount();
      this.getDPFTFLJobCount();
      // this.pollData2();
      this.isActiveUserCheck();
      // this.userData();
      this.getcertdata1();
      this.getContactSupportEmpFlMesaageCount();
    }

    if (
      this.$store.getters.currentUser.user_type_id == 1 ||
      this.$store.getters.currentUser.user_type_id == 2
    ) {
      // this.flJobCount();
      this.getUsersCount();
      this.getPendingHoldPayoutCount();
      this.getAdminSupportCount();
      // this.pollData();
    }
    if (this.$store.getters.currentUser.user_type_id == 2) {
      this.isActiveUserCheck();
    }
  },
};
</script>
