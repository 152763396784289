<template>
  <div class="animated fadeIn">
    <div class="manage-job-section">
      <div
        class="black-strip-head d-lg-flex d-sm-flex align-items-center padding_top_25 manage_job_top_anchor new_mobile_display_block justify-content-between"
      >
        <div class="dashboard-headline">
          <h1 class="mr-auto">Company Log</h1>
        </div>
        <!-- <div class="top_black_btn margin_left_15_desktop mobile_text_start">
                <b-button v-if="show('export')" class="common_z_index_zero button ripple-effect add_btn_admin_e  desktop_margin_right_zero" @click="exportData()" style="margin-left: 19px;">Export</b-button>
          </div> -->
      </div>
    </div>
    <div v-if="userLog">
      <div
        class="sidebar-widget single-page-section common_side_bar_sections_margin jobseeker_dp_sidebar"
        v-if="userLog.length > 0"
      >
        <div class="job-overview">
          <div class="job-overview-headline">
            <h3 class="font_weight_600 common_margin_bottom_zero mobile_font_16">Company Log</h3>
          </div>
          <span v-if="userLog.length > 0">
            <ul
              v-if="userLog.length > 0"
              class="common_padding_30 desktop_margin_bottom_zero timeline_activity"
            >
              <li v-for="logs in userLog" class="logs_last_p" style="margin-left: 17px">
                <div class="timeline_circle"></div>
                <div class="timeline_text">
                  <p v-if="logs.process == 'employer_account_verification_status_approved'">
                    <span class="timeline_span_date">{{ logs.created_at | momentLog }}</span
                    ><b class="green_timeline_text">Approved By:</b>
                    {{ logs.editor_first_name | capitalize }}
                    {{ logs.editor_last_name | capitalize }} <br /><span
                      v-if="JSON.parse(logs.process_log).reason"
                      ><b>Reason:</b>
                      {{
                        JSON.parse(logs.process_log).reason
                          ? JSON.parse(logs.process_log).reason
                          : JSON.parse(logs.process_log).deactivate_reason | removeUnderscore
                      }}</span
                    >
                  </p>
                  <p v-else-if="logs.process == 'activate_employer'">
                    <span class="timeline_span_date">{{ logs.created_at | momentLog }} </span
                    ><b class="green_timeline_text">Activated By:</b>
                    {{ logs.editor_first_name | capitalize }}
                    {{ logs.editor_last_name | capitalize }} <br />
                    <!-- <b class="remarks_timeline_color">Remarks:</b>
                    {{ JSON.parse(logs.process_log).remarks }} -->
                  </p>
                  <p v-else-if="logs.process == 'inactivate_employer'">
                    <span class="timeline_span_date">{{ logs.created_at | momentLog }}</span
                    ><b class="red_timeline_text">Inactivated By:</b>
                    {{ logs.editor_first_name | capitalize }} {{ logs.editor_last_name | capitalize
                    }}<br />
                    <!-- <b class="remarks_timeline_color">Remarks:</b>
                    {{ JSON.parse(logs.process_log).remarks }} -->
                  </p>
                  <p v-else-if="logs.process == 'employer_account_verification_status_rejected'">
                    <span class="timeline_span_date">{{ logs.created_at | momentLog }}</span
                    ><b class="green_timeline_text">Rejected By:</b>
                    {{ logs.editor_first_name | capitalize }}
                    {{ logs.editor_last_name | capitalize
                    }}<span v-if="JSON.parse(logs.process_log).reason"
                      ><b>Reason:</b>
                      {{
                        JSON.parse(logs.process_log).reason
                          ? JSON.parse(logs.process_log).reason
                          : JSON.parse(logs.process_log).deactivate_reason | removeUnderscore
                      }}</span
                    >
                  </p>
                  <p v-else-if="logs.process == 'mail_subscribed'">
                    <span class="timeline_span_date">{{ logs.created_at | momentLog }}</span
                    ><b class="green_timeline_text">Mailing List Subscribed By:</b>
                    {{ logs.editor_first_name | capitalize }}
                    {{ logs.editor_last_name | capitalize }}
                  </p>
                  <p v-else-if="logs.process == 'mail_unsubscribed'">
                    <span class="timeline_span_date">{{ logs.created_at | momentLog }}</span
                    ><b class="red_timeline_text">Mailing List Unsubscribed By:</b>
                    {{ logs.editor_first_name | capitalize }}
                    {{ logs.editor_last_name | capitalize }}
                  </p>

                  <!-- <p v-else-if="logs.process == 'delete_jobseeker_web'">
                    <span class="timeline_span_date">{{ logs.created_at | momentLog }}</span
                    ><b class="red_timeline_text">Deleted By:</b>
                    {{ logs.editor_first_name | capitalize }}
                    {{ logs.editor_last_name | capitalize }} <br />
                    <b>Reason:</b>
                    {{
                      JSON.parse(logs.process_log).remarks
                        ? JSON.parse(logs.process_log).remarks
                        : JSON.parse(logs.process_log).reason | removeUnderscore
                    }}
                  </p>
                  <p v-else-if="logs.process == 'delete_jobseeker_app'">
                    <span class="timeline_span_date">{{ logs.created_at | momentLog }}</span
                    ><b class="red_timeline_text">Deleted By:</b>
                    {{ logs.editor_first_name | capitalize }}
                    {{ logs.editor_last_name | capitalize }} <br />
                    <b>Reason:</b>
                    {{
                      JSON.parse(logs.process_log).remarks
                        ? JSON.parse(logs.process_log).remarks
                        : JSON.parse(logs.process_log).reason | removeUnderscore
                    }}
                  </p> -->

                  <p v-else-if="logs.process == 'blacklist_employer'">
                    <span class="timeline_span_date">{{ logs.created_at | momentLog }}</span
                    ><b class="red_timeline_text">Blacklisted By:</b>
                    {{ logs.editor_first_name | capitalize }}
                    {{ logs.editor_last_name | capitalize }} <br />
                    <b class="remarks_timeline_color">Remarks:</b>
                    {{ JSON.parse(logs.process_log).remarks }}
                  </p>
                  <p v-else-if="logs.process == 'reactivate_employer'">
                    <span class="timeline_span_date">{{ logs.created_at | momentLog }}</span
                    ><b class="red_timeline_text">Reactivated By:</b>
                    {{ logs.editor_first_name | capitalize }}
                    {{ logs.editor_last_name | capitalize }} <br />
                    <b class="remarks_timeline_color">Remarks:</b>
                    {{ JSON.parse(logs.process_log).remarks }}
                  </p>
                  <p v-else-if="logs.process == 'updated_employer_profile'">
                    <span class="timeline_span_date">{{ logs.created_at | momentLog }}</span
                    ><b class="red_timeline_text">Edited By:</b>
                    {{ logs.editor_first_name | capitalize }}
                    {{ logs.editor_last_name | capitalize }}
                    {{ JSON.parse(logs.process_log) }}
                  </p>

                  <p v-else-if="logs.process == 'employer_com_user_created'">
                    <span class="timeline_span_date">{{ logs.created_at | momentLog }}</span
                    ><b class="red_timeline_text">Created By:</b>
                    {{ logs.editor_first_name | capitalize }}
                    {{ logs.editor_last_name | capitalize }}
                    <br />
                    <b class="red_timeline_text">Company User: </b>
                    <b>
                      {{
                        JSON.parse(logs.process_log).user_type_id == 4 ? "(Supervisor)" : "(OM)"
                      }}</b
                    >
                    {{ logs.company_user_first_name | capitalize }}
                    {{ logs.company_user_last_name | capitalize }}
                  </p>
                  <p v-else-if="logs.process == 'employer_com_user_updated'">
                    <span class="timeline_span_date">{{ logs.created_at | momentLog }}</span
                    ><b class="red_timeline_text">Updated By:</b>
                    {{ logs.editor_first_name | capitalize }}
                    {{ logs.editor_last_name | capitalize }}
                    <br />
                    <b class="red_timeline_text">Company User: </b>
                    <b>
                      {{
                        JSON.parse(logs.process_log).user_type_id == 4 ? "(Supervisor)" : "(OM)"
                      }}</b
                    >
                    {{ logs.companmy_user_first_name | capitalize }}
                    {{ logs.company_user_last_name | capitalize }}
                    {{ JSON.parse(logs.process_log) }}
                  </p>
                </div>
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { POST_API, GET_API } from "../../../store/actions.type";
import JwtService from "../../../common/jwt.service";
import moment from "moment";
import Vue from "vue";
import permission from "../../../../../server/permission.js";

export default {
  data() {
    return {
      form: {
        rowsPerPage: 50,
      },
      items: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      sortBy: null,
      sortDirection: "desc",
      filter: null,
      status: "active",
      isBusy: false,
      sortDesc: true,
      to: "",
      from: "",
      dat: new Date(),
      webUrl: process.env.MIX_APP_URL,
      actions: "",
      user_type_id: "",
      startDate: new Date(moment().format("YYYY-MM-DD")),
      defaultDate: moment().subtract(1, "month").format("DD MMM YYYY"),
      disabledFromDate: {
        from: new Date(moment().subtract(1, "month")),
      },
      disabledFromDate1: {
        to: new Date(),
      },
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      actionsEmployer: "",
      searchKeyword: "",
      emp_id: atob(this.$route.params.id) ? atob(this.$route.params.id) : "",
      company_id: atob(this.$route.params.company_id) ? atob(this.$route.params.company_id) : "",

      userLog: "",
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    momentLog: function (date) {
      if (date != null) {
        return moment(date).utc().format("DD-MM-YYYY hh:mm A");
      } else {
        return "";
      }
    },
    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
  },
  methods: {
    getEmpLog() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.emp_id,
            company_id: this.company_id,
          },
          api: "/api/emp-log-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.userLog = this.$store.getters.getResults.data;
          }
        });
    },

    showAlert() {
      // window.scrollTo(0,0);
      this.dismissCountDown = this.dismissSecs;
      this.$swal({
        position: "top-center",
        type: "error",
        text: this.error_message,
        showConfirmButton: false,
        timer: 3000,
      });
    },

    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Employer Balance");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showEmployer(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsEmployer.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    this.user_type_id = this.$store.getters.currentUser.user_type_id;
    this.permissionStaff();
    this.getEmpLog();
  },
};
</script>
