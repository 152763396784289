<template>
  <div class="animated fadeIn create_footer desktop_padding_bottom_zero">
    <div class="create-new-job-section email-template-section">
      <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Edit Reward</h1 >
            </div> -->
      <!-- <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit Reward</h1 >
            </div> -->
      <div id="breadcrumb-v2" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb :items="breadCrumb()" />
        </div>
      </div>
      <div
        class="edit-profile-section border-radius-4 white-bg  common_padding_30"
      >
        <b-form>
          <div class="edit-form-box desktop_margin_top_zero">
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="3"  class="submit-field">
                  <div class="upload-form-group">
                    <h5 id="title-group" class="required">Company Logo</h5>
                    <b-form-group id="link-group" class="required mb-0">
                      <div
                        class="single-image-upload my_single_img common_pointer_class emp_img_one height_unset_desktop mobile_max_width_image"
                        :class="form.companyLogo.image ? 'if_image_exist' : 'if_image_not_exist'"
                      >
                        <b-form-file
                          class="customUpload form-control-file"
                          name="Image"
                          accept="image/*"
                          ref="coverimage"
                          v-model="form.company_logo"
                        ></b-form-file>
                        <span
                          v-bind:style="
                            form.companyLogo.image
                              ? 'background-color: #fff !important;'
                              : 'background-color:#F2FBFC !important;'
                          "
                        >
                          <b-img
                            v-if="form.companyLogo.image"
                            :src="form.companyLogo.image"
                            alt="cover-image"
                            class="actual_img"
                          ></b-img>
                          <b-img
                            v-else
                            :src="webUrl + 'assets/images/upload-icon.svg'"
                            alt="image"
                            class="dummy_img"
                          ></b-img>
                        </span>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="3"  class="submit-field">
                  <div class="upload-form-group">
                    <h5 id="title-group" class="required">Reward Poster</h5>
                    <b-form-group id="link-group" class="required mb-0">
                      <div
                        class="single-image-upload my_single_img common_pointer_class emp_img_one height_unset_desktop mobile_max_width_image"
                        :class="
                          form.selectedBannerImage.image ? 'if_image_exist' : 'if_image_not_exist'
                        "
                      >
                        <b-form-file
                          class="customUpload form-control-file"
                          name="Image"
                          accept="image/*"
                          ref="image"
                          v-model="form.banner_image"
                        ></b-form-file>
                        <span
                          v-bind:style="
                            form.selectedBannerImage.image
                              ? 'background-color: #fff !important;'
                              : 'background-color:#F2FBFC !important;'
                          "
                        >
                          <!-- <b-img v-if='form.selectedBannerImage' :src="form.selectedBannerImage.image ? form.selectedBannerImage.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 300px;" alt="image"></b-img> -->
                          <b-img
                            v-if="form.selectedBannerImage.image"
                            :src="form.selectedBannerImage.image"
                            alt="image"
                            class="actual_img"
                          ></b-img>
                          <b-img
                            v-else
                            :src="webUrl + 'assets/images/upload-icon.svg'"
                            alt="image"
                            class="dummy_img"
                          ></b-img>
                        </span>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
                <div class="col-xl-6">
                  <b-col lg="12"  class="submit-field pl-0 pr-0">
                    <h5 id="title-group" class="required">Company Name</h5>
                    <b-form-input
                      id="title"
                      v-model="form.company_name"
                      type="text"
                      :disabled="true"
                      placeholder="Enter Company Name.."
                      class="with-border mb-0"
                    ></b-form-input>
                  </b-col>
                  <b-col lg="12"  class="submit-field pl-0 pr-0">
                    <h5 id="title-group" class="required">Title</h5>
                    <b-form-input
                      id="title"
                      v-model="form.title"
                      :disabled="true"
                      type="text"
                      placeholder="Enter Title.."
                      class="with-border mb-0"
                    ></b-form-input>
                  </b-col>
                </div>
              </b-row>
              <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5 id="title-group" class="required">Description</h5>
                  <b-form-textarea :disabled="true" v-model="form.description" class="mb-0"></b-form-textarea>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5 id="title-group" class="required">T&C</h5>
                  <b-form-textarea
                    :disabled="true"
                    v-model="form.terms_and_conditions"
                    class="mb-0"
                  ></b-form-textarea>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5 id="title-group" class="required">Merchant Location</h5>
                  <b-form-textarea
                    id="address-box"
                    v-model="form.addressArr"
                    :disabled="true"
                    placeholder="Enter Merchant Location.."
                    rows="3"
                    class="mb-0"
                  ></b-form-textarea>
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="6" md="6" class="submit-field">
                  <h5 id="title-group" class="">Start Date</h5>
                  <b-form-group id="title-group" label="" class="required">
                    <datepicker
                      v-model="form.start_date"
                      :format="customFormatterOne"
                      :disabled="true"
                      :disabled-dates="disabledStartDate()"
                      class="ticket_close_btn_from_date"
                      :class="form.start_date ? 'common_date_disabled_exist' : 'common__date_disabled_not_exist'"
                    ></datepicker>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6" class="submit-field">
                  <h5 id="title-group" class="">End Date</h5>
                  <b-form-group id="title-group" label="" class="required">
                    <datepicker
                      v-model="form.end_date"
                      :format="customFormatterOne"
                      :disabled-dates="disabledEndDate()"
                      :disabled="true"
                      class="ticket_close_btn_from_date"
                      :class="form.start_date ? 'common_date_disabled_exist' : 'common__date_disabled_not_exist'"
                    ></datepicker>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <!-- <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
              <b-button type="submit" variant="success" class="desktop_margin_left_zero"
                >Submit</b-button
              >
              <b-button :to="{ name: 'reward-list' }" variant="light">Cancel</b-button>
            </div> -->
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import CKEditor from "ckeditor4-vue";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      form: {
        title: "",
        description: "",
        company_name: "",
        terms_and_conditions: "",
        address: "",
        start_date: "",
        end_date: "",

        banner_image: "",
        selectedBannerImage: {
          name: "",
          image: "",
        },
        company_logo: "",
        companyLogo: {
          name: "",
          image: "",
        },

        addressArr: [{ address: "" }],
      },
      items: null,
      // id: Vue.prototype.decrypt(this.$route.params.id),
      id: atob(this.$route.params.id),
      error_message: "",
      success_message: "",
      dismissSecs: 3,
      webUrl: process.env.VUE_APP_URL,
      data: null,
    };
  },
  components: {
    ckeditor: CKEditor.component,
    VueCropper,
    Datepicker,
    Multiselect,
  },

  methods: {
    breadCrumb() {
      var item = [
        {
          to: { name: "emp-reward-list" },
          text: "Rewards",
        },
        {
          to: null,
          text: "Reward",
        },
      ];
      return item;
    },

    customFormatterOne(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    disabledStartDate() {
      const minStartDate = new Date();
      minStartDate.setDate(minStartDate.getDate() - 1);
      return { to: minStartDate };
    },
    disabledEndDate() {
      if (!this.form.start_date) {
        return { to: new Date() };
      } else {
        var minEndDate = new Date(this.form.start_date);
        minEndDate.setDate(minEndDate.getDate());
        return {
          to: minEndDate,
        };
      }
    },

    getEditData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            status: this.status,
            page: this.currentPage,
            keyword: this.filter,
            id: this.id,
          },
          api: "/api/reward-edit",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.form.title = this.$store.getters.getResults.data.title;
            this.form.description = this.$store.getters.getResults.data.description
              ? this.$store.getters.getResults.data.description.replace(/<\/?[^>]+(>|$)/g, "")
              : "";
            this.form.company_name = this.$store.getters.getResults.data.company_name;
            //             const termsString = "<p>sdfsd</p>";
            // this.form.terms_and_conditions = termsString.replace(/<\/?[^>]+(>|$)/g, "");
            this.form.terms_and_conditions = this.$store.getters.getResults.data
              .terms_and_conditions
              ? this.$store.getters.getResults.data.terms_and_conditions.replace(
                  /<\/?[^>]+(>|$)/g,
                  ""
                )
              : "";
            this.form.selectedBannerImage.image = this.$store.getters.getResults.data.banner_image;
            this.form.companyLogo.image = this.$store.getters.getResults.data.company_logo;

            this.form.start_date = this.$store.getters.getResults.data.start_date;
            this.form.end_date = this.$store.getters.getResults.data.end_date
              ? this.$store.getters.getResults.data.end_date
              : "";

            const addressString = this.$store.getters.getResults.data.address;
            const addressArray = JSON.parse(addressString);
            this.form.addressArr = addressArray.map((address) => `• ${address}`).join("\n");
          }
        });
    },
  },
  mounted() {
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    // document.title = "EL Connect - Admin - Website Contents - Edit Reward";
    this.getEditData();
  },
};
</script>

<style>
.submit-field {
  margin-bottom: 1rem;
}
.is-invalid {
  border-color: #dc3545;
}
.invalid-feedback {
  display: block;
  color: #dc3545;
}
</style>
