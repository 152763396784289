<template>
  <div class="bg_lucky_draw" style="background-color: #4C8BF5;">
    <LuckyDrawHeaderComponent />
      <!-- <ChitHeaderImageComponent /> -->
      <b-container class="common_lucky_draw_container">
          <!-- <img :src="webUrl + 'assets/jobeekerImages/Group 81.png'" class="bkgselect_img">
          <img :src="webUrl + 'assets/jobeekerImages/Path 10.png'" style="z-index: 0;position:absolute;left: 100px;top: 10px;" class="bkg_results">
          <img :src="webUrl + 'assets/jobeekerImages/Path 10.png'" style="z-index: 0;position:absolute;transform: scaleX(-1 );top: 10px;left: 818px;" class="bkg_results2"> -->
          
          <img :src="webUrl + 'assets/jobeekerImages/Group 133.png'" class="congratulations_img elucky_draw_desktop">
          <img :src="webUrl + 'assets/jobeekerImages/Frame-1949.png'" class="congratulations_img elucky_draw_mobile">
          <!-- <img :src="webUrl + 'assets/jobeekerImages/cornerlogo.png'" class="corner_logor"> -->
          <!-- <img :src="webUrl + 'assets/jobeekerImages/Group 120.png'" class="congratulations_img_mobile"> -->
          <div class="two_penguin_result">
            <div class="right_penguin_img_lucky_draw elucky_draw_desktop">
                <img :src="webUrl + 'assets/jobeekerImages/mascot-left.png'">
                <!-- <span><b>"Congratulations on your winnings!
                    We will contact you soon and liase
                    with you on how to collect your prizes.</b>"
                </span> -->
            </div>
            <div class="whats_app_bag"
                :style="{ backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/Group60.png' + ')' }">

                <div class="result_draw">
                    <p class="results"> ${{ result }}</p>
                    <img :src="webUrl+'assets/jobeekerImages/mascot-left.png'" class="mascot_result_img result_1 elucky_draw_mobile">
                    <img :src="webUrl+'assets/jobeekerImages/mascot-left.png'" class="mascot_result_img result_2 elucky_draw_mobile">
                </div>
                <div class="whatsapp_link_lucky_draw">
                        <span>We will contact you soon</span>
                </div>
                
            </div>
            
            <div class="left_penguin_img_lucky_draw elucky_draw_desktop">
                <img :src="webUrl + 'assets/jobeekerImages/mascot-left.png'">
            </div>
           
          </div>
           <p class="black_term_result">*Term & condition applies.</p>
      </b-container>
  </div>
</template>

<script>
import { POST_API, GET_API } from '../../../store/actions.type'
import LuckyDrawHeaderComponent from './LuckyDrawHeaderComponent.vue';
import ChitHeaderImageComponent from './ChitHeaderImageComponent.vue';

export default {
  data() {
      return {
          webUrl: process.env.VUE_APP_URL,
          result: 0,
          id: atob(this.$route.params.id),
      }
  },
  components: {
      LuckyDrawHeaderComponent,
      ChitHeaderImageComponent
  },
  methods: {
      sendEmail() {
          return this.$store.dispatch(POST_API, {
              data: {
                  id: this.id,
              },
              api: '/api/elucky-draw-sendEmail'
          })
              .then(() => {
                  // console.log("Whatsapp message sent")
              })
              .catch(function (err) {
                  console.log(err)
              })
      }
  },
  mounted() {
      this.result = atob(this.$route.params.amount);
      this.sendEmail();
      history.pushState(null, null, window.location.href);
      history.back();
      window.onpopstate = () => history.forward();
  }
}
</script>

<style></style>
