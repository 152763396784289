<template>
  <div class="animated fadeIn create_footer desktop_padding_bottom_zero">
    <div class="create-new-job-section email-template-section">
      <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Edit Reward</h1 >
            </div> -->
      <!-- <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Add Reward</h1 >
            </div> -->
      <div id="breadcrumb-v2" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb :items="breadCrumb()" />
        </div>
      </div>
      <div
        class="edit-profile-section border-radius-4 white-bg commmon_label_heading common_padding_30"
      >
        <b-form @submit="formSubmit">
          <div class="edit-form-box desktop_margin_top_zero">
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <div class="submit-field">
                    <h5>Company <span class="required_star">*</span></h5>
                    <b-form-group id="input-group-8" class="required">
                      <multiselect
                        id="ajax"
                        v-model="form.company_id"
                        :custom-label="customLabel"
                        tag-placeholder="Add this as new Company"
                        placeholder="Enter to search Company"
                        :options="companies"
                        label="full_name"
                        track-by="company_id"
                        :multiple="false"
                        :searchable="true"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :allow-empty="false"
                        deselect-label="Can't remove this value"
                        class="job_list_multi_select model_multi_select"
                        ><span slot="noResult">No Company Found.</span></multiselect
                      >
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="3" md="3" class="submit-field">
                  <div class="upload-form-group">
                    <h5 id="title-group" class="required">
                      Company Logo <span class="required_star">*</span>
                    </h5>
                    <b-form-group id="link-group" class="required">
                      <div
                        class="single-image-upload my_single_img common_pointer_class emp_img_one height_unset_desktop mobile_max_width_image"
                        :class="form.companyLogo.image ? 'if_image_exist' : 'if_image_not_exist'"
                      >
                        <b-form-file
                          class="customUpload form-control-file"
                          name="Image"
                          accept="image/*"
                          @change="onCompanyLogoChange"
                          ref="coverimage"
                          v-model="form.company_logo"
                        ></b-form-file>
                        <span
                          v-bind:style="
                            form.companyLogo.image
                              ? 'background-color: #fff !important;'
                              : 'background-color:#F2FBFC !important;'
                          "
                        >
                          <b-img
                            v-if="form.companyLogo.image"
                            :src="form.companyLogo.image"
                            alt="cover-image"
                            class="actual_img"
                          ></b-img>
                          <b-img
                            v-else
                            :src="webUrl + 'assets/images/upload-icon.svg'"
                            alt="image"
                            class="dummy_img"
                          ></b-img>
                        </span>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="9" md="9" class="submit-field">
                  <h5 id="title-group" class="required">
                    Company Name <span class="required_star">*</span>
                  </h5>
                  <b-form-input
                    id="title"
                    v-model="form.company_name"
                    type="text"
                    placeholder="Enter Company Name"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5 id="title-group" class="required">
                    Title <span class="required_star">*</span>
                  </h5>
                  <b-form-input
                    id="title"
                    v-model="form.title"
                    type="text"
                    placeholder="Enter Title"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5 id="title-group" class="required">
                    Description <span class="required_star">*</span>
                  </h5>
                  <ckeditor v-model="form.description" :config="editorConfigDesc"></ckeditor>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5 id="title-group" class="required">
                    Terms and Conditions <span class="required_star">*</span>
                  </h5>
                  <ckeditor v-model="form.terms_and_conditions" :config="editorConfig"></ckeditor>
                </b-col>
              </b-row>
              <!-- <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5 id="title-group" class="required">
                    Merchant Location<span class="required_star">*</span>
                  </h5>
                  <b-form-textarea
                    id="title"
                    v-model="form.address"
                    type="textarea"
                    placeholder="Enter Merchant Location.."
                  ></b-form-textarea>
                </b-col>
              </b-row> -->
              <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5 id="title-group" class="required">
                    Merchant Location <span class="required_star">*</span>
                  </h5>
                  <div
                    v-for="(addressObj, index) in form.addressArr"
                    :key="index"
                    class="loop_one_rem"
                  >
                    <b-row>
                      <b-col
                        lg="9"
                        md="9"
                        class="mobile_max_width_and_flex big_screen_max_width_and_flex"
                      >
                        <b-form-textarea
                          :id="'address-' + index"
                          v-model="addressObj.address"
                          placeholder="e.g., 745 Lorong 5 Toa Payoh ..."
                          rows="3"
                          :class="{ 'is-invalid': addressErrors[index] }"
                        ></b-form-textarea>
                        <div v-if="addressErrors[index]" class="invalid-feedback">
                          {{ addressErrors[index] }}
                        </div>
                      </b-col>
                      <b-col
                        lg="3"
                        md="3"
                        class="mobile_max_width_and_flex submit-field big_screen_max_width_and_flex one_thousand_margin_bottom_zero new_mobile_margin_bottom_zero"
                        v-if="index !== 0"
                      >
                        <b-button
                          v-if="index !== 0"
                          variant="danger"
                          @click="removeAddress(index)"
                          class="ml-0 mr-0 ripple-effect mobile_anchore_set common_vertical_align_bottom button ripple-effect reward_margin"
                          style="background-color: red"
                        >
                          <span class="add_plus">x</span> Remove
                        </b-button>
                      </b-col>
                      <b-col
                        lg="3"
                        md="3"
                        class="mobile_max_width_and_flex submit-field big_screen_max_width_and_flex one_thousand_margin_bottom_zero new_mobile_margin_bottom_zero"
                        v-if="index == 0"
                      >
                        <b-button
                          variant="primary"
                          @click="addAddress"
                          class="ml-0 mr-0 reward_margin mobile_anchore_set common_vertical_align_bottom button ripple-effect"
                        >
                          <span class="add_plus">+</span> Add more
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
              <!-- <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5 id="title-group" class="required">
                    Total Quantity <span class="required_star">*</span>
                  </h5>
                  <b-form-input
                    id="title"
                    v-model="form.total_quantity"
                    type="number"
                    min="1"
                    max="100000"
                    placeholder="Enter Total Quantity.."
                  ></b-form-input>
                </b-col>
              </b-row> -->
              <!-- <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5 id="title-group" class="required">
                    Requirment <span class="required_star">*</span>
                  </h5>
                  <b-form-textarea
                    id="title"
                    v-model="form.requirement"
                    type="textarea"
                    placeholder="Enter Requirment.."
                  ></b-form-textarea>
                </b-col>
              </b-row> -->

              <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5 id="title-group">Claim Conditions</h5>
                  <b-form-textarea
                    id="title"
                    v-model="form.claim_conditions"
                    type="textarea"
                    placeholder="e.g., with min $10 spend"
                  ></b-form-textarea>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6" md="6">
                  <b-form-group id="title-group" label="Start Date" class="required">
                    <datepicker
                      v-model="form.start_date"
                      :format="customFormatterOne"
                      :disabled-dates="disabledStartDate()"
                      clear-button=""
                      class="ticket_close_btn_from_date"
                      @input="onChaneStartDate"
                    ></datepicker>
                    <span class="input-icon calender_input_icon obtainment_date_calender"
                      ><b-img
                        :src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                        alt="Calander Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6">
                  <b-form-group id="title-group" label="End Date" class="required">
                    <datepicker
                      v-model="form.end_date"
                      :format="customFormatterOne"
                      :disabled-dates="disabledEndDate()"
                      clear-button=""
                      class="ticket_close_btn_from_date"
                    ></datepicker>
                    <span class="input-icon calender_input_icon obtainment_date_calender"
                      ><b-img
                        :src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                        alt="Calander Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5 id="title-group" class="required">QR Code</h5>
                  <b-form-input
                    id="title"
                    v-model="form.qrcode"
                    type="text"
                    placeholder="Enter QR Code.."
                  ></b-form-input>
                </b-col>
              </b-row> -->
              <!-- <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5 id="title-group" class="required">QR Code Link</h5>
                  <b-form-input
                    id="title"
                    v-model="form.qrcode_link"
                    type="text"
                    placeholder="Enter QR Code Link.."
                  ></b-form-input>
                </b-col>
              </b-row> -->
              <!-- <b-row>
                <b-col lg="4" md="4" class="submit-field">
                  <h5 id="type-group" class="required">
                    Expiry Date <span class="required_star">*</span>
                  </h5>
                  <datepicker
                    v-model="form.expiry_date"
                    :format="customFormatterOne"
                    :disabled-dates="disabledFromDate"
                    class="certificate_date_picker"
                  ></datepicker>
                </b-col>
                <b-col lg="4" md="4" class="submit-field">
                  <h5 id="title-group" class="required">
                    Total Quantity <span class="required_star">*</span>
                  </h5>
                  <b-form-input
                    id="title"
                    v-model="form.total_quantity"
                    type="number"
                    min="1"
                    max="100000"
                    placeholder="Enter Total Quantity.."
                  ></b-form-input>
                </b-col>
                <b-col lg="4" md="4" class="submit-field">
                  <h5 id="title-group" class="required">
                    Reduced Quantity <span class="required_star">*</span>
                  </h5>
                  <b-form-input
                    id="title"
                    v-model="form.reduced_quantity"
                    type="number"
                    min="1"
                    max="100000"
                    placeholder="Enter Reduced Quantity.."
                  ></b-form-input>
                </b-col>
              </b-row> -->
              <b-row>
                <b-col lg="3" md="3" class="submit-field">
                  <div class="upload-form-group">
                    <h5 id="title-group" class="required">
                      Upload Banner Image (1180px x 720px) <span class="required_star">*</span>
                    </h5>
                    <b-form-group id="link-group" class="required">
                      <div
                        class="single-image-upload my_single_img common_pointer_class emp_img_one height_unset_desktop mobile_max_width_image"
                        :class="
                          form.selectedBannerImage.image ? 'if_image_exist' : 'if_image_not_exist'
                        "
                      >
                        <b-form-file
                          class="customUpload form-control-file"
                          name="Image"
                          accept="image/*"
                          @change="onImageChangeBanner"
                          ref="image"
                          v-model="form.banner_image"
                        ></b-form-file>
                        <span
                          v-bind:style="
                            form.selectedBannerImage.image
                              ? 'background-color: #fff !important;'
                              : 'background-color:#F2FBFC !important;'
                          "
                        >
                          <!-- <b-img v-if='form.selectedBannerImage' :src="form.selectedBannerImage.image ? form.selectedBannerImage.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 300px;" alt="image"></b-img> -->
                          <b-img
                            v-if="form.selectedBannerImage.image"
                            :src="form.selectedBannerImage.image"
                            alt="image"
                            class="actual_img"
                          ></b-img>
                          <b-img
                            v-else
                            :src="webUrl + 'assets/images/upload-icon.svg'"
                            alt="image"
                            class="dummy_img"
                          ></b-img>
                        </span>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
                <!-- <b-col lg="3" md="3" class="submit-field">
                  <div class="upload-form-group">
                    <h5 id="title-group" class="required">
                      Upload QR Code Image <span class="required_star">*</span>
                    </h5>
                    <b-form-group id="link-group" class="required">
                      <div
                        class="single-image-upload my_single_img common_pointer_class emp_img_one height_unset_desktop mobile_max_width_image"
                        :class="
                          form.selectedQRCodeImage.image ? 'if_image_exist' : 'if_image_not_exist'
                        "
                      >
                        <b-form-file
                          class="customUpload form-control-file"
                          name="Image"
                          accept="image/*"
                          @change="onImageChangeQRCode"
                          ref="image"
                          v-model="form.qrcode_image"
                        ></b-form-file>
                        <span
                          v-bind:style="
                            form.selectedQRCodeImage.image
                              ? 'background-color: #fff !important;'
                              : 'background-color:#F2FBFC !important;'
                          "
                        >
                          <b-img
                            v-if="form.selectedQRCodeImage.image"
                            :src="form.selectedQRCodeImage.image"
                            alt="image"
                            class="actual_img"
                          ></b-img>
                          <b-img
                            v-else
                            :src="webUrl + 'assets/images/upload-icon.svg'"
                            alt="image"
                            class="dummy_img"
                          ></b-img>
                        </span>
                      </div>
                    </b-form-group>
                  </div>
                </b-col> -->
              </b-row>
            </div>
            <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
              <b-button type="submit" variant="success" class="desktop_margin_left_zero"
                >Submit</b-button
              >
              <b-button :to="{ name: 'reward-list' }" variant="light">Cancel</b-button>
            </div>
          </div>
        </b-form>
        <b-modal
          ref="crop-modal-banner"
          title="Edit Banner Image"
          hide-footer
          :no-close-on-backdrop="noClose"
          content-class="common_model_header common_model_title"
          modal-class="background_custom_class"
        >
          <b-form @submit="onSubmitBannerImg">
            <template>
              <div>
                <div class="content" @change="setBannerImage">
                  <section class="cropper-area">
                    <div class="img-cropper">
                      <vue-cropper
                        ref="cropper"
                        :aspect-ratio="0 / 0"
                        :src="tempLogo"
                        preview=".preview"
                      />
                    </div>
                  </section>
                </div>
              </div>
              <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                <b-button
                  variant="success"
                  class="text-uppercase x-md btn-shadow"
                  @click.prevent="cropBannerImageFun"
                  >Crop</b-button
                >
                <b-button
                  variant="danger"
                  class="text-uppercase x-md btn-shadow"
                  @click.prevent="reset"
                  >Reset</b-button
                >
              </div>
            </template>
          </b-form>
        </b-modal>

        <b-modal
          ref="crop-modal-company-logo"
          title="Edit Company Logo"
          hide-footer
          :no-close-on-backdrop="noClose"
          content-class="common_model_header common_model_title"
          modal-class="background_custom_class"
        >
          <b-form @submit="onSubmitCompanyLogo">
            <template>
              <div>
                <div class="content" @change="setCompanyLogo">
                  <section class="cropper-area">
                    <div class="img-cropper">
                      <vue-cropper
                        ref="cropper"
                        :aspect-ratio="0 / 0"
                        :src="tempLogo"
                        preview=".preview"
                      />
                    </div>
                  </section>
                </div>
              </div>
              <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                <b-button
                  variant="success"
                  class="text-uppercase x-md btn-shadow"
                  @click.prevent="cropCompanyLogoFun"
                  >Crop</b-button
                >
                <b-button
                  variant="danger"
                  class="text-uppercase x-md btn-shadow"
                  @click.prevent="reset"
                  >Reset</b-button
                >
              </div>
            </template>
          </b-form>
        </b-modal>
        <b-modal
          ref="crop-modal-qrcode-image"
          title="Edit QR Code Image"
          hide-footer
          :no-close-on-backdrop="noClose"
          content-class="common_model_header common_model_title"
          modal-class="background_custom_class"
        >
          <b-form @submit="onSubmitQRCodeImage">
            <template>
              <div>
                <div class="content" @change="setQRCodeImage">
                  <section class="cropper-area">
                    <div class="img-cropper">
                      <vue-cropper
                        ref="cropper"
                        :aspect-ratio="0 / 0"
                        :src="tempLogo"
                        preview=".preview"
                      />
                    </div>
                  </section>
                </div>
              </div>
              <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                <b-button
                  variant="success"
                  class="text-uppercase x-md btn-shadow"
                  @click.prevent="cropQRCodeImageFun"
                  >Crop</b-button
                >
                <b-button
                  variant="danger"
                  class="text-uppercase x-md btn-shadow"
                  @click.prevent="reset"
                  >Reset</b-button
                >
              </div>
            </template>
          </b-form>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import CKEditor from "ckeditor4-vue";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      form: {
        title: "",
        description: "",
        company_name: "",
        requirement: "",
        terms_and_conditions: "",
        address: "",
        claim_conditions: "",
        start_date: "",
        end_date: "",
        expiry_date: "",
        qrcode: "",
        qrcode_link: "",
        total_quantity: "",
        reduced_quantity: "",

        banner_image: "",
        selectedBannerImage: {
          name: "",
          image: "",
        },
        company_logo: "",
        companyLogo: {
          name: "",
          image: "",
        },
        qrcode_image: "",
        selectedQRCodeImage: {
          name: "",
          image: "",
        },
        addressArr: [{ address: "" }],
        company_id: "",
      },
      items: null,
      error_message: "",
      success_message: "",
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,

      delta: undefined,
      editorConfig: {
        versionCheck: false,
        removeButtons: "Maximize",
        extraPlugins: "copyformatting,colorbutton,bidi,colordialog,widget,justify",
        allowedContent: true,
      },
      editorConfigDesc: {
        versionCheck: false,
        removeButtons: "Maximize",
        extraPlugins: "copyformatting,colorbutton,bidi,colordialog,widget,justify",
        allowedContent: true,
      },
      coreStyles_bold: {
        element: "b",
        overrides: "strong",
      },
      // Custom style definition for the Italic feature.
      coreStyles_italic: {
        element: "i",
        overrides: "em",
      },
      webUrl: process.env.VUE_APP_URL,
      cropBannerImage: "",
      cropCompanyLogo: "",
      cropQRCodeImage: "",
      data: null,
      cropmove: null,
      tempLogo: "",
      noClose: true,
      disabledFromDate: {
        to: new Date(Date.now() + 8640000),
      },
      addressErrors: [],
      companies: [],
    };
  },
  components: {
    ckeditor: CKEditor.component,
    VueCropper,
    Datepicker,
    Multiselect,
  },

  methods: {
    breadCrumb() {
      var item = [
        {
          to: { name: "reward-list" },
          text: "Rewards",
        },
        {
          to: null,
          text: "Add Reward",
        },
      ];
      return item;
    },
    customLabel({ first_name, last_name, name }) {
      return `${name} (${last_name} ${first_name})`;
    },
    customFormatterOne(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    disabledStartDate() {
      const minStartDate = new Date();
      minStartDate.setDate(minStartDate.getDate() - 1);
      return { to: minStartDate };
    },
    disabledEndDate() {
      if (!this.form.start_date) {
        return { to: new Date() };
      } else {
        var minEndDate = new Date(this.form.start_date);
        minEndDate.setDate(minEndDate.getDate());
        return {
          to: minEndDate,
        };
      }
    },
    onChaneStartDate() {
      this.form.end_date = "";
    },
    cropBannerImageFun() {
      this.$refs["crop-modal-banner"].hide();
      this.form.selectedBannerImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    cropCompanyLogoFun() {
      this.$refs["crop-modal-company-logo"].hide();
      this.form.companyLogo.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    cropQRCodeImageFun() {
      this.$refs["crop-modal-qrcode-image"].hide();
      this.form.selectedQRCodeImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    setBannerImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropBannerImage = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    setCompanyLogo(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropCompanyLogo = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    setQRCodeImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropQRCodeImage = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    showBannerImg() {
      this.$refs["crop-modal-banner"].show();
    },
    showCompanyLogo() {
      this.$refs["crop-modal-company-logo"].show();
    },
    showQRCodeImage() {
      this.$refs["crop-modal-qrcode-image"].show();
    },
    onSubmitBannerImg(evt) {
      evt.preventDefault();
      this.$refs["crop-modal-banner"].hide();
      this.$refs["crop-modal-banner"].refresh();
    },
    onSubmitCompanyLogo(evt) {
      evt.preventDefault();
      this.$refs["crop-modal-company-logo"].hide();
      this.$refs["crop-modal-company-logo"].refresh();
    },
    onSubmitQRCodeImage(evt) {
      evt.preventDefault();
      this.$refs["crop-modal-qrcode-image"].hide();
      this.$refs["crop-modal-qrcode-image"].refresh();
    },
    onImageChangeBannerPng(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.banner_image = input.files[0];
        if (
          this.form.banner_image.type != "image/png" &&
          this.form.banner_image.type != "image/jpeg" &&
          this.form.banner_image.type != "image/jpg"
        ) {
          this.form.selectedBannerImage.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.selectedBannerImage.image = "";
          this.showAlert();
        } else if (this.form.banner_image.size > 5242880) {
          this.form.selectedBannerImage.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Image should be less than 5 MB.";
          this.form.selectedBannerImage.image = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogo = e.target.result;
            this.form.selectedBannerImage.name = this.form.banner_image.name;
            this.showBannerImg();
            event.target.value = "";
          };
        }
      }
    },
    onCompanyLogoChangePng(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.company_logo = input.files[0];
        if (
          this.form.company_logo.type != "image/png" &&
          this.form.company_logo.type != "image/jpeg" &&
          this.form.company_logo.type != "image/jpg"
        ) {
          this.form.companyLogo.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.companyLogo.image = "";
          this.showAlert();
        } else if (this.form.company_logo.size > 5242880) {
          this.form.companyLogo.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Image should be less than 5 MB.";
          this.form.companyLogo.image = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogo = e.target.result;
            this.form.companyLogo.name = this.form.company_logo.name;
            this.showCompanyLogo();
            event.target.value = "";
          };
        }
      }
    },
    onImageChangeBanner(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.banner_image = input.files[0];
        if (
          this.form.banner_image.type != "image/jpeg" &&
          this.form.banner_image.type != "image/jpg"
        ) {
          this.form.selectedBannerImage.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Please select only .jpg or .jpeg image.";
          this.form.selectedBannerImage.image = "";
          this.showAlert();
        } else if (this.form.banner_image.size > 5242880) {
          this.form.selectedBannerImage.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Image should be less than 5 MB.";
          this.form.selectedBannerImage.image = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogo = e.target.result;
            this.form.selectedBannerImage.name = this.form.banner_image.name;
            this.showBannerImg();
            event.target.value = "";
          };
        }
      }
    },
    onCompanyLogoChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.company_logo = input.files[0];
        if (
          this.form.company_logo.type != "image/jpeg" &&
          this.form.company_logo.type != "image/jpg"
        ) {
          this.form.companyLogo.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Please select only .jpg or .jpeg image.";
          this.form.companyLogo.image = "";
          this.showAlert();
        } else if (this.form.company_logo.size > 5242880) {
          this.form.companyLogo.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Image should be less than 5 MB.";
          this.form.companyLogo.image = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogo = e.target.result;
            this.form.companyLogo.name = this.form.company_logo.name;
            this.showCompanyLogo();
            event.target.value = "";
          };
        }
      }
    },
    onImageChangeQRCode(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.qrcode_image = input.files[0];
        if (
          this.form.qrcode_image.type != "image/jpeg" &&
          this.form.qrcode_image.type != "image/jpg"
        ) {
          this.form.selectedQRCodeImage.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Please select only .jpg or .jpeg image.";
          this.form.selectedQRCodeImage.image = "";
          this.showAlert();
        } else if (this.form.qrcode_image.size > 5242880) {
          this.form.selectedQRCodeImage.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Image should be less than 5 MB.";
          this.form.selectedQRCodeImage.image = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogo = e.target.result;
            this.form.selectedQRCodeImage.name = this.form.qrcode_image.name;
            this.showQRCodeImage();
            event.target.value = "";
          };
        }
      }
    },
    getCompanies() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/employer-job-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.companies = this.$store.getters.getResults.data;
          }
          console.log(this.companies, "dd");
        });
    },
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        this.$router.push({ name: "reward-list" });
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    addAddress() {
      this.form.addressArr.push({ address: "" });
    },
    removeAddress(index) {
      if (this.form.addressArr.length > 1) {
        this.form.addressArr.splice(index, 1);
        this.addressErrors.splice(index, 1);
      }
    },
    validateForm() {
      this.addressErrors = [];
      this.form.addressArr.forEach((addressObj, index) => {
        if (!addressObj.address) {
          this.addressErrors[index] = "Merchant Location field is required";
        } else if (addressObj.address.length < 1 || addressObj.address.length > 150) {
          this.addressErrors[index] = "Merchant Location must be between 1-150 characters";
        } else {
          this.addressErrors[index] = "";
        }
      });
      return this.addressErrors.every((error) => !error); // Return true if no errors
    },
    formSubmit(evt) {
      evt.preventDefault();
      var err = false;
      if (!this.validateForm()) {
        err = true;
        this.error_message = "Please fix the errors in the address fields.";
        this.showAlert();
      }
      if (
        err == false &&
        (this.form.start_date == "Invalid date" ||
          this.form.start_date == null ||
          this.form.start_date == "")
      ) {
        err = true;
        this.error_message = "Please select start date";
        this.showAlert();
      }
      if (
        err == false &&
        this.form.end_date &&
        this.form.end_date != "Invalid date" &&
        (this.form.start_date == "Invalid date" ||
          this.form.start_date == null ||
          this.form.start_date == "")
      ) {
        err = true;
        this.error_message = "Please select start date";
        this.showAlert();
      }
      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              title: this.form.title,
              description: this.form.description,
              expiry_date: this.form.expiry_date,
              company_name: this.form.company_name,
              requirement: this.form.requirement,
              terms_and_conditions: this.form.terms_and_conditions,
              // address: this.form.address,
              addressArr: this.form.addressArr,

              total_quantity: this.form.total_quantity,
              reduced_quantity: this.form.reduced_quantity,
              claim_conditions: this.form.claim_conditions,
              start_date:
                moment(this.form.start_date).format("YYYY-MM-DD") == "Invalid date"
                  ? null
                  : moment(this.form.start_date).format("YYYY-MM-DD"),
              end_date:
                moment(this.form.end_date).format("YYYY-MM-DD") == "Invalid date"
                  ? null
                  : moment(this.form.end_date).format("YYYY-MM-DD"),
              qrcode: this.form.qrcode,
              qrcode_link: this.form.qrcode_link,
              banner_image: this.form.selectedBannerImage.image,
              company_logo: this.form.companyLogo.image,
              qrcode_image: this.form.selectedQRCodeImage.image,
              // company_id:
              //   this.form.company_id && this.form.company_id.id ? this.form.company_id.id : "",
              // company_id:
              //   this.form.company_id.length > 0
              //     ? this.form.company_id[0].company_id
              //     : this.form.company_id,
              company_id:
                this.form.company_id && this.form.company_id.company_id
                  ? this.form.company_id.company_id
                  : this.form.company_id,
              items: this.form,
            },
            api: "/api/reward-store",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              var message = "Reward Updated successfully.";
              this.showSuccessAlert(message);
              this.$router.push({ name: "reward-list" });
            }
          });
      }
    },
  },
  mounted() {
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    this.getCompanies();
    // document.title = "EL Connect - Admin - Website Contents - Edit Reward";
  },
};
</script>
