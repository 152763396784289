<template>
  <div>
    <div class="back_arrow_icon">
      <!-- <span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span> -->
      <!-- <b-link v-if="this.billing_address.length > 0" class="btn green-border-btn new_edit_ft_btn desktop_margin_top_zero desktop_margin_right_12" style="margin-left: 7px;" :to="{name: 'editbillingAddressAdmin', params: {id: encrypt(this.billing_address[0].id), user_id: this.$route.params.id}}">Edit Billing Address</b-link> -->
      <!-- <b-link v-else class="btn green-border-btn new_edit_ft_btn desktop_margin_top_zero desktop_margin_right_12" style="margin-left: 7px;" :to="{name: 'addbillingAddressAdmin', params: {id: this.$route.params.id}}">Add Billing Address</b-link> -->
    </div>
    <!-- Dashboard Headline -->

    <!-- Page Content
        ================================================== -->
    <div class="">
      <div class="breadcrumb_with_btn new_buttton_blue">
        <!-- <h1> <span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit Profile (Employer) -->
        <div id="breadcrumb-v2">
          <b-breadcrumb>
            <b-breadcrumb-item href="" :to="{ name: 'employer-list' }">Employers</b-breadcrumb-item>
            <b-breadcrumb-item active>Edit Profile (Employer)</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <b-link
          v-if="this.billing_address.length > 0 && show('edit')"
          class="btn green-border-btn new_edit_ft_btn desktop_margin_top_zero desktop_margin_right_12 desktop_margin_right_zero"
          style="margin-left: 7px"
          :to="{
            name: 'editbillingAddressAdmin',
            params: {
              id: encrypt(this.billing_address[0].id),
              user_id: this.$route.params.id,
              company_id: encrypt(this.billing_address[0].company_id),
            },
          }"
          >Edit Billing Address</b-link
        >
        <b-link
          v-else-if="show('add')"
          class="btn green-border-btn new_edit_ft_btn desktop_margin_top_zero desktop_margin_right_12"
          style="margin-left: 7px"
          :to="{
            name: 'addbillingAddressAdmin',
            params: { id: this.$route.params.id, company_id: encrypt(this.form.company_id) },
          }"
          >Add Billing Address</b-link
        >
        <!-- </h1> -->

        <!-- Breadcrumbs -->
        <nav id="breadcrumbs" class="dark nav_on_emp">
          <ul>
            <li><a href="#">Home</a></li>
            <li>Profile</li>
          </ul>
        </nav>
      </div>
      <div class="notification notice" v-if="status == 'pending'">
        <p><span class="icon-feather-info"></span> Account Status {{ status }}</p>
        <a class="close"></a>
      </div>
      <div
        class="notification warning"
        v-if="status == 'incomplete'"
        style="background-color: rgb(236 159 167 / 49%); color: rgb(234, 20, 81)"
      >
        <p>
          <span class="icon-feather-info"></span> Account Status {{ status }}. To complete profile:
        </p>
        <ul class="notification_box">
          <li v-if="this.form.mobile_verified_check != true">- Verify Mobile Number</li>
          <li v-if="this.form.email_verify != 'yes'">- Verify Email Address</li>
          <li v-if="this.form.company_name == null || this.form.company_name == ''">
            - Add Company Name
          </li>
          <li v-if="this.form.company_reg_no == null || this.form.company_reg_no == ''">
            - Add Company Registration Number (UEN)
          </li>
          <li v-if="this.form.skill == null">- Add Company Industry</li>
          <!-- <li> Save changes to profile </li> -->
        </ul>
        <a class="close"></a>
      </div>
      <div class="row second_indiv_step_wrap mb_btn_edit">
        <b-form method="post" id="register-account-form" class="nw_frm">
          <!-- <p id="error"></p> -->
          <!-- Dashboard Box -->
          <div class="col-xl-12">
            <div class="dashboard-box margin-top-0">
              <!-- Headline -->
              <div class="headline">
                <h3><i class="icon-material-outline-face"></i> Account</h3>
              </div>

              <div class="content with-padding">
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <!-- <div class="col-xl-4">
                                                <div class="submit-field error_submit_field">
                                                    <h5>Given Name <span class="required_star">*</span></h5>
                                                    <input type="text" v-model="form.first_name" class="with-border required-field" id="first_name">
                                                    <input type="hidden" v-model="form.user_id" class="with-border" >
                                                </div>
                                                     <span class="error_x_white">Please enter Given Name </span>
                                            </div>

                                            <div class="col-xl-4">
                                                <div class="submit-field error_submit_field">
                                                    <h5>Surname <span class="required_star">*</span></h5>
                                                    <input type="text" v-model="form.last_name" class="with-border required-field" id="last_name">
                                                </div>
                                                     <span class="error_x_white">Please enter Surname </span>
                                            </div> -->

                      <div class="col-xl-8">
                        <div class="submit-field error_submit_field">
                          <h5>Full Name<span class="required_star">*</span></h5>
                          <input
                            type="text"
                            v-model="form.full_name"
                            class="with-border required-field"
                            id="first_name"
                          />
                          <input type="hidden" v-model="form.user_id" class="with-border" />
                        </div>
                        <span class="error_x_white">Please enter Full Name </span>
                      </div>

                      <div class="col-xl-4">
                        <div class="submit-field">
                          <h5>Account Type</h5>
                          <input
                            type="text"
                            v-model="form.user_type_id_val"
                            readonly="readonly"
                            class="with-border form-control"
                          />
                        </div>
                        <div class="show_span">
                          <span class="error_x_white">Please enter Account type </span>
                        </div>
                      </div>

                      <div class="hundered_width">
                        <b-input-group>
                          <div class="col-xl-4 rm">
                            <div
                              class="error_submit_field submit-field mobile_view_numbr mobile_email desktop_margin_bottom_zero big_screen_bottom_28 mobile_view_numbr mobile_email mobile_margin_bottom_28"
                            >
                              <h5>
                                Mobile Number <span class="required_star">*</span
                                ><span
                                  class="verify_badge"
                                  v-if="this.form.mobile_verified_check == true"
                                  ><i class="fa fa-check"></i>Verified</span
                                >
                              </h5>
                              <div
                                class="input-group mb-3 desktop_margin_bottom_zero error_submit_field"
                              >
                                <input
                                  type="text"
                                  v-model="form.country_code"
                                  class="with-border mobile_input_prf form-control required-field country_code_txt"
                                  readonly="readonly"
                                />
                                <input
                                  type="text"
                                  v-model="form.mobile"
                                  class="with-border mobile_input_prf form-control required-field"
                                  id="contact"
                                />
                                <!-- <div class="input-group-append" v-if="this.form.mobile_verified_check == false" >
                                                                <button @click="sendOTP" class="btn btn-outline-primary" type="button">Send OTP</button>
                                                            </div> -->
                              </div>
                              <span class="error_x_white desktop_margin_bottom_zero"
                                >Please enter Contact Number
                              </span>
                            </div>
                            <!-- <div class="show_span">
                                                            <span class="error_x_white">Please enter Contact Number </span>
                                                        </div> -->
                          </div>
                          <div class="col-xl-4">
                            <div
                              class="error_submit_field submit-field mobile_view_numbr mobile_email desktop_margin_bottom_zero mobile_margin_bottom_28 big_screen_bottom_28"
                            >
                              <h5>
                                Email Address <span class="required_star">*</span
                                ><span class="verify_badge" v-if="this.form.email_verify == 'yes'"
                                  ><i class="fa fa-check"></i>Verified</span
                                >
                              </h5>
                              <div
                                class="input-group mb-3 desktop_margin_bottom_zero error_submit_field"
                              >
                                <input
                                  type="text"
                                  v-model="form.email"
                                  class="with-border mobile_input_prf form-control required-field"
                                  id="email"
                                />
                                <!-- <div class="input-group-append" v-if="this.form.email_verify == 'no'" >
                                                        <button class="btn btn-outline-primary" @click="emailVerify" type="button">Verify Email</button>
                                                    </div> -->
                              </div>
                              <span class="error_x_white desktop_margin_bottom_zero"
                                >Please enter Email
                              </span>
                            </div>
                            <!-- <div class="show_span">
                                                            <span class="error_x_white">Please enter Email </span>
                                                        </div> -->
                          </div>
                          <div class="col-xl-4">
                            <div class="submit-field desktop_margin_bottom_zero error_submit_field">
                              <h5>Gender <span class="required_star">*</span></h5>
                              <b-form-select
                                data-size="7"
                                v-model="form.gender"
                                class="custom-select web_bottom_zero required-field"
                                id="gender"
                              >
                                <option value="" disabled>Select</option>
                                <option value="female">Female</option>
                                <option value="male">Male</option>
                              </b-form-select>
                            </div>
                            <!-- <div class="show_span"> -->
                            <span class="error_x_white desktop_margin_bottom_zero"
                              >Please enter Gender
                            </span>
                            <!-- </div> -->
                          </div>
                          <!-- <div class="col-xl-6">
                                                        <div class="submit-field mobile_view_numbr mobile_email" v-if="this.form.mobile_verified_check == false">
                                                            <h5>Verification Code</h5>
                                                            <div class="input-group mb-3">
                                                            <input type="text" v-model="form.var_code" class="with-border mobile_input_prf form-control" >
                                                            <div class="input-group-append" v-if="this.form.verify_otp_button == true">
                                                                <button class="btn btn-outline-primary" @click="mobileVerify" type="button">Verify OTP</button>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div> -->
                        </b-input-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-12">
            <div class="dashboard-box">
              <!-- Headline -->
              <div class="headline">
                <h3><i class="icon-material-outline-business-center"></i> Company Profile</h3>
                <button
                  class="btn btn-outline-primary"
                  v-if="this.form.apply_for_dp == false && this.form.pending_msg == false"
                  @click="applyForDailyPaid"
                  type="button"
                >
                  Apply for Temp. Staffing Posting
                </button>
                <h3 v-if="this.form.pending_msg == true" style="color: blue">
                  Temp. Staffing Status: Pending
                </h3>
              </div>

              <div class="content">
                <ul class="fields-ul">
                  <li class="common_padding_30">
                    <div class="row">
                      <div
                        class="col-auto img_mobile col-xl-3 submit-field big_screen_max_width_and_flex max_width_and_flex_769"
                      >
                        <!-- <div class="avatar-wrapper" data-tippy-placement="bottom" title="Change Avatar">

                                                        <div class="upload-button"></div>
                                                            <input class="file-upload" type="file" @change="onprofileChange" accept="image/*"/>
                                                    </div> -->
                        <h5>Company Logo</h5>
                        <!--<div class="single-image-upload emp_img_one">
                                                        <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onprofileChange" ref="image"  v-model="form.profile_image"></b-form-file>
                                                        <span>
                                                            <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/company-logo-placeholders.png'" :disabled="true" style="max-width: 300px;"></b-img>
                                                        </span>
                                                    </div>-->

                        <b-form-group
                          id="link-group"
                          class="mobile_tab_image_change top_banner_jobs_full new_update_banner profile_top_div"
                        >
                          <div class="single-image-upload">
                            <b-form-file
                              class="customUpload form-control-file"
                              name="Image"
                              accept="image/*"
                              @change="onprofileChange"
                              ref="image"
                              v-model="form.profile_image"
                            ></b-form-file>
                            <span>
                              <b-img
                                v-if="form.selectedImage"
                                :src="
                                  form.selectedImage.image
                                    ? form.selectedImage.image
                                    : webUrl + 'assets/images/company-logo-placeholders.png'
                                "
                              ></b-img>
                              <!--<b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="default_img"></b-img>-->
                            </span>
                            <!-- <div class="textare_pdng">
                                            <vue-upload-multiple-image @upload-success="uploadImageSuccess" :maxImage="maxImage" :multiple="multiple" @before-remove="beforeRemove" @edit-image="editImage" :showEdit="false" :data-images="images" dragText="Browse" browseText="Select Files"  primaryText="" markIsPrimaryText="" popupText="Image" v-model="form.images" accept=".jpg, .png, .jpeg" limit-exceeded="5" class="customize_delete_icon_file_upload image_glr new_post_image">
                                            </vue-upload-multiple-image>
                                        </div> -->
                          </div>
                        </b-form-group>
                      </div>

                      <div class="col-xl-9 submit-field">
                        <h5 class="img_txt">
                          Company Banner Image<span
                            >(Recommended Banner size should be 952*280.)</span
                          >
                        </h5>

                        <b-form-group
                          id="link-group"
                          class="top_banner_jobs_full new_update_banner"
                        >
                          <div class="single-image-upload">
                            <b-form-file
                              class="customUpload form-control-file"
                              name="BannerImage"
                              accept="image/*"
                              @change="onBannerChange"
                              ref="image"
                              v-model="form.banner_image"
                            ></b-form-file>
                            <span>
                              <b-img
                                v-if="form.selectedBannerImage.image"
                                :src="form.selectedBannerImage.image"
                              ></b-img>
                              <!--<b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="default_img"></b-img>-->
                              <i v-else class="fa fa-camera default_img"></i>
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col">
                        <div class="row">
                          <div class="col-xl-4">
                            <div class="submit-field">
                              <h5>Company Name <span class="required_star">*</span></h5>
                              <b-form-input
                                type="text"
                                v-model="form.company_name"
                                id="company_name"
                                class="input-text with-border required-field web_bottom_zero"
                                name="co_name"
                                placeholder=""
                                value=""
                              />
                            </div>
                            <div class="show_span">
                              <span class="error_x_white">Please enter Company Name </span>
                            </div>
                          </div>

                          <div class="col-xl-4">
                            <div class="submit-field web_bottom_zero mobile_tab_big_screen_margin">
                              <h5>
                                Company Registration Number (UEN)
                                <span class="required_star">*</span>
                              </h5>
                              <b-form-input
                                type="text"
                                v-model="form.company_reg_no"
                                id="registration_number"
                                class="input-text with-border required-field uen"
                                name="company_reg_no"
                                placeholder=""
                                value=""
                              />
                            </div>
                            <!-- <div class="show_span"> -->
                            <span class="error_x_white enter_uen">Please enter UEN </span>
                            <!-- </div> -->
                            <!-- <div class="show_span"> -->
                            <span class="error_x_white_uen error red_span"
                              >UEN in use - Please contact your own company admin or our support for
                              help
                            </span>
                            <!-- </div> -->
                          </div>

                          <div class="col-xl-4">
                            <div class="submit-field">
                              <h5>Company Industry <span class="required_star">*</span></h5>
                              <b-form-select
                                v-model="form.skill"
                                id="industry"
                                class="web_bottom_zero required-field"
                                @change="showCategory(form.skill)"
                              >
                                <option value="" disabled>Select Company Industry</option>
                                <option :value="skillsArrs.id" v-for="skillsArrs in skillsArr">
                                  {{ skillsArrs.name }}
                                </option>
                              </b-form-select>
                            </div>
                            <div class="show_span">
                              <span class="error_x_white">Please enter Industry </span>
                            </div>
                          </div>
                          <div class="col-xl-4 cat_inner_divs other_category_class">
                            <div class="submit-field" v-if="form.skill == other_category_id">
                              <h5
                                :class="
                                  form.skill == other_category_id ? 'height_38_banner_heading' : ''
                                "
                              >
                                Specify Industry
                              </h5>
                              <b-form-input
                                type="text"
                                v-model="form.specified_company_industry"
                                class="input-text with-border"
                                name="company_reg_no"
                                placeholder=""
                                value=""
                              />
                            </div>
                          </div>

                          <div class="col-xl-4">
                            <div class="submit-field">
                              <h5>On Demand Charges <span class="required_star">*</span></h5>
                              <input
                                type="text"
                                class="with-border"
                                v-model="form.on_demand_charges"
                              />
                            </div>
                          </div>

                          <div class="col-xl-4">
                            <div class="submit-field">
                              <h5>
                                Signup Days Completion For Employer Promotion
                                <span class="required_star">*</span>
                              </h5>
                              <input
                                type="text"
                                class="with-border"
                                v-model="form.signup_days_completion_for_employer_promotion"
                              />
                            </div>
                          </div>

                          <div class="col-xl-4">
                            <div class="submit-field">
                              <h5>Registered On <span class="required_star">*</span></h5>
                              <datepicker
                                v-model="form.created_at"
                                :format="customFormatterDate"
                                :minimumView="'day'"
                                :maximumView="'year'"
                                id="fromyear"
                                class="web_bottom_zero add_calender_icon new_add_calender_icon"
                              ></datepicker>
                            </div>
                          </div>

                          <div class="col-xl-4">
                            <div class="submit-field">
                              <h5>Min Late Minutes For FL<span class="required_star">*</span></h5>
                              <input
                                type="text"
                                class="with-border"
                                v-model="form.min_late_minutes_for_fl"
                              />
                            </div>
                          </div>

                          <div class="col-xl-4">
                            <div class="submit-field">
                              <h5>Due Days <span class="required_star">*</span></h5>
                              <input type="text" class="with-border" v-model="form.due_days" />
                            </div>
                          </div>

                          <div class="col-xl-4">
                            <div class="submit-field">
                              <h5>Payment Terms <span class="required_star">*</span></h5>
                              <input
                                type="number"
                                class="with-border"
                                v-model="form.payment_terms"
                              />
                            </div>
                          </div>

                          <div class="col-xl-4" v-if="form.charge_type == 'platform_fee'">
                            <div class="submit-field">
                              <h5>Service Fee Percent <span class="required_star">*</span></h5>
                              <input
                                type="text"
                                class="with-border"
                                v-model="form.non_cpf_service_fee_percent"
                              />
                            </div>
                          </div>
                          <div class="col-xl-4">
                            <div class="submit-field">
                              <h5>Service Agreement (PDF Only) (Maximum file size: 10 MB)</h5>
                              <div
                                class=""
                                v-if="
                                  form.show_file_name_sa &&
                                  form.document_detail &&
                                  form.is_emp_service_agreement
                                "
                              >
                                <div class="file_name_full_div">
                                  <span class="file_name_span">File Name: </span>
                                  <span class="file_name_clr file_first_last"
                                    >{{ form.company_name | capitalize }} -
                                    <span class="file_name_clr">Service Agreement.pdf</span>
                                  </span>
                                </div>
                                <span>Uploaded On: </span>
                                <span class="file_name_clr">{{ form.document_detail }}</span>
                                <span></span>
                              </div>
                              <button
                                v-if="form.show_file_name_sa && form.sa_doc_url"
                                type="button"
                                class="agreement_view_btn uploadButton-button ripple-effect single_view_btn"
                                data-tippy-placement="top"
                                @click="viewServiceAgreement($event)"
                              >
                                View
                              </button>
                              <b-link
                                :to="{}"
                                v-if="form.is_emp_service_agreement"
                                class="common_font_weight_700 add_service_btn_emp desktop_margin_left_zero desktop_margin_right_zero btn green-border-btn ml-2 text-white upload_img_cst gallery_upload_btn"
                                @click="getServiceAgreementData()"
                              >
                                Replace
                              </b-link>
                              <b-link
                                :to="{}"
                                v-else
                                class="add_new_like_upload_btn desktop_margin_left_zero desktop_margin_right_zero desktop_margin_top_zero btn green-border-btn ml-2 text-white upload_img_cst gallery_upload_btn"
                                @click="clickToAdd('add')"
                              >
                                Add New
                              </b-link>
                            </div>
                          </div>
                          <div class="col-xl-4">
                            <div class="submit-field">
                              <h5>
                                Company ACRA (PDF Only) (Maximum file size 10 MB)<span
                                  class="required_star"
                                ></span>
                              </h5>
                              <!-- Attachments -->
                              <div class="attachments-container margin-top-0 margin-bottom-0">
                                <!-- <a :href="acraUrl" target="_blank"> -->
                                <div class="" v-if="showACRA">
                                  <!-- <span>ACRA</span> -->
                                  <div class="file_name_full_div">
                                    <span class="file_name_span">File Name </span>
                                    <span class="file_name_clr file_first_last"
                                      >{{ form.last_name | capitalize }}
                                      {{ form.first_name | capitalize }} -
                                      <span class="file_name_clr">ACRA.pdf</span>
                                      <!-- <span v-else class="file_name_clr">ACRA.docx</span> -->
                                      <button
                                        v-if="
                                          showACRA &&
                                          this.form.apply_for_dp == false &&
                                          this.account_verification_status != 'completed'
                                        "
                                        type="button"
                                        class="remove-attachment remove_cv_btn"
                                        @click="removeACRA($event)"
                                        data-tippy-placement="top"
                                        title="Remove"
                                      >
                                        <i
                                          class="fa fa-trash-o"
                                          style="
                                            font-family: 'FontAwesome';
                                            color: red;
                                            font-size: 22px;
                                          "
                                        ></i>
                                      </button>
                                    </span>
                                  </div>
                                  <!-- <span></span> -->
                                  <span>Uploaded On: </span>
                                  <span class="file_name_clr">{{ acra_detail }}</span>
                                  <span></span>
                                  <!-- <i v-if="acraUrl.split(/[#?]/)[0].split('.').pop().trim() =='pdf'">PDF</i>
                                                                    <i v-else>DOC</i> -->
                                </div>
                                <!-- </a> -->

                                <!-- <button v-if="showACRA && this.form.apply_for_dp == false" type="button" class="remove-attachment" @click="removeACRA($event)" data-tippy-placement="top" title="Remove"><i class="fa fa-trash-o" style="font-family: 'FontAwesome';color: red;font-size: 22px;"></i></button> -->
                              </div>
                              <div class="clearfix"></div>

                              <!-- Upload Button -->
                              <div
                                class="uploadButton margin-top-0 web_bottom_zero"
                                v-if="!showACRA"
                              >
                                <input
                                  class="uploadButton-input"
                                  type="file"
                                  accept=".pdf"
                                  @change="onAcraChange"
                                  id="upload"
                                />
                                <label
                                  class="uploadButton-button ripple-effect"
                                  for="upload"
                                  v-if="!showACRA"
                                  >Upload</label
                                >
                                <!-- <span id="acra" class="uploadButton-file-name unset_min_height" style="color: blue;font-weight: bold;text-decoration: underline;"></span> -->
                              </div>

                              <div
                                class="multiple_view_btn"
                                v-if="showACRA && this.form.apply_for_dp == false"
                              >
                                <button
                                  v-if="showACRA && this.form.apply_for_dp == false"
                                  type="button"
                                  class="uploadButton-button ripple-effect"
                                  data-tippy-placement="top"
                                  @click="viewACRA($event)"
                                >
                                  View
                                </button>

                                <div
                                  class="uploadButton margin-top-0 web_bottom_zero"
                                  v-if="showACRA && this.form.apply_for_dp == false"
                                >
                                  <input
                                    class="uploadButton-input"
                                    type="file"
                                    accept=".pdf"
                                    @change="onAcraChange"
                                    id="upload"
                                  />
                                  <label
                                    class="uploadButton-button ripple-effect replace_btn"
                                    for="upload"
                                    v-if="showACRA"
                                    >Replace</label
                                  >
                                  <!-- <span class="" v-if="showACRA">Maximum file size: 10 MB</span> -->
                                  <!-- <span id="acra" class="uploadButton-file-name unset_min_height" style="color: blue;font-weight: bold;text-decoration: underline;"></span> -->
                                </div>
                              </div>

                              <p
                                class="uploadButton-file-name desktop_margin_bottom_zero"
                                v-if="showACRA && this.form.apply_for_dp == false"
                              >
                                Maximum file size: 10 MB
                              </p>
                              <span
                                class="uploadButton-file-name unset_min_height"
                                id="acra"
                                style="color: blue; font-weight: bold; text-decoration: underline"
                              ></span>
                              <button
                                v-if="showACRA && this.form.apply_for_dp == true"
                                type="button"
                                class="uploadButton-button ripple-effect single_view_btn"
                                data-tippy-placement="top"
                                @click="viewACRA($event)"
                              >
                                View
                              </button>
                            </div>
                          </div>
                          <!-- <div class="col-xl-3"> -->
                        </div>
                        <div class="row">
                          <div class="col-xl-3">
                            <div class="submit-field">
                              <h5>Charge Type for Jobs <span class="required_star">*</span></h5>
                              <b-form-select
                                data-size="7"
                                v-model="form.charge_type"
                                class="custom-select web_bottom_zero required-field"
                                id="gender"
                              >
                                <option value="" disabled>Select</option>
                                <option value="platform_fee">Platform Fee</option>
                                <option value="fixed_rate">Fixed Rate</option>
                              </b-form-select>
                            </div>
                            <!-- <div class="show_span"> -->
                            <span class="error_x_white desktop_margin_bottom_zero"
                              >Please select charge type
                            </span>
                            <!-- </div> -->
                          </div>
                          <div class="col-xl-3" v-if="form.charge_type == 'fixed_rate'">
                            <div class="submit-field">
                              <h5 class="">Fixed Rate<span class="required_star">*</span></h5>
                              <input type="text" class="with-border" v-model="form.fixed_rate" />
                            </div>
                          </div>
                          <div class="col-xl-3" v-if="form.charge_type == 'fixed_rate'">
                            <div class="submit-field">
                              <h5 class="">Holiday Fee<span class="required_star">*</span></h5>
                              <input type="text" class="with-border" v-model="form.holiday_fee" />
                            </div>
                          </div>
                          <div class="col-xl-3" v-if="form.charge_type == 'fixed_rate'">
                            <div class="submit-field">
                              <h5 class="">
                                Min. Rate for On-Demand<span class="required_star">*</span>
                              </h5>
                              <input
                                type="text"
                                class="with-border"
                                v-model="form.rate_for_on_demand"
                              />
                            </div>
                          </div>
                          <div class="col-xl-3" v-if="form.charge_type == 'fixed_rate'">
                            <div class="submit-field">
                              <h5 class="">OT Fee<span class="required_star">*</span></h5>
                              <input type="text" class="with-border" v-model="form.ot_fee" />
                            </div>
                          </div>
                          <div class="col-xl-3" v-if="form.charge_type == 'fixed_rate'">
                            <div class="submit-field">
                              <h5 class="">Extension Fee<span class="required_star">*</span></h5>
                              <input type="text" class="with-border" v-model="form.extension_fee" />
                            </div>
                          </div>
                          <div class="col-xl-3">
                            <div class="submit-field">
                              <h5 class="">
                                Free DP Job Posts<span class="required_star">*</span>
                              </h5>
                              <input
                                type="text"
                                class="with-border"
                                v-model="form.signup_free_post"
                              />
                            </div>
                          </div>
                          <div class="col-xl-3">
                            <div class="submit-field">
                              <h5 class="">
                                Free DP Job Posts Validity(Months)<span class="required_star"
                                  >*</span
                                >
                              </h5>
                              <input
                                type="text"
                                class="with-border"
                                v-model="form.signup_free_post_duration"
                              />
                            </div>
                          </div>
                          <div class="col-xl-12">
                            <div class="submit-field">
                              <h5>Company Office Address</h5>
                              <b-form-input
                                type="text"
                                v-model="form.office_address"
                                id="address"
                                class="input-text with-border"
                                name="office_address"
                                placeholder=""
                                value=""
                              />
                            </div>
                            <!-- <div class="show_span">
                                                            <span class="error_x_white">Please enter Office Address </span>
                                                        </div> -->
                          </div>
                          <div class="col-xl-12">
                            <div class="submit-field">
                              <h5>Company Short Description (Max 100 characters are allowed)</h5>
                              <input
                                type="text"
                                class="with-border"
                                v-model="form.sortdesc"
                                maxlength="100"
                              />
                            </div>
                          </div>
                          <div class="col-xl-12">
                            <div class="submit-field">
                              <h5>Company Overview</h5>
                              <ckeditor
                                v-model="form.overview"
                                :config="editorConfig"
                                id="overview"
                                class="employer_editor"
                              ></ckeditor>
                              <!-- <textarea type="text" class="with-border" value="" v-model="form.overview"></textarea> -->
                            </div>
                            <div class="show_span">
                              <span class="error_x_white">Please enter Overview </span>
                            </div>
                          </div>

                          <div class="col-xl-12">
                            <div class="submit-field web_bottom_zero">
                              <h5>Referred by Sales Staff</h5>
                              <multiselect
                                v-model="form.staffName"
                                id="ajax"
                                :custom-label="customLabel"
                                track-by="id"
                                placeholder="Type to search staff name"
                                open-direction="bottom"
                                :options="allStaff"
                                :searchable="true"
                                :clear-on-select="true"
                                :close-on-select="true"
                                :allow-empty="true"
                                :multiple="false"
                              >
                                <span slot="noResult">No Staff Found.</span>
                              </multiselect>
                            </div>
                          </div>
                          <!-- <b-col  xl="12">
                                                        <b-form-group class="whats_app_update_div">
                                                            <div
                                                            class=""
                                                            >
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model = 'form.receive_whatsapp_update' @input="receive_whatsapp_upd($event)" class="opacity_unset"><strong>Receive Whatsapp for New Applicants</strong>
                                                            </b-form-checkbox>
                                                            </div>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col lg="12" md="12" v-if="form.receive_whatsapp_update == 'yes'">
                                                        <b-form-group id="input-group-8" label="" class="category-radio employer_multiple_radio">
                                                            <b-form-radio v-model="form.whatsapp_update_user" value="1" ><b>Assigned Supervisor</b></b-form-radio>
                                                            <b-form-radio v-model="form.whatsapp_update_user" value="2" ><b>Main Account Only</b></b-form-radio>
                                                        </b-form-group>
                                                    </b-col> -->

                          <!-- <b-col xl="12">
                                                        <b-form-group class ="whats_app_update_div">
                                                            <div class="">
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model = 'form.send_notification' @input="send_noti($event)" class="opacity_unset"><strong>Send Notification to All Users</strong></b-form-checkbox>
                                                            </div>
                                                        </b-form-group>
                                                        <div class="tooltip_icon email_tooltip_with_verified" @click="mobileToolTipNumber">
                                                            <span class="material-icons-outlined info_tooltip_icon common_cusror_pointer_hand">info_outlined</span>
                                                            <span class="tooltip_text tooltip_txt_mobile_number whats_app_tooltip">All attached users to receive notification</span>
                                                        </div>
                                                    </b-col> -->

                          <!-- <b-col xl="12">
                                                        <b-form-group>
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model = 'form.send_invoice' @input="send_inv($event)" class="opacity_unset"><strong>Send Invoice to All Users</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>

                                                    <b-col xl="12">
                                                        <b-form-group>
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model = 'form.qr_based_arrival' @input="qr_code($event)" class="opacity_unset"><strong>Use QR Based Arrival</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>

                                                   
                                                   

                                                    <b-col xl="12" v-if="form.qr_based_arrival == 'yes'">
                                                        <b-form-group>
                                                            <b-form-checkbox value="yes" unchecked-value="no"  v-model = 'form.gps_check_enabled' @input="gps_check($event)" class="opacity_unset"><strong>Enable GPS Check</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>

                                                    <b-col xl="12">
                                                        <b-form-group>
                                                            <b-form-checkbox value="yes" unchecked-value="no"  v-model = 'form.approval_require' @input="approval_check($event)" class="opacity_unset"><strong>Require Approval for Job Creation</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>


                                                    <b-col xl="12">
                                                        <b-form-group class ="whats_app_update_div">
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model = 'form.enable_email_for_job_assign' @input="enable_email($event)" class="opacity_unset"><strong>Receive Email for Job Assign</strong></b-form-checkbox>
                                                        </b-form-group>
                                                        <div class="tooltip_icon email_tooltip_with_verified" @click="mobileToolTipNumber">
                                                            <span class="material-icons-outlined info_tooltip_icon common_cusror_pointer_hand">info_outlined</span>
                                                            <span class="tooltip_text tooltip_txt_mobile_number whats_app_tooltip">Users received email when job assigned to them</span>
                                                        </div>
                                                    </b-col>


                                                    <b-col xl="12">
                                                        <b-form-group>
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model = 'form.exempted' @input="exempt($event)" class="opacity_unset"><strong>Exempt from Early Cancellation</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col xl="12">
                                                        <b-form-group>
                                                            <b-form-checkbox value="yes" unchecked-value="no"  v-model = 'form.exempt_from_auto_pay_enabled' @input="exempt_from_auto_pay($event)" class="opacity_unset"><strong>Exempt from Auto Pay</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col xl="12">
                                                        <b-form-group>
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model = 'form.enable_multiple_shift' @input="enable_multiple_shift($event)" class="opacity_unset"><strong>Enable Multiple Shift</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>



                                                    <b-col xl="12">
                                                        <b-form-group>
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model='form.show_applied_and_worked_before_logo' @input="show_applied_and_worked_before_logo($event)" class="opacity_unset"><strong>Show Applied & Worked before logo</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col xl="12">
                                                        <b-form-group>
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model ='form.is_prepaid_employer' @input="is_prepaid_employer($event)" class="opacity_unset"><strong>Is Prepaid Employer</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col xl="12">
                                                        <b-form-group>
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model='form.receive_email_from_awarded_fl' @input="receive_email_from_awarded_fl($event)" class="opacity_unset"><strong>Receive Email for Award FL Excel</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col lg="12" md="12" v-if="form.receive_email_from_awarded_fl == 'yes'">
                                                        <b-form-group id="input-group-8" label="" class="category-radio employer_multiple_radio">
                                                            <b-form-radio v-model="form.receive_email_from_awarded_fl_day" value="next_day"><b>Next Day</b></b-form-radio>
                                                            <b-form-radio v-model="form.receive_email_from_awarded_fl_day" value="same_day"><b>Same Day</b></b-form-radio>
                                                        </b-form-group>
                                                    </b-col> -->
                          <!-- <b-col xl="12" v-if="form.receive_email_from_awarded_fl == 'yes'" class="full_time_empz">
                                                        <p class="desktop_margin_bottom_zero">Receive Email for Award FL Excel Time<span class="required" style="color:red">*</span></p>
                                                        <b-form-group id="input-group-5" label="" class="required">
                                                            <vue-timepicker format="hh:mm A" v-model="form.receive_email_from_awarded_fl_hrs" class="common_time_picker scheduled_min desktop_margin_bottom_zero"></vue-timepicker> -->
                          <!-- <input type="number" class="with-border" v-model="form.receive_email_from_awarded_fl_hrs"> -->
                          <!-- </b-form-group> -->
                          <!-- </b-col> -->
                          <!-- <b-col xl="12" v-if="form.receive_email_from_awarded_fl == 'yes'">
                                                        <p class="desktop_margin_bottom_zero">Receiving Emails<span class="required" style="color:red">*</span></p>
                                                        <b-form-group id="input-group-5" label="" class="required">
                                                            <input type="text" class="with-border" v-model="form.receiving_email">
                                                        </b-form-group>
                                                    </b-col> -->
                          <!-- <b-col xl="12">
                                                        <p class="desktop_margin_bottom_zero">Break Billing Hrs<span class="required" style="color:red">*</span></p>
                                                        <b-form-group id="input-group-5" label="" class="required">
                                                            <input type="text" class="with-border" v-model="form.bill_break_hrs">
                                                        </b-form-group>
                                                    </b-col> -->
                          <!-- <b-col lg="12" md="12">
                                                        <b-form-group id="input-group-8" label="" class="category-radio employer_multiple_radio"><label class="bold_label">Send Whatsapp message to first-time applicants</label>
                                                            <b-form-radio v-model="form.send_whatsapp_msg_to_first_time_fl" value="yes"><b>Yes</b></b-form-radio>
                                                            <b-form-radio v-model="form.send_whatsapp_msg_to_first_time_fl" value="no"><b>No</b></b-form-radio>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col xl="12" v-if="form.send_whatsapp_msg_to_first_time_fl == 'yes'">
                                                        <p class="desktop_margin_bottom_zero">Whatsapp Message<span class="required" style="color:red">*</span></p>
                                                        <b-form-group id="input-group-5" label="" class="required">
                                                            <input type="text" class="with-border" v-model="form.whatsapp_msg_to_first_time_fl">
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col xl="12">
                                                        <b-form-group>
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model='form.auto_award_fl' class="opacity_unset"><strong>Auto Award FL</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col xl="12" v-if="form.auto_award_fl == 'yes'">
                                                        <p class="desktop_margin_bottom_zero">Minimum Completed Jobs<span class="required" style="color:red">*</span></p>
                                                        <b-form-group id="input-group-5" label="" class="required">
                                                            <input type="text" class="with-border" v-model="form.auto_award_min_completed_jobs">
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col xl="12" v-if="form.auto_award_fl == 'yes'">
                                                        <p class="desktop_margin_bottom_zero">Attendance Rate</p>
                                                        <b-form-group id="input-group-5" label="" class="">
                                                            <input type="text" class="with-border" v-model="form.attendance_rate_for_auto_award">
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col xl="12">
                                                        <b-form-group>
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model='form.generate_monthly_qr' class="opacity_unset"><strong>Generate Monthly QR</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col lg="6" md="6" v-if="form.generate_monthly_qr == 'yes'">
                                                        <b-form-group id="input-group-5" label="Generate QR Date" class="required">
                                                            <input type="text" class="with-border" v-model="form.generate_monthly_qr_date">
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col xl="12">
                                                        <b-form-group>
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model ='form.receive_auto_sr' @input="receive_auto_sr($event)" class="opacity_unset"><strong>Receive Auto SR</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col lg="6" md="6" v-if="form.receive_auto_sr == 'yes'">
                                                        <b-form-group class="category-radio employer_multiple_radio employer_multiple_checkbox">
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model ='form.sr_to_assigned' @input="sr_to_assigned($event)" class="opacity_unset"><strong>Assigned Users</strong></b-form-checkbox>
                                                        </b-form-group>
                                                         <b-form-group class="category-radio employer_multiple_radio employer_multiple_checkbox">
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model ='form.sr_to_company_admin' @input="sr_to_company_admin($event)" class="opacity_unset"><strong>Company Admin</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col> -->
                          <!-- <b-col lg="6" md="6" v-if="form.receive_auto_sr == 'yes'">
                                                        <b-form-group class="category-radio employer_multiple_radio">
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model ='form.sr_to_company_admin' @input="sr_to_company_admin($event)" class="opacity_unset"><strong>Company Admin</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col> -->
                          <!-- <b-col xl="12">
                                                        <b-form-group>
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model ='form.full_vacancy_scan_in_prompt' @input="full_vacancy_scan_in_prompt($event)" class="opacity_unset"><strong>Full Vacancy scan in prompt</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col xl="12">
                                                        <b-form-group>
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model ='form.show_job_list_rate' @input="show_job_list_rate($event)" class="opacity_unset"><strong>Show Job List Rate</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col xl="12">
                                                        <b-form-group>
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model ='form.bill_break_hrs' @input="bill_break_hrs($event)" class="opacity_unset"><strong>Bill Break Hours</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col xl="12">
                                                        <b-form-group>
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model ='form.has_department' class="opacity_unset"><strong>Has Department</strong></b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col> -->
                          <!-- <b-col lg="12" md="12">
                                                        <b-form-group id="input-group-8" label="" class="category-radio employer_multiple_radio"><label class="bold_label">Bill break hrs</label>
                                                            <b-form-radio v-model="form.bill_break_hrs" value="yes"><b>Yes</b></b-form-radio>
                                                            <b-form-radio v-model="form.bill_break_hrs" value="no"><b>No</b></b-form-radio>
                                                        </b-form-group>
                                                    </b-col> -->
                          <!-- <b-col xl="12">
                                                        <b-row>
                                                            <b-col lg="6" md="6" class="mobile_tab_max_width_flex">
                                                                <p class="desktop_margin_bottom_zero">Billing Cycle<span class="required" style="color:red">*</span></p>
                                                                <b-form-group id="input-group-8" label="" class="category-radio employer_multiple_radio">
                                                                    <b-form-radio v-model="form.billing_cycle" name="billing_cycle" value="monthly" >Monthly</b-form-radio>
                                                                    <b-form-radio v-model="form.billing_cycle" name="billing_cycle" value="bi_monthly" >Bi-Monthly</b-form-radio> -->
                          <!-- <b-form-radio v-model="form.billing_cycle" name="billing_cycle" value="weekly" >Weekly</b-form-radio> -->
                          <!-- </b-form-group>
                                                            </b-col> -->
                          <!-- <b-col lg="6" md="6" v-if="form.billing_cycle == 'weekly'" class="submit-field mobile_tab_max_width_flex">
                                                                <b-form-group id="input-group-3" label="Invoice Day" class="required desktop_margin_bottom_zero" >
                                                                    <span class="custom-dropdown">
                                                                        <b-form-select v-model="form.billing_week_day" class="desktop_margin_bottom_zero ">
                                                                            <option value="" disabled>Please Select</option>
                                                                            <option value="mon" >Monday</option>
                                                                            <option value="tue" >Tuesday</option>
                                                                            <option value="wed" >Wednesday</option>
                                                                            <option value="thur" >Thursday</option>
                                                                            <option value="fri" >Friday</option>
                                                                            <option value="sat" >Saturday</option>
                                                                            <option value="sun" >Sunday</option>
                                                                        </b-form-select>
                                                                    </span>
                                                                </b-form-group>
                                                            </b-col> 
                                                        </b-row>
                                                    </b-col>-->

                          <!-- <div class="col-xl-6">
                                                        <div class="submit-field mobile_view_numbr mobile_email" >
                                                            <h5>Company Telephone No. <span class="required_star">*</span></h5>
                                                            <div class="input-group mb-3">
                                                            <input type="text" v-model="form.company_contact_number" class="with-border mobile_input_prf form-control required-field" id="company_number">
                                                            </div>
                                                        </div>
                                                         <div class="show_span">
                                                            <span class="error_x_white">Please enter Company Telephone Number </span>
                                                        </div>
                                                    </div>-->

                          <!-- <div class="col-xl-12">
                                                    <div class="submit-field">
                                                        <h5>Certificates</h5>
                                                        <div class="edit-images-listing inline_cnt">
                                                         <div class="profile-content-detail" >
                                                            <ul  v-if='docs.length != 0'>
                                                                <li v-for="doc in docs">
                                                                    <b-button @click="deleteImage(doc.id)" class="cross_btn">X</b-button>
                                                                    <b-img v-if="doc.image_type == 'image'" :src="doc.document" alt="certification img"></b-img>
                                                                    <b-img v-if="doc.image_type == 'pdf'" :src="webUrl+ 'assets/images/pdf.png'"></b-img>
                                                                    <iframe v-if="doc.image_type == 'pdf'" :src="doc.document"></iframe>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        </div>

                                                        <vue-upload-multiple-image @upload-success="uploadImageSuccess" @before-remove="beforeRemove" @edit-image="editImage" :data-images="images" dragText="Browse" browseText="Select Certificate" primaryText="" markIsPrimaryText="" popupText="Image"
                                                                            v-model="form.certificate" accept=".jpg, .png, .jpeg, .pdf">
                                                        </vue-upload-multiple-image>
                                                    </div>
                                                </div> -->
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-xl-12">
            <div class="dashboard-box">
              <div class="headline">
                <h3>
                  <i class="material-icons-outlined account_set">settings_outlined</i>
                  <span class="account_txt_emp">Company Permissions</span>
                </h3>
              </div>
              <div class="content with-padding">
                <div class="row">
                  <div class="col">
                    <div class="row six_grid_permission mb-3">
                      <b-col xl="6">
                        <b-form-group class="mb-0">
                          <b-form-checkbox
                            value="yes"
                            unchecked-value="no"
                            v-model="form.gst_on_platform_fee"
                            @input="gst_fee($event)"
                            class="opacity_unset"
                            ><strong>GST on Platform Fee</strong></b-form-checkbox
                          >
                        </b-form-group>
                      </b-col>
                      <div class="col-xl-12 margin_bottom_10">
                        <b-col xl="0">
                          <b-form-group class="mb-0">
                            <b-form-checkbox
                              value="yes"
                              unchecked-value="no"
                              v-model="form.is_prepaid_employer"
                              @input="is_prepaid_employer($event)"
                              class="opacity_unset"
                              ><strong>Is Prepaid Employer</strong></b-form-checkbox
                            >
                          </b-form-group>
                        </b-col>
                        <div class="row">
                          <b-col xl="6" md="6" v-if="form.is_prepaid_employer == 'yes'">
                            <p class="desktop_margin_bottom_zero">
                              Cashback Amount<span class="required" style="color: red">*</span>
                            </p>
                            <b-form-group id="input-group-5" label="" class="required mb-0">
                              <input
                                type="text"
                                class="with-border mb-0"
                                v-model="form.cashback_amount"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col xl="6" md="6" v-if="form.is_prepaid_employer == 'yes'">
                            <p class="desktop_margin_bottom_zero">
                              Cashback Percentage<span class="required" style="color: red">*</span>
                            </p>
                            <b-form-group id="input-group-5" label="" class="required mb-0">
                              <input
                                type="text"
                                class="with-border mb-0"
                                v-model="form.cashback_percent"
                              />
                            </b-form-group>
                          </b-col>
                        </div>
                      </div>
                      <b-col xl="6">
                        <b-form-group class="whats_app_update_div">
                          <div class="">
                            <b-form-checkbox
                              value="yes"
                              unchecked-value="no"
                              v-model="form.send_notification"
                              @input="send_noti($event)"
                              class="opacity_unset"
                              ><strong
                                >Send Notification to All Attached Users</strong
                              ></b-form-checkbox
                            >
                          </div>
                        </b-form-group>
                        <div
                          class="tooltip_icon email_tooltip_with_verified"
                          @click="mobileToolTipNumber"
                        >
                          <span
                            class="material-icons-outlined info_tooltip_icon common_cusror_pointer_hand"
                            >info_outlined</span
                          >
                          <span class="tooltip_text tooltip_txt_mobile_number whats_app_tooltip"
                            >Notification for New Applicants, Applicants Cancelled, etc</span
                          >
                        </div>
                      </b-col>
                      <b-col xl="6">
                        <b-form-group>
                          <b-form-checkbox
                            value="yes"
                            unchecked-value="no"
                            v-model="form.send_invoice"
                            @input="send_inv($event)"
                            class="opacity_unset"
                            ><strong>Send Invoice to All Users</strong></b-form-checkbox
                          >
                        </b-form-group>
                      </b-col>
                      <b-col xl="6">
                        <b-form-group>
                          <b-form-checkbox
                            value="yes"
                            unchecked-value="no"
                            v-model="form.qr_based_arrival"
                            @input="qr_code($event)"
                            class="opacity_unset"
                            ><strong>Use QR Based Arrival</strong></b-form-checkbox
                          >
                        </b-form-group>
                      </b-col>
                      <b-col xl="6" v-if="form.qr_based_arrival == 'yes'">
                        <b-form-group>
                          <b-form-checkbox
                            value="yes"
                            unchecked-value="no"
                            v-model="form.gps_check_enabled"
                            @input="gps_check($event)"
                            class="opacity_unset"
                            ><strong>Enable GPS Check</strong></b-form-checkbox
                          >
                        </b-form-group>
                      </b-col>
                      <b-col xl="6">
                        <b-form-group class="whats_app_update_div">
                          <b-form-checkbox
                            value="yes"
                            unchecked-value="no"
                            v-model="form.approval_require"
                            @input="approval_check($event)"
                            class="opacity_unset"
                            ><strong>Require Approval for Job Creation</strong></b-form-checkbox
                          >
                        </b-form-group>
                        <div
                          class="tooltip_icon email_tooltip_with_verified"
                          @click="mobileToolTipNumber"
                        >
                          <span
                            class="material-icons-outlined info_tooltip_icon common_cusror_pointer_hand"
                            >info_outlined</span
                          >
                          <span class="tooltip_text tooltip_txt_mobile_number whats_app_tooltip"
                            >Company Admin to approve Jobs before posted to public</span
                          >
                        </div>
                      </b-col>
                      <b-col xl="6">
                        <b-form-group class="whats_app_update_div">
                          <b-form-checkbox
                            value="yes"
                            unchecked-value="no"
                            v-model="form.enable_email_for_job_assign"
                            @input="enable_email($event)"
                            class="opacity_unset"
                            ><strong>Receive Email for Job Assign</strong></b-form-checkbox
                          >
                        </b-form-group>
                        <div
                          class="tooltip_icon email_tooltip_with_verified"
                          @click="mobileToolTipNumber"
                        >
                          <span
                            class="material-icons-outlined info_tooltip_icon common_cusror_pointer_hand"
                            >info_outlined</span
                          >
                          <span class="tooltip_text tooltip_txt_mobile_number whats_app_tooltip"
                            >Assigned Supervisor will receive email on job assign</span
                          >
                        </div>
                      </b-col>
                      <b-col xl="6">
                        <b-form-group class="whats_app_update_div">
                          <b-form-checkbox
                            value="yes"
                            unchecked-value="no"
                            v-model="form.exempted"
                            @input="exempt($event)"
                            class="opacity_unset"
                            ><strong>Exempt from Early Cancellation</strong></b-form-checkbox
                          >
                        </b-form-group>
                        <div
                          class="tooltip_icon email_tooltip_with_verified"
                          @click="mobileToolTipNumber"
                        >
                          <span
                            class="material-icons-outlined info_tooltip_icon common_cusror_pointer_hand"
                            >info_outlined</span
                          >
                          <span class="tooltip_text tooltip_txt_mobile_number whats_app_tooltip"
                            >Job will not drop when no applicants / awarded
                          </span>
                        </div>
                      </b-col>
                      <b-col xl="6">
                        <b-form-group>
                          <b-form-checkbox
                            value="yes"
                            unchecked-value="no"
                            v-model="form.exempt_from_auto_pay_enabled"
                            @input="exempt_from_auto_pay($event)"
                            class="opacity_unset"
                            ><strong>Exempt from Auto Pay</strong></b-form-checkbox
                          >
                        </b-form-group>
                      </b-col>
                      <b-col xl="6">
                        <b-form-group>
                          <b-form-checkbox
                            value="yes"
                            unchecked-value="no"
                            v-model="form.enable_multiple_shift"
                            @input="enable_multiple_shift($event)"
                            class="opacity_unset"
                            ><strong>Enable Multiple Shift</strong></b-form-checkbox
                          >
                        </b-form-group>
                      </b-col>
                      <b-col xl="6">
                        <b-form-group>
                          <b-form-checkbox
                            value="yes"
                            unchecked-value="no"
                            v-model="form.full_vacancy_scan_in_prompt"
                            @input="full_vacancy_scan_in_prompt($event)"
                            class="opacity_unset"
                            ><strong>Full Vacancy scan in prompt</strong></b-form-checkbox
                          >
                        </b-form-group>
                      </b-col>
                      <b-col xl="6">
                        <b-form-group>
                          <b-form-checkbox
                            value="yes"
                            unchecked-value="no"
                            v-model="form.show_applied_and_worked_before_logo"
                            @input="show_applied_and_worked_before_logo($event)"
                            class="opacity_unset"
                            ><strong>Show Applied & Worked before logo</strong></b-form-checkbox
                          >
                        </b-form-group>
                      </b-col>
                      <b-col xl="6">
                        <b-form-group>
                          <b-form-checkbox
                            value="yes"
                            unchecked-value="no"
                            v-model="form.show_job_list_rate"
                            @input="show_job_list_rate($event)"
                            class="opacity_unset"
                            ><strong>Show Job List Rate</strong></b-form-checkbox
                          >
                        </b-form-group>
                      </b-col>
                      <b-col xl="6">
                        <b-form-group>
                          <b-form-checkbox
                            value="yes"
                            unchecked-value="no"
                            v-model="form.bill_break_hrs"
                            @input="bill_break_hrs($event)"
                            class="opacity_unset"
                            ><strong>Bill Break Hours</strong></b-form-checkbox
                          >
                        </b-form-group>
                      </b-col>
                      <b-col xl="6">
                        <b-form-group>
                          <b-form-checkbox
                            value="yes"
                            unchecked-value="no"
                            v-model="form.has_department"
                            class="opacity_unset"
                            ><strong>Has Department</strong></b-form-checkbox
                          >
                        </b-form-group>
                      </b-col>
                    </div>
                    <div class="row">
                      <b-col lg="12" md="12" class="margin_bottom_10">
                        <b-form-group
                          id="input-group-8"
                          label=""
                          class="category-radio employer_multiple_radio mb-0"
                          ><label class="bold_label mb-0"
                            >Send Whatsapp message to first-time applicants</label
                          >
                          <b-form-radio
                            v-model="form.send_whatsapp_msg_to_first_time_fl"
                            value="yes"
                            ><b>Yes</b></b-form-radio
                          >
                          <b-form-radio v-model="form.send_whatsapp_msg_to_first_time_fl" value="no"
                            ><b>No</b></b-form-radio
                          >
                        </b-form-group>
                      </b-col>
                      <b-col xl="12" v-if="form.send_whatsapp_msg_to_first_time_fl == 'yes'">
                        <p class="desktop_margin_bottom_zero">
                          Whatsapp Message<span class="required" style="color: red">*</span>
                        </p>
                        <b-form-group id="input-group-5" label="" class="required">
                          <input
                            type="text"
                            class="with-border mb-0"
                            v-model="form.whatsapp_msg_to_first_time_fl"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col lg="6" md="6" class="mobile_tab_max_width_flex margin_bottom_10">
                        <p class="desktop_margin_bottom_zero">
                          Billing Cycle<span class="required" style="color: red">*</span>
                        </p>
                        <b-form-group
                          id="input-group-8"
                          label=""
                          class="category-radio employer_multiple_radio mb-0"
                        >
                          <b-form-radio
                            v-model="form.billing_cycle"
                            name="billing_cycle"
                            value="monthly"
                            >Monthly</b-form-radio
                          >
                          <b-form-radio
                            v-model="form.billing_cycle"
                            name="billing_cycle"
                            value="bi_monthly"
                            >Bi-Monthly</b-form-radio
                          >
                          <!-- <b-form-radio v-model="form.billing_cycle" name="billing_cycle" value="weekly" >Weekly</b-form-radio> -->
                        </b-form-group>
                      </b-col>
                      <div class="col-xl-12 margin_bottom_10">
                        <b-col xl="0">
                          <b-form-group class="whats_app_update_div mb-0">
                            <div class="">
                              <b-form-checkbox
                                value="yes"
                                unchecked-value="no"
                                v-model="form.receive_whatsapp_update"
                                @input="receive_whatsapp_upd($event)"
                                class="opacity_unset"
                                ><strong>Receive Whatsapp for New Applicants</strong>
                              </b-form-checkbox>
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col lg="0" md="0" v-if="form.receive_whatsapp_update == 'yes'">
                          <b-form-group
                            id="input-group-8"
                            label=""
                            class="category-radio employer_multiple_radio mb-0"
                          >
                            <b-form-radio v-model="form.whatsapp_update_user" value="1"
                              ><b>Assigned Supervisor</b></b-form-radio
                            >
                            <b-form-radio v-model="form.whatsapp_update_user" value="2"
                              ><b>Main Account Only</b></b-form-radio
                            >
                          </b-form-group>
                        </b-col>
                      </div>
                      <div class="col-xl-12 margin_bottom_10">
                        <b-col xl="0">
                          <b-form-group class="mb-0">
                            <b-form-checkbox
                              value="yes"
                              unchecked-value="no"
                              v-model="form.receive_auto_sr"
                              @input="receive_auto_sr($event)"
                              class="opacity_unset"
                              ><strong>Receive Auto SR</strong></b-form-checkbox
                            >
                          </b-form-group>
                        </b-col>
                        <b-col lg="0" md="0" v-if="form.receive_auto_sr == 'yes'">
                          <b-form-group
                            class="category-radio employer_multiple_radio employer_multiple_checkbox mb-0"
                          >
                            <b-form-checkbox
                              value="yes"
                              unchecked-value="no"
                              v-model="form.sr_to_assigned"
                              @input="sr_to_assigned($event)"
                              class="opacity_unset"
                              ><strong>Assigned Users</strong></b-form-checkbox
                            >
                          </b-form-group>
                          <b-form-group
                            class="category-radio employer_multiple_radio employer_multiple_checkbox mb-0"
                          >
                            <b-form-checkbox
                              value="yes"
                              unchecked-value="no"
                              v-model="form.sr_to_company_admin"
                              @input="sr_to_company_admin($event)"
                              class="opacity_unset"
                              ><strong>Company Admin</strong></b-form-checkbox
                            >
                          </b-form-group>
                        </b-col>
                      </div>
                      <div class="col-xl-12 margin_bottom_10">
                        <b-col xl="0">
                          <b-form-group class="mb-0">
                            <b-form-checkbox
                              value="yes"
                              unchecked-value="no"
                              v-model="form.auto_award_fl"
                              class="opacity_unset"
                              ><strong>Auto Award FL</strong></b-form-checkbox
                            >
                          </b-form-group>
                        </b-col>
                        <div class="row">
                          <b-col xl="6" md="6" v-if="form.auto_award_fl == 'yes'">
                            <p class="desktop_margin_bottom_zero">
                              Minimum Completed Jobs<span class="required" style="color: red"
                                >*</span
                              >
                            </p>
                            <b-form-group id="input-group-5" label="" class="required mb-0">
                              <input
                                type="text"
                                class="with-border mb-0"
                                v-model="form.auto_award_min_completed_jobs"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col xl="6" md="6" v-if="form.auto_award_fl == 'yes'">
                            <p class="desktop_margin_bottom_zero">Attendance Rate</p>
                            <b-form-group id="inpmargin_bottom_10ut-group-5" label="" class="mb-0">
                              <input
                                type="text"
                                class="with-border mb-0"
                                v-model="form.attendance_rate_for_auto_award"
                              />
                            </b-form-group>
                          </b-col>
                        </div>
                      </div>
                      <div class="col-xl-12 margin_bottom_10">
                        <b-col xl="0">
                          <b-form-group class="mb-0">
                            <b-form-checkbox
                              value="yes"
                              unchecked-value="no"
                              v-model="form.receive_email_from_awarded_fl"
                              @input="receive_email_from_awarded_fl($event)"
                              class="opacity_unset"
                              ><strong>Receive Email for Award FL Excel</strong></b-form-checkbox
                            >
                          </b-form-group>
                        </b-col>
                        <!-- <b-col lg="0" md="0" v-if="form.receive_email_from_awarded_fl == 'yes'">
                          <b-form-group
                            id="input-group-8"
                            label=""
                            class="category-radio employer_multiple_radio"
                          >
                            <b-form-radio
                              v-model="form.receive_email_from_awarded_fl_day"
                              value="next_day"
                              ><b>Next Day</b></b-form-radio
                            >
                            <b-form-radio
                              v-model="form.receive_email_from_awarded_fl_day"
                              value="same_day"
                              ><b>Same Day</b></b-form-radio
                            >
                          </b-form-group>
                        </b-col> -->
                        <div class="row">
                          <b-col
                            xl="6"
                            md="6"
                            v-if="form.receive_email_from_awarded_fl == 'yes'"
                            class="full_time_empz"
                          >
                            <p class="desktop_margin_bottom_zero">
                              Receive Email for Award FL Excel Time<span
                                class="required"
                                style="color: red"
                                >*</span
                              >
                            </p>
                            <b-form-group id="input-group-5" label="" class="required mb-0">
                              <vue-timepicker
                                format="hh:mm A"
                                v-model="form.receive_email_from_awarded_fl_hrs"
                                class="common_time_picker scheduled_min desktop_margin_bottom_zero"
                              ></vue-timepicker>
                              <!-- <input type="number" class="with-border" v-model="form.receive_email_from_awarded_fl_hrs"> -->
                            </b-form-group>
                          </b-col>
                          <b-col xl="6" md="6" v-if="form.receive_email_from_awarded_fl == 'yes'">
                            <div class="submit-field">
                              <p class="desktop_margin_bottom_zero">
                                No. of days before start time
                              </p>
                              <b-form-select
                                data-size="7"
                                v-model="form.receive_email_from_awarded_fl_day"
                                class="custom-select web_bottom_zero required-field"
                                id="receive_email_from_awarded_fl_day"
                              >
                                <option value="" disabled>Select</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>

                              </b-form-select>
                            </div>
                          </b-col>
                          <b-col xl="6" md="6" v-if="form.receive_email_from_awarded_fl == 'yes'">
                            <p class="desktop_margin_bottom_zero">
                              Receiving Emails<span class="required" style="color: red">*</span>
                            </p>
                            <b-form-group id="input-group-5" label="" class="required mb-0">
                              <input
                                type="text"
                                class="with-border mb-0"
                                v-model="form.receiving_email"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col xl="6" md="6" v-if="form.receive_email_from_awarded_fl == 'yes'">
                            <p class="desktop_margin_bottom_zero">
                              Receiver Name<span class="required" style="color: red">*</span>
                            </p>
                            <b-form-group id="input-group-5" label="" class="required mb-0">
                              <input
                                type="text"
                                class="with-border mb-0"
                                v-model="form.receiver_name"
                              />
                            </b-form-group>
                          </b-col>
                        </div>
                      </div>
                      <b-col xl="12">
                        <b-form-group class="mb-0">
                          <b-form-checkbox
                            value="yes"
                            unchecked-value="no"
                            v-model="form.generate_monthly_qr"
                            class="opacity_unset"
                            ><strong>Generate Monthly QR</strong></b-form-checkbox
                          >
                        </b-form-group>
                      </b-col>
                      <b-col lg="6" md="6" v-if="form.generate_monthly_qr == 'yes'" class="submit-field mt-3">
                        <h5>Generate QR Date</h5>
                        <b-form-group
                          id="input-group-5"
                          label=""
                          class="required mb-0 mt-2"
                        >
                          <input
                            type="text"
                            class="with-border mb-0"
                            v-model="form.generate_monthly_qr_date"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        lg="12"
                        md="12"
                        class="submit-field"
                        v-if="form.generate_monthly_qr == 'yes'"
                      >
                        <h5 id="title-group" class="required">
                          Monthly QR Email <span class="required_star">*</span>
                        </h5>
                        <div
                          v-for="(monthlyQREmailObj, index) in form.monthlyQREmailArr"
                          :key="index"
                          class="loop_one_rem"
                        >
                          <b-row>
                            <b-col
                              lg="9"
                              md="9"
                              class="mobile_max_width_and_flex big_screen_max_width_and_flex"
                            >
                              <input
                                type="text"
                                :id="'address-' + index"
                                v-model="monthlyQREmailObj.monthlyQREmail"
                                placeholder="Enter Monthly QR Email.."
                                rows="3"
                                class="with-border"
                                :class="{ 'is-invalid': monthlyQREmailErrors[index] }"
                              />
                              <div v-if="monthlyQREmailErrors[index]" class="invalid-feedback">
                                {{ monthlyQREmailErrors[index] }}
                              </div>
                            </b-col>
                            <b-col
                              lg="3"
                              md="3"
                              class="mobile_max_width_and_flex submit-field big_screen_max_width_and_flex one_thousand_margin_bottom_zero new_mobile_margin_bottom_zero"
                              v-if="index !== 0"
                            >
                              <b-button
                                v-if="index !== 0"
                                variant="danger"
                                @click="removemonthlyQREmail(index)"
                                class="add_remove_same_width mt-0 ml-0 mr-0 ripple-effect mobile_anchore_set common_vertical_align_bottom button ripple-effect reward_margin"
                                style="background-color: red"
                              >
                                <span class="add_plus">x</span> Remove
                              </b-button>
                            </b-col>
                            <b-col
                              lg="3"
                              md="3"
                              class="mobile_max_width_and_flex submit-field big_screen_max_width_and_flex one_thousand_margin_bottom_zero new_mobile_margin_bottom_zero"
                              v-if="index == 0"
                            >
                              <b-button
                                variant="primary"
                                @click="addmonthlyQREmail"
                                class="add_remove_same_width mt-0 ml-0 mr-0 reward_margin mobile_anchore_set common_vertical_align_bottom button ripple-effect"
                              >
                                <span class="add_plus">+</span> Add more
                              </b-button>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-container class="single_accordian coloured_icon">
            <!-- <br> -->
            <div>
              <div class="faq-content-box clearfix">
                <div>
                  <div class="accordion-listing-box">
                    <b-card no-body>
                      <b-card-header header-tag="header" role="tab">
                        <b-button
                          v-b-toggle="'accordion-linked-social-account-admin-employer-edit-' + key"
                          class="m-1"
                          variant="info"
                        >
                          <div class="dashboard-box">
                            <div class="headline">
                              <h3>
                                <i class="icon-line-awesome-bullhorn"></i>Linked Social Account
                                (Increase your connectivity or get others to know you better)
                              </h3>
                            </div>
                          </div>
                        </b-button>
                      </b-card-header>
                      <b-collapse
                        :id="'accordion-linked-social-account-admin-employer-edit-' + key"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <div class="content with-padding">
                            <div class="row">
                              <div class="col-xl-8">
                                <div class="submit-field">
                                  <h5>
                                    <span class="icon-feather-globe"></span>Company Website URL Link
                                  </h5>
                                  <b-form-input
                                    type="text"
                                    v-model="link.website"
                                    id="website"
                                    class="input-text with-border"
                                    name="website"
                                    placeholder=""
                                    value=""
                                  />
                                </div>
                              </div>
                              <div class="col-xl-8">
                                <div class="submit-field">
                                  <h5>
                                    <span class="icon-brand-facebook-f"></span>Company Facebook URL
                                    Link
                                  </h5>
                                  <b-form-input
                                    type="text"
                                    v-model="link.fb"
                                    id="fb"
                                    class="input-text with-border"
                                    name="fb"
                                    placeholder=""
                                    value=""
                                  />
                                </div>
                              </div>
                              <div class="col-xl-8">
                                <div class="submit-field">
                                  <h5>
                                    <span class="icon-line-awesome-twitter-square"></span>Company
                                    Twitter URL Link
                                  </h5>
                                  <b-form-input
                                    type="text"
                                    v-model="link.twitter"
                                    id="twitter"
                                    class="input-text with-border"
                                    name="twitter"
                                    placeholder=""
                                    value=""
                                  />
                                </div>
                              </div>
                              <!-- <div class="col-xl-8">
                                                                        <div class="submit-field">
                                                                            <h5><span class="icon-brand-google-plus-g"></span>&nbsp;Google Profile URL Link</h5>
                                                                            <b-form-input
                                                                                type="text"
                                                                                v-model="link.google"
                                                                                id="google"
                                                                                class="input-text with-border"
                                                                                name="google"
                                                                                placeholder=""
                                                                                value=""
                                                                            />
                                                                        </div>
                                                                    </div> -->
                              <div class="col-xl-8">
                                <div class="submit-field common_mobile_mrgn">
                                  <h5>
                                    <span class="icon-brand-linkedin-in"></span>&nbsp;Company
                                    LinkedIn URL Link
                                  </h5>
                                  <b-form-input
                                    type="text"
                                    v-model="link.link"
                                    id=""
                                    class="input-text with-border desktop_margin_bottom_zero"
                                    name="link"
                                    placeholder=""
                                    value=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </div>
              </div>
            </div>
          </b-container>
          <!-- <div class="col-xl-12 coloured_icon">
                <div id="test1" class="dashboard-box"> -->

          <!-- Headline -->
          <!-- <div class="headline">
                        <h3><i class="icon-line-awesome-bullhorn"></i>Linked Social Account (Increase your connectivity or get others to know you better)</h3>


                    </div> -->
          <!--
                    <div class="content with-padding">
                        <div class="row"> -->
          <!-- <div class="col-xl-8">
                                <div class="submit-field">
                                    <h5><span class="icon-feather-globe"></span>Company Website URL Link</h5>
                                        <b-form-input
                                            type="text"
                                            v-model="link.website"
                                            id="website"
                                            class="input-text with-border"
                                            name="website"
                                            placeholder=""
                                            value=""
                                        />
                                    </div>
                            </div>
                            <div class="col-xl-8">
                                <div class="submit-field">
                                    <h5><span class="icon-brand-facebook-f"></span>Company Facebook URL Link</h5>
                                     <b-form-input
                                        type="text"
                                        v-model="link.fb"
                                        id="fb"
                                        class="input-text with-border"
                                        name="fb"
                                        placeholder=""
                                        value=""
                                     />
                                </div>
                            </div>
                            <div class="col-xl-8">
                                <div class="submit-field">
                                    <h5><span class="icon-line-awesome-twitter-square"></span>Company Twitter URL Link</h5>
                                    <b-form-input
                                        type="text"
                                        v-model="link.twitter"
                                        id="twitter"
                                        class="input-text with-border"
                                        name="twitter"
                                        placeholder=""
                                        value=""
                                    />
                                </div>
                            </div> -->
          <!-- <div class="col-xl-8">
                                <div class="submit-field">
                                    <h5><span class="icon-brand-google-plus-g"></span>&nbsp;Google Profile URL Link</h5>
                                    <b-form-input
                                        type="text"
                                        v-model="link.google"
                                        id="google"
                                        class="input-text with-border"
                                        name="google"
                                        placeholder=""
                                        value=""
                                    />
                                </div>
                            </div> -->
          <!-- <div class="col-xl-8">
                                <div class="submit-field common_mobile_mrgn">
                                    <h5><span class="icon-brand-linkedin-in"></span>&nbsp;Company LinkedIn URL Link</h5>
                                    <b-form-input
                                        type="text"
                                        v-model="link.link"
                                        id="link"
                                        class="input-text with-border"
                                        name="link"
                                        placeholder=""
                                        value=""
                                    />
                                </div>
                            </div> -->

          <!-- </div>
                    </div>
                </div>


                </div> -->

          <div class="col-xl-12">
            <a
              @click="submit"
              class="button ripple-effect big margin-top-30 employee_btn common_vertical_align_bottom"
              >Save Changes</a
            >
          </div>
          <!-- Button -->
        </b-form>
        <b-modal
          ref="crop-modal"
          id="background_custom_class_for_banner"
          title="Edit Image"
          hide-footer
          :no-close-on-backdrop="noClose"
          content-class="common_model_header common_model_title"
          modal-class="background_custom_class"
        >
          <b-form @submit="onSubmitImg">
            <template>
              <div>
                <div class="content" @change="setImage">
                  <section class="cropper-area">
                    <div class="img-cropper">
                      <vue-cropper
                        ref="cropper"
                        :aspect-ratio="1 / 1"
                        :src="tempLogo"
                        preview=".preview"
                      />
                    </div>
                  </section>
                </div>
              </div>
              <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                <b-button
                  variant="success"
                  class="text-uppercase x-md btn-shadow"
                  @click.prevent="cropImage"
                  >Crop</b-button
                >

                <b-button
                  variant="danger"
                  class="text-uppercase x-md btn-shadow"
                  @click.prevent="reset"
                  >Reset</b-button
                >
              </div>
            </template>
          </b-form>
        </b-modal>
        <b-modal
          ref="crop-modal-banner"
          id="background_custom_class_for_banner"
          title="Edit Banner Image"
          hide-footer
          :no-close-on-backdrop="noClose"
          content-class="common_model_header common_model_title"
          modal-class="background_custom_class"
        >
          <b-form @submit="onSubmitImgBanner">
            <template>
              <div>
                <div class="content" @change="setImageBanner">
                  <section class="cropper-area">
                    <div class="img-cropper">
                      <vue-cropper
                        ref="cropper"
                        :aspect-ratio="10 / 3"
                        :src="tempLogoBanner"
                        preview=".preview"
                      />
                    </div>
                  </section>
                </div>
              </div>
              <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                <b-button
                  variant="success"
                  class="text-uppercase x-md btn-shadow"
                  @click.prevent="cropImageBanner"
                  >Crop</b-button
                >

                <b-button
                  variant="danger"
                  class="text-uppercase x-md btn-shadow"
                  @click.prevent="reset"
                  >Reset</b-button
                >
              </div>
            </template>
          </b-form>
        </b-modal>
        <b-modal
          ref="verify-mobile-modal"
          no-close-on-backdrop
          hide-footer
          content-class="mobile_verification_model"
        >
          <b-form class="">
            <div class="edit-form-box web_top_zero">
              <div class="profile-repeate form-box-field">
                <div class="col-xl-12">
                  <h3><b>Phone Verification</b></h3>
                  <h5 class="gray_h5">
                    Enter the verification code that<br />
                    was sent to your mobile number.
                  </h5>
                </div>
                <b-row>
                  <b-col lg="12" md="12">
                    <div class="form-map-box">
                      <b-col lg="12" md="12" class="input-with-icon-left">
                        <i class="fa fa-mobile pop_up_i"></i>
                        <b-form-group id="input-group-9" label="" class="web_bottom_zero">
                          <b-form-input
                            id="input-9"
                            maxlength="20"
                            v-model="form.mobile"
                            type="text"
                            placeholder=""
                            readonly="readonly"
                            class="number_input form-control"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col lg="12" md="12">
                        <b-form-group id="input-group-9" label="" class="web_bottom_zero">
                          <input
                            type="text"
                            v-model="form.var_code"
                            class="with-border mobile_input_prf form-control"
                            placeholder="Enter the 4-digit code"
                          />
                        </b-form-group>
                      </b-col>

                      <div slot="footer" class="form-btn col-md-12 col-lg-12">
                        <b-button
                          @click="mobileVerify"
                          variant="success"
                          style="background-color: #4c8bf5 !important"
                          class="otp_btn"
                          >Verify OTP</b-button
                        >
                        <!-- <b-button @click = "hideVerifyMobileModel()" variant="light">Cancel</b-button> -->
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <div class="col-xl-12 bottom_text_pop_phone">
                  <h5>
                    Verification code may take up to a few <br />minutes to be sent to your phone.
                  </h5>
                  <h5 class="web_bottom_zero">
                    Didn't recevie the code?
                    <b-link @click="sendOTP" style="color: #4c8bf5 !important"> Resend Code</b-link>
                  </h5>
                </div>
              </div>
            </div>
          </b-form>
        </b-modal>
        <b-modal
          ref="upload-modal"
          :title="modalTitle"
          hide-footer
          modal-class="background_custom_class"
          content-class="common_model_header common_model_title"
        >
          <b-form @submit="formSubmit">
            <div class="edit-form-box model_margin_top_zero model_legend">
              <div class="profile-repeate form-box-field">
                <b-row>
                  <b-col lg="12" md="12">
                    <div class="form-map-box">
                      <div class="submit-field effective_date_calender">
                        <h5>Effective Date<span class="required_star">*</span></h5>
                        <!-- <datepicker v-model="form.effective_date" :disabled-dates="disabledFrom" :format="customFormatterOne" clear-button='' class="ticket_close_btn_from_date"></datepicker> -->
                        <datepicker
                          v-model="form.effective_date"
                          :format="customFormatterOne"
                          clear-button=""
                          class="ticket_close_btn_from_date"
                          @input="onChaneEffectiveDate"
                        ></datepicker>

                        <span class="input-icon calender_input_icon obtainment_date_calender"
                          ><b-img
                            :src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                            alt="Calander Icon"
                          ></b-img
                        ></span>
                      </div>
                      <div class="submit-field expiry_date_calender">
                        <h5>Expiry Date<span class="required_star">*</span></h5>
                        <datepicker
                          v-model="form.expiration_date"
                          :disabled-dates="disabledFrom"
                          :format="customFormatterOne"
                          clear-button=""
                          class="ticket_close_btn_from_date"
                        ></datepicker>
                        <span class="input-icon calender_input_icon obtainment_date_calender"
                          ><b-img
                            :src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                            alt="Calander Icon"
                          ></b-img
                        ></span>
                      </div>
                      <b-form-group id="input-group-9" label="Agreement ID" class="required">
                        <b-form-input
                          id="input-9"
                          maxlength="50"
                          v-model="form.agreement_id"
                          type="text"
                          placeholder=""
                          class="with-border"
                        ></b-form-input>
                      </b-form-group>
                      <div class="submit-field model_file_upload">
                        <div class="uploadButton margin-top-0 web_bottom_zero" v-if="!showDocument">
                          <input
                            class="uploadButton-input"
                            type="file"
                            accept=".pdf"
                            @change="onDocumentChange"
                            id="document_upload"
                          />
                          <label
                            class="uploadButton-button ripple-effect"
                            for="document_upload"
                            v-if="!showDocument"
                            >Upload</label
                          >
                          <span class="new_upload" v-if="!showDocument"
                            >(Maximum file size: 10 MB. PDF Only)</span
                          >
                          <!-- <span id="acra" class="uploadButton-file-name unset_min_height" style="color: blue;font-weight: bold;text-decoration: underline;"></span> -->
                        </div>

                        <div class="multiple_view_btn" v-if="showDocument">
                          <!-- <button v-if="showDocument && this.form.apply_for_dp == false" type="button" class="uploadButton-button ripple-effect" data-tippy-placement="top" @click="viewACRA($event)">View</button> -->
                          <div
                            class="uploadButton margin-top-0 web_bottom_zero"
                            v-if="showDocument"
                          >
                            <input
                              class="uploadButton-input"
                              type="file"
                              accept=".pdf"
                              @change="onDocumentChange"
                              id="document_upload"
                            />
                            <label
                              class="uploadButton-button ripple-effect replace_btn"
                              for="document_upload"
                              v-if="showDocument"
                              >Replace</label
                            >
                            <span
                              class="uploadButton-file-name desktop_margin_bottom_zero new_upload"
                              v-if="showDocument"
                              >(Maximum file size: 10 MB. PDF Only)</span
                            >
                          </div>
                        </div>
                        <span
                          class="uploadButton-file-name unset_min_height"
                          id="document"
                          style="color: blue; font-weight: bold; text-decoration: underline"
                        ></span>
                        <!-- <button v-if="showDocument && form.sa_doc_url" type="button" class="uploadButton-button ripple-effect single_view_btn" data-tippy-placement="top" @click="viewServiceAgreement($event)">View</button> -->
                        <!-- <div class="" v-if="showDocument">
                                            <div class="file_name_full_div">
                                                <span class="file_name_span">File Name: </span>
                                                <span class="file_name_clr file_first_last">{{form.company_name | capitalize}} - <span  class="file_name_clr">Service Agreement.pdf</span>
                                                </span>
                                            </div>
                                            <span>Uploaded On:</span>
                                            <span class="file_name_clr">{{form.document_detail}}</span>
                                            <span></span>
                                        </div> -->
                      </div>
                      <div slot="footer" class="form-btn common_crop_reset_btn gallery_btn">
                        <b-button type="submit" variant="success">Submit</b-button>
                        <b-button @click="hideModel()" variant="light">Cancel</b-button>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-form>
        </b-modal>
      </div>
    </div>

    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";
import VueCropper from "vue-cropperjs";
import Datepicker from "vuejs-datepicker";
import CKEditor from "ckeditor4-vue";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import moment from "moment";
import VueTimepicker from "vuejs-timepicker";
import Multiselect from "vue-multiselect";
import * as faceapi from "face-api.js";
import permission from "../../../../../server/permission.js";

export default {
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        full_name: "",

        gender: "",
        user_type_id_val: "",
        country_code: "",
        mobile: "",
        company_contact_number: "",
        var_code: "",
        email: "",
        skill: "",
        created_at: "",
        specified_company_industry: "",
        email_verify: "",
        mobile_verify: "",
        verify_otp_button: false,
        mobile_verified_check: false,
        apply_for_dp: false,
        pending_msg: false,
        emp_daily_paid_status: "",
        company_name: "",
        office_address: "",
        company_reg_no: "",
        overview: "",
        sortdesc: "",
        website: "",
        certificate: "",
        fb: "",
        google: "",
        twitter: "",
        link: "",
        user_id: atob(this.$route.params.id),
        image: "",
        acra: "",
        profile_image: "",
        banner_image: "",
        company_id: "",
        selectedImage: {
          name: "",
          image: "",
          company_id: "",
        },
        selectedBannerImage: {
          name: "",
          image: "",
        },
        selectedAcra: {
          name: "",
          image: "",
        },
        staffName: "",
        staffNamePrev: "",
        non_cpf_service_fee_percent: "",
        on_cpf_service_fee_percent: "",
        on_demand_charges: "",
        signup_days_completion_for_employer_promotion: "",
        signup_free_post: "",
        signup_free_post_duration: "",
        due_days: "",
        payment_terms: "",
        min_late_minutes_for_fl: "",
        is_individual_contractor: "no",
        receive_whatsapp_update: "no",
        send_notification: "no",
        gps_check_enabled: "no",
        show_applied_and_worked_before_logo: "no",
        is_prepaid_employer: "no",
        cashback_amount: 0,
        cashback_percent: 0,
        gst_on_platform_fee: "no",
        receive_auto_sr: "no",
        full_vacancy_scan_in_prompt: "no",
        show_job_list_rate: "no",
        sr_to_assigned: "no",
        sr_to_company_admin: "no",

        receive_email_from_awarded_fl: "no",
        receive_email_from_awarded_fl_day: "0",
        // receive_email_from_awarded_fl_hrs:0,
        receive_email_from_awarded_fl_hrs: {
          hh: "09",
          mm: "00",
          A: "PM",
        },
        receiving_email: "",
        receiver_name: "",
        send_whatsapp_msg_to_first_time_fl: "no",
        whatsapp_msg_to_first_time_fl: "",
        charge_type: "platform_fee",
        fixed_rate: "",
        holiday_fee: "",
        rate_for_on_demand: "",
        ot_fee: "",
        extension_fee: "",

        exempt_from_auto_pay_enabled: "no",
        send_invoice: "no",
        exempted: "yes",
        enable_multiple_shift: "yes",
        qr_based_arrival: "yes",
        approval_require: "yes",
        enable_email_for_job_assign: "no",
        bill_break_hrs: "no",
        has_department: "no",
        billing_cycle: "bi_monthly",
        billing_week_day: "mon",

        whatsapp_update_user: 1,
        admin_id: "",
        auto_award_fl: "no",
        generate_monthly_qr: "no",
        generate_monthly_qr_date: "1",
        auto_award_min_completed_jobs: "2",
        attendance_rate_for_auto_award: "",
        effective_date: "",
        document_detail: "",
        expiration_date: "",
        company_id: "",
        document: "",
        agreement_id: "",
        selectedDocument: {
          name: "",
          image: "",
        },
        is_emp_service_agreement: "",
        show_file_name_sa: false,
        service_agreement_id: "",
        sa_doc_url: "",
        monthlyQREmailArr: [{ monthlyQREmail: "" }],
      },
      billing_address: [],
      other_category_id: "",
      maxImage: 1,
      multiple: false,
      link: {
        website: "",
        fb: "",
        google: "",
        twitter: "",
        link: "",
        user_id: "",
      },
      allStaff: [],
      images: [],
      exemptedCompanies: [],
      showDismissibleAlert: false,
      error_message: "",
      isActive: 1,
      disabled: true,
      personal: true,
      skillsArr: [],
      tempLogo: "",
      links: false,
      disabled: true,
      noClose: true,
      cropImg: "",
      showACRA: "",
      acra_detail: "",
      acraUrl: "",
      data: null,
      cropmove: null,
      tempLogo: "",
      cropImgBanner: "",
      tempLogoBanner: "",
      noClose: true,
      webUrl: process.env.VUE_APP_URL,
      docs: [],
      editorConfig: {
        versionCheck: false,
        removeButtons: "Maximize",
        extraPlugins: "copyformatting,colorbutton,bidi,colordialog,widget,justify",
      },
      coreStyles_bold: {
        element: "b",
        overrides: "strong",
      },
      // Custom style definition for the Italic feature.
      coreStyles_italic: {
        element: "i",
        overrides: "em",
      },
      status: "",
      account_verification_status: "",
      key: "",
      companies: [],
      showDocument: false,

      modalTitle: "Upload New Service Agreement",
      disabledFrom: {
        to: new Date(Date.now() - 8640000),
      },
      type: "add",
      actions: "",

      monthlyQREmailErrors: [],
    };
  },
  components: {
    VueCropper,
    Datepicker,
    Multiselect,
    ckeditor: CKEditor.component,
    VueTimepicker,
    VueUploadMultipleImage,
    faceapi,
  },

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },

  methods: {
    customLabel({ first_name, last_name }) {
      return `${last_name} ${first_name}`;
    },
    showVerifyMobileModel() {
      this.$refs["verify-mobile-modal"].show();
    },
    mobileToolTipNumber() {
      var newWindowWidth = $(window).width();
      if (newWindowWidth <= 999) {
        $(".tooltip_txt_mobile_number").toggleClass("tooltip_text_mobile_view_number");
      }
    },
    showCategory(id) {
      if (id == this.other_category_id) {
        $(".other_category_class").css("display", "block");
      } else {
        $(".other_category_class").css("display", "none");
      }
    },
    receive_whatsapp_upd($event) {
      if ($event != null) {
        this.form.receive_whatsapp_update = $event;
      } else {
        this.form.receive_whatsapp_update = $event;
      }
    },
    clickToAdd(typeVal) {
      // if( typeVal == 'add'){
      //     this.getEmployers();
      // }
      this.$refs["upload-modal"].show();
      this.form.agreement_id = "";
      this.disable_company = false;
      this.showDocument = false;
      this.form.effective_date = "";
      this.form.expiration_date = "";
      this.form.selectedDocument.image = "";
      this.form.selectedDocument.name = "";
      this.modalTitle =
        typeVal == "add" ? "Upload New Service Agreement" : "Edit Service Agreement";
      this.type = typeVal == "add" ? "Add" : "Update";
      this.disabledFrom = {
        to: new Date(Date.now() - 8640000),
      };
    },
    hideModel() {
      this.$refs["upload-modal"].hide();
      this.form.effective_date = "";
      this.form.expiration_date = "";
      this.form.agreement_id = "";
      this.form.document = "";
      this.showDocument = false;
      this.form.selectedDocument.image = "";
      this.form.selectedDocument.name = "";
    },
    onDocumentChange(event) {
      this.showDocument = false;
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.document = input.files[0];
        if (this.form.document.type != "application/pdf") {
          this.error_message = "Please select only .pdf only.";
          this.form.selectedDocument.image = "";
          this.form.selectedDocument.name = "";
          $("#document").empty().append("");
          event.target.value = "";
          this.showAlert();
        } else if (this.form.document.size > 10523520) {
          this.form.selectedDocument.image = this.webUrl + "/images/default-user-icon.svg";
          this.error_message = "ACRA should be less than 10 MB.";
          this.form.selectedDocument.image = "";
          this.form.selectedDocument.name = "";
          $("#document").empty().append("");
          event.target.value = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.modelBox = "logo";
            this.form.selectedDocument.image = e.target.result;
            this.form.selectedDocument.name = this.form.document.name;
            $("#document").empty().append(this.form.selectedDocument.name);
          };
        }
      }
    },
    customFormatterOne(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    onChaneEffectiveDate() {
      this.form.expiration_date = moment(this.form.effective_date)
        .add(1, "year")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      this.disabledFrom = {
        to: new Date(moment(this.form.effective_date) - 8640000),
        from: new Date(moment(this.form.effective_date).add(1, "year") - 8640000),
      };
    },
    formSubmit(evt) {
      evt.preventDefault();
      if (this.type == "update") {
        var api = "/api/emp-service-agreement-update";
      } else {
        var api = "/api/emp-service-agreement-store";
      }
      var err = false;
      var msg = "";
      if (this.form.expiration_date) {
        if (moment().diff(moment(this.form.expiration_date)) >= 0) {
          err = true;
          msg = "Please select correct expiration date";
        }
        if (
          moment(this.form.expiration_date).diff(
            moment(this.form.effective_date).add(1, "year").subtract(1, "day")
          ) >= 0 &&
          err == false
        ) {
          err = true;
          msg = "Effective date and expiration date difference should not be more than 1 year";
        }
      }

      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              id: this.form.service_agreement_id,
              company_id: this.form.company_id,
              agreement_id: this.form.agreement_id,
              expiration_date: this.form.expiration_date,
              effective_date: this.form.effective_date,
              document: this.form.selectedDocument.image,
              items: this.form,
            },
            api: api,
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              if (this.type == "update") {
                var message = "Service Agreement Updated successfully";
              } else {
                var message = "Service Agreement Added successfully";
              }
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: message,
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.getEditData();
              });
              this.hideModel();
            }
          });
      } else {
        this.$swal({
          position: "center",
          icon: "error",
          title: msg,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    getServiceAgreementData() {
      this.clickToAdd("update");
      this.type = "update";
      return this.$store
        .dispatch(POST_API, {
          data: {
            company_id: this.form.company_id,
          },
          api: "/api/emp-service-agreement-admin-edit",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.form.service_agreement_id = this.$store.getters.getResults.data.id;
            this.form.document_detail = this.$store.getters.getResults.document_detail;
            this.form.effective_date = this.$store.getters.getResults.data.effective_date;
            this.form.expiration_date = this.$store.getters.getResults.data.expiration_date;
            this.form.agreement_id = this.$store.getters.getResults.data.agreement_id;
            this.form.selectedDocument.image = this.$store.getters.getResults.data.document;
            this.form.sa_doc_url = this.$store.getters.getResults.data.document;
            this.showDocument =
              this.$store.getters.getResults.data.document != "" &&
              this.$store.getters.getResults.data.document != null
                ? true
                : false;
          }
        });
    },
    getServiceAgreementFileName() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            company_id: this.form.company_id,
          },
          api: "/api/emp-service-agreement-admin-edit",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.form.service_agreement_id = this.$store.getters.getResults.data.id;
            this.form.document_detail = this.$store.getters.getResults.document_detail;
            this.form.effective_date = this.$store.getters.getResults.data.effective_date;
            this.form.expiration_date = this.$store.getters.getResults.data.expiration_date;
            this.form.agreement_id = this.$store.getters.getResults.data.agreement_id;
            this.form.selectedDocument.image = this.$store.getters.getResults.data.document;
            this.form.sa_doc_url = this.$store.getters.getResults.data.document;
            this.showDocument =
              this.$store.getters.getResults.data.document != "" &&
              this.$store.getters.getResults.data.document != null
                ? true
                : false;
            this.disabledFrom = {
              to: new Date(moment(this.form.effective_date) - 8640000),
              from: new Date(moment(this.form.effective_date).add(1, "year") - 8640000),
            };
          }
        });
    },
    send_noti($event) {
      if ($event != null) {
        this.form.send_notification = $event;
      } else {
        this.form.send_notification = $event;
      }
    },
    gps_check($event) {
      if ($event != null) {
        this.form.gps_check_enabled = $event;
      } else {
        this.form.gps_check_enabled = $event;
      }
    },
    show_applied_and_worked_before_logo($event) {
      if ($event != null) {
        this.form.show_applied_and_worked_before_logo = $event;
      } else {
        this.form.show_applied_and_worked_before_logo = $event;
      }
    },
    gst_fee($event) {
      if ($event != null) {
        this.form.gst_on_platform_fee = $event;
      } else {
        this.form.gst_on_platform_fee = $event;
      }
    },
    is_prepaid_employer($event) {
      if ($event != null) {
        this.form.is_prepaid_employer = $event;
      } else {
        this.form.is_prepaid_employer = $event;
      }
    },
    gst_fee($event) {
      if ($event != null) {
        this.form.gst_on_pl = $event;
      } else {
        this.form.is_prepaid_employer = $event;
      }
    },
    receive_auto_sr($event) {
      if ($event != null) {
        this.form.receive_auto_sr = $event;
        if ($event == "no") {
          this.form.sr_to_assigned = "no";
          this.form.sr_to_company_admin = "no";
        }
      } else {
        this.form.receive_auto_sr = $event;
      }
    },
    full_vacancy_scan_in_prompt($event) {
      if ($event != null) {
        this.form.full_vacancy_scan_in_prompt = $event;
      } else {
        this.form.full_vacancy_scan_in_prompt = $event;
      }
    },
    show_job_list_rate($event) {
      if ($event != null) {
        this.form.show_job_list_rate = $event;
      } else {
        this.form.show_job_list_rate = $event;
      }
    },
    sr_to_assigned($event) {
      if ($event != null) {
        this.form.sr_to_assigned = $event;
        if ($event == "no" && this.form.sr_to_company_admin == "no") {
          this.form.receive_auto_sr = "no";
        }
      } else {
        this.form.sr_to_assigned = $event;
      }
    },
    sr_to_company_admin($event) {
      if ($event != null) {
        this.form.sr_to_company_admin = $event;
        if ($event == "no" && this.form.sr_to_assigned == "no") {
          this.form.receive_auto_sr = "no";
        }
      } else {
        this.form.sr_to_company_admin = $event;
      }
    },
    receive_email_from_awarded_fl($event) {
      if ($event != null) {
        this.form.receive_email_from_awarded_fl = $event;
      } else {
        this.form.receive_email_from_awarded_fl = $event;
      }
    },
    exempt_from_auto_pay($event) {
      if ($event != null) {
        this.form.exempt_from_auto_pay_enabled = $event;
      } else {
        this.form.exempt_from_auto_pay_enabled = $event;
      }
    },
    send_inv($event) {
      if ($event != null) {
        this.form.send_invoice = $event;
      } else {
        this.form.send_invoice = $event;
      }
    },
    qr_code($event) {
      if ($event != null) {
        this.form.qr_based_arrival = $event;
      } else {
        this.form.qr_based_arrival = $event;
      }
    },
    approval_check($event) {
      if ($event != null) {
        this.form.approval_require = $event;
      } else {
        this.form.approval_require = $event;
      }
    },
    enable_email($event) {
      if ($event != null) {
        this.form.enable_email_for_job_assign = $event;
      } else {
        this.form.enable_email_for_job_assign = $event;
      }
    },
    exempt($event) {
      if ($event != null) {
        this.form.exempted = $event;
      } else {
        this.form.exempted = $event;
      }
    },
    enable_multiple_shift($event) {
      if ($event != null) {
        this.form.enable_multiple_shift = $event;
      } else {
        this.form.enable_multiple_shift = $event;
      }
    },
    uploadImageSuccess(formData, index, fileList) {
      for (var i = 0; i < fileList.length; i++) {
        var name = fileList[i].name.split(".");
        if (name[name.length - 1] == "pdf") {
          if (typeof fileList[i].url == "undefined") {
            var url = fileList[i].image;
            fileList[i].url = url;
          }
          fileList[i].image = this.webUrl + "assets/images/pdf.png";
        } else {
          if (typeof fileList[i].url == "undefined") {
            var url = fileList[i].image;
            fileList[i].url = url;
          }
        }
      }

      this.images = fileList;
    },

    beforeRemove(index, done, fileList) {
      fileList.splice(index, 1);
      this.images = fileList;
    },

    editImage(formData, index, fileList) {
      for (var i = 0; i < fileList.length; i++) {
        var name = fileList[i].name.split(".");
        if (name[name.length - 1] == "pdf") {
          if (typeof fileList[i].url == "undefined") {
            var url = fileList[i].image;
            fileList[i].url = url;
          }
          fileList[i].image = this.webUrl + "/images/pdf.png";
        } else {
          if (typeof fileList[i].url == "undefined") {
            var url = fileList[i].image;
            fileList[i].url = url;
          }
        }
      }
      this.images = fileList;
    },
    hideVerifyMobileModel() {
      this.$refs["verify-mobile-modal"].hide();
      //   this.form.title = '';
      //   this.form.description = '';
      //   this.form.url = '';
      //   this.selectedImage = '';
    },
    cropImage() {
      this.$refs["crop-modal"].hide();
      this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImg = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    showImg() {
      this.$refs["crop-modal"].show();
    },
    customFormatterDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    onSubmitImg(evt) {
      evt.preventDefault();
      this.$refs["crop-modal"].hide();
      this.$refs["crop-modal"].refresh();
    },
    cropImageBanner() {
      this.$refs["crop-modal-banner"].hide();
      this.form.selectedBannerImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    setImageBanner(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImgBanner = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showImgBanner() {
      this.$refs["crop-modal-banner"].show();
    },
    onSubmitImgBanner(evt) {
      evt.preventDefault();
      this.$refs["crop-modal-banner"].hide();
      this.$refs["crop-modal-banner"].refresh();
    },
    onBannerChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.banner_image = input.files[0];
        if (
          this.form.banner_image.type != "image/png" &&
          this.form.banner_image.type != "image/jpeg" &&
          this.form.banner_image.type != "image/jpg"
        ) {
          this.form.selectedBannerImage.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.selectedBannerImage.image = "";
          this.form.selectedBannerImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else if (this.form.banner_image.size > 5242880) {
          this.form.selectedBannerImage.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Banner image should be less than 5 MB.";
          this.form.selectedBannerImage.image = "";
          this.form.selectedBannerImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogoBanner = e.target.result;
            this.form.selectedBannerImage.name = this.form.banner_image.name;
            this.showImgBanner();
            event.target.value = "";
          };
        }
      }
    },

    deleteImage(img_id) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to delete this image?",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((value) => {
          return this.$store
            .dispatch(POST_API, {
              data: {
                id: img_id,
              },
              api: "/api/delete-document",
            })
            .then(() => {
              alert;
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
              } else {
                window.location.replace("/v2/admin/employer");
              }
            });
        })
        .catch((err) => {
          // An error occurred
        });
    },
    onAcraChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.acra = input.files[0];
        if (this.form.acra.type != "application/pdf") {
          this.error_message = "Please select only .pdf only.";
          this.form.selectedAcra.image = "";
          this.form.selectedAcra.name = "";
          $("#acra").empty().append("");
          event.target.value = "";
          this.showAlert();
        } else if (this.form.acra.size > 10523520) {
          this.form.selectedAcra.image = this.webUrl + "/images/default-user-icon.svg";
          this.error_message = "ACRA should be less than 10 MB.";
          this.form.selectedAcra.image = "";
          this.form.selectedAcra.name = "";
          $("#acra").empty().append("");
          event.target.value = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.modelBox = "logo";
            this.form.selectedAcra.image = e.target.result;
            this.form.selectedAcra.name = this.form.acra.name;
            $("#acra").empty().append(this.form.selectedAcra.name);
          };
        }
      }
    },
    viewACRA(event) {
      if (event) {
        event.stopPropagation();
      }
      window.open(this.acraUrl, "_blank");
    },
    viewServiceAgreement(event) {
      if (event) {
        event.stopPropagation();
      }
      window.open(this.form.sa_doc_url, "_blank");
    },
    removeACRA(event) {
      if (event) {
        event.stopPropagation();
      }

      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to remove ACRA?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  company_id: this.form.company_id,
                  user_id: this.form.user_id,
                },
                api: "/api/employer-acra-remove",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  // this.showAlert();
                  return [];
                } else {
                  this.form.acra = "";
                  this.showACRA = false;
                  this.form.apply_for_dp = false;
                  $("#acra").empty().append("");
                  this.$swal({
                    position: "center",
                    icon: "success",
                    iconColor: "#4c8bf5",
                    title: "ACRA removed successfully",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.getDropdownData("service_categories");
                  this.getSettingData();
                  this.getEditData();
                  this.getSocialData();
                  this.getBillingAddress();
                }
              });
          }
        })
        .catch((err) => {});
    },
    onprofileChange(event) {
      var input = event.target;
      // const image = loadRandomImage();
      // const faces =  faceapi.detectAllFaces(input.files[0], new faceapi.TinyFaceDetectorOptions());
      // const detection = await faceapi.detectSingleFace(input)
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.profile_image = input.files[0];

        if (
          this.form.profile_image.type != "image/png" &&
          this.form.profile_image.type != "image/jpeg" &&
          this.form.profile_image.type != "image/jpg"
        ) {
          this.form.selectedImage.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.selectedImage.image = "";
          this.form.selectedImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else if (this.form.profile_image.size > 1048576) {
          this.form.selectedImage.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Company Logo should be less than 1 MB.";
          this.form.selectedImage.image = "";
          this.form.selectedImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogo = e.target.result;
            this.form.selectedImage.name = this.form.profile_image.name;
            this.showImg();
            event.target.value = "";
          };
        }
      }
    },
    // onFileChange(event) {
    //     var input = event.target;
    //     if (input.files && input.files[0]) {
    //         var reader = new FileReader();
    //         // reader.onload = (e) => {
    //         //     this.selectedImage = e.target.result;
    //         // }
    //         reader.readAsDataURL(input.files[0]);
    //         this.form.profile_image = input.files[0];
    //         if (this.form.profile_image.type != 'image/png' && this.form.profile_image.type != 'image/jpeg' && this.form.profile_image.type != 'image/jpg') {
    //             this.selectedImage = this.webUrl + '/images/upload-icon.svg'
    //             this.error_message = 'Please select only .png,.jpg or .jpeg image.';
    //             this.selectedImage='';
    //             this.showAlert();
    //         } else if (this.form.profile_image.size > 1048576) {

    //             this.selectedImage = this.webUrl + '/images/upload-icon.svg'
    //             this.error_message = 'Logo should be less than 1 MB.';
    //             this.selectedImage='';
    //             this.showAlert();
    //         } else {
    //             reader.onload = (e) => {
    //                 this.tempLogo = e.target.result;
    //                 this.showImg();
    //             }
    //         }
    //         // if(this.form.profile_image.type !='image/png' && this.form.profile_image.type !='image/jpeg' && this.form.profile_image.type !='image/jpg'){
    //         //   this.$bvModal.msgBoxOk('Please select only .png,.jpg or .jpeg image.')
    //         // }
    //     }
    // },
    applyForDailyPaid() {
      var err = false;
      if (this.form.skill == this.other_category_id) {
        if (this.form.specified_company_industry) {
          err = false;
        } else {
          err = true;
          this.$swal({
            position: "center",
            icon: "error",
            title: "Specify industry is required",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              billing_address: this.billing_address,
              skill: this.form.skill,
              specified_company_industry:
                this.form.skill == this.other_category_id
                  ? this.form.specified_company_industry
                  : null,
              company_name: this.form.company_name,
              company_id: this.form.company_id,
              company_reg_no: this.form.company_reg_no,
              mobile_verified: this.form.mobile_verify,
              email_verified: this.form.email_verify,
              // acra: this.form.selectedAcra.image ? this.form.selectedAcra.image: this.form.acra,
              acra: this.form.selectedAcra.image,
              user_id: this.form.user_id,
              profile_image: this.form.selectedImage.image,
              items: this.form,
            },
            // api:'/api/empApplyForDP'
            api: "/api/empApplyForDPNew",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.form.apply_for_dp == false;
              this.$swal({
                position: "center",
                icon: "error",
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              this.form.apply_for_dp = true;
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Applied for Temp. Staffing Posting successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {});
              this.getDropdownData("service_categories");
              this.getEditData();
              this.getSocialData();
              this.getBillingAddress();
            }
          });
      }
    },
    getBillingAddress() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.form.user_id,
          },
          api: "/api/emp-get-billing-address",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.billing_address = this.$store.getters.getResults.data;
          }
        });
    },
    sendOTP() {
      this.$store
        .dispatch(POST_API, {
          data: {
            mobile: this.form.mobile,
            user_id: this.form.user_id,
            company_id: this.form.company_id,
            user_type_id: 3,
          },
          api: "/api/sendOTP",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showDismissibleAlert = true;
            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            var userData = this.$store.getters.currentUser;
            this.form.verify_otp_button = true;
            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "OTP sent successfully.",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {});
            this.showVerifyMobileModel();
          }
        });
    },
    getDropdownData(type) {
      this.$store
        .dispatch(POST_API, {
          data: {
            table: "service_categories",
          },
          api: "/api/dropdown-list-category",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.skillsArr = this.$store.getters.getResults.data;
            this.other_category_id = this.$store.getters.getResults.other_category_id;
            $(".other_category_class").css("display", "none");
          }
        });
    },
    addmonthlyQREmail() {
      this.form.monthlyQREmailArr.push({ monthlyQREmail: "" });
    },
    removemonthlyQREmail(index) {
      if (this.form.monthlyQREmailArr.length > 1) {
        this.form.monthlyQREmailArr.splice(index, 1);
        this.monthlyQREmailErrors.splice(index, 1);
      }
    },
    validateMonthlyQREmail() {
      this.monthlyQREmailErrors = [];
      var emailFormat = "[a-zA-Z0-9._-]+@[0-9a-zA-Z-_.]+\.[a-zA-Z]{2,3}$";
      // const hasDuplicates = (arr) => arr.length !== new Set(arr).size;
      const hasDuplicates = (arr) => {
        const emailSet = new Set();
        for (const item of arr) {
          if (emailSet.has(item.monthlyQREmail)) {
            return true; // Duplicate found
          }
          emailSet.add(item.monthlyQREmail);
        }
        return false; // No duplicates
      };
      if (hasDuplicates(this.form.monthlyQREmailArr)) {
        this.monthlyQREmailErrors[0] = "Duplicate Monthly QR Email";
        return this.monthlyQREmailErrors.every((error) => !error); // Return true if no errors
      } else {
        this.form.monthlyQREmailArr.forEach((monthlyQREmailObj, index) => {
          if (!monthlyQREmailObj.monthlyQREmail) {
            this.monthlyQREmailErrors[index] = "Monthly QR Email field is required";
          } else if (
            monthlyQREmailObj.monthlyQREmail != "" &&
            !monthlyQREmailObj.monthlyQREmail.match(emailFormat)
          ) {
            this.monthlyQREmailErrors[index] = "Invalid Monthly QR Email";
          } else {
            this.monthlyQREmailErrors[index] = "";
          }
        });

        return this.monthlyQREmailErrors.every((error) => !error); // Return true if no errors
      }
    },
    submit() {
      // console.log(this.form.company_name.replace(/[^a-zA-Z&- ]/g, ""))

      var err = false;

      $(".required-field").each(function () {
        if ($(this).val() == "") {
          err = true;
          $(".error_x_white_uen").hide();
          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $("#gender").each(function () {
        if (!$("#gender").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $("#gender").change(function () {
        if ($(this).val() != "") {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $("#industry").each(function () {
        if (!$("#industry").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $("#industry").change(function () {
        if ($(this).val() != "") {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      setTimeout(function () {
        document
          .querySelector(".second_indiv_step_wrap .error")
          .scrollIntoView({ behavior: "smooth", block: "center" });
        //$('.second_indiv_step_wrap .error').first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        });
      }, 100);

      if (!this.form.mobile.match("^[0-9]*$")) {
        err = true;

        this.$swal({
          position: "center",
          icon: "error",
          title: "Only number allow for mobile number",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      if (this.form.skill == this.other_category_id) {
        if (this.form.specified_company_industry) {
          err = false;
        } else {
          err = true;
          this.$swal({
            position: "center",
            icon: "error",
            title: "Specify industry is required",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
      if (
        !this.validateMonthlyQREmail() &&
        this.form.generate_monthly_qr == "yes" &&
        err == false
      ) {
        err = true;
        this.error_message = "Please fix the errors in the Monthly QR Email fields.";
        this.showAlert();
      }
      if (err == false) {
        $("#overview").focusin(function () {
          $("#overview-warning-message").hide();
          $("#overview").removeClass("invalid");
        });
        $("#overview").focusout(function () {
          if ($("#overview").val() === "") {
            $("#overview").addClass("invalid");
            $("#overview-warning-message").show();
          } else {
            $("#overview").removeClass("invalid");
            $("#overview-warning-message").hide();
          }
        });
        window.scrollTo(0, 0);
        console.log(
          this.form.staffName,
          this.form.staffName != "" && this.form.staffName != null
            ? this.form.staffName.id
            : this.form.staffNamePrev,
          this.form.staffNamePrev
        );
        this.$store
          .dispatch(POST_API, {
            data: {
              first_name: this.form.first_name,
              last_name: this.form.last_name,
              full_name: this.form.full_name,

              gender: this.form.gender,
              mobile_number: this.form.mobile,
              var_code: this.form.var_code,
              email: this.form.email,
              company_name: this.form.company_name,
              mobile_number: this.form.mobile,
              office_address: this.form.office_address,
              company_reg_no: this.form.company_reg_no,
              emp_daily_paid_status: this.form.emp_daily_paid_status,
              overview: this.form.overview,
              closed_by:
                this.form.staffName != "" && this.form.staffName != null
                  ? this.form.staffName.id
                    ? this.form.staffName.id
                    : this.form.staffName[0].id
                  : "",
              profile_image: this.form.selectedImage.image,
              banner_image: this.form.selectedBannerImage.image,
              acra: this.form.selectedAcra.image,
              items: this.form,
              website: this.link.website,
              fb: this.link.fb,
              google: this.link.google,
              link: this.link.link,
              twitter: this.link.twitter,
              user_id: this.form.user_id,
              company_id: this.form.company_id,
              short_description: this.form.sortdesc,
              ft_account_status: this.status,
              certificate: this.form.certificate,
              images: this.images,
              mobile_verified: this.form.mobile_verify,
              email_verified: this.form.email_verify,
              created_at: this.form.created_at,
              non_cpf_service_fee_percent: this.form.non_cpf_service_fee_percent,
              on_demand_charges: this.form.on_demand_charges,
              signup_days_completion_for_employer_promotion:
                this.form.signup_days_completion_for_employer_promotion,
              signup_free_post: this.form.signup_free_post,
              signup_free_post_duration: this.form.signup_free_post_duration,
              due_days: this.form.due_days,
              payment_terms: this.form.payment_terms,

              min_late_minutes_for_fl: this.form.min_late_minutes_for_fl,
              specified_company_industry:
                this.form.skill == this.other_category_id
                  ? this.form.specified_company_industry
                  : null,
              skill: this.form.skill,
              receive_whatsapp_update: this.form.receive_whatsapp_update,
              send_notification: this.form.send_notification,
              gps_check_enabled: this.form.gps_check_enabled,
              exempt_from_auto_pay_enabled: this.form.exempt_from_auto_pay_enabled,
              send_invoice: this.form.send_invoice,
              qr_based_arrival: this.form.qr_based_arrival,
              approval_require: this.form.approval_require,

              show_applied_and_worked_before_logo: this.form.show_applied_and_worked_before_logo,
              is_prepaid_employer: this.form.is_prepaid_employer,
              gst_on_platform_fee: this.form.gst_on_platform_fee,
              cashback_amount: this.form.cashback_amount,
              cashback_percent: this.form.cashback_percent,
              receive_auto_sr: this.form.receive_auto_sr,
              full_vacancy_scan_in_prompt: this.form.full_vacancy_scan_in_prompt,
              show_job_list_rate: this.form.show_job_list_rate,
              sr_to_assigned: this.form.sr_to_assigned,
              sr_to_company_admin: this.form.sr_to_company_admin,
              receive_email_from_awarded_fl: this.form.receive_email_from_awarded_fl,
              receive_email_from_awarded_fl_day: this.form.receive_email_from_awarded_fl_day,
              receive_email_from_awarded_fl_hrs:
                this.form.receive_email_from_awarded_fl == "yes"
                  ? this.form.receive_email_from_awarded_fl_hrs
                  : 0,
              receiving_email:
                this.form.receive_email_from_awarded_fl == "yes" ? this.form.receiving_email : "",
              receiver_name:
                this.form.receive_email_from_awarded_fl == "yes" ? this.form.receiver_name : "",
              send_whatsapp_msg_to_first_time_fl: this.form.send_whatsapp_msg_to_first_time_fl,
              whatsapp_msg_to_first_time_fl:
                this.form.send_whatsapp_msg_to_first_time_fl == "yes"
                  ? this.form.whatsapp_msg_to_first_time_fl
                  : "",
              charge_type: this.form.charge_type,
              fixed_rate: this.form.fixed_rate,
              holiday_fee: this.form.holiday_fee,
              rate_for_on_demand: this.form.rate_for_on_demand,
              ot_fee: this.form.ot_fee,
              extension_fee: this.form.extension_fee,

              auto_award_fl: this.form.auto_award_fl,
              generate_monthly_qr: this.form.generate_monthly_qr,
              generate_monthly_qr_date: this.form.generate_monthly_qr_date,
              monthlyQREmailArr: this.form.monthlyQREmailArr,

              auto_award_min_completed_jobs: this.form.auto_award_min_completed_jobs,
              attendance_rate_for_auto_award: this.form.attendance_rate_for_auto_award,

              billing_cycle: this.form.billing_cycle,
              bill_break_hrs: this.form.bill_break_hrs,
              has_department: this.form.has_department,
              billing_week_day: this.form.billing_week_day,
              enable_email_for_job_assign: this.form.enable_email_for_job_assign,
              exempted: this.form.exempted,
              enable_multiple_shift: this.form.enable_multiple_shift,
              whatsapp_update_user: this.form.whatsapp_update_user,
              updated_by: this.form.admin_id,
            },
            api: "/api/update-profile-emp-admin",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.$swal({
                position: "center",
                icon: "error",
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500,
              });
              if (
                this.error_message == "UEN Already in use! Please create support ticket for help"
              ) {
                $(".uen").addClass("error");
                setTimeout(function () {
                  //$('.second_indiv_step_wrap .error').first().focus();
                  document
                    .querySelector(".second_indiv_step_wrap .error")
                    .scrollIntoView({ behavior: "smooth", block: "center" });
                  $(".error").unbind("keypress");
                  $(".error").bind("keypress", function () {
                    $(this).removeClass("error");
                    $(this)
                      .parent()
                      .parent()
                      .find(".error_x_white")
                      .removeClass("show")
                      .parent()
                      .find(".error_submit_field")
                      .removeClass("bind_error_class");
                  });
                }, 100);
                $(".error_x_white_uen").show();

                //$(this).parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                //$(this).parent().parent().find('.error_x_white_uen').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
              } else {
                $(".error_x_white_uen").hide();
              }
            } else {
              var userData = this.$store.getters.currentUser;

              // userData.ability = [{
              //     action: "manage", subject: "all"
              // }];

              // localStorage.setItem('userData', JSON.stringify(userData))
              // this.$ability.update(userData.ability)

              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Profile Updated successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                //  this.personal = false;
                // this.links = true;
                // this.disabled = false;
                window.location.replace("/v2/admin/employer");
                // window.scrollTo(0,0);
              });
            }
          });
      }
    },
    storeAccounts() {
      this.$store
        .dispatch(POST_API, {
          data: {
            website: this.link.website,
            fb: this.link.fb,
            google: this.link.google,
            link: this.link.link,
            twitter: this.link.twitter,
            user_id: this.form.user_id,
          },

          api: "/api/storeAccounts",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showDismissibleAlert = true;
            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            var userData = this.$store.getters.currentUser;

            // userData.ability = [{
            //     action: "manage", subject: "all"
            // }];

            // localStorage.setItem('userData', JSON.stringify(userData))
            // this.$ability.update(userData.ability)

            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Step 2 completed successfully.",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {});
          }
        });
    },
    //     onprofileChange(event) {
    //     var input = event.target;
    //     if (input.files && input.files[0]) {
    //         var reader = new FileReader();
    //         reader.readAsDataURL(input.files[0]);
    //         this.form.profile_image = input.files[0];

    //         if (this.form.profile_image.type != 'image/png' && this.form.profile_image.type != 'image/jpeg' && this.form.profile_image.type != 'image/jpg') {
    //             this.form.selectedImage = this.webUrl + '/images/default-user-icon.svg'
    //             this.error_message = 'Please select only .png,.jpg or .jpeg image.';
    //             this.selectedImage = '';
    //             this.showAlert();
    //         } else if (this.form.profile_image.size > 1048576) {

    //             this.form.selectedImage = this.webUrl + '/images/default-user-icon.svg'
    //             this.error_message = 'Profile image should be less than 1 MB.';
    //             this.form.selectedImage = '';
    //             this.showAlert();
    //         } else {
    //             reader.onload = (e) => {
    //                 this.modelBox = 'logo';
    //                 this.form.selectedImage.image = e.target.result;
    //                 this.form.selectedImage.name = this.form.profile_image.name;
    //                 $('#profile').empty().append(this.form.selectedImage.name)

    //             }
    //         }
    //     }
    // },
    getSettingData() {
      this.$store
        .dispatch(POST_API, {
          api: "/api/setting-cpf-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.settings = this.$store.getters.getResults.data;
            this.settings.forEach((element) => {
              if (element.option_key == "last_24_hours_on_demand_fee_percent") {
                this.form.on_demand_charges = element.option_value;
              }
              if (element.option_key == "non_cpf_service_fee_percent") {
                this.form.non_cpf_service_fee_percent = element.option_value;
              }
              if (element.option_key == "signup_days_completion_for_employer_promotion") {
                this.form.signup_days_completion_for_employer_promotion = element.option_value;
              }
              if (
                element.option_key ==
                "min_of_working_hours_for_cancellation_time_of_in_progress_job_when_no_pt_user_arrived"
              ) {
                this.form.min_late_minutes_for_fl = element.option_value;
              }
              if (element.option_key == "default_attendance_for_auto_award") {
                this.form.attendance_rate_for_auto_award = element.option_value;
              }
            });
          }
        });
    },
    getEditData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.form.user_id,
          },
          api: "/api/employee-profile-edit-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.exemptedCompanies = this.$store.getters.getResults.exemptedCompanies;
            if (this.$store.getters.getResults.data) {
              if (this.$store.getters.getResults.user["mobile_number"] == "12345678") {
                this.form.mobile = "";
              } else {
                this.form.mobile = this.$store.getters.getResults.user["mobile_number"];
                this.form.verify_otp_button = this.$store.getters.getResults.user["mobile_number"]
                  ? (this.form.verify_otp_button = true)
                  : (this.form.verify_otp_button = false);
              }

              if (this.$store.getters.getResults.data.contact_number == "12345678") {
                this.form.company_contact_number = "";
              } else {
                this.form.company_contact_number =
                  this.$store.getters.getResults.data.contact_number;
              }

              this.form.email = this.$store.getters.getResults.user["email"];
              if (this.$store.getters.getResults.data.name == "test.co") {
                this.form.company_name = "";
              } else {
                this.form.company_name = this.$store.getters.getResults.data.name;
              }
              this.form.company_reg_no = this.$store.getters.getResults.data.registration_id;
              this.form.min_late_minutes_for_fl =
                this.$store.getters.getResults.data.min_late_minutes_for_fl;

              this.form.overview = this.$store.getters.getResults.data.description;
              this.form.staffName = this.$store.getters.getResults.data.closed_by;
              this.form.staffNamePrev = this.$store.getters.getResults.data.closed_by;

              this.form.office_address = this.$store.getters.getResults.data.address;
              this.form.selectedImage.image = this.$store.getters.getResults.data.company_logo;
              this.form.selectedAcra.image = this.$store.getters.getResults.data.acra;
              this.form.due_days = this.$store.getters.getResults.data.due_days;
              this.form.payment_terms = this.$store.getters.getResults.data.payment_terms;
              this.form.is_emp_service_agreement =
                this.$store.getters.getResults.is_emp_service_agreement;
              this.form.show_file_name_sa = this.form.is_emp_service_agreement ? true : false;

              this.form.selectedBannerImage.image =
                this.$store.getters.getResults.user["banner_image"];
              this.form.banner_image =
                this.$store.getters.getResults.user["banner_image"] != null
                  ? this.$store.getters.getResults.user["banner_image"]
                  : "";

              if (this.$store.getters.getResults.user["first_name"] == "Demo") {
                this.form.first_name = "";
              } else {
                this.form.first_name = this.$store.getters.getResults.user["first_name"];
              }
              if (this.$store.getters.getResults.user["last_name"] == "Demo") {
                this.form.last_name = "";
              } else {
                this.form.last_name = this.$store.getters.getResults.user["last_name"];
              }

              this.form.full_name = this.$store.getters.getResults.user["full_name"];

              // this.form.last_name = this.$store.getters.getResults.user['last_name'];
              this.form.country_code = this.$store.getters.getResults.user["country_code"];
              this.form.gender = this.$store.getters.getResults.user["gender"]
                ? this.$store.getters.getResults.user["gender"]
                : "";

              this.form.user_type_id_val =
                this.$store.getters.getResults.user["user_type_id"] == "3"
                  ? "Company Admin"
                  : "Company";
              this.form.email_verify = this.$store.getters.getResults.user["email_verified"];
              this.form.mobile_verify = this.$store.getters.getResults.user["mobile_verified"];
              this.form.sortdesc = this.$store.getters.getResults.user["short_description"];
              this.form.created_at = this.$store.getters.getResults.user["created_at"];
              this.form.on_demand_charges =
                this.$store.getters.getResults.user["on_demand_charges"];
              this.form.non_cpf_service_fee_percent =
                this.$store.getters.getResults.user["non_cpf_service_fee_percent"];
              this.form.signup_days_completion_for_employer_promotion =
                this.$store.getters.getResults.user[
                  "signup_days_completion_for_employer_promotion"
                ];
              (this.form.receive_whatsapp_update =
                this.$store.getters.getResults.user.receive_whatsapp_update),
                (this.form.send_notification =
                  this.$store.getters.getResults.user.send_notification);
              this.form.gps_check_enabled = this.$store.getters.getResults.user.gps_check_enabled;
              // this.form.exempt_from_auto_pay_enabled = this.exemptedCompanies ? this.exemptedCompanies.indexOf((this.$store.getters.getResults.data.id).toString()) != -1 ? 'yes' : 'no' : 'no';
              this.form.exempt_from_auto_pay_enabled =
                this.$store.getters.getResults.exempt_from_auto_pay_enabled;
              this.form.signup_free_post = this.$store.getters.getResults.user["signup_free_post"];
              this.form.signup_free_post_duration =
                this.$store.getters.getResults.user["signup_free_post_duration"];

              this.form.send_invoice = this.$store.getters.getResults.user.send_invoice;
              this.form.qr_based_arrival = this.$store.getters.getResults.user.qr_based_arrival;
              this.form.approval_require = this.$store.getters.getResults.user.require_job_approval;

              this.form.show_applied_and_worked_before_logo =
                this.$store.getters.getResults.data.show_applied_and_worked_before_logo;
              this.form.is_prepaid_employer =
                this.$store.getters.getResults.user.is_prepaid_employer;
              this.form.receive_auto_sr = this.$store.getters.getResults.user.receive_auto_sr;
              this.form.gst_on_platform_fee =
                this.$store.getters.getResults.user.gst_on_platform_fee;
              this.form.cashback_amount = this.$store.getters.getResults.user.cashback_amount;
              this.form.cashback_percent = this.$store.getters.getResults.user.cashback_percent;

              this.form.sr_to_assigned = this.$store.getters.getResults.user.sr_to_assigned;
              this.form.sr_to_company_admin =
                this.$store.getters.getResults.user.sr_to_company_admin;

              this.form.receive_email_from_awarded_fl =
                this.$store.getters.getResults.data.receive_email_from_awarded_fl;
              this.form.receive_email_from_awarded_fl_day =
                this.$store.getters.getResults.data.receive_email_from_awarded_fl_day;

              this.form.auto_award_fl = this.$store.getters.getResults.user.auto_award_fl;
              this.form.generate_monthly_qr =
                this.$store.getters.getResults.data.generate_monthly_qr;
              this.form.generate_monthly_qr_date =
                this.$store.getters.getResults.data.generate_monthly_qr_date;

              const addressString = this.$store.getters.getResults.data.generate_monthly_qr_email;
              const monthlyQREmailArray = JSON.parse(addressString);
              this.form.monthlyQREmailArr = monthlyQREmailArray
                ? monthlyQREmailArray.map((monthlyQREmail) => ({ monthlyQREmail }))
                : [{ monthlyQREmail: "" }];
              this.monthlyQREmailErrors = Array(this.form.monthlyQREmailArr.length).fill("");

              this.form.full_vacancy_scan_in_prompt =
                this.$store.getters.getResults.data.full_vacancy_scan_in_prompt;
              this.form.show_job_list_rate = this.$store.getters.getResults.data.show_job_list_rate;

              this.form.auto_award_min_completed_jobs =
                this.$store.getters.getResults.user.auto_award_min_completed_jobs;
              this.form.attendance_rate_for_auto_award = this.$store.getters.getResults.user[
                "attendance_rate_for_auto_award"
              ]
                ? this.$store.getters.getResults.user["attendance_rate_for_auto_award"]
                : this.form.attendance_rate_for_auto_award;

              // this.form.receive_email_from_awarded_fl_hrs = (this.$store.getters.getResults.data.receive_email_from_awarded_fl_hrs) == '0' ? '' : (this.$store.getters.getResults.data.receive_email_from_awarded_fl_hrs);
              var receive_email_from_awarded_fl_hrs =
                this.$store.getters.getResults.data.receive_email_from_awarded_fl_hrs;

              if (receive_email_from_awarded_fl_hrs) {
                var startArray = receive_email_from_awarded_fl_hrs.split(":");

                this.form.receive_email_from_awarded_fl_hrs.hh =
                  startArray[0] > 12 ? startArray[0] - 12 : startArray[0];

                if (this.form.receive_email_from_awarded_fl_hrs.hh.length) {
                  this.form.receive_email_from_awarded_fl_hrs.hh.length != 2
                    ? (this.form.receive_email_from_awarded_fl_hrs.hh =
                        "0" + this.form.receive_email_from_awarded_fl_hrs.hh)
                    : this.form.receive_email_from_awarded_fl_hrs.hh;
                } else {
                  this.form.receive_email_from_awarded_fl_hrs.hh < 10
                    ? (this.form.receive_email_from_awarded_fl_hrs.hh =
                        "0" + this.form.receive_email_from_awarded_fl_hrs.hh)
                    : this.form.receive_email_from_awarded_fl_hrs.hh;
                }
                this.form.receive_email_from_awarded_fl_hrs.mm = startArray[1]
                  ? startArray[1]
                  : "00";
                this.form.receive_email_from_awarded_fl_hrs.A = startArray[0] >= 12 ? "PM" : "AM";
              } else {
                (this.form.receive_email_from_awarded_fl_hrs.hh = "00"),
                  (this.form.receive_email_from_awarded_fl_hrs.mm = "00");
                this.form.receive_email_from_awarded_fl_hrs.A = "AM";
              }
              this.form.receiving_email =
                this.$store.getters.getResults.data.receiving_email == ""
                  ? ""
                  : this.$store.getters.getResults.data.receiving_email;
              this.form.receiver_name =
                this.$store.getters.getResults.data.receiver_name == ""
                  ? ""
                  : this.$store.getters.getResults.data.receiver_name;
              this.form.send_whatsapp_msg_to_first_time_fl =
                this.$store.getters.getResults.data.send_whatsapp_msg_to_first_time_fl;
              this.form.whatsapp_msg_to_first_time_fl =
                this.$store.getters.getResults.data.whatsapp_msg_to_first_time_fl == ""
                  ? ""
                  : this.$store.getters.getResults.data.whatsapp_msg_to_first_time_fl;
              this.form.charge_type = this.$store.getters.getResults.user.charge_type;
              this.form.fixed_rate =
                this.$store.getters.getResults.user.charge_type == "fixed_rate"
                  ? this.$store.getters.getResults.user.fixed_rate
                  : "";
              this.form.holiday_fee =
                this.$store.getters.getResults.user.charge_type == "fixed_rate"
                  ? this.$store.getters.getResults.user.holiday_fee
                  : "";
              this.form.rate_for_on_demand =
                this.$store.getters.getResults.user.charge_type == "fixed_rate"
                  ? this.$store.getters.getResults.user.rate_for_on_demand
                  : "";
              this.form.ot_fee =
                this.$store.getters.getResults.user.charge_type == "fixed_rate"
                  ? this.$store.getters.getResults.user.ot_fee
                  : "";
              this.form.extension_fee =
                this.$store.getters.getResults.user.charge_type == "fixed_rate"
                  ? this.$store.getters.getResults.user.extension_fee
                  : "";

              this.form.billing_cycle = this.$store.getters.getResults.user.billing_cycle;
              this.form.billing_week_day = this.$store.getters.getResults.user.billing_week_day;
              (this.form.bill_break_hrs = this.$store.getters.getResults.user.bill_break_hrs),
                (this.form.has_department = this.$store.getters.getResults.data.has_department),
                (this.form.enable_email_for_job_assign =
                  this.$store.getters.getResults.user.enable_email_for_job_assign);

              this.form.exempted = this.$store.getters.getResults.user.exempted;
              this.form.enable_multiple_shift =
                this.$store.getters.getResults.user.enable_multiple_shift;
              this.form.whatsapp_update_user =
                this.$store.getters.getResults.user.whatsapp_update_user;
              this.form.mobile_verified_check =
                this.$store.getters.getResults.user["mobile_verified"] == "yes"
                  ? (this.form.mobile_verified_check = true)
                  : (this.form.mobile_verified_check = false);
              this.form.skill = this.$store.getters.getResults.data.company_industry_id;
              this.form.company_id = this.$store.getters.getResults.data.id;
              this.acra_detail = this.$store.getters.getResults.acra_detail;
              this.showACRA =
                this.$store.getters.getResults.data.acra != "" &&
                this.$store.getters.getResults.data.acra != null
                  ? true
                  : false;
              this.acraUrl =
                this.$store.getters.getResults.data.acra != "" &&
                this.$store.getters.getResults.data.acra != null
                  ? this.$store.getters.getResults.data.acra
                  : "";
              this.form.acra = this.acraUrl;
              // this.form.apply_for_dp = this.$store.getters.getResults.user['account_verification_status'] == 'completed' ? this.form.apply_for_dp = true : this.form.apply_for_dp = false;
              this.form.apply_for_dp =
                this.$store.getters.getResults.user["account_verification_status"] == "completed"
                  ? (this.form.apply_for_dp = false)
                  : (this.form.apply_for_dp = false);

              this.form.pending_msg =
                this.$store.getters.getResults.user["account_verification_status"] == "pending"
                  ? (this.form.pending_msg = true)
                  : (this.form.pending_msg = false);

              // this.form.apply_for_dp = this.$store.getters.getResults.user['emp_daily_paid_status'] == 'yes' ? this.form.apply_for_dp = true : this.form.apply_for_dp = false;
              this.form.emp_daily_paid_status =
                this.$store.getters.getResults.user["emp_daily_paid_status"];
              this.status = this.$store.getters.getResults.user["ft_account_status"];
              this.account_verification_status =
                this.$store.getters.getResults.user["account_verification_status"];

              this.form.specified_company_industry =
                this.$store.getters.getResults.data.specified_company_industry;
              this.getDocuments();
              this.getClosedByData(this.form.staffName);

              if (this.form.skill == this.other_category_id) {
                $(".other_category_class").css("display", "block");
              } else {
                $(".other_category_class").css("display", "none");
              }

              // if(this.form.email_verify == 'yes'){
              //     $('#email').attr('disabled', 'disabled');
              // }else{
              //     $('#email').removeAttr('disabled');
              // }

              // if(this.form.mobile_verify == 'yes'){
              //     $('#contact').attr('disabled', 'disabled');
              // }else{
              //     $('#contact').removeAttr('disabled');
              // }
            } else {
              this.form.mobile = "";
              this.form.email = "";
            }
          }
          if (this.form.is_emp_service_agreement) {
            this.getServiceAgreementFileName();
          }
        });
    },
    getSocialData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.form.user_id,
          },
          api: "/api/employee-social",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              var link = this.link;

              this.$store.getters.getResults.data.forEach(function (accounts) {
                if (accounts.account_type == "website") {
                  link.website = accounts.url;
                }

                if (accounts.account_type == "fb") {
                  link.fb = accounts.url;
                }
                if (accounts.account_type == "google") {
                  link.google = accounts.url;
                }
                if (accounts.account_type == "link") {
                  link.link = accounts.url;
                }
                if (accounts.account_type == "twitter") {
                  link.twitter = accounts.url;
                }

                // code
              });

              // this.form.mobile = this.$store.getters.getResults.data.contact_number;
              // this.form.email = this.$store.getters.getResults.data.email;
              // this.form.company_name = this.$store.getters.getResults.data.name;
              // this.form.company_reg_no = this.$store.getters.getResults.data.registration_id;
              // this.form.overview = this.$store.getters.getResults.data.description;
            } else {
              // this.form.mobile = '';
              // this.form.email = '';
            }
          }
        });
    },
    getClosedByData(closed_by) {
      this.$store
        .dispatch(POST_API, {
          data: {
            closed_by: closed_by,
          },
          api: "/api/update-sales-closed-by-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.form.staffName = this.$store.getters.getResults.data;
          }
        });
    },
    getDocuments() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.form.company_id,
          },
          api: "/api/employee-document",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.docs = this.$store.getters.getResults.data;
          }
        });
    },
    emailVerify() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            email: this.form.email,
            user_id: this.form.user_id,
          },
          api: "/api/sendEmailVerify",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Verification mail sent successfully.",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              // window.location.reload();
            });
          }
        });
    },
    allStaffList() {
      return this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/update-sales-all-staff-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.allStaff = this.$store.getters.getResults.data;
            // return this.selectedSupervisor;
          }
        });
    },
    mobileVerify() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            verification_code: this.form.var_code,
            user_id: this.form.user_id,
          },
          api: "/api/verifyMobile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            // return [];
          } else {
            this.form.mobile_verified_check = true;
            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Mobile Number verified successfully.",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              this.form.mobile_verify == "yes";
              this.hideVerifyMobileModel();
              // if(this.form.mobile_verify == 'yes'){
              $("#contact").attr("disabled", "disabled");
              // }else{
              //     $('#contact').removeAttr('disabled');
              // }
              // window.location.reload();
            });
          }
        });
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Billing Address");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(".error_x_white_uen").hide();
    this.form.admin_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.getDropdownData("service_categories");
    this.getSettingData();
    this.allStaffList();
    this.getEditData();
    this.getSocialData();
    this.getBillingAddress();
    //this.getDocuments();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    this.permissionStaff();
  },
};
</script>
