<template>
  <div>
    <!-- <div class="container full_container_dsk new_full_container_dsk">
            <div class="row mobile_email_row custom_dsk_row"> -->
    <div class="row">
      <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <!-- <div class="dashboard-headline margin_bottom_40 mobile_margin_bottom_20"> -->
        <div class="dashboard-headline">
          <h1>Rewards</h1>
          <!-- <b-link class="btn green-border-btn ml-2 text-white" @click="exportPdf">Export PDF</b-link>
                    <b-link :to="{}" class="btn green-border-btn ml-2 text-white" @click="exportData">Export CSV</b-link> -->

          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark desktop_margin_top_zero">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Rewards</li>
            </ul>
          </nav>
        </div>
        <!-- <div class="col-xl-12"> -->
        <div class="row">
          <div
            class="search_common_margin_bottom_28 col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job submit-field mobile_margin_bottom_18"
          >
            <div class="search-box custom_search_admin desktop_margin_top_zero">
              <!-- <b-input-group> -->
              <b-input-group-prepend>
                <b-form-input
                  id="searchBtn"
                  @keyup.enter.native="filter = searchKeyword"
                  v-model="searchKeyword"
                  placeholder="Search by keyword"
                ></b-form-input>
                <b-button class="btn points_events"
                  ><i class="fa fa-search" @click="filter = searchKeyword"></i
                ></b-button>
              </b-input-group-prepend>
              <!-- </b-input-group> -->
            </div>
          </div>
          <div
            class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28"
          >
            <!-- <span>DP Status:</span> -->
            <b-form-select
              v-model="status"
              @change="changeStatus"
              class="mb-0 common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset"
            >
              <option value="all">Status</option>
              <option :value="status" v-for="status in allStatus">
                {{ status | capitalize }}
              </option>
            </b-form-select>
          </div>
          <!-- <div class="col-xl-5 col-md-5">
                    </div> -->
          <div
            class="search_common_margin_bottom_28 double_btn col-xl-4 col-md-4 submit-field service_report_dual_btn mobile_tab_max_width_flex"
          >
            <b-link
              class="common_export_anchor button btn green-border-btn ml-2 text-white desktop_margin_top_zero desktop_margin_left_zero"
              :to="{ name: 'emp-reward-enquiry' }"
              v-if="showPermissionEM('new reward enquiry')"
              >New Reward Enquiry</b-link
            >
          </div>
        </div>
        <!-- </div>                    -->
      </div>
    </div>
    <!-- Page Content
        ================================================== -->
    <!-- <div class="container full_container_dsk margin_top_25_mobile new_full_container_dsk">
           <div class="row"> -->
    <div class="row">
      <div class="container my_new_container show_filter_arrow">
        <b-table
          ref="datatable"
          show-empty
          striped
          hover
          responsive
          :items="getReward"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          class="new_layout_table_like_v1"
        >
          <template #cell(title)="items">
            {{ items.item.title | capitalize }}
          </template>
          <template #cell(start_date)="items">
            <p v-if="items.item.end_date">
              {{ items.item.start_date | moment }} -
              {{ items.item.end_date | moment }}
            </p>
            <p v-else>
              {{ items.item.start_date | moment }}
            </p>
          </template>
          <template #cell(status)="items">
            <p
              class="red_status"
              v-if="
                items.item.status == 'active' && items.item.end_date < today
              "
            >
              {{ "Expired" }}
            </p>
            <p
              class="green_status"
              v-else-if="
                items.item.status == 'active' &&
                (items.item.end_date >= today || !items.item.end_date)
              "
            >
              {{ items.item.status | capitalize }}
            </p>
            <p
              class="orange__status"
              v-else-if="items.item.status == 'inactive'"
            >
              {{ items.item.status | capitalize }}
            </p>
          </template>
          <template #cell(banner_image)="items">
            <img
              :src="
                items.item.banner_image != null
                  ? items.item.banner_image
                  : webUrl + 'assets/images/logo.png'
              "
              height="100px"
              width="100px"
              alt="logo"
            />
          </template>
          <template #cell(toggleAction)="items">
            <div class="toggle-action toggle_margin_unset">
              <b-dropdown class="mx-1" right text="Action" boundary="window">
                <b-dropdown-item
                  v-if="showPermissionEM('view reward detail')"
                  :to="{
                    name: 'emp-reward-view',
                    params: { id: encrypt(items.item.id) },
                  }"
                  >View</b-dropdown-item
                >
                <!-- <b-dropdown-item
                  @click="
                    shareReward(
                      webUrl + 'reward-detail/' + encrypt(items.item.id)
                    )
                  "
                  v-if="
                    showPermissionEM('share reward') &&
                    (!items.item.end_date || items.item.end_date >= today)
                  "
                  >Share reward</b-dropdown-item
                > -->
                <!-- <b-dropdown-item> -->
                  <li>
                    <a class="dropdown-item custom_dropdown_reward_share">
                      <i class="material-icons-outlined new_icon_sidebar_material not_img" @click="showHideSocialShareIcon()">share_outlined</i>
                      <!-- <div class="share-buttons-trigger">
                        <i class="icon-feather-share-2"></i>
                      </div> -->
                      <div class="share-buttons-content custom_share_button_content">
                        <!-- <span>Interesting? <strong>Share It!</strong></span> -->
                        <ul class="share-buttons-icons custom_share_icon_rewards">
                          <li style="">
                            <ShareNetwork
                              network="facebook"
                              quote="AwesomeArticle"
                              title="Please find the shared reward by using the given url"
                              :url="
                                webUrl + 'reward-detail/' + encrypt(items.item.id)
                              "
                              hashtags="Rewards, SharedRewards"
                            >
                            </ShareNetwork>
                          </li>
                          <li>
                            <ShareNetwork
                              network="twitter"
                              :url="
                                webUrl + 'reward-detail/' + encrypt(items.item.id)
                              "
                              title="Please find the shared reward by using the given url"
                              hashtags="Rewards, SharedRewards"
                            >
                            </ShareNetwork>
                          </li>
                          <li>
                            <ShareNetwork
                              network="linkedin"
                              :url="
                                webUrl + 'reward-detail/' + encrypt(items.item.id)
                              "
                              title="Please find the shared reward by using the given url"
                              hashtags="Rewards, SharedRewards"
                            >
                            </ShareNetwork>
                          </li>
                        </ul>
                      </div>
                    </a>
                  </li>
                <!-- </b-dropdown-item> -->
              </b-dropdown>
            </div>
          </template>
          <template #empty="scope">
            <p class="text-center">No Rewards Listed Yet</p>
            <p class="text-center">
              Only rewards that successfully listed on our app will appear here.
              Submit a new enquiry to get started!
            </p>
          </template>
        </b-table>
        <nav class="pagination-box custom_pagination_border_radius">
          <div class="page-row-box d-inline-block">
            <b-form-group id="input-group-4">
              <span class="d-inline-block">Rows per page</span>
              <span class="d-inline-block"
                ><b-form-select v-model="form.rowsPerPage" @change="getReward">
                  <option>25</option>
                  <option>50</option>
                  <option>75</option>
                  <option>100</option>
                </b-form-select></span
              >
            </b-form-group>
          </div>
          <div class="total-page-count d-inline-block">
            <ul>
              <li>
                <span v-if="totalRows != 0"
                  >{{ from }} - {{ to }} of {{ totalRows }}</span
                >
              </li>
            </ul>
          </div>
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            prev-text=""
            next-text=""
            hide-goto-end-buttons
          />
        </nav>
      </div>
    </div>
    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";
import moment from "moment";
import permission from "../../../../../server/permission.js";
import Bus from "../../../event-bus";

export default {
  data() {
    return {
      fields: [
        {
          key: "title",
          label: "Title",
          sortable: true,
          thStyle: { width: "25%" },
        },
        {
          key: "start_date",
          label: "Validity Period",
          sortable: true,
          thStyle: { width: "35%" },
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          thStyle: { width: "15%" },
        },
        {
          key: "banner_image",
          label: "Poster",
          sortable: true,
          thStyle: { width: "25%" },
        },
        { key: "toggleAction", label: "Actions", thStyle: { width: "25%" } },
      ],
      pager: {},
      pageOfItems: [],
      items: null,
      totalRows: 0,
      from: "",
      to: "",
      searchKeyword: "",
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      sortBy: null,
      sortDirection: "desc",
      filter: "",
      status: "all",
      sortDesc: true,
      webUrl: process.env.VUE_APP_URL,
      base_url: process.env.VUE_APP_URL_V1,

      form: {
        rowsPerPage: 25,
      },
      permissionActions: "",
      allStatus: ["active", "inactive", "expired"],
      today: moment().format("YYYY-MM-DD"),
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    moment: function (date) {
      if (date) {
        return moment(date).format("DD MMM YYYY");
      } else {
        return "-";
      }
    },
  },
  methods: {
    showHideSocialShareIcon()
    {
      $('.custom_share_icon_rewards').toggleClass('show_reward_share_icon');
      $('.custom_share_button_content').toggleClass('after_click_share_button_content');
    },
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        // this.$router.push({ name: 'country-list' });
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        // icon: 'error',
        imageUrl: "/assets/images/404_elcy.gif",
        customClass: {
          container: "mascot_error_container",
        },
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    shareReward(url) {
      console.log(url);
      window.open(url);
    },
    changeStatus() {
      this.getReward().then(() => {
        this.$refs.datatable.refresh();
      });
    },
    getReward() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.filter,
            company_admin_id: this.company_admin_id
              ? this.company_admin_id
              : this.user_id,
            company_id: this.company_id ? this.company_id : null,
            // company_id: null,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "id",
            sortDirection: this.sortDesc ? "desc" : "asc",
            status: this.status,
          },
          api: "/api/emp-reward-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            return this.items;
          }
        });
    },
    permissionEM() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.is_company_head == "no" &&
          (this.$store.getters.currentUser.user_type_id == 3 ||
            this.$store.getters.currentUser.user_type_id == 4)
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.permissionActions = permission.getPermission(menu, "Rewards");
        }
      }
    },
    showPermissionEM(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionActions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Employer - Transactions";
    this.user_id =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.id
        : "";
    this.company_admin_id =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.company_admin_id
        : "";
    this.company_id =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.company_id
        : "";
    this.permissionEM();
    Bus.$emit("active_users");
  },
};
</script>
